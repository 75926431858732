import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import { counties } from '../../../../util/data/counties'
import * as filterValuesUtils from '../../../../util/filterValuesUtils';

export function CountiesFilters(props){

    const handleOnSearch = (string, results) => {
        // onSearch will have as the first callback parameter
        // the string searched and for the second the results.
        console.log(string, results)
    }

    const handleOnHover = (result) => {
        // the item hovered
        console.log(result)
    }

    const handleOnSelect = (item) => {
        let newFilterValues = filterValuesUtils.addCounty(item, props.activeFilterValues);
        props.setActiveFilterValues(newFilterValues);
    }

    const handleOnFocus = () => {
        console.log('Focused')
    }

    const formatCity = (item) => {
        return (
            <>
            <p style={{ display: 'block', textAlign: 'left' }}>{item.county}, {item.state}</p>
            </>
        )
    }

    const removeActiveFilter = (filterValue) => {
        console.log("Remove filter " + filterValue);
        const indexToRemove = props.activeFilterValues.counties.indexOf(filterValue);
        let newFilterValues = JSON.parse(JSON.stringify(props.activeFilterValues));
        newFilterValues.counties.splice(indexToRemove, 1);
        props.setActiveFilterValues(newFilterValues);
      };

    const activeCountyButtons = props.activeFilterValues.counties.map((filterValue) =>
        <button key={filterValue} className = "activeFilterBubble" onClick={() => removeActiveFilter(filterValue)}> {filterValue} </button>
    );

    return(
        <>

        <p>Search for the county name:</p>
        <ReactSearchAutocomplete
                fuseOptions={{ keys: ["county"] }}
                resultStringKeyName="county"
                items={counties}
                onSearch={handleOnSearch}
                onHover={handleOnHover}
                onSelect={handleOnSelect}
                onFocus={handleOnFocus}
                autoFocus
                formatResult={formatCity}
            />

            <ul className='activeSearchFilters'>
                {activeCountyButtons}
            </ul>
        </>
    );
}