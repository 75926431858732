import './details.css';
import locationIcon from '../../img/svg/location-pin-svgrepo-com.svg';
import calendarIcon from '../../img/svg/calendar-svgrepo-com.svg';

function Details() {                    
    return (
        <>    
            <div className="vetsourceDetailsImage"></div>
            <div className="vetsourceDetailsContainer">
                <h1 className='vetsourceTitle'>Job Fair</h1>
                <h2 className='vetsourceType'>Event</h2>
                <div>
                    <h2 className='vetsourceDetailsTextHeader'>Date and time</h2>
                    <div className='iconTextSideBySide'>
                        <img className='squareIcon' src={calendarIcon}  alt=""/>
                        <div>
                        <p className='vetsourceDetailsText'>Saturday, August 12</p>
                        </div>
                    </div>
                    <p className='vetsourceDetailsText'>4:00 PM - 8:00 PM</p>
                </div>
                <h2 className='vetsourceDetailsTextHeader'>Location</h2>
                <div className='iconTextSideBySide'>
                    <img className='squareIcon' src={locationIcon}  alt=""/>
                    <div>
                        <p className='vetsourceDetailsText'>123 E. Somewhere Street</p>
                    </div>
                </div>
                
                <hr/>
                <p className='vetsourceDetailsDescription'>Some description for this event that has a realistic length to it except actually this description is overly long and even though we probably won't allow the description to be so huge it's going to be anyways for testing purposes.</p>
            </div>       
        </>        
    );
  }
  
  export default Details;