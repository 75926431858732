import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import {universities} from '../../../../util/data/university';
import * as filterValuesUtils from '../../../../util/filterValuesUtils';

export function UniversityFilters(props){

  const handleOnSearch = (string, results) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    console.log(string, results)
  }

  const handleOnHover = (result) => {
    // the item hovered
    console.log(result)
  }

  const handleOnSelect = (item) => {
    let newFilterValues = filterValuesUtils.addInstitution(item, props.activeFilterValues);
    props.setActiveFilterValues(newFilterValues);
  }

  const handleOnFocus = () => {
    console.log('Focused')
  }

  const formatResult = (item) => {
    return (
      <>
        <span style={{ display: 'block', textAlign: 'left' }}>{item.institution}</span>
      </>
    )
  }

  const removeActiveFilter = (filterValue) => {
    console.log("Remove filter " + filterValue);
    const indexToRemove = props.activeFilterValues.institutions.indexOf(filterValue);
    let newFilterValues = JSON.parse(JSON.stringify(props.activeFilterValues));
    newFilterValues.institutions.splice(indexToRemove, 1);
    props.setActiveFilterValues(newFilterValues);
  };
//TODO: Says line 48: props.activeFilterValues.universities is undfined. Ask Wyatt how cities from CitiesFilters.js is defined, try to figure it out first. Think I'll have to do removeUniversityFilter() and its button.
  const activeUniversityButtons = props.activeFilterValues.institutions.map((filterValue) =>
  <button key={filterValue} className = "activeFilterBubble" onClick={() => removeActiveFilter(filterValue)}> {filterValue} </button>
  );
    return(
        <>
          <p>Search for any educaton institution name:</p>
          <ReactSearchAutocomplete
              fuseOptions={{keys: ["institution"]}}
              resultStringKeyName="institution"
              items={universities}
              onSearch={handleOnSearch}
              onHover={handleOnHover}
              onSelect={handleOnSelect}
              onFocus={handleOnFocus}
              autoFocus
              formatResult={formatResult}
          />

          <ul className='activeSearchFilters'>
              {activeUniversityButtons}
          </ul>

        </>
    );
}