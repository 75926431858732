export const universities = 
[{"institution":"Alabama A & M University","id":0},
{"institution":"University of Alabama at Birmingham","id":1},
{"institution":"Amridge University","id":2},
{"institution":"University of Alabama in Huntsville","id":3},
{"institution":"Alabama State University","id":4},
{"institution":"University of Alabama System Office","id":5},
{"institution":"The University of Alabama","id":6},
{"institution":"Central Alabama Community College","id":7},
{"institution":"Athens State University","id":8},
{"institution":"Auburn University at Montgomery","id":9},
{"institution":"Auburn University","id":10},
{"institution":"Birmingham Southern College","id":11},
{"institution":"Chattahoochee Valley Community College","id":12},
{"institution":"Concordia College Alabama","id":13},
{"institution":"South University-Montgomery","id":14},
{"institution":"Enterprise State Community College","id":15},
{"institution":"James H Faulkner State Community College","id":16},
{"institution":"Faulkner University","id":17},
{"institution":"Gadsden State Community College","id":18},
{"institution":"New Beginning College of Cosmetology","id":19},
{"institution":"George C Wallace State Community College-Dothan","id":20},
{"institution":"George C Wallace State Community College-Hanceville","id":21},
{"institution":"George C Wallace State Community College-Selma","id":22},
{"institution":"Herzing University-Birmingham","id":23},
{"institution":"Huntingdon College","id":24},
{"institution":"Heritage Christian University","id":25},
{"institution":"J F Drake State Community and Technical College","id":26},
{"institution":"J F Ingram State Technical College","id":27},
{"institution":"Jacksonville State University","id":28},
{"institution":"Jefferson Davis Community College","id":29},
{"institution":"Jefferson State Community College","id":30},
{"institution":"John C Calhoun State Community College","id":31},
{"institution":"Judson College","id":32},
{"institution":"Lawson State Community College-Birmingham Campus","id":33},
{"institution":"University of West Alabama","id":34},
{"institution":"Lurleen B Wallace Community College","id":35},
{"institution":"Marion Military Institute","id":36},
{"institution":"Miles College","id":37},
{"institution":"University of Mobile","id":38},
{"institution":"University of Montevallo","id":39},
{"institution":"Northwest-Shoals Community College","id":40},
{"institution":"University of North Alabama","id":41},
{"institution":"Northeast Alabama Community College","id":42},
{"institution":"Oakwood University","id":43},
{"institution":"Alabama Southern Community College","id":44},
{"institution":"Prince Institute-Southeast","id":45},
{"institution":"Reid State Technical College","id":46},
{"institution":"Bishop State Community College","id":47},
{"institution":"Samford University","id":48},
{"institution":"Selma University","id":49},
{"institution":"Shelton State Community College","id":50},
{"institution":"Snead State Community College","id":51},
{"institution":"University of South Alabama","id":52},
{"institution":"Spring Hill College","id":53},
{"institution":"Southeastern Bible College","id":54},
{"institution":"Stillman College","id":55},
{"institution":"Talladega College","id":56},
{"institution":"H Councill Trenholm State Technical College","id":57},
{"institution":"Troy University","id":58},
{"institution":"Tuskegee University","id":59},
{"institution":"United States Sports Academy","id":60},
{"institution":"Bevill State Community College","id":61},
{"institution":"University of Alaska Anchorage","id":62},
{"institution":"Alaska Bible College","id":63},
{"institution":"University of Alaska Fairbanks","id":64},
{"institution":"University of Alaska Southeast","id":65},
{"institution":"Alaska Pacific University","id":66},
{"institution":"AVTEC-Alaska's Institute of Technology","id":67},
{"institution":"Charter College-Anchorage","id":68},
{"institution":"Prince William Sound Community College","id":69},
{"institution":"Alaska Career College","id":70},
{"institution":"University of Alaska System of Higher Education","id":71},
{"institution":"Everest College-Phoenix","id":72},
{"institution":"Collins College","id":73},
{"institution":"Empire Beauty School-Paradise Valley","id":74},
{"institution":"Empire Beauty School-Tucson","id":75},
{"institution":"Thunderbird School of Global Management","id":76},
{"institution":"American Indian College of the Assemblies of God Inc","id":77},
{"institution":"American Institute of Technology","id":78},
{"institution":"Carrington College-Phoenix","id":79},
{"institution":"Carrington College-Mesa","id":80},
{"institution":"Carrington College-Tucson","id":81},
{"institution":"College America-Flagstaff","id":82},
{"institution":"Arizona Academy of Beauty-East","id":83},
{"institution":"Arizona Automotive Institute","id":84},
{"institution":"Brookline College-Phoenix","id":85},
{"institution":"Arizona State University-Tempe","id":86},
{"institution":"Arizona Western College","id":87},
{"institution":"University of Arizona","id":88},
{"institution":"Southwest University of Visual Arts-Tucson","id":89},
{"institution":"Brillare Hairdressing Academy","id":90},
{"institution":"Central Arizona College","id":91},
{"institution":"Brown Mackie College-Tucson","id":92},
{"institution":"Charles of Italy Beauty College","id":93},
{"institution":"Cochise College","id":94},
{"institution":"Empire Beauty School-Flagstaff","id":95},
{"institution":"Empire Beauty School-Chandler","id":96},
{"institution":"Cortiva Institute-Tucson","id":97},
{"institution":"DeVry University-Arizona","id":98},
{"institution":"Avalon School of Cosmetology-Mesa","id":99},
{"institution":"Eastern Arizona College","id":100},
{"institution":"Embry-Riddle Aeronautical University-Prescott","id":101},
{"institution":"Frank Lloyd Wright School of Architecture","id":102},
{"institution":"Glendale Community College","id":103},
{"institution":"Grand Canyon University","id":104},
{"institution":"Anthem College-Phoenix","id":105},
{"institution":"International Academy of Hair Design","id":106},
{"institution":"Kaplan College-Phoenix","id":107},
{"institution":"Maricopa Community College System Office","id":108},
{"institution":"GateWay Community College","id":109},
{"institution":"Mesa Community College","id":110},
{"institution":"ITT Technical Institute-Tucson","id":111},
{"institution":"ITT Technical Institute-Tempe","id":112},
{"institution":"Mohave Community College","id":113},
{"institution":"Universal Technical Institute of Arizona Inc-Motorcycle Mechanics Institute Division","id":114},
{"institution":"Dine College","id":115},
{"institution":"Northern Arizona University","id":116},
{"institution":"Northland Pioneer College","id":117},
{"institution":"Ottawa University-Phoenix","id":118},
{"institution":"Phoenix College","id":119},
{"institution":"Cortiva Institute-Scottsdale","id":120},
{"institution":"University of Phoenix-Phoenix Campus","id":121},
{"institution":"Pima Community College","id":122},
{"institution":"Pima Medical Institute-Tucson","id":123},
{"institution":"Pima Medical Institute-Albuquerque","id":124},
{"institution":"Prescott College","id":125},
{"institution":"Refrigeration School Inc","id":126},
{"institution":"Rio Salado College","id":127},
{"institution":"Roberto-Venn School of Luthiery","id":128},
{"institution":"Hair Academy of Safford","id":129},
{"institution":"Scottsdale Community College","id":130},
{"institution":"South Mountain Community College","id":131},
{"institution":"Arizona Christian University","id":132},
{"institution":"Tucson College","id":133},
{"institution":"Universal Technical Institute of Arizona Inc","id":134},
{"institution":"Western International University","id":135},
{"institution":"Empire Beauty School-NW Phoenix","id":136},
{"institution":"Yavapai College","id":137},
{"institution":"University of Arkansas at Little Rock","id":138},
{"institution":"University of Arkansas for Medical Sciences","id":139},
{"institution":"ABC Beauty College Inc","id":140},
{"institution":"Arkansas Baptist College","id":141},
{"institution":"Arkansas Beauty School","id":142},
{"institution":"Arkansas Beauty College","id":143},
{"institution":"Lyon College","id":144},
{"institution":"Arkansas College of Barbering and Hair Design","id":145},
{"institution":"Arthur's Beauty College Inc-Fort Smith","id":146},
{"institution":"University of Arkansas","id":147},
{"institution":"University of Arkansas at Pine Bluff","id":148},
{"institution":"Arkansas State University-Beebe","id":149},
{"institution":"Arkansas State University-Main Campus","id":150},
{"institution":"Arkansas Tech University","id":151},
{"institution":"University of Arkansas at Monticello","id":152},
{"institution":"Arthur's Beauty College Inc-Jacksonville","id":153},
{"institution":"Baptist Health Schools-Little Rock","id":154},
{"institution":"Black River Technical College","id":155},
{"institution":"University of Central Arkansas","id":156},
{"institution":"Central Baptist College","id":157},
{"institution":"Cossatot Community College of the University of Arkansas","id":158},
{"institution":"Crowley's Ridge College","id":159},
{"institution":"Crowley's Ridge Technical Institute","id":160},
{"institution":"East Arkansas Community College","id":161},
{"institution":"Eastern College of Health Vocations-Little Rock","id":162},
{"institution":"Imagine-Paul Mitchell Partner School","id":163},
{"institution":"National Park Community College","id":164},
{"institution":"University of Arkansas Community College-Batesville","id":165},
{"institution":"Harding University","id":166},
{"institution":"Henderson State University","id":167},
{"institution":"Hendrix College","id":168},
{"institution":"Hot Springs Beauty College","id":169},
{"institution":"Jefferson Regional Medical Center School of Nursing","id":170},
{"institution":"John Brown University","id":171},
{"institution":"Lees School of Cosmetology","id":172},
{"institution":"Career Academy of Hair Design","id":173},
{"institution":"Marsha Kay Beauty College","id":174},
{"institution":"Academy of Salon and Spa","id":175},
{"institution":"Mid-South Community College","id":176},
{"institution":"Arkansas Northeastern College","id":177},
{"institution":"Margaret's Hair Academy Inc","id":178},
{"institution":"New Tyler Barber College Inc","id":179},
{"institution":"North Arkansas College","id":180},
{"institution":"Northwest Technical Institute","id":181},
{"institution":"Ouachita Baptist University","id":182},
{"institution":"College of the Ouachitas","id":183},
{"institution":"Ozarka College","id":184},
{"institution":"University of the Ozarks","id":185},
{"institution":"University of Arkansas Community College-Morrilton","id":186},
{"institution":"Philander Smith College","id":187},
{"institution":"Phillips Community College of the University of Arkansas","id":188},
{"institution":"Southeast Arkansas College","id":189},
{"institution":"Professional Cosmetology Education Center","id":190},
{"institution":"Pulaski Technical College","id":191},
{"institution":"University of Arkansas Community College-Hope","id":192},
{"institution":"Rich Mountain Community College","id":193},
{"institution":"Paul Mitchell the School-Arkansas","id":194},
{"institution":"Searcy Beauty College Inc","id":195},
{"institution":"Shorter College","id":196},
{"institution":"Williams Baptist College","id":197},
{"institution":"South Arkansas Community College","id":198},
{"institution":"Southern Arkansas University Main Campus","id":199},
{"institution":"Southern Arkansas University Tech","id":200},
{"institution":"University of Arkansas System Office","id":201},
{"institution":"Velvatex College of Beauty Culture","id":202},
{"institution":"University of Arkansas-Fort Smith","id":203},
{"institution":"Academy of Art University","id":204},
{"institution":"ITT Technical Institute-Rancho Cordova","id":205},
{"institution":"Academy of Chinese Culture and Health Sciences","id":206},
{"institution":"The Academy of Radio and TV Broadcasting","id":207},
{"institution":"Avalon School of Cosmetology-Alameda","id":208},
{"institution":"College of Alameda","id":209},
{"institution":"Allan Hancock College","id":210},
{"institution":"American Academy of Dramatic Arts-Los Angeles","id":211},
{"institution":"American Baptist Seminary of the West","id":212},
{"institution":"American Film Institute Conservatory","id":213},
{"institution":"American Beauty College","id":214},
{"institution":"American Career College-Los Angeles","id":215},
{"institution":"American Conservatory Theater","id":216},
{"institution":"American River College","id":217},
{"institution":"Everest College-Hayward","id":218},
{"institution":"Antelope Valley College","id":219},
{"institution":"Art Center College of Design","id":220},
{"institution":"Associated Technical College-Los Angeles","id":221},
{"institution":"Associated Technical College-San Diego","id":222},
{"institution":"Azusa Pacific University","id":223},
{"institution":"Bakersfield College","id":224},
{"institution":"Barstow Community College","id":225},
{"institution":"Bellus Academy-National City","id":226},
{"institution":"Bethesda University of California","id":227},
{"institution":"Biola University","id":228},
{"institution":"Brooks Institute","id":229},
{"institution":"Brownson Technical School","id":230},
{"institution":"Bryan University","id":231},
{"institution":"Butte College","id":232},
{"institution":"Phillips Graduate Institute","id":233},
{"institution":"California Institute of Integral Studies","id":234},
{"institution":"Cabrillo College","id":235},
{"institution":"California Baptist University","id":236},
{"institution":"California College of the Arts","id":237},
{"institution":"University of California-Hastings College of Law","id":238},
{"institution":"California Institute of Technology","id":239},
{"institution":"California Lutheran University","id":240},
{"institution":"California Polytechnic State University-San Luis Obispo","id":241},
{"institution":"Alliant International University","id":242},
{"institution":"California State University-Bakersfield","id":243},
{"institution":"California State University-Stanislaus","id":244},
{"institution":"California State University-Chancellors Office","id":245},
{"institution":"California State University-San Bernardino","id":246},
{"institution":"California State Polytechnic University-Pomona","id":247},
{"institution":"California State University-Chico","id":248},
{"institution":"California State University-Dominguez Hills","id":249},
{"institution":"California State University-Fresno","id":250},
{"institution":"California State University-Fullerton","id":251},
{"institution":"California State University-East Bay","id":252},
{"institution":"California State University-Long Beach","id":253},
{"institution":"California State University-Los Angeles","id":254},
{"institution":"California State University-Northridge","id":255},
{"institution":"California State University-Sacramento","id":256},
{"institution":"University of California-Berkeley","id":257},
{"institution":"University of California-Davis","id":258},
{"institution":"University of California-Irvine","id":259},
{"institution":"University of California-Los Angeles","id":260},
{"institution":"University of California-Riverside","id":261},
{"institution":"University of California-San Diego","id":262},
{"institution":"University of California-San Francisco","id":263},
{"institution":"University of California-Santa Barbara","id":264},
{"institution":"University of California-Santa Cruz","id":265},
{"institution":"Sofia University","id":266},
{"institution":"California Beauty School","id":267},
{"institution":"California Christian College","id":268},
{"institution":"California College San Diego","id":269},
{"institution":"Le Cordon Bleu College of Culinary Arts-San Francisco","id":270},
{"institution":"California Hair Design Academy","id":271},
{"institution":"California Institute of the Arts","id":272},
{"institution":"California Maritime Academy","id":273},
{"institution":"California Western School of Law","id":274},
{"institution":"Canada College","id":275},
{"institution":"College of the Canyons","id":276},
{"institution":"Career Academy of Beauty","id":277},
{"institution":"Casa Loma College-Van Nuys","id":278},
{"institution":"CET-San Diego","id":279},
{"institution":"CET-Sobrato","id":280},
{"institution":"CET-El Centro","id":281},
{"institution":"CET-San Bernardino","id":282},
{"institution":"CET-Watsonville","id":283},
{"institution":"CET-Gilroy","id":284},
{"institution":"CET-Salinas","id":285},
{"institution":"Cerritos College","id":286},
{"institution":"Cerro Coso Community College","id":287},
{"institution":"Chabot College","id":288},
{"institution":"Chaffey College","id":289},
{"institution":"Chapman University","id":290},
{"institution":"Charles R Drew University of Medicine and Science","id":291},
{"institution":"Concordia University-Irvine","id":292},
{"institution":"San Diego Christian College","id":293},
{"institution":"Church Divinity School of the Pacific","id":294},
{"institution":"Citrus College","id":295},
{"institution":"Citrus Heights Beauty College","id":296},
{"institution":"City College of San Francisco","id":297},
{"institution":"Claremont Graduate University","id":298},
{"institution":"Claremont McKenna College","id":299},
{"institution":"Milan Institute-Visalia","id":300},
{"institution":"Clovis Adult Education","id":301},
{"institution":"Coast Community College District Office","id":302},
{"institution":"Coastline Community College","id":303},
{"institution":"Cogswell College","id":304},
{"institution":"Coleman University","id":305},
{"institution":"COBA Academy","id":306},
{"institution":"Western University of Health Sciences","id":307},
{"institution":"Columbia College","id":308},
{"institution":"Columbia College-Hollywood","id":309},
{"institution":"El Camino College-Compton Center","id":310},
{"institution":"Western Beauty Institute","id":311},
{"institution":"Contra Costa Community College District Office","id":312},
{"institution":"Contra Costa College","id":313},
{"institution":"Cosumnes River College","id":314},
{"institution":"Crafton Hills College","id":315},
{"institution":"Cuesta College","id":316},
{"institution":"Cuyamaca College","id":317},
{"institution":"Cypress College","id":318},
{"institution":"De Anza College","id":319},
{"institution":"Marinello Schools of Beauty-Hemet","id":320},
{"institution":"International School of Beauty Inc","id":321},
{"institution":"Dell'Arte International School of Physical Theatre","id":322},
{"institution":"College of the Desert","id":323},
{"institution":"Design Institute of San Diego","id":324},
{"institution":"DeVry University-California","id":325},
{"institution":"Diablo Valley College","id":326},
{"institution":"Dominican University of California","id":327},
{"institution":"Dominican School of Philosophy & Theology","id":328},
{"institution":"East Los Angeles College","id":329},
{"institution":"Marinello Schools of Beauty-Santa Clara","id":330},
{"institution":"San Diego Continuing Education","id":331},
{"institution":"El Camino Community College District","id":332},
{"institution":"Elegance International","id":333},
{"institution":"Marinello Schools of Beauty-Burbank","id":334},
{"institution":"Marinello Schools of Beauty-Moreno Valley","id":335},
{"institution":"Emperor's College of Traditional Oriental Medicine","id":336},
{"institution":"Empire College School of Business","id":337},
{"institution":"Evergreen Valley College","id":338},
{"institution":"Fashion Institute of Design & Merchandising-Los Angeles","id":339},
{"institution":"Fashion Careers College","id":340},
{"institution":"Fashion Institute of Design & Merchandising-San Francisco","id":341},
{"institution":"Fashion Institute of Design & Merchandising-Orange County","id":342},
{"institution":"Feather River Community College District","id":343},
{"institution":"Federico Beauty Institute","id":344},
{"institution":"Fielding Graduate University","id":345},
{"institution":"Five Branches University","id":346},
{"institution":"Hair California Beauty Academy","id":347},
{"institution":"Foothill College","id":348},
{"institution":"Franciscan School of Theology","id":349},
{"institution":"Fredrick and Charles Beauty College","id":350},
{"institution":"Fresno City College","id":351},
{"institution":"Fresno Pacific University","id":352},
{"institution":"Foothill-De Anza Community College District","id":353},
{"institution":"Fuller Theological Seminary in California","id":354},
{"institution":"Fullerton College","id":355},
{"institution":"Galen College of Medical and Dental Assistants","id":356},
{"institution":"Gavilan College","id":357},
{"institution":"Gemological Institute of America-Carlsbad","id":358},
{"institution":"Glendale Community College","id":359},
{"institution":"Glendale Career College","id":360},
{"institution":"Golden Gate University-San Francisco","id":361},
{"institution":"Golden West College","id":362},
{"institution":"Graduate Theological Union","id":363},
{"institution":"Grossmont-Cuyamaca Community College District","id":364},
{"institution":"Grossmont College","id":365},
{"institution":"Salon Success Academy-San Bernardino","id":366},
{"institution":"Hartnell College","id":367},
{"institution":"Harvey Mudd College","id":368},
{"institution":"Heald College-Rancho Cordova","id":369},
{"institution":"Heald College-Fresno","id":370},
{"institution":"Heald College-San Jose","id":371},
{"institution":"Heald College-San Francisco","id":372},
{"institution":"Heald College-Concord","id":373},
{"institution":"Hilltop Beauty School","id":374},
{"institution":"Holy Names University","id":375},
{"institution":"Pacifica Graduate Institute","id":376},
{"institution":"Humboldt State University","id":377},
{"institution":"Humphreys College-Stockton and Modesto Campuses","id":378},
{"institution":"Imperial Valley College","id":379},
{"institution":"Institute for Business and Technology","id":380},
{"institution":"LA College International","id":381},
{"institution":"Interior Designers Institute","id":382},
{"institution":"Toni & Guy Hairdressing Academy-Santa Monica","id":383},
{"institution":"Irvine Valley College","id":384},
{"institution":"ITT Technical Institute-National City","id":385},
{"institution":"ITT Technical Institute-San Dimas","id":386},
{"institution":"ITT Technical Institute-Orange","id":387},
{"institution":"Bellus Academy-El Cajon","id":388},
{"institution":"Joe Blasco Makeup Artist Training Center","id":389},
{"institution":"John F Kennedy University","id":390},
{"institution":"American Jewish University","id":391},
{"institution":"Reedley College","id":392},
{"institution":"Life Pacific College","id":393},
{"institution":"The Art Institute of California-Argosy University San Diego","id":394},
{"institution":"University of La Verne","id":395},
{"institution":"Laguna College of Art and Design","id":396},
{"institution":"Lake Tahoe Community College","id":397},
{"institution":"Lancaster Beauty School","id":398},
{"institution":"Laney College","id":399},
{"institution":"Lassen Community College","id":400},
{"institution":"Life Chiropractic College West","id":401},
{"institution":"Lincoln University","id":402},
{"institution":"Southern California Seminary","id":403},
{"institution":"La Sierra University","id":404},
{"institution":"Loma Linda University","id":405},
{"institution":"Long Beach City College","id":406},
{"institution":"Southern California University of Health Sciences","id":407},
{"institution":"Los Angeles Community College District Office","id":408},
{"institution":"Los Angeles Harbor College","id":409},
{"institution":"Los Angeles Pierce College","id":410},
{"institution":"Los Angeles Southwest College","id":411},
{"institution":"Los Angeles Trade Technical College","id":412},
{"institution":"Los Angeles Valley College","id":413},
{"institution":"The Master's College and Seminary","id":414},
{"institution":"Los Angeles City College","id":415},
{"institution":"Los Angeles County College of Nursing and Allied Health","id":416},
{"institution":"Los Angeles Mission College","id":417},
{"institution":"Los Medanos College","id":418},
{"institution":"Los Rios Community College District Office","id":419},
{"institution":"The Art Institute of California-Argosy University San Francisco","id":420},
{"institution":"Loyola Marymount University","id":421},
{"institution":"Lu Ross Academy","id":422},
{"institution":"Manchester Beauty College","id":423},
{"institution":"Tulare Beauty College","id":424},
{"institution":"Lyles Bakersfield College of Beauty","id":425},
{"institution":"Lyles Fresno College of Beauty","id":426},
{"institution":"Lytles Redwood Empire Beauty College Inc","id":427},
{"institution":"MTI Business College Inc","id":428},
{"institution":"MTI College","id":429},
{"institution":"Kaplan College-Sacramento","id":430},
{"institution":"Kaplan College-San Diego","id":431},
{"institution":"Kaplan College-Vista","id":432},
{"institution":"College of Marin","id":433},
{"institution":"Marinello Schools of Beauty-Los Angeles","id":434},
{"institution":"Marymount California University","id":435},
{"institution":"Mendocino College","id":436},
{"institution":"Menlo College","id":437},
{"institution":"Merced College","id":438},
{"institution":"Merritt College","id":439},
{"institution":"Mills College","id":440},
{"institution":"MiraCosta College","id":441},
{"institution":"Marinello Schools of Beauty-San Francisco","id":442},
{"institution":"Mission College","id":443},
{"institution":"Modern Beauty Academy","id":444},
{"institution":"Kaplan College-North Hollywood","id":445},
{"institution":"Modesto Junior College","id":446},
{"institution":"Moler Barber College","id":447},
{"institution":"Monterey Institute of International Studies","id":448},
{"institution":"Monterey Peninsula College","id":449},
{"institution":"Moorpark College","id":450},
{"institution":"Mt San Antonio College","id":451},
{"institution":"Mount St Mary's College","id":452},
{"institution":"Mt San Jacinto Community College District","id":453},
{"institution":"Mueller College","id":454},
{"institution":"Musicians Institute","id":455},
{"institution":"Napa Valley College","id":456},
{"institution":"Everest College-San Francisco","id":457},
{"institution":"Everest College-Reseda","id":458},
{"institution":"Everest College-LA Wilshire","id":459},
{"institution":"Everest College-San Jose","id":460},
{"institution":"Everest College-Gardena","id":461},
{"institution":"Everest College-Alhambra","id":462},
{"institution":"Everest College-San Bernardino","id":463},
{"institution":"The National Hispanic University","id":464},
{"institution":"National Holistic Institute","id":465},
{"institution":"National University","id":466},
{"institution":"Naval Postgraduate School","id":467},
{"institution":"Newschool of Architecture and Design","id":468},
{"institution":"Newberry School of Beauty","id":469},
{"institution":"Adrian's College of Beauty Modesto","id":470},
{"institution":"North Orange County Community College District","id":471},
{"institution":"North-West College-Pomona","id":472},
{"institution":"North-West College-West Covina","id":473},
{"institution":"North-West College-Pasadena","id":474},
{"institution":"Northwestern Polytechnic University","id":475},
{"institution":"Notre Dame de Namur University","id":476},
{"institution":"Occidental College","id":477},
{"institution":"Oceanside College of Beauty","id":478},
{"institution":"Ohlone College","id":479},
{"institution":"Orange Coast College","id":480},
{"institution":"Otis College of Art and Design","id":481},
{"institution":"Oxnard College","id":482},
{"institution":"Hope International University","id":483},
{"institution":"Concorde Career College-San Diego","id":484},
{"institution":"Palo Alto University","id":485},
{"institution":"Pacific Lutheran Theological Seminary","id":486},
{"institution":"Pacific Oaks College","id":487},
{"institution":"Pacific School of Religion","id":488},
{"institution":"Pacific States University","id":489},
{"institution":"Pacific Union College","id":490},
{"institution":"University of the Pacific","id":491},
{"institution":"Palo Verde College","id":492},
{"institution":"Palomar College","id":493},
{"institution":"Paris Beauty College","id":494},
{"institution":"Pasadena City College","id":495},
{"institution":"Pepperdine University","id":496},
{"institution":"Peralta Community College System Office","id":497},
{"institution":"Pitzer College","id":498},
{"institution":"Platt College-San Diego","id":499},
{"institution":"Point Loma Nazarene University","id":500},
{"institution":"Pomona College","id":501},
{"institution":"Porterville College","id":502},
{"institution":"Westwood College-South Bay","id":503},
{"institution":"Professional Institute of Beauty","id":504},
{"institution":"Santa Ana College","id":505},
{"institution":"Pardee RAND Graduate School","id":506},
{"institution":"Shasta School of Cosmetology","id":507},
{"institution":"University of Redlands","id":508},
{"institution":"College of the Redwoods","id":509},
{"institution":"Salon Success Academy-Upland","id":510},
{"institution":"Salon Success Academy-Corona","id":511},
{"institution":"Rio Hondo College","id":512},
{"institution":"Riverside City College","id":513},
{"institution":"Argosy University-San Francisco Bay Area","id":514},
{"institution":"Rosemead Beauty School","id":515},
{"institution":"Dongguk University-Los Angeles","id":516},
{"institution":"Sacramento City College","id":517},
{"institution":"Saddleback College","id":518},
{"institution":"Saint Patrick's Seminary and University","id":519},
{"institution":"Samuel Merritt University","id":520},
{"institution":"San Diego Community College District-District Office","id":521},
{"institution":"San Diego City College","id":522},
{"institution":"Golf Academy of America-Carlsbad","id":523},
{"institution":"San Diego Mesa College","id":524},
{"institution":"San Diego Miramar College","id":525},
{"institution":"San Diego State University","id":526},
{"institution":"University of San Diego","id":527},
{"institution":"San Francisco Art Institute","id":528},
{"institution":"San Francisco Conservatory of Music","id":529},
{"institution":"San Francisco State University","id":530},
{"institution":"San Francisco Theological Seminary","id":531},
{"institution":"University of San Francisco","id":532},
{"institution":"San Joaquin College of Law","id":533},
{"institution":"San Joaquin Delta College","id":534},
{"institution":"San Joaquin Valley College-Visalia","id":535},
{"institution":"San Joaquin Valley College-Bakersfield","id":536},
{"institution":"William Jessup University","id":537},
{"institution":"San Jose-Evergreen Community College District","id":538},
{"institution":"San Jose City College","id":539},
{"institution":"San Jose State University","id":540},
{"institution":"San Mateo County Community College District Office","id":541},
{"institution":"College of San Mateo","id":542},
{"institution":"Santa Barbara Business College-Bakersfield","id":543},
{"institution":"Westwood College-Los Angeles","id":544},
{"institution":"Santa Barbara Business College-Santa Maria","id":545},
{"institution":"Santa Barbara City College","id":546},
{"institution":"Santa Clara University","id":547},
{"institution":"Santa Monica College","id":548},
{"institution":"Santa Rosa Junior College","id":549},
{"institution":"Saybrook University","id":550},
{"institution":"Scripps College","id":551},
{"institution":"Wyotech-Fremont","id":552},
{"institution":"College of the Sequoias","id":553},
{"institution":"Shasta Bible College and Graduate School","id":554},
{"institution":"Shasta College","id":555},
{"institution":"Sierra College","id":556},
{"institution":"Sierra College of Beauty","id":557},
{"institution":"Sierra Valley College of Court Reporting","id":558},
{"institution":"Trinity Law School","id":559},
{"institution":"Simpson University","id":560},
{"institution":"College of the Siskiyous","id":561},
{"institution":"Charles A Jones Career and Education Center","id":562},
{"institution":"Skyline College","id":563},
{"institution":"San Bernardino Valley College","id":564},
{"institution":"Saint Mary's College of California","id":565},
{"institution":"Solano Community College","id":566},
{"institution":"Sonoma State University","id":567},
{"institution":"Pinnacle College","id":568},
{"institution":"South Baylo University","id":569},
{"institution":"South Coast College","id":570},
{"institution":"Vanguard University of Southern California","id":571},
{"institution":"Concorde Career College-Garden Grove","id":572},
{"institution":"Southwestern College","id":573},
{"institution":"St John's Seminary","id":574},
{"institution":"Starr King School for Ministry","id":575},
{"institution":"State Center Community College District","id":576},
{"institution":"Marshall B Ketchum University","id":577},
{"institution":"Southern California Institute of Architecture","id":578},
{"institution":"University of Southern California","id":579},
{"institution":"Southwestern Law School","id":580},
{"institution":"Taft College","id":581},
{"institution":"Claremont School of Theology","id":582},
{"institution":"Thomas Aquinas College","id":583},
{"institution":"Marinello Schools of Beauty-Lake Forest","id":584},
{"institution":"Epic Bible College","id":585},
{"institution":"University of California-System Administration Central Office","id":586},
{"institution":"United Education Institute-Huntington Park Campus","id":587},
{"institution":"Concorde Career College-San Bernardino","id":588},
{"institution":"Universal College of Beauty Inc-Los Angeles 1","id":589},
{"institution":"Concorde Career College-North Hollywood","id":590},
{"institution":"Ventura County Community College System Office","id":591},
{"institution":"Ventura College","id":592},
{"institution":"The Santa Barbara and Ventura Colleges of Law-Ventura","id":593},
{"institution":"Victor Valley Beauty College Inc","id":594},
{"institution":"Victor Valley College","id":595},
{"institution":"Berkeley City College","id":596},
{"institution":"West Valley Mission Community College District Office","id":597},
{"institution":"Walden University","id":598},
{"institution":"Waynes College of Beauty","id":599},
{"institution":"West Hills College-Coalinga","id":600},
{"institution":"West Los Angeles College","id":601},
{"institution":"West Valley College","id":602},
{"institution":"Carrington College California-Sacramento","id":603},
{"institution":"Westminster Theological Seminary in California","id":604},
{"institution":"Westmont College","id":605},
{"institution":"Whittier College","id":606},
{"institution":"Woodbury University","id":607},
{"institution":"The Wright Institute","id":608},
{"institution":"Western State College of Law at Argosy University","id":609},
{"institution":"Thomas Jefferson School of Law","id":610},
{"institution":"Yeshiva Ohr Elchonon Chabad West Coast Talmudical Seminary","id":611},
{"institution":"Yosemite Community College District Office","id":612},
{"institution":"Yuba College","id":613},
{"institution":"The Salon Professional Academy-Grand Junction","id":614},
{"institution":"Adams State University","id":615},
{"institution":"Aims Community College","id":616},
{"institution":"Arapahoe Community College","id":617},
{"institution":"Bel-Rea Institute of Animal Technology","id":618},
{"institution":"Everest College-Colorado Springs","id":619},
{"institution":"Boulder College of Massage Therapy","id":620},
{"institution":"University of Colorado Denver","id":621},
{"institution":"University of Colorado Colorado Springs","id":622},
{"institution":"Redstone College","id":623},
{"institution":"University of Colorado Boulder","id":624},
{"institution":"Colorado Christian University","id":625},
{"institution":"Colorado College","id":626},
{"institution":"Concorde Career College-Aurora","id":627},
{"institution":"The Art Institute of Colorado","id":628},
{"institution":"Colorado Mountain College","id":629},
{"institution":"Colorado Northwestern Community College","id":630},
{"institution":"Colorado School of Mines","id":631},
{"institution":"Colorado School of Trades","id":632},
{"institution":"Colorado State University-Fort Collins","id":633},
{"institution":"Colorado Technical University-Colorado Springs","id":634},
{"institution":"Empire Beauty School-Lakewood","id":635},
{"institution":"Community College of Aurora","id":636},
{"institution":"College America-Denver","id":637},
{"institution":"Delta Montrose Technical College","id":638},
{"institution":"Prince Institute-Rocky Mountains","id":639},
{"institution":"Community College of Denver","id":640},
{"institution":"Lincoln College of Technology-Denver","id":641},
{"institution":"Denver Seminary","id":642},
{"institution":"Westwood College-Denver North","id":643},
{"institution":"University of Denver","id":644},
{"institution":"Emily Griffith Technical College","id":645},
{"institution":"Fort Lewis College","id":646},
{"institution":"Front Range Community College","id":647},
{"institution":"Glenwood Beauty Academy","id":648},
{"institution":"Hair Dynamics Education Center","id":649},
{"institution":"Iliff School of Theology","id":650},
{"institution":"International Salon and Spa Academy","id":651},
{"institution":"Lamar Community College","id":652},
{"institution":"Colorado Mesa University","id":653},
{"institution":"Metropolitan State University of Denver","id":654},
{"institution":"Morgan Community College","id":655},
{"institution":"Naropa University","id":656},
{"institution":"National American University-Colorado Springs","id":657},
{"institution":"National American University-Denver","id":658},
{"institution":"Nazarene Bible College","id":659},
{"institution":"Northeastern Junior College","id":660},
{"institution":"University of Northern Colorado","id":661},
{"institution":"Otero Junior College","id":662},
{"institution":"Everest College-Thornton","id":663},
{"institution":"Pikes Peak Community College","id":664},
{"institution":"Intellitec Medical Institute","id":665},
{"institution":"Pueblo Community College","id":666},
{"institution":"Red Rocks Community College","id":667},
{"institution":"Regis University","id":668},
{"institution":"Rocky Mountain College of Art and Design","id":669},
{"institution":"Montessori Education Center of the Rockies","id":670},
{"institution":"Colorado State University-Pueblo","id":671},
{"institution":"Pickens Technical College","id":672},
{"institution":"Intellitec College-Colorado Springs","id":673},
{"institution":"Intellitec College-Grand Junction","id":674},
{"institution":"Trinidad State Junior College","id":675},
{"institution":"University of Colorado System Office","id":676},
{"institution":"United States Air Force Academy","id":677},
{"institution":"Empire Beauty School-Thornton","id":678},
{"institution":"Western State Colorado University","id":679},
{"institution":"Yeshiva Toras Chaim Talmudical Seminary","id":680},
{"institution":"Albertus Magnus College","id":681},
{"institution":"Paul Mitchell the School-Danbury","id":682},
{"institution":"Asnuntuck Community College","id":683},
{"institution":"Bais Binyomin Academy","id":684},
{"institution":"Branford Hall Career Institute-Branford Campus","id":685},
{"institution":"Lincoln College of New England-Southington","id":686},
{"institution":"Bridgeport Hospital School of Nursing","id":687},
{"institution":"University of Bridgeport","id":688},
{"institution":"Central Connecticut State University","id":689},
{"institution":"Charter Oak State College","id":690},
{"institution":"Connecticut Center for Massage Therapy-Newington","id":691},
{"institution":"Connecticut College","id":692},
{"institution":"Marinello Schools of Beauty-East Hartford","id":693},
{"institution":"Porter and Chester Institute of Branford","id":694},
{"institution":"University of Connecticut","id":695},
{"institution":"Goodwin College","id":696},
{"institution":"Lincoln Technical Institute-East Windsor","id":697},
{"institution":"Eastern Connecticut State University","id":698},
{"institution":"Fairfield University","id":699},
{"institution":"Northhaven Academy","id":700},
{"institution":"Capital Community College","id":701},
{"institution":"Rensselaer Hartford Graduate Center Inc","id":702},
{"institution":"Fox Institute of Business-West Hartford","id":703},
{"institution":"Hartford Seminary","id":704},
{"institution":"University of Hartford","id":705},
{"institution":"Holy Apostles College and Seminary","id":706},
{"institution":"Housatonic Community College","id":707},
{"institution":"Sanford-Brown College-Farmington","id":708},
{"institution":"The Leon Institute of Hair Design","id":709},
{"institution":"Lyme Academy College of Fine Arts","id":710},
{"institution":"Manchester Community College","id":711},
{"institution":"Naugatuck Valley Community College","id":712},
{"institution":"Middlesex Community College","id":713},
{"institution":"Mitchell College","id":714},
{"institution":"Three Rivers Community College","id":715},
{"institution":"Lincoln Technical Institute-New Britain","id":716},
{"institution":"New England Tractor Trailer Training School of Connecticut","id":717},
{"institution":"University of New Haven","id":718},
{"institution":"Ridley-Lowell Business & Technical Institute-New London","id":719},
{"institution":"Norwalk Community College","id":720},
{"institution":"Northwestern Connecticut Community College","id":721},
{"institution":"Paier College of Art Inc","id":722},
{"institution":"Marinello Schools of Beauty-Meriden","id":723},
{"institution":"Porter and Chester Institute of Stratford","id":724},
{"institution":"Post University","id":725},
{"institution":"Quinebaug Valley Community College","id":726},
{"institution":"Quinnipiac University","id":727},
{"institution":"Sacred Heart University","id":728},
{"institution":"University of Saint Joseph","id":729},
{"institution":"Gateway Community College","id":730},
{"institution":"St Vincent's College","id":731},
{"institution":"Southern Connecticut State University","id":732},
{"institution":"Stone Academy-Hamden","id":733},
{"institution":"Tri-State College of Acupuncture","id":734},
{"institution":"Trinity College","id":735},
{"institution":"Tunxis Community College","id":736},
{"institution":"United States Coast Guard Academy","id":737},
{"institution":"Wesleyan University","id":738},
{"institution":"Western Connecticut State University","id":739},
{"institution":"Yale-New Haven Hospital Dietetic Internship","id":740},
{"institution":"Yale University","id":741},
{"institution":"Beebe Medical Center Margaret H Rollins School of Nursing","id":742},
{"institution":"Dawn Career Institute Inc","id":743},
{"institution":"Delaware Technical Community College-Central Office","id":744},
{"institution":"Delaware Technical Community College-Owens","id":745},
{"institution":"Delaware Technical Community College-Terry","id":746},
{"institution":"Delaware Technical Community College-Stanton/Wilmington","id":747},
{"institution":"Delaware State University","id":748},
{"institution":"University of Delaware","id":749},
{"institution":"Goldey-Beacom College","id":750},
{"institution":"Schilling-Douglas School of Hair Design","id":751},
{"institution":"Wesley College","id":752},
{"institution":"Wilmington University","id":753},
{"institution":"American University","id":754},
{"institution":"Catholic University of America","id":755},
{"institution":"Corcoran College of Art and Design","id":756},
{"institution":"University of the District of Columbia","id":757},
{"institution":"Pontifical Faculty of the Immaculate Conception at the Dominican House of Studies","id":758},
{"institution":"Gallaudet University","id":759},
{"institution":"George Washington University","id":760},
{"institution":"Georgetown University","id":761},
{"institution":"Howard University","id":762},
{"institution":"Medtech Institute","id":763},
{"institution":"Strayer University-District of Columbia","id":764},
{"institution":"National Conservatory of Dramatic Arts","id":765},
{"institution":"Trinity Washington University","id":766},
{"institution":"Wesley Theological Seminary","id":767},
{"institution":"Wyotech-Daytona","id":768},
{"institution":"The Art Institute of Fort Lauderdale","id":769},
{"institution":"Atlantic Technical Center","id":770},
{"institution":"The Baptist College of Florida","id":771},
{"institution":"Barry University","id":772},
{"institution":"Gooding Institute of Nurse Anesthesia","id":773},
{"institution":"Bethune-Cookman University","id":774},
{"institution":"Lynn University","id":775},
{"institution":"GUTI The Premier Beauty & Wellness Academy","id":776},
{"institution":"Bradford-Union Technical Center","id":777},
{"institution":"Eastern Florida State College","id":778},
{"institution":"Broward College","id":779},
{"institution":"Fortis College-Winter Park","id":780},
{"institution":"Carlos Albizu University-Miami","id":781},
{"institution":"College of Central Florida","id":782},
{"institution":"Johnson University Florida","id":783},
{"institution":"University of Central Florida","id":784},
{"institution":"Charlotte Technical Center","id":785},
{"institution":"Chipola College","id":786},
{"institution":"Clearwater Christian College","id":787},
{"institution":"Lorenzo Walker Institute of Technology","id":788},
{"institution":"Daytona State College","id":789},
{"institution":"South University-West Palm Beach","id":790},
{"institution":"Eckerd College","id":791},
{"institution":"Edison State College","id":792},
{"institution":"Edward Waters College","id":793},
{"institution":"Embry-Riddle Aeronautical University-Daytona Beach","id":794},
{"institution":"Florida Agricultural and Mechanical University","id":795},
{"institution":"Florida Atlantic University","id":796},
{"institution":"Florida State College at Jacksonville","id":797},
{"institution":"Flagler College-St Augustine","id":798},
{"institution":"Florida College","id":799},
{"institution":"Concorde Career Institute-Jacksonville","id":800},
{"institution":"Concorde Career Institute-Miramar","id":801},
{"institution":"Concorde Career Institute-Tampa","id":802},
{"institution":"Adventist University of Health Sciences","id":803},
{"institution":"Florida Institute of Technology","id":804},
{"institution":"Florida Institute of Ultrasound Inc","id":805},
{"institution":"Florida International University","id":806},
{"institution":"Florida Keys Community College","id":807},
{"institution":"Florida Memorial University","id":808},
{"institution":"Florida Career College-Miami","id":809},
{"institution":"Florida School of Massage","id":810},
{"institution":"Florida Southern College","id":811},
{"institution":"Florida State University","id":812},
{"institution":"Florida Technical College","id":813},
{"institution":"University of Southernmost Florida","id":814},
{"institution":"University of Florida","id":815},
{"institution":"Everest University-Pompano Beach","id":816},
{"institution":"Fort Pierce Beauty Academy","id":817},
{"institution":"Full Sail University","id":818},
{"institution":"George Stone Technical Center","id":819},
{"institution":"George T Baker Aviation School","id":820},
{"institution":"Gulf Coast State College","id":821},
{"institution":"Key College","id":822},
{"institution":"Hillsborough Community College","id":823},
{"institution":"Hobe Sound Bible College","id":824},
{"institution":"Hollywood Institute of Beauty Careers","id":825},
{"institution":"Cortiva Institute-Florida","id":826},
{"institution":"Indian River State College","id":827},
{"institution":"International Academy of Design and Technology-Tampa","id":828},
{"institution":"International Academy","id":829},
{"institution":"AI Miami International University of Art and Design","id":830},
{"institution":"ITT Technical Institute-Tampa","id":831},
{"institution":"Jacksonville University","id":832},
{"institution":"Jones College-Jacksonville","id":833},
{"institution":"Keiser University-Ft Lauderdale","id":834},
{"institution":"Heritage Institute-Ft Myers","id":835},
{"institution":"La Belle Beauty School","id":836},
{"institution":"Florida Gateway College","id":837},
{"institution":"Lake Technical Center","id":838},
{"institution":"Lake-Sumter State College","id":839},
{"institution":"Fort Myers Institute of Technology","id":840},
{"institution":"Lively Technical Center","id":841},
{"institution":"Lindsey Hopkins Technical Education Center","id":842},
{"institution":"Loraines Academy Inc","id":843},
{"institution":"Luther Rice University & Seminary","id":844},
{"institution":"State College of Florida-Manatee-Sarasota","id":845},
{"institution":"Manatee Technical Institute","id":846},
{"institution":"Manhattan Hairstyling Academy","id":847},
{"institution":"Traviss Career Center","id":848},
{"institution":"Trinity International University-Florida","id":849},
{"institution":"Miami Lakes Educational Center","id":850},
{"institution":"Miami Dade College","id":851},
{"institution":"University of Miami","id":852},
{"institution":"Mid Florida Tech","id":853},
{"institution":"Beauty Schools of America-Miami","id":854},
{"institution":"Remington College-Tampa Campus","id":855},
{"institution":"Everest Institute-North Miami","id":856},
{"institution":"Everest Institute-Hialeah","id":857},
{"institution":"Academy of Healing Arts Massage & Facial Skin Care","id":858},
{"institution":"Lincoln College of Technology-West Palm Beach","id":859},
{"institution":"Fortis College-Tampa","id":860},
{"institution":"Paul Mitchell the School-Jacksonville","id":861},
{"institution":"North Florida Community College","id":862},
{"institution":"University of North Florida","id":863},
{"institution":"Northwood University-Florida","id":864},
{"institution":"Nova Southeastern University","id":865},
{"institution":"Northwest Florida State College","id":866},
{"institution":"Orange Park International School of Beauty","id":867},
{"institution":"Everest University-North Orlando","id":868},
{"institution":"Orlando Tech","id":869},
{"institution":"Palm Beach Atlantic University","id":870},
{"institution":"Palm Beach State College","id":871},
{"institution":"Pasco-Hernando Community College","id":872},
{"institution":"Pensacola State College","id":873},
{"institution":"Pinellas Technical Education Center-Clearwater","id":874},
{"institution":"Polk State College","id":875},
{"institution":"Radford M Locklin Technical Center","id":876},
{"institution":"Saint Vincent de Paul Regional Seminary","id":877},
{"institution":"Ridge Career Center","id":878},
{"institution":"Ringling College of Art and Design","id":879},
{"institution":"Toni & Guy Hairdressing Academy-Jacksonville","id":880},
{"institution":"Robert Morgan Educational Center","id":881},
{"institution":"Rollins College","id":882},
{"institution":"First Coast Technical College","id":883},
{"institution":"Saint Leo University","id":884},
{"institution":"St Petersburg College","id":885},
{"institution":"Pinellas Technical Education Center-St Petersburg","id":886},
{"institution":"Santa Fe College","id":887},
{"institution":"Sarasota County Technical Institute","id":888},
{"institution":"Argosy University-Sarasota","id":889},
{"institution":"Seminole State College of Florida","id":890},
{"institution":"Sheridan Technical Center","id":891},
{"institution":"Saint John Vianney College Seminary","id":892},
{"institution":"Saint Johns River State College","id":893},
{"institution":"South Florida State College","id":894},
{"institution":"University of South Florida-Main Campus","id":895},
{"institution":"St Thomas University","id":896},
{"institution":"Stenotype Institute of Jacksonville Inc-Jacksonville","id":897},
{"institution":"Stetson University","id":898},
{"institution":"Southeastern University","id":899},
{"institution":"Benes International School of Beauty","id":900},
{"institution":"Suwannee-Hamilton Technical Center","id":901},
{"institution":"Tallahassee Community College","id":902},
{"institution":"Talmudic College of Florida","id":903},
{"institution":"Everest University-Tampa","id":904},
{"institution":"Everest University-Largo","id":905},
{"institution":"The University of Tampa","id":906},
{"institution":"Taylor Technical Institute","id":907},
{"institution":"Tom P Haney Technical Center","id":908},
{"institution":"Trinity Baptist College","id":909},
{"institution":"Trinity College of Florida","id":910},
{"institution":"Fashion Focus Hair Academy","id":911},
{"institution":"Valencia College","id":912},
{"institution":"Warner University","id":913},
{"institution":"Washington-Holmes Technical Center","id":914},
{"institution":"Webber International University","id":915},
{"institution":"Rasmussen College-Florida","id":916},
{"institution":"The University of West Florida","id":917},
{"institution":"Westside Tech","id":918},
{"institution":"William T McFatter Technical Center","id":919},
{"institution":"Winter Park Tech","id":920},
{"institution":"Withlacoochee Technical Institute","id":921},
{"institution":"Abraham Baldwin Agricultural College","id":922},
{"institution":"Agnes Scott College","id":923},
{"institution":"Interactive College of Technology-Chamblee","id":924},
{"institution":"Interactive College of Technology-Morrow","id":925},
{"institution":"Albany Technical College","id":926},
{"institution":"Darton State College","id":927},
{"institution":"Albany State University","id":928},
{"institution":"Andrew College","id":929},
{"institution":"Armstrong Atlantic State University","id":930},
{"institution":"The Art Institute of Atlanta","id":931},
{"institution":"Georgia Career Institute","id":932},
{"institution":"Atlanta Technical College","id":933},
{"institution":"Point University","id":934},
{"institution":"Atlanta Metropolitan State College","id":935},
{"institution":"Atlanta's John Marshall Law School","id":936},
{"institution":"Atlanta School of Massage","id":937},
{"institution":"Clark Atlanta University","id":938},
{"institution":"Augusta Technical College","id":939},
{"institution":"Augusta State University","id":940},
{"institution":"Bainbridge State College","id":941},
{"institution":"Bauder College","id":942},
{"institution":"Beauty College of America","id":943},
{"institution":"Berry College","id":944},
{"institution":"Beulah Heights University","id":945},
{"institution":"Brenau University","id":946},
{"institution":"Brewton-Parker College","id":947},
{"institution":"Brown College of Court Reporting","id":948},
{"institution":"College of Coastal Georgia","id":949},
{"institution":"West Georgia Technical College","id":950},
{"institution":"Carver Bible College","id":951},
{"institution":"Clayton  State University","id":952},
{"institution":"Columbia Theological Seminary","id":953},
{"institution":"Columbus Technical College","id":954},
{"institution":"Columbus State University","id":955},
{"institution":"Georgia Northwestern Technical College","id":956},
{"institution":"Covenant College","id":957},
{"institution":"Dalton State College","id":958},
{"institution":"Pro Way Hair School","id":959},
{"institution":"DeVry University-Georgia","id":960},
{"institution":"South University-Savannah","id":961},
{"institution":"East Georgia State College","id":962},
{"institution":"Emmanuel College","id":963},
{"institution":"Emory University","id":964},
{"institution":"Georgia Highlands College","id":965},
{"institution":"Fort Valley State University","id":966},
{"institution":"Grady Health System Professional Schools","id":967},
{"institution":"Georgia Institute of Technology-Main Campus","id":968},
{"institution":"Georgia Southwestern State University","id":969},
{"institution":"Gainesville State College","id":970},
{"institution":"Georgia College and State University","id":971},
{"institution":"Georgia Military College","id":972},
{"institution":"Georgia Southern University","id":973},
{"institution":"Georgia State University","id":974},
{"institution":"University of Georgia","id":975},
{"institution":"Gordon State College","id":976},
{"institution":"Southern Crescent Technical College","id":977},
{"institution":"Gupton Jones College of Funeral Service","id":978},
{"institution":"Gwinnett College-Lilburn","id":979},
{"institution":"Gwinnett Technical College","id":980},
{"institution":"Middle Georgia Technical College","id":981},
{"institution":"Interdenominational Theological Center","id":982},
{"institution":"Kennesaw State University","id":983},
{"institution":"LaGrange College","id":984},
{"institution":"Lanier Technical College","id":985},
{"institution":"Life University","id":986},
{"institution":"Central Georgia Technical College","id":987},
{"institution":"Macon State College","id":988},
{"institution":"Chattahoochee Technical College","id":989},
{"institution":"Herzing University-Atlanta","id":990},
{"institution":"Georgia Health Sciences University","id":991},
{"institution":"Mercer University","id":992},
{"institution":"Middle Georgia College","id":993},
{"institution":"Morehouse College","id":994},
{"institution":"Morehouse School of Medicine","id":995},
{"institution":"Moultrie Technical College","id":996},
{"institution":"University of North Georgia","id":997},
{"institution":"North Georgia Technical College","id":998},
{"institution":"Oglethorpe University","id":999},
{"institution":"Paine College","id":1000},
{"institution":"Piedmont College","id":1001},
{"institution":"Portfolio Center","id":1002},
{"institution":"Reinhardt University","id":1003},
{"institution":"Savannah Technical College","id":1004},
{"institution":"Savannah College of Art and Design","id":1005},
{"institution":"Savannah State University","id":1006},
{"institution":"Shorter University","id":1007},
{"institution":"South Georgia College","id":1008},
{"institution":"South Georgia Technical College","id":1009},
{"institution":"The Creative Circus","id":1010},
{"institution":"Spelman College","id":1011},
{"institution":"Southern Polytechnic State University","id":1012},
{"institution":"Southwest Georgia Technical College","id":1013},
{"institution":"Thomas University","id":1014},
{"institution":"Toccoa Falls College","id":1015},
{"institution":"Truett-McConnell College","id":1016},
{"institution":"Wiregrass Georgia Technical College","id":1017},
{"institution":"Valdosta State University","id":1018},
{"institution":"Waycross College","id":1019},
{"institution":"Wesleyan College","id":1020},
{"institution":"University of West Georgia","id":1021},
{"institution":"Young Harris College","id":1022},
{"institution":"Heald College-Honolulu","id":1023},
{"institution":"Chaminade University of Honolulu","id":1024},
{"institution":"University of Hawaii at Hilo","id":1025},
{"institution":"University of Hawaii at Manoa","id":1026},
{"institution":"Hawaii Institute of Hair Design","id":1027},
{"institution":"Hawaii Pacific University","id":1028},
{"institution":"Honolulu Community College","id":1029},
{"institution":"Kapiolani Community College","id":1030},
{"institution":"Kauai Community College","id":1031},
{"institution":"Leeward Community College","id":1032},
{"institution":"University of Hawaii Maui College","id":1033},
{"institution":"Med-Assist School of Hawaii Inc","id":1034},
{"institution":"World Medicine Institute","id":1035},
{"institution":"Travel Institute of the Pacific","id":1036},
{"institution":"University of Hawaii System Office","id":1037},
{"institution":"University of Hawaii-West Oahu","id":1038},
{"institution":"Windward Community College","id":1039},
{"institution":"Carrington College-Boise","id":1040},
{"institution":"Boise Bible College","id":1041},
{"institution":"Boise State University","id":1042},
{"institution":"Eastern Idaho Technical College","id":1043},
{"institution":"Idaho State University","id":1044},
{"institution":"University of Idaho","id":1045},
{"institution":"The College of Idaho","id":1046},
{"institution":"Lewis-Clark State College","id":1047},
{"institution":"ITT Technical Institute-Boise","id":1048},
{"institution":"Joseph Charles Institute of Cosmetology","id":1049},
{"institution":"Mr Leon's School of Hair Design-Moscow","id":1050},
{"institution":"North Idaho College","id":1051},
{"institution":"Northwest Nazarene University","id":1052},
{"institution":"The School of Hairstyling","id":1053},
{"institution":"Brigham Young University-Idaho","id":1054},
{"institution":"College of Southern Idaho","id":1055},
{"institution":"Adler School of Professional Psychology","id":1056},
{"institution":"Alvareitas College of Cosmetology-Edwardsville","id":1057},
{"institution":"Alvareitas College of Cosmetology-Godfrey","id":1058},
{"institution":"American Academy of Art","id":1059},
{"institution":"School of the Art Institute of Chicago","id":1060},
{"institution":"The College of Office Technology","id":1061},
{"institution":"Augustana College","id":1062},
{"institution":"Aurora University","id":1063},
{"institution":"Concept College of Cosmetology","id":1064},
{"institution":"Southwestern Illinois College","id":1065},
{"institution":"Bethany Theological Seminary","id":1066},
{"institution":"Black Hawk College","id":1067},
{"institution":"Blackburn College","id":1068},
{"institution":"Blessing Rieman College of Nursing","id":1069},
{"institution":"Blessing Hospital School of Radiologic Technology","id":1070},
{"institution":"Bradley University","id":1071},
{"institution":"Paul Mitchell the School-Bradley","id":1072},
{"institution":"Cameo Beauty Academy","id":1073},
{"institution":"Cannella School of Hair Design-Villa Park","id":1074},
{"institution":"Cannella School of Hair Design-Chicago","id":1075},
{"institution":"Cannella School of Hair Design-Chicago","id":1076},
{"institution":"Cannella School of Hair Design-Elgin","id":1077},
{"institution":"Capri Beauty College","id":1078},
{"institution":"Empire Beauty School-Hanover Park","id":1079},
{"institution":"Capri Beauty College","id":1080},
{"institution":"Carl Sandburg College","id":1081},
{"institution":"Catholic Theological Union at Chicago","id":1082},
{"institution":"La James International College-East Moline","id":1083},
{"institution":"Midwestern University-Downers Grove","id":1084},
{"institution":"The Chicago School of Professional Psychology at Chicago","id":1085},
{"institution":"Chicago State University","id":1086},
{"institution":"Chicago Theological Seminary","id":1087},
{"institution":"University of Chicago","id":1088},
{"institution":"City Colleges of Chicago-Kennedy-King College","id":1089},
{"institution":"City Colleges of Chicago-Malcolm X College","id":1090},
{"institution":"City Colleges of Chicago-Olive-Harvey College","id":1091},
{"institution":"City Colleges of Chicago-Harry S Truman College","id":1092},
{"institution":"City Colleges of Chicago-Richard J Daley College","id":1093},
{"institution":"City Colleges of Chicago-Harold Washington College","id":1094},
{"institution":"City Colleges of Chicago-Wilbur Wright College","id":1095},
{"institution":"Columbia College-Chicago","id":1096},
{"institution":"Concordia University-Chicago","id":1097},
{"institution":"Le Cordon Bleu College of Culinary Arts-Chicago","id":1098},
{"institution":"Coyne College","id":1099},
{"institution":"City Colleges of Chicago-District Office","id":1100},
{"institution":"Danville Area Community College","id":1101},
{"institution":"Concept College of Cosmetology","id":1102},
{"institution":"DePaul University","id":1103},
{"institution":"DeVry University-Illinois","id":1104},
{"institution":"DeVry University-Administrative Office","id":1105},
{"institution":"Cosmetology & Spa Academy","id":1106},
{"institution":"College of DuPage","id":1107},
{"institution":"East-West University","id":1108},
{"institution":"Eastern Illinois University","id":1109},
{"institution":"Elgin Community College","id":1110},
{"institution":"Elmhurst College","id":1111},
{"institution":"Eureka College","id":1112},
{"institution":"First Institute Inc","id":1113},
{"institution":"Fox College","id":1114},
{"institution":"Garrett-Evangelical Theological Seminary","id":1115},
{"institution":"Gem City College","id":1116},
{"institution":"Governors State University","id":1117},
{"institution":"Graham Hospital School of Nursing","id":1118},
{"institution":"Greenville College","id":1119},
{"institution":"Hair Professionals Academy of Cosmetology","id":1120},
{"institution":"Hair Professionals Career College","id":1121},
{"institution":"Hair Professionals School of Cosmetology","id":1122},
{"institution":"Harrington College of Design","id":1123},
{"institution":"Hebrew Theological College","id":1124},
{"institution":"Highland Community College","id":1125},
{"institution":"Rosalind Franklin University of Medicine and Science","id":1126},
{"institution":"University of Illinois at Chicago","id":1127},
{"institution":"Benedictine University","id":1128},
{"institution":"Illinois College of Optometry","id":1129},
{"institution":"University of Illinois at Urbana-Champaign","id":1130},
{"institution":"Illinois Wesleyan University","id":1131},
{"institution":"Illinois Central College","id":1132},
{"institution":"Illinois College","id":1133},
{"institution":"Olney Central College","id":1134},
{"institution":"Illinois Institute of Technology","id":1135},
{"institution":"Argosy University-Chicago","id":1136},
{"institution":"Illinois State University","id":1137},
{"institution":"Illinois Valley Community College","id":1138},
{"institution":"Institute for Clinical Social Work","id":1139},
{"institution":"International Academy of Design and Technology-Chicago","id":1140},
{"institution":"John A Logan College","id":1141},
{"institution":"The John Marshall Law School","id":1142},
{"institution":"John Wood Community College","id":1143},
{"institution":"Joliet Junior College","id":1144},
{"institution":"Judson University","id":1145},
{"institution":"Kankakee Community College","id":1146},
{"institution":"Kaskaskia College","id":1147},
{"institution":"DeVry University's Keller Graduate School of Management-Illinois","id":1148},
{"institution":"Kendall College","id":1149},
{"institution":"Kishwaukee College","id":1150},
{"institution":"Knox College","id":1151},
{"institution":"Educators of Beauty College of Cosmetology-La Salle","id":1152},
{"institution":"College of Lake County","id":1153},
{"institution":"Lake Forest College","id":1154},
{"institution":"Lake Forest Graduate School of Management","id":1155},
{"institution":"Lake Land College","id":1156},
{"institution":"Lakeview College of Nursing","id":1157},
{"institution":"Lewis and Clark Community College","id":1158},
{"institution":"Lewis University","id":1159},
{"institution":"Lexington College","id":1160},
{"institution":"Lincoln Christian University","id":1161},
{"institution":"Lincoln College","id":1162},
{"institution":"Lincoln Land Community College","id":1163},
{"institution":"Lincoln College of Technology-Melrose Park","id":1164},
{"institution":"Loyola University Chicago","id":1165},
{"institution":"Lutheran School of Theology at Chicago","id":1166},
{"institution":"Trinity College of Nursing & Health Sciences","id":1167},
{"institution":"CET-Chicago","id":1168},
{"institution":"Mac Daniels Beauty School","id":1169},
{"institution":"MacCormac College","id":1170},
{"institution":"MacMurray College","id":1171},
{"institution":"McCormick Theological Seminary","id":1172},
{"institution":"McHenry County College","id":1173},
{"institution":"McKendree University","id":1174},
{"institution":"Meadville Lombard Theological School","id":1175},
{"institution":"Methodist College","id":1176},
{"institution":"Midstate College","id":1177},
{"institution":"Millikin University","id":1178},
{"institution":"Monmouth College","id":1179},
{"institution":"Moody Bible Institute","id":1180},
{"institution":"Moraine Valley Community College","id":1181},
{"institution":"Morrison Institute of Technology","id":1182},
{"institution":"Morton College","id":1183},
{"institution":"Mr John's School of Cosmetology Esthetics & Nails-Decatur","id":1184},
{"institution":"National Louis University","id":1185},
{"institution":"National University of Health Sciences","id":1186},
{"institution":"Niles School of Cosmetology","id":1187},
{"institution":"North Central College","id":1188},
{"institution":"North Park University","id":1189},
{"institution":"Northern Baptist Theological Seminary","id":1190},
{"institution":"Northern Illinois University","id":1191},
{"institution":"Northwestern College-Chicago Campus","id":1192},
{"institution":"Northwestern University","id":1193},
{"institution":"Northeastern Illinois University","id":1194},
{"institution":"Oakton Community College","id":1195},
{"institution":"Oehrlein School of Cosmetology","id":1196},
{"institution":"Olivet Nazarene University","id":1197},
{"institution":"Parkland College","id":1198},
{"institution":"Pivot Point Academy-Evanston","id":1199},
{"institution":"Prairie State College","id":1200},
{"institution":"Principia College","id":1201},
{"institution":"Professional's Choice Hair Design Academy","id":1202},
{"institution":"Quincy University","id":1203},
{"institution":"Vatterott College-Quincy","id":1204},
{"institution":"The Illinois Institute of Art-Chicago","id":1205},
{"institution":"Rend Lake College","id":1206},
{"institution":"Richland Community College","id":1207},
{"institution":"Robert Morris University Illinois","id":1208},
{"institution":"Rock Valley College","id":1209},
{"institution":"Madison Media Institute - Rockford Career College","id":1210},
{"institution":"Rockford University","id":1211},
{"institution":"Roosevelt University","id":1212},
{"institution":"Dominican University","id":1213},
{"institution":"Rush University","id":1214},
{"institution":"Saint Francis Medical Center College of Nursing","id":1215},
{"institution":"University of St Francis","id":1216},
{"institution":"St John's College of Nursing","id":1217},
{"institution":"Saint Xavier University","id":1218},
{"institution":"University of Illinois at Springfield","id":1219},
{"institution":"Sauk Valley Community College","id":1220},
{"institution":"Seabury-Western Theological Seminary","id":1221},
{"institution":"Shawnee Community College","id":1222},
{"institution":"Shimer College","id":1223},
{"institution":"Saint Augustine College","id":1224},
{"institution":"University of Saint Mary of the Lake","id":1225},
{"institution":"Southeastern Illinois College","id":1226},
{"institution":"National Latino Education Institute","id":1227},
{"institution":"Spertus College","id":1228},
{"institution":"Spoon River College","id":1229},
{"institution":"Saint Anthony College of Nursing","id":1230},
{"institution":"Educators of Beauty College of Cosmetology-Sterling","id":1231},
{"institution":"Southern Illinois University-Carbondale","id":1232},
{"institution":"Southern Illinois University-Edwardsville","id":1233},
{"institution":"Southern Illinois University-System Office","id":1234},
{"institution":"Taylor Business Institute","id":1235},
{"institution":"Telshe Yeshiva-Chicago","id":1236},
{"institution":"South Suburban College","id":1237},
{"institution":"Tri-County Beauty Academy","id":1238},
{"institution":"Trinity Christian College","id":1239},
{"institution":"Trinity International University-Illinois","id":1240},
{"institution":"Triton College","id":1241},
{"institution":"University of Spa & Cosmetology Arts","id":1242},
{"institution":"University of Illinois University Administration","id":1243},
{"institution":"VanderCook College of Music","id":1244},
{"institution":"Waubonsee Community College","id":1245},
{"institution":"Resurrection University","id":1246},
{"institution":"Western Illinois University","id":1247},
{"institution":"Wheaton College","id":1248},
{"institution":"Harper College","id":1249},
{"institution":"Ancilla College","id":1250},
{"institution":"Anderson University","id":1251},
{"institution":"Apex Academy of Hair Design Inc","id":1252},
{"institution":"Ball State University","id":1253},
{"institution":"Bethel College-Indiana","id":1254},
{"institution":"Butler University","id":1255},
{"institution":"Calumet College of Saint Joseph","id":1256},
{"institution":"Christian Theological Seminary","id":1257},
{"institution":"College of Court Reporting Inc","id":1258},
{"institution":"Concordia Theological Seminary","id":1259},
{"institution":"Tricoci University of Beauty Culture-Highland","id":1260},
{"institution":"DePauw University","id":1261},
{"institution":"Don Roberts School of Hair Design","id":1262},
{"institution":"Don Roberts Beauty School","id":1263},
{"institution":"Earlham College","id":1264},
{"institution":"University of Evansville","id":1265},
{"institution":"Franklin College","id":1266},
{"institution":"Good Samaritan Hospital School of Radiologic Technology","id":1267},
{"institution":"Goshen College","id":1268},
{"institution":"Grace College and Theological Seminary","id":1269},
{"institution":"Hair Fashions By Kaye Beauty College-Noblesville","id":1270},
{"institution":"Hair Fashions By Kaye Beauty College-Indianapolis","id":1271},
{"institution":"Hanover College","id":1272},
{"institution":"J Michael Harrold Beauty Academy","id":1273},
{"institution":"Holy Cross College","id":1274},
{"institution":"PJ's College of Cosmetology-Clarksville","id":1275},
{"institution":"Huntington University","id":1276},
{"institution":"Ivy Tech Community College","id":1277},
{"institution":"Indiana University-Purdue University-Fort Wayne","id":1278},
{"institution":"Indiana University-Purdue University-Indianapolis","id":1279},
{"institution":"Harrison College-Indianapolis","id":1280},
{"institution":"University of Indianapolis","id":1281},
{"institution":"Indiana Institute of Technology","id":1282},
{"institution":"University of Southern Indiana","id":1283},
{"institution":"Indiana State University","id":1284},
{"institution":"Indiana University-Kokomo","id":1285},
{"institution":"Indiana University-South Bend","id":1286},
{"institution":"Indiana University-Bloomington","id":1287},
{"institution":"Indiana University-Northwest","id":1288},
{"institution":"Indiana University-Southeast","id":1289},
{"institution":"Indiana University-East","id":1290},
{"institution":"International Business College-Fort Wayne","id":1291},
{"institution":"International Business College-Indianapolis","id":1292},
{"institution":"ITT Educational Services Inc-System Office","id":1293},
{"institution":"ITT Technical Institute-Nashville","id":1294},
{"institution":"ITT Technical Institute-Fort Wayne","id":1295},
{"institution":"ITT Technical Institute-Indianapolis","id":1296},
{"institution":"J Everett Light Career Center","id":1297},
{"institution":"Knox Beauty College","id":1298},
{"institution":"Lafayette Beauty Academy","id":1299},
{"institution":"Brown Mackie College-Merrillville","id":1300},
{"institution":"Brown Mackie College-Michigan City","id":1301},
{"institution":"Empire Beauty School-Indianapolis","id":1302},
{"institution":"Lincoln College of Technology-Indianapolis","id":1303},
{"institution":"Manchester University","id":1304},
{"institution":"Marian University","id":1305},
{"institution":"Indiana Wesleyan University","id":1306},
{"institution":"Martin University","id":1307},
{"institution":"Masters of Cosmetology College","id":1308},
{"institution":"Anabaptist Mennonite Biblical Seminary","id":1309},
{"institution":"Merrillville Beauty College","id":1310},
{"institution":"Brown Mackie College-South Bend","id":1311},
{"institution":"Mid-America College of Funeral Service","id":1312},
{"institution":"West Michigan College of Barbering and Beauty","id":1313},
{"institution":"University of Notre Dame","id":1314},
{"institution":"Oakland City University","id":1315},
{"institution":"PJ's College of Cosmetology-Richmond","id":1316},
{"institution":"Kaplan College-Indianapolis","id":1317},
{"institution":"Purdue University-Calumet Campus","id":1318},
{"institution":"Purdue University-North Central Campus","id":1319},
{"institution":"Ravenscroft Beauty College","id":1320},
{"institution":"Rogers Academy of Hair Design","id":1321},
{"institution":"Rose-Hulman Institute of Technology","id":1322},
{"institution":"Rudae's School of Beauty Culture-Kokomo","id":1323},
{"institution":"University of Saint Francis-Fort Wayne","id":1324},
{"institution":"Saint Josephs College","id":1325},
{"institution":"Saint Mary-of-the-Woods College","id":1326},
{"institution":"Saint Mary's College","id":1327},
{"institution":"Kaplan College-Hammond","id":1328},
{"institution":"Kaplan College-Merrillville","id":1329},
{"institution":"Saint Meinrad School of Theology","id":1330},
{"institution":"Saint Elizabeth School of Nursing","id":1331},
{"institution":"Taylor University","id":1332},
{"institution":"Trine University","id":1333},
{"institution":"Valparaiso University","id":1334},
{"institution":"Vincennes Beauty College","id":1335},
{"institution":"Vincennes University","id":1336},
{"institution":"Wabash College","id":1337},
{"institution":"Allen College","id":1338},
{"institution":"AIB College of Business","id":1339},
{"institution":"Briar Cliff University","id":1340},
{"institution":"Buena Vista University","id":1341},
{"institution":"Capri College-Dubuque","id":1342},
{"institution":"Capri College-Cedar Rapids","id":1343},
{"institution":"American College of Hairstyling-Cedar Rapids","id":1344},
{"institution":"Central College","id":1345},
{"institution":"Clarke University","id":1346},
{"institution":"Coe College","id":1347},
{"institution":"Cornell College","id":1348},
{"institution":"Iowa School of Beauty-Marshalltown","id":1349},
{"institution":"Des Moines Area Community College","id":1350},
{"institution":"Divine Word College","id":1351},
{"institution":"Dordt College","id":1352},
{"institution":"Drake University","id":1353},
{"institution":"University of Dubuque","id":1354},
{"institution":"Ellsworth Community College","id":1355},
{"institution":"Emmaus Bible College","id":1356},
{"institution":"Eastern Iowa Community College District","id":1357},
{"institution":"Faith Baptist Bible College and Theological Seminary","id":1358},
{"institution":"Faust Institute of Cosmetology-Storm Lake","id":1359},
{"institution":"Graceland University-Lamoni","id":1360},
{"institution":"Grand View University","id":1361},
{"institution":"Grinnell College","id":1362},
{"institution":"Kaplan University-Mason City Campus","id":1363},
{"institution":"Kaplan University-Cedar Rapids Campus","id":1364},
{"institution":"Hamilton Technical College","id":1365},
{"institution":"Hawkeye Community College","id":1366},
{"institution":"American Hair Academy","id":1367},
{"institution":"Indian Hills Community College","id":1368},
{"institution":"Iowa Central Community College","id":1369},
{"institution":"Iowa Lakes Community College","id":1370},
{"institution":"UnityPoint Health-Des Moines School of Radiologic Technology","id":1371},
{"institution":"American College of Hairstyling-Des Moines","id":1372},
{"institution":"Iowa School of Beauty-Des Moines","id":1373},
{"institution":"Iowa State University","id":1374},
{"institution":"Iowa Wesleyan College","id":1375},
{"institution":"Iowa Western Community College","id":1376},
{"institution":"University of Iowa","id":1377},
{"institution":"Kirkwood Community College","id":1378},
{"institution":"La James International College-Cedar Falls","id":1379},
{"institution":"La James International College-Johnston","id":1380},
{"institution":"La James College of Hairstyling and Cosmetology","id":1381},
{"institution":"La James International College-Ft Dodge","id":1382},
{"institution":"Loras College","id":1383},
{"institution":"Luther College","id":1384},
{"institution":"Maharishi University of Management","id":1385},
{"institution":"Marshalltown Community College","id":1386},
{"institution":"Mercy College of Health Sciences","id":1387},
{"institution":"Morningside College","id":1388},
{"institution":"Mount Mercy University","id":1389},
{"institution":"Ashford University","id":1390},
{"institution":"North Iowa Area Community College","id":1391},
{"institution":"University of Northern Iowa","id":1392},
{"institution":"Northwestern College","id":1393},
{"institution":"Northeast Iowa Community College-Calmar","id":1394},
{"institution":"Northwest Iowa Community College","id":1395},
{"institution":"Des Moines University-Osteopathic Medical Center","id":1396},
{"institution":"Iowa School of Beauty-Ottumwa","id":1397},
{"institution":"Palmer College of Chiropractic-Davenport","id":1398},
{"institution":"PCI Academy-Ames","id":1399},
{"institution":"Saint Ambrose University","id":1400},
{"institution":"St Luke's College","id":1401},
{"institution":"La James International College-Davenport","id":1402},
{"institution":"La James International College-Iowa City","id":1403},
{"institution":"Simpson College","id":1404},
{"institution":"Southeastern Community College","id":1405},
{"institution":"Southwestern Community College","id":1406},
{"institution":"Capri College-Davenport","id":1407},
{"institution":"Iowa School of Beauty-Sioux City","id":1408},
{"institution":"E Q School of Hair Design","id":1409},
{"institution":"Upper Iowa University","id":1410},
{"institution":"Waldorf College","id":1411},
{"institution":"Wartburg College","id":1412},
{"institution":"Wartburg Theological Seminary","id":1413},
{"institution":"College of Hair Design","id":1414},
{"institution":"Western Iowa Tech Community College","id":1415},
{"institution":"William Penn University","id":1416},
{"institution":"Allen County Community College","id":1417},
{"institution":"Baker University","id":1418},
{"institution":"Barton County Community College","id":1419},
{"institution":"Benedictine College","id":1420},
{"institution":"Bethany College","id":1421},
{"institution":"Bethel College-North Newton","id":1422},
{"institution":"Brown Mackie College-Kansas City","id":1423},
{"institution":"Brown Mackie College-Salina","id":1424},
{"institution":"Bryan University","id":1425},
{"institution":"Butler Community College","id":1426},
{"institution":"Central Baptist Theological Seminary","id":1427},
{"institution":"Central Christian College of Kansas","id":1428},
{"institution":"Cloud County Community College","id":1429},
{"institution":"Coffeyville Community College","id":1430},
{"institution":"Colby Community College","id":1431},
{"institution":"Cowley County Community College","id":1432},
{"institution":"Marinello Schools of Beauty-Manhattan","id":1433},
{"institution":"Dodge City Community College","id":1434},
{"institution":"Donnelly College","id":1435},
{"institution":"Emporia State University","id":1436},
{"institution":"Flint Hills Technical College","id":1437},
{"institution":"Fort Hays State University","id":1438},
{"institution":"Barclay College","id":1439},
{"institution":"Friends University","id":1440},
{"institution":"Fort Scott Community College","id":1441},
{"institution":"Garden City Community College","id":1442},
{"institution":"Haskell Indian Nations University","id":1443},
{"institution":"Hays Academy of Hair Design","id":1444},
{"institution":"Hesston College","id":1445},
{"institution":"Highland Community College","id":1446},
{"institution":"Hutchinson Community College","id":1447},
{"institution":"Independence Community College","id":1448},
{"institution":"Johnson County Community College","id":1449},
{"institution":"Concorde Career Colleges","id":1450},
{"institution":"Kansas City Kansas Community College","id":1451},
{"institution":"University of Kansas","id":1452},
{"institution":"Newman University","id":1453},
{"institution":"Old Town Barber College-Wichita","id":1454},
{"institution":"Kansas State University","id":1455},
{"institution":"Kansas Wesleyan University","id":1456},
{"institution":"Washburn Institute of Technology","id":1457},
{"institution":"La Baron Hairdressing Academy-Overland Park","id":1458},
{"institution":"Labette Community College","id":1459},
{"institution":"Manhattan Area Technical College","id":1460},
{"institution":"Manhattan Christian College","id":1461},
{"institution":"McPherson College","id":1462},
{"institution":"MidAmerica Nazarene University","id":1463},
{"institution":"Neosho County Community College","id":1464},
{"institution":"North Central Kansas Technical College","id":1465},
{"institution":"Northwest Kansas Technical College","id":1466},
{"institution":"Ottawa University-Ottawa","id":1467},
{"institution":"Ottawa University-Kansas City","id":1468},
{"institution":"Pittsburg State University","id":1469},
{"institution":"Pratt Community College","id":1470},
{"institution":"University of Saint Mary","id":1471},
{"institution":"Salina Area Technical College","id":1472},
{"institution":"Seward County Community College and Area Technical School","id":1473},
{"institution":"Sidneys Hair Dressing College","id":1474},
{"institution":"Southwestern College","id":1475},
{"institution":"Sterling College","id":1476},
{"institution":"Tabor College","id":1477},
{"institution":"Washburn University","id":1478},
{"institution":"Wichita Area Technical College","id":1479},
{"institution":"Wichita State University","id":1480},
{"institution":"Wichita Technical Institute","id":1481},
{"institution":"Alice Lloyd College","id":1482},
{"institution":"Asbury University","id":1483},
{"institution":"Asbury Theological Seminary","id":1484},
{"institution":"Ashland Community and Technical College","id":1485},
{"institution":"Barrett and Company School of Hair Design","id":1486},
{"institution":"Bellarmine University","id":1487},
{"institution":"Berea College","id":1488},
{"institution":"PJ's College of Cosmetology-Bowling Green","id":1489},
{"institution":"Southcentral Kentucky Community and Technical College","id":1490},
{"institution":"Brescia University","id":1491},
{"institution":"Campbellsville University","id":1492},
{"institution":"Bluegrass Community and Technical College","id":1493},
{"institution":"Centre College","id":1494},
{"institution":"Clear Creek Baptist Bible College","id":1495},
{"institution":"Paul Mitchell the School-Lexington","id":1496},
{"institution":"Collins School of Cosmetology","id":1497},
{"institution":"Galen College of Nursing-Louisville","id":1498},
{"institution":"University of the Cumberlands","id":1499},
{"institution":"Eastern Kentucky University","id":1500},
{"institution":"Elizabethtown Community and Technical College","id":1501},
{"institution":"Ezell's Cosmetology School","id":1502},
{"institution":"Frontier Nursing University","id":1503},
{"institution":"Georgetown College","id":1504},
{"institution":"PJ's College of Cosmetology-Glasgow","id":1505},
{"institution":"Hazard Community and Technical College","id":1506},
{"institution":"Paul Mitchell the School-Louisville","id":1507},
{"institution":"Henderson Community College","id":1508},
{"institution":"Hopkinsville Community College","id":1509},
{"institution":"Daymar College-Paducah Main","id":1510},
{"institution":"Jefferson Community and Technical College","id":1511},
{"institution":"Jenny Lea Academy of Cosmetology","id":1512},
{"institution":"The Salon Professional Academy-Lexington","id":1513},
{"institution":"National College-Lexington","id":1514},
{"institution":"Kentucky Mountain Bible College","id":1515},
{"institution":"Kentucky State University","id":1516},
{"institution":"Kentucky Wesleyan College","id":1517},
{"institution":"University of Kentucky","id":1518},
{"institution":"Kentucky Christian University","id":1519},
{"institution":"Lexington Theological Seminary","id":1520},
{"institution":"Lindsey Wilson College","id":1521},
{"institution":"Sullivan College of Technology and Design","id":1522},
{"institution":"University of Louisville","id":1523},
{"institution":"Louisville Presbyterian Theological Seminary","id":1524},
{"institution":"Madisonville Community College","id":1525},
{"institution":"Maysville Community and Technical College","id":1526},
{"institution":"Mid-Continent University","id":1527},
{"institution":"Midway College","id":1528},
{"institution":"Morehead State University","id":1529},
{"institution":"Murray State University","id":1530},
{"institution":"Gateway Community and Technical College","id":1531},
{"institution":"Northern Kentucky University","id":1532},
{"institution":"Nutek Academy of Beauty Inc","id":1533},
{"institution":"Daymar College-Owensboro","id":1534},
{"institution":"West Kentucky Community and Technical College","id":1535},
{"institution":"Pat Wilson's Beauty College","id":1536},
{"institution":"University of Pikeville","id":1537},
{"institution":"Big Sandy Community and Technical College","id":1538},
{"institution":"Brown Mackie College-Louisville","id":1539},
{"institution":"Empire Beauty School-Chenoweth","id":1540},
{"institution":"Saint Catharine College","id":1541},
{"institution":"Empire Beauty School-Elizabethtown","id":1542},
{"institution":"Empire Beauty School-Dixie","id":1543},
{"institution":"Empire Beauty School-Florence","id":1544},
{"institution":"Brown Mackie College-Northern Kentucky","id":1545},
{"institution":"Somerset Community College","id":1546},
{"institution":"Southeast Kentucky Community and Technical College","id":1547},
{"institution":"The Southern Baptist Theological Seminary","id":1548},
{"institution":"Spalding University","id":1549},
{"institution":"Spencerian College-Louisville","id":1550},
{"institution":"Sullivan University","id":1551},
{"institution":"Thomas More College","id":1552},
{"institution":"Transylvania University","id":1553},
{"institution":"Trend Setters' Academy of Beauty Culture-Louisville","id":1554},
{"institution":"Kentucky Community and Technical College System","id":1555},
{"institution":"Union College","id":1556},
{"institution":"Western Kentucky University","id":1557},
{"institution":"Central Louisiana Technical Community College","id":1558},
{"institution":"American School of Business","id":1559},
{"institution":"Ayers Career College","id":1560},
{"institution":"Baton Rouge General Medical Center-School of Nursing","id":1561},
{"institution":"Baton Rouge General Medical Center-Radiologic Technology","id":1562},
{"institution":"Baton Rouge School of Computers","id":1563},
{"institution":"Capital Area Technical College","id":1564},
{"institution":"Bossier Parish Community College","id":1565},
{"institution":"Cameron College","id":1566},
{"institution":"Centenary College of Louisiana","id":1567},
{"institution":"Cloyd's Beauty School 1 Inc","id":1568},
{"institution":"Crescent City Bartending School","id":1569},
{"institution":"D'Jay's School of Beauty Arts and Sciences","id":1570},
{"institution":"Delgado Community College","id":1571},
{"institution":"Delta School of Business and Technology","id":1572},
{"institution":"Northeast Louisiana Technical College","id":1573},
{"institution":"Demmons School of Beauty","id":1574},
{"institution":"Denham Springs Beauty School","id":1575},
{"institution":"Dillard University","id":1576},
{"institution":"Nunez Community College","id":1577},
{"institution":"Grambling State University","id":1578},
{"institution":"Guy's Shreveport Academy of Cosmetology Inc","id":1579},
{"institution":"ITI Technical College","id":1580},
{"institution":"John Jay Beauty College","id":1581},
{"institution":"John Jay Beauty College","id":1582},
{"institution":"Louisiana State University Health Sciences Center-New Orleans","id":1583},
{"institution":"Louisiana State University-Alexandria","id":1584},
{"institution":"Louisiana State University and Agricultural & Mechanical College","id":1585},
{"institution":"Louisiana State University-Eunice","id":1586},
{"institution":"Louisiana State University-Shreveport","id":1587},
{"institution":"Acadiana Technical College-Lafayette Campus","id":1588},
{"institution":"Aveda Institute-Baton Rouge","id":1589},
{"institution":"Aveda Institute-Lafayette","id":1590},
{"institution":"Louisiana Academy of Beauty","id":1591},
{"institution":"Louisiana College","id":1592},
{"institution":"Opelousas School of Cosmetology Inc","id":1593},
{"institution":"Louisiana State University-System Office","id":1594},
{"institution":"Louisiana Tech University","id":1595},
{"institution":"Loyola University New Orleans","id":1596},
{"institution":"McNeese State University","id":1597},
{"institution":"University of New Orleans","id":1598},
{"institution":"New Orleans Baptist Theological Seminary","id":1599},
{"institution":"Nicholls State University","id":1600},
{"institution":"University of Louisiana at Monroe","id":1601},
{"institution":"Northwest Louisiana Technical College","id":1602},
{"institution":"Notre Dame Seminary Graduate School of Theology","id":1603},
{"institution":"Northwestern State University of Louisiana","id":1604},
{"institution":"Our Lady of Holy Cross College","id":1605},
{"institution":"Our Lady of the Lake College","id":1606},
{"institution":"Pat Goins Benton Road Beauty School","id":1607},
{"institution":"Pat Goins Monroe Beauty School","id":1608},
{"institution":"Pat Goins Ruston Beauty School","id":1609},
{"institution":"Pineville Beauty School","id":1610},
{"institution":"Aveda Institute-Covington","id":1611},
{"institution":"Saint Joseph Seminary College","id":1612},
{"institution":"Vanguard College of Cosmetology-Slidell","id":1613},
{"institution":"South Louisiana Beauty College","id":1614},
{"institution":"L E Fletcher Technical Community College","id":1615},
{"institution":"Remington College-Lafayette Campus","id":1616},
{"institution":"Southern University-Board and System","id":1617},
{"institution":"SOWELA Technical Community College","id":1618},
{"institution":"Stevensons Academy of Hair Design","id":1619},
{"institution":"Southeastern Louisiana University","id":1620},
{"institution":"Southern University and A & M College","id":1621},
{"institution":"Southern University at New Orleans","id":1622},
{"institution":"Southern University at Shreveport","id":1623},
{"institution":"University of Louisiana at Lafayette","id":1624},
{"institution":"Northshore Technical Community College","id":1625},
{"institution":"Tulane University of Louisiana","id":1626},
{"institution":"Xavier University of Louisiana","id":1627},
{"institution":"South Central Louisiana Technical College-Young Memorial Campus","id":1628},
{"institution":"Kaplan University-Maine Campus","id":1629},
{"institution":"College of the Atlantic","id":1630},
{"institution":"Bangor Theological Seminary","id":1631},
{"institution":"Bates College","id":1632},
{"institution":"Beal College","id":1633},
{"institution":"Bowdoin College","id":1634},
{"institution":"Central Maine Medical Center College of Nursing and Health Professions","id":1635},
{"institution":"Central Maine Community College","id":1636},
{"institution":"Colby College","id":1637},
{"institution":"Eastern Maine Community College","id":1638},
{"institution":"Husson University","id":1639},
{"institution":"Kennebec Valley Community College","id":1640},
{"institution":"The Landing School","id":1641},
{"institution":"University of Maine at Augusta","id":1642},
{"institution":"University of Maine at Farmington","id":1643},
{"institution":"University of Maine at Fort Kent","id":1644},
{"institution":"University of Maine at Machias","id":1645},
{"institution":"University of Maine","id":1646},
{"institution":"University of Maine-System Central Office","id":1647},
{"institution":"Maine Maritime Academy","id":1648},
{"institution":"University of Maine at Presque Isle","id":1649},
{"institution":"University of New England","id":1650},
{"institution":"Northern Maine Community College","id":1651},
{"institution":"Empire Beauty School-Maine","id":1652},
{"institution":"Maine College of Art","id":1653},
{"institution":"Saint Joseph's College of Maine","id":1654},
{"institution":"Spa Tech Institute-Westbrook","id":1655},
{"institution":"Southern Maine Community College","id":1656},
{"institution":"University of Southern Maine","id":1657},
{"institution":"Thomas College","id":1658},
{"institution":"Unity College","id":1659},
{"institution":"Washington County Community College","id":1660},
{"institution":"Aaron's Academy of Beauty","id":1661},
{"institution":"Aesthetics Institute of Cosmetology","id":1662},
{"institution":"Allegany College of Maryland","id":1663},
{"institution":"Anne Arundel Community College","id":1664},
{"institution":"TESST College of Technology-Towson","id":1665},
{"institution":"Award Beauty School","id":1666},
{"institution":"Baltimore Studio of Hair Design","id":1667},
{"institution":"Baltimore City Community College","id":1668},
{"institution":"University of Baltimore","id":1669},
{"institution":"Bowie State University","id":1670},
{"institution":"Capitol College","id":1671},
{"institution":"Cecil College","id":1672},
{"institution":"College of Southern Maryland","id":1673},
{"institution":"Chesapeake College","id":1674},
{"institution":"Washington Adventist University","id":1675},
{"institution":"Coppin State University","id":1676},
{"institution":"Delmarva Beauty Academy","id":1677},
{"institution":"North American Trade Schools","id":1678},
{"institution":"Frederick Community College","id":1679},
{"institution":"Frostburg State University","id":1680},
{"institution":"Garrett College","id":1681},
{"institution":"Goucher College","id":1682},
{"institution":"Kaplan University-Hagerstown Campus","id":1683},
{"institution":"Hagerstown Community College","id":1684},
{"institution":"Harford Community College","id":1685},
{"institution":"Hood College","id":1686},
{"institution":"Howard Community College","id":1687},
{"institution":"The Colorlab Academy of Hair","id":1688},
{"institution":"Johns Hopkins University","id":1689},
{"institution":"L'Academie de Cuisine","id":1690},
{"institution":"Lincoln College of Technology-Columbia","id":1691},
{"institution":"Loyola University Maryland","id":1692},
{"institution":"Maryland Beauty Academy of Reisterstown","id":1693},
{"institution":"University of Maryland-University College","id":1694},
{"institution":"University of Maryland-Baltimore","id":1695},
{"institution":"University of Maryland-Baltimore County","id":1696},
{"institution":"University of Maryland-College Park","id":1697},
{"institution":"Maryland Institute College of Art","id":1698},
{"institution":"University of Maryland Eastern Shore","id":1699},
{"institution":"Fortis Institute-Towson","id":1700},
{"institution":"Montgomery Beauty School","id":1701},
{"institution":"Montgomery College","id":1702},
{"institution":"Morgan State University","id":1703},
{"institution":"Mount St Mary's University","id":1704},
{"institution":"Ner Israel Rabbinical College","id":1705},
{"institution":"Hair Academy Inc-New Carrollton","id":1706},
{"institution":"Notre Dame of Maryland University","id":1707},
{"institution":"Prince George's Community College","id":1708},
{"institution":"TESST College of Technology-Baltimore","id":1709},
{"institution":"Robert Paul Academy of Cosmetology Arts & Sciences","id":1710},
{"institution":"Maryland Beauty Academy of Essex","id":1711},
{"institution":"St Mary's Seminary & University","id":1712},
{"institution":"Salisbury University","id":1713},
{"institution":"St Mary's College of Maryland","id":1714},
{"institution":"Sojourner-Douglass College","id":1715},
{"institution":"St John's College","id":1716},
{"institution":"TESST College of Technology-Beltsville","id":1717},
{"institution":"Towson University","id":1718},
{"institution":"Maryland University of Integrative Health","id":1719},
{"institution":"International Beauty School","id":1720},
{"institution":"University System of Maryland","id":1721},
{"institution":"United States Naval Academy","id":1722},
{"institution":"Stevenson University","id":1723},
{"institution":"Washington Bible College-Capital Bible Seminary","id":1724},
{"institution":"Washington College","id":1725},
{"institution":"Washington Theological Union","id":1726},
{"institution":"McDaniel College","id":1727},
{"institution":"Wor-Wic Community College","id":1728},
{"institution":"Hult International Business School","id":1729},
{"institution":"New England College of Business and Finance","id":1730},
{"institution":"American International College","id":1731},
{"institution":"Amherst College","id":1732},
{"institution":"Andover Newton Theological School","id":1733},
{"institution":"Anna Maria College","id":1734},
{"institution":"Assabet Valley Regional Technical School","id":1735},
{"institution":"Assumption College","id":1736},
{"institution":"Babson College","id":1737},
{"institution":"Bancroft School of Massage Therapy","id":1738},
{"institution":"Boston Baptist College","id":1739},
{"institution":"Bay Path College","id":1740},
{"institution":"Bay State College","id":1741},
{"institution":"Bay State School of Technology","id":1742},
{"institution":"Becker College","id":1743},
{"institution":"Bentley University","id":1744},
{"institution":"Berklee College of Music","id":1745},
{"institution":"Berkshire Community College","id":1746},
{"institution":"Empire Beauty School-Boston","id":1747},
{"institution":"Boston Architectural College","id":1748},
{"institution":"Boston Graduate School of Psychoanalysis Inc","id":1749},
{"institution":"Boston College","id":1750},
{"institution":"The Boston Conservatory","id":1751},
{"institution":"Boston University","id":1752},
{"institution":"Brandeis University","id":1753},
{"institution":"Bridgewater State University","id":1754},
{"institution":"Bristol Community College","id":1755},
{"institution":"Signature Healthcare Brockton Hospital School of Nursing","id":1756},
{"institution":"Bunker Hill Community College","id":1757},
{"institution":"Cambridge College","id":1758},
{"institution":"Cape Cod Community College","id":1759},
{"institution":"Catherine Hinds Institute of Esthetics","id":1760},
{"institution":"Laboure College","id":1761},
{"institution":"Clark University","id":1762},
{"institution":"Conway School of Landscape Design","id":1763},
{"institution":"Curry College","id":1764},
{"institution":"Lincoln Technical Institute-Somerville","id":1765},
{"institution":"Dean College","id":1766},
{"institution":"Diman Regional Technical Institute","id":1767},
{"institution":"National Aviation Academy of New England","id":1768},
{"institution":"Eastern Nazarene College","id":1769},
{"institution":"Emerson College","id":1770},
{"institution":"Emmanuel College","id":1771},
{"institution":"Endicott College","id":1772},
{"institution":"Episcopal Divinity School","id":1773},
{"institution":"Rob Roy Academy-Fall River","id":1774},
{"institution":"Fisher College","id":1775},
{"institution":"Fitchburg State University","id":1776},
{"institution":"Framingham State University","id":1777},
{"institution":"Benjamin Franklin Institute of Technology","id":1778},
{"institution":"Gordon College","id":1779},
{"institution":"Gordon-Conwell Theological Seminary","id":1780},
{"institution":"Greenfield Community College","id":1781},
{"institution":"Hallmark Institute of Photography","id":1782},
{"institution":"Hampshire College","id":1783},
{"institution":"Harvard University","id":1784},
{"institution":"Hebrew College","id":1785},
{"institution":"Hellenic College-Holy Cross Greek Orthodox School of Theology","id":1786},
{"institution":"Henris School of Hair Design","id":1787},
{"institution":"College of the Holy Cross","id":1788},
{"institution":"Holyoke Community College","id":1789},
{"institution":"Sanford-Brown College-Boston","id":1790},
{"institution":"Kay Harvey Academy of Hair Design","id":1791},
{"institution":"La Baron Hairdressing Academy-Brockton","id":1792},
{"institution":"La Baron Hairdressing Academy-New Bedford","id":1793},
{"institution":"Lasell College","id":1794},
{"institution":"Lawrence Memorial Hospital School of Nursing","id":1795},
{"institution":"Lesley University","id":1796},
{"institution":"Longy School of Music of Bard College","id":1797},
{"institution":"Lowell Academy Hairstyling Institute","id":1798},
{"institution":"University of Massachusetts-Lowell","id":1799},
{"institution":"Mansfield Beauty Schools-Springfield","id":1800},
{"institution":"Mansfield Beauty Schools-Quincy","id":1801},
{"institution":"Marian Court College","id":1802},
{"institution":"University of Massachusetts-Amherst","id":1803},
{"institution":"University of Massachusetts-Boston","id":1804},
{"institution":"Massachusetts Bay Community College","id":1805},
{"institution":"MCPHS University","id":1806},
{"institution":"University of Massachusetts-Central Office","id":1807},
{"institution":"Massachusetts College of Art and Design","id":1808},
{"institution":"Massachusetts Institute of Technology","id":1809},
{"institution":"Massachusetts Maritime Academy","id":1810},
{"institution":"University of Massachusetts Medical School Worcester","id":1811},
{"institution":"Massachusetts School of Professional Psychology","id":1812},
{"institution":"Massachusetts School of Barbering","id":1813},
{"institution":"Massasoit Community College","id":1814},
{"institution":"Merrimack College","id":1815},
{"institution":"MGH Institute of Health Professions","id":1816},
{"institution":"Middlesex Community College","id":1817},
{"institution":"Montserrat College of Art","id":1818},
{"institution":"Mount Holyoke College","id":1819},
{"institution":"Mount Ida College","id":1820},
{"institution":"Mount Wachusett Community College","id":1821},
{"institution":"Cortiva Institute-Boston","id":1822},
{"institution":"School of the Museum of Fine Arts-Boston","id":1823},
{"institution":"Everest Institute-Brighton","id":1824},
{"institution":"Rob Roy Academy-New Bedford","id":1825},
{"institution":"The New England Conservatory of Music","id":1826},
{"institution":"New England College of Optometry","id":1827},
{"institution":"New England School of Acupuncture","id":1828},
{"institution":"New England School of Law","id":1829},
{"institution":"New England School of Photography","id":1830},
{"institution":"Newbury College","id":1831},
{"institution":"Nichols College","id":1832},
{"institution":"Massachusetts College of Liberal Arts","id":1833},
{"institution":"North Bennet Street School","id":1834},
{"institution":"North Shore Community College","id":1835},
{"institution":"The New England Institute of Art","id":1836},
{"institution":"Northeastern University","id":1837},
{"institution":"Northern Essex Community College","id":1838},
{"institution":"College of Our Lady of the Elms","id":1839},
{"institution":"Pine Manor College","id":1840},
{"institution":"Blessed John XXIII National Seminary","id":1841},
{"institution":"Quincy College","id":1842},
{"institution":"Quinsigamond Community College","id":1843},
{"institution":"Kaplan Career Institute-Charlestown","id":1844},
{"institution":"Regis College","id":1845},
{"institution":"Roxbury Community College","id":1846},
{"institution":"Saint John's Seminary","id":1847},
{"institution":"Salem State University","id":1848},
{"institution":"Salter College-West Boylston","id":1849},
{"institution":"Simmons College","id":1850},
{"institution":"Bard College at Simon's Rock","id":1851},
{"institution":"Smith College","id":1852},
{"institution":"Southeastern Technical Institute","id":1853},
{"institution":"Springfield College","id":1854},
{"institution":"Springfield Technical Community College","id":1855},
{"institution":"University of Massachusetts-Dartmouth","id":1856},
{"institution":"Stonehill College","id":1857},
{"institution":"Suffolk University","id":1858},
{"institution":"Rob Roy Academy-Taunton","id":1859},
{"institution":"Tufts University","id":1860},
{"institution":"Wellesley College","id":1861},
{"institution":"Wentworth Institute of Technology","id":1862},
{"institution":"Western New England University","id":1863},
{"institution":"Westfield State University","id":1864},
{"institution":"Wheaton College","id":1865},
{"institution":"Wheelock College","id":1866},
{"institution":"Williams College","id":1867},
{"institution":"Worcester Polytechnic Institute","id":1868},
{"institution":"Worcester State University","id":1869},
{"institution":"Adrian College","id":1870},
{"institution":"Albion College","id":1871},
{"institution":"Hillsdale Beauty College","id":1872},
{"institution":"Northwestern Technological Institute","id":1873},
{"institution":"Alma College","id":1874},
{"institution":"Alpena Community College","id":1875},
{"institution":"Andrews University","id":1876},
{"institution":"Aquinas College","id":1877},
{"institution":"Baker College of Owosso","id":1878},
{"institution":"Baker College of Flint","id":1879},
{"institution":"Bay de Noc Community College","id":1880},
{"institution":"Bayshire Academy of Beauty Craft Inc","id":1881},
{"institution":"Cadillac Institute of Cosmetology","id":1882},
{"institution":"Calvin College","id":1883},
{"institution":"Calvin Theological Seminary","id":1884},
{"institution":"Carnegie Institute","id":1885},
{"institution":"Michigan School of Professional Psychology","id":1886},
{"institution":"Central Michigan University","id":1887},
{"institution":"Mott Community College","id":1888},
{"institution":"Empire Beauty School-Michigan","id":1889},
{"institution":"Cleary University","id":1890},
{"institution":"Concordia University-Ann Arbor","id":1891},
{"institution":"Cranbrook Academy of Art","id":1892},
{"institution":"College for Creative Studies","id":1893},
{"institution":"Davenport University","id":1894},
{"institution":"David Pressley School of Cosmetology","id":1895},
{"institution":"Delta College","id":1896},
{"institution":"Detroit Business Institute-Downriver","id":1897},
{"institution":"Michigan State University-College of Law","id":1898},
{"institution":"MIAT College of Technology","id":1899},
{"institution":"University of Detroit Mercy","id":1900},
{"institution":"Dorsey Business Schools-Madison Heights","id":1901},
{"institution":"Dorsey Business Schools-Southgate","id":1902},
{"institution":"Eastern Michigan University","id":1903},
{"institution":"Ferris State University","id":1904},
{"institution":"Flint Institute of Barbering Inc","id":1905},
{"institution":"Glen Oaks Community College","id":1906},
{"institution":"Kettering University","id":1907},
{"institution":"Gogebic Community College","id":1908},
{"institution":"Grace Bible College","id":1909},
{"institution":"Cornerstone University","id":1910},
{"institution":"Everest Institute-Grand Rapids","id":1911},
{"institution":"Grand Rapids Community College","id":1912},
{"institution":"Grand Valley State University","id":1913},
{"institution":"Great Lakes Christian College","id":1914},
{"institution":"Henry Ford Community College","id":1915},
{"institution":"Hope College","id":1916},
{"institution":"ITT Technical Institute-Wyoming","id":1917},
{"institution":"Jackson College","id":1918},
{"institution":"Wright Beauty Academy","id":1919},
{"institution":"Kalamazoo College","id":1920},
{"institution":"Kalamazoo Valley Community College","id":1921},
{"institution":"Kellogg Community College","id":1922},
{"institution":"Kirtland Community College","id":1923},
{"institution":"Michigan College of Beauty-Monroe","id":1924},
{"institution":"Lake Michigan College","id":1925},
{"institution":"Lake Superior State University","id":1926},
{"institution":"Lansing Community College","id":1927},
{"institution":"Lawrence Technological University","id":1928},
{"institution":"M J Murphy Beauty College of Mount Pleasant","id":1929},
{"institution":"Macomb Community College","id":1930},
{"institution":"Madonna University","id":1931},
{"institution":"Marygrove College","id":1932},
{"institution":"Rochester College","id":1933},
{"institution":"University of Michigan-Ann Arbor","id":1934},
{"institution":"Michigan Barber School Inc","id":1935},
{"institution":"Michigan State University","id":1936},
{"institution":"Michigan Technological University","id":1937},
{"institution":"University of Michigan-Dearborn","id":1938},
{"institution":"University of Michigan-Flint","id":1939},
{"institution":"Mid Michigan Community College","id":1940},
{"institution":"Monroe County Community College","id":1941},
{"institution":"Montcalm Community College","id":1942},
{"institution":"Mr Bela's School of Cosmetology Inc","id":1943},
{"institution":"Baker College of Muskegon","id":1944},
{"institution":"Muskegon Community College","id":1945},
{"institution":"Everest College-Skokie","id":1946},
{"institution":"Everest Institute-Southfield","id":1947},
{"institution":"North Central Michigan College","id":1948},
{"institution":"Northern Michigan University","id":1949},
{"institution":"Paul Mitchell the School-Escanaba","id":1950},
{"institution":"Northwestern Michigan College","id":1951},
{"institution":"Northwood University-Michigan","id":1952},
{"institution":"Oakland Community College","id":1953},
{"institution":"Oakland University","id":1954},
{"institution":"Olivet College","id":1955},
{"institution":"Port Huron Cosmetology College","id":1956},
{"institution":"Kuyper College","id":1957},
{"institution":"Ross Medical Education Center-Lansing","id":1958},
{"institution":"Ross Medical Education Center-Madison Heights","id":1959},
{"institution":"Ross Medical Education Center-Flint","id":1960},
{"institution":"Sacred Heart Major Seminary","id":1961},
{"institution":"Saginaw Valley State University","id":1962},
{"institution":"Schoolcraft College","id":1963},
{"institution":"Siena Heights University","id":1964},
{"institution":"St Clair County Community College","id":1965},
{"institution":"Southwestern Michigan College","id":1966},
{"institution":"Specs Howard School of Media Arts","id":1967},
{"institution":"Spring Arbor University","id":1968},
{"institution":"Michigan Career and Technical Institute","id":1969},
{"institution":"Finlandia University","id":1970},
{"institution":"Thomas M Cooley Law Schoo","id":1971},
{"institution":"Twin City Beauty College","id":1972},
{"institution":"Walsh College of Accountancy and Business Administration","id":1973},
{"institution":"Washtenaw Community College","id":1974},
{"institution":"Wayne County Community College District","id":1975},
{"institution":"Wayne State University","id":1976},
{"institution":"West Shore Community College","id":1977},
{"institution":"Western Michigan University","id":1978},
{"institution":"Western Theological Seminary","id":1979},
{"institution":"Wright Beauty Academy","id":1980},
{"institution":"Academy College","id":1981},
{"institution":"Alexandria Technical & Community College","id":1982},
{"institution":"American Indian OIC Inc","id":1983},
{"institution":"Anoka Technical College","id":1984},
{"institution":"Anoka-Ramsey Community College","id":1985},
{"institution":"Apostolic Bible Institute Inc","id":1986},
{"institution":"Augsburg College","id":1987},
{"institution":"Riverland Community College","id":1988},
{"institution":"Northwest Technical College","id":1989},
{"institution":"Bemidji State University","id":1990},
{"institution":"Bethany Lutheran College","id":1991},
{"institution":"Bethel University","id":1992},
{"institution":"Bethel Seminary-St Paul","id":1993},
{"institution":"Central Lakes College-Brainerd","id":1994},
{"institution":"Carleton College","id":1995},
{"institution":"Concordia College at Moorhead","id":1996},
{"institution":"Concordia University-Saint Paul","id":1997},
{"institution":"Dakota County Technical College","id":1998},
{"institution":"Martin Luther College","id":1999},
{"institution":"Lake Superior College","id":2000},
{"institution":"Cosmetology Careers Unlimited College of Hair Skin and Nails","id":2001},
{"institution":"Duluth Business University","id":2002},
{"institution":"Minnesota State Community and Technical College","id":2003},
{"institution":"Globe University-Woodbury","id":2004},
{"institution":"Minnesota West Community and Technical College","id":2005},
{"institution":"Gustavus Adolphus College","id":2006},
{"institution":"Hamline University","id":2007},
{"institution":"Hazelden Graduate School of Addiction Studies","id":2008},
{"institution":"Hennepin Technical College","id":2009},
{"institution":"Cosmetology Careers Unlimited College of Hair Skin and Nails","id":2010},
{"institution":"Hibbing Community College","id":2011},
{"institution":"Aveda Institute-Minneapolis","id":2012},
{"institution":"Inver Hills Community College","id":2013},
{"institution":"Itasca Community College","id":2014},
{"institution":"The Art Institutes International-Minnesota","id":2015},
{"institution":"Luther Seminary","id":2016},
{"institution":"Macalester College","id":2017},
{"institution":"South Central College","id":2018},
{"institution":"Minnesota State University-Mankato","id":2019},
{"institution":"Regency Beauty Institute-Blaine","id":2020},
{"institution":"Mayo Medical School","id":2021},
{"institution":"Mayo School of Health Sciences","id":2022},
{"institution":"Argosy University-Twin Cities","id":2023},
{"institution":"Mesabi Range Community and Technical College","id":2024},
{"institution":"Metropolitan State University","id":2025},
{"institution":"University of Minnesota-Twin Cities","id":2026},
{"institution":"University of Minnesota-Crookston","id":2027},
{"institution":"Minneapolis Business College","id":2028},
{"institution":"Minneapolis College of Art and Design","id":2029},
{"institution":"Minneapolis Community and Technical College","id":2030},
{"institution":"Herzing University-Minneapolis","id":2031},
{"institution":"Minnesota School of Cosmetology-Woodbury Campus","id":2032},
{"institution":"Crossroads College","id":2033},
{"institution":"University of Minnesota-Duluth","id":2034},
{"institution":"University of Minnesota-Morris","id":2035},
{"institution":"Minnesota School of Business-Richfield","id":2036},
{"institution":"Model College of Hair Design","id":2037},
{"institution":"Minnesota State University-Moorhead","id":2038},
{"institution":"North Hennepin Community College","id":2039},
{"institution":"National American University-Roseville","id":2040},
{"institution":"Brown College-Mendota Heights","id":2041},
{"institution":"Normandale Community College","id":2042},
{"institution":"North Central University","id":2043},
{"institution":"Northland Community and Technical College","id":2044},
{"institution":"Northwest Technical Institute","id":2045},
{"institution":"University of Northwestern-St Paul","id":2046},
{"institution":"Northwestern Health Sciences University","id":2047},
{"institution":"Oak Hills Christian College","id":2048},
{"institution":"Regency Beauty Institute-Burnsville","id":2049},
{"institution":"Pine Technical College","id":2050},
{"institution":"Rainy River Community College","id":2051},
{"institution":"Rochester Community and Technical College","id":2052},
{"institution":"College of Saint Benedict","id":2053},
{"institution":"St Cloud Technical and Community College","id":2054},
{"institution":"Regency Beauty Institute-St Cloud","id":2055},
{"institution":"Saint Cloud State University","id":2056},
{"institution":"Saint Johns University","id":2057},
{"institution":"Saint Mary's University of Minnesota","id":2058},
{"institution":"St Olaf College","id":2059},
{"institution":"Crown College","id":2060},
{"institution":"The College of Saint Scholastica","id":2061},
{"institution":"University of St Thomas","id":2062},
{"institution":"College of Visual Arts","id":2063},
{"institution":"Empire Beauty School-Bloomington","id":2064},
{"institution":"St Catherine University","id":2065},
{"institution":"Rasmussen College-Minnesota","id":2066},
{"institution":"Saint Paul College","id":2067},
{"institution":"Southwest Minnesota State University","id":2068},
{"institution":"Summit Academy Opportunities Industrialization Center","id":2069},
{"institution":"United Theological Seminary of the Twin Cities","id":2070},
{"institution":"Vermilion Community College","id":2071},
{"institution":"Dunwoody College of Technology","id":2072},
{"institution":"Ridgewater College","id":2073},
{"institution":"Minnesota State College-Southeast Technical","id":2074},
{"institution":"Winona State University","id":2075},
{"institution":"William Mitchell College of Law","id":2076},
{"institution":"Century College","id":2077},
{"institution":"Alcorn State University","id":2078},
{"institution":"Belhaven University","id":2079},
{"institution":"Blue Mountain College","id":2080},
{"institution":"Chris Beauty College","id":2081},
{"institution":"Coahoma Community College","id":2082},
{"institution":"Antonelli College-Jackson","id":2083},
{"institution":"Copiah-Lincoln Community College","id":2084},
{"institution":"Creations College of Cosmetology","id":2085},
{"institution":"Delta Beauty College","id":2086},
{"institution":"Delta State University","id":2087},
{"institution":"East Central Community College","id":2088},
{"institution":"East Mississippi Community College","id":2089},
{"institution":"Fosters Cosmetology College","id":2090},
{"institution":"Academy of Hair Design-Grenada","id":2091},
{"institution":"Hinds Community College","id":2092},
{"institution":"Holmes Community College","id":2093},
{"institution":"Itawamba Community College","id":2094},
{"institution":"Jackson State University","id":2095},
{"institution":"Jones County Junior College","id":2096},
{"institution":"Meridian Community College","id":2097},
{"institution":"Millsaps College","id":2098},
{"institution":"Board of Trustees-Mississippi State Institutions of Higher Learning","id":2099},
{"institution":"Mississippi Delta Community College","id":2100},
{"institution":"University of Mississippi","id":2101},
{"institution":"University of Mississippi Medical Center","id":2102},
{"institution":"Mississippi University for Women","id":2103},
{"institution":"Mississippi Valley State University","id":2104},
{"institution":"Mississippi College","id":2105},
{"institution":"Mississippi College of Beauty Culture","id":2106},
{"institution":"Mississippi Gulf Coast Community College","id":2107},
{"institution":"Mississippi State University","id":2108},
{"institution":"Northeast Mississippi Community College","id":2109},
{"institution":"Northwest Mississippi Community College","id":2110},
{"institution":"Pearl River Community College","id":2111},
{"institution":"Rust College","id":2112},
{"institution":"Southeastern Baptist College","id":2113},
{"institution":"Southwest Mississippi Community College","id":2114},
{"institution":"University of Southern Mississippi","id":2115},
{"institution":"Magnolia College of Cosmetology","id":2116},
{"institution":"Tougaloo College","id":2117},
{"institution":"Wesley Biblical Seminary","id":2118},
{"institution":"William Carey University","id":2119},
{"institution":"Anthem College-Maryland Heights","id":2120},
{"institution":"Aquinas Institute of Theology","id":2121},
{"institution":"Assemblies of God Theological Seminary","id":2122},
{"institution":"Avila University","id":2123},
{"institution":"ITT Technical Institute-Earth City","id":2124},
{"institution":"Baptist Bible College","id":2125},
{"institution":"House of Heavilin Beauty College-Blue Springs","id":2126},
{"institution":"Boonslick Technical Education Center","id":2127},
{"institution":"Cox College","id":2128},
{"institution":"Calvary Bible College and Theological Seminary","id":2129},
{"institution":"Cape Girardeau Career and Technology Center","id":2130},
{"institution":"Central Christian College of the Bible","id":2131},
{"institution":"Central Bible College","id":2132},
{"institution":"Central Methodist University-College of Liberal Arts and Sciences","id":2133},
{"institution":"University of Central Missouri","id":2134},
{"institution":"Grand River Technical School","id":2135},
{"institution":"Chillicothe Beauty Academy Inc","id":2136},
{"institution":"Cleveland University-Kansas City","id":2137},
{"institution":"Columbia Area Career Center","id":2138},
{"institution":"Columbia College","id":2139},
{"institution":"Conception Seminary College","id":2140},
{"institution":"Concordia Seminary","id":2141},
{"institution":"Cottey College","id":2142},
{"institution":"Covenant Theological Seminary","id":2143},
{"institution":"Crowder College","id":2144},
{"institution":"Culver-Stockton College","id":2145},
{"institution":"DeVry University-Missouri","id":2146},
{"institution":"Drury University","id":2147},
{"institution":"East Central College","id":2148},
{"institution":"Eden Theological Seminary","id":2149},
{"institution":"Elaine Steven Beauty College","id":2150},
{"institution":"Pinnacle Career Institute-South Kansas City","id":2151},
{"institution":"Evangel University","id":2152},
{"institution":"Fontbonne University","id":2153},
{"institution":"Forest Institute of Professional Psychology","id":2154},
{"institution":"Four Rivers Career Center","id":2155},
{"institution":"Ozarks Technical Community College","id":2156},
{"institution":"Hannibal-LaGrange University","id":2157},
{"institution":"Harris-Stowe State University","id":2158},
{"institution":"Hickey College","id":2159},
{"institution":"House of Heavilin Beauty College-Kansas City","id":2160},
{"institution":"Independence College of Cosmetology","id":2161},
{"institution":"Jefferson College","id":2162},
{"institution":"Cosmetology Concepts Institute","id":2163},
{"institution":"Barnes-Jewish College Goldfarb School of Nursing","id":2164},
{"institution":"Kansas City Art Institute","id":2165},
{"institution":"Kennett Career and Technology Center","id":2166},
{"institution":"Kenrick Glennon Seminary","id":2167},
{"institution":"A T Still University of Health Sciences","id":2168},
{"institution":"Lake Career and Technical Center","id":2169},
{"institution":"Lex La-Ray Technical Center","id":2170},
{"institution":"Lincoln University","id":2171},
{"institution":"Lindenwood University","id":2172},
{"institution":"Linn State Technical College","id":2173},
{"institution":"Logan University","id":2174},
{"institution":"Metropolitan Community College-Kansas City","id":2175},
{"institution":"Lutheran School of Nursing","id":2176},
{"institution":"Maryville University of Saint Louis","id":2177},
{"institution":"Merrell University of Beauty Arts and Science","id":2178},
{"institution":"Metro Business College-Cape Girardeau","id":2179},
{"institution":"Metropolitan Community College-Kansas City","id":2180},
{"institution":"Midwest Institute","id":2181},
{"institution":"Midwestern Baptist Theological Seminary","id":2182},
{"institution":"Mineral Area College","id":2183},
{"institution":"Westwood College-O'Hare Airport","id":2184},
{"institution":"Missouri Baptist University","id":2185},
{"institution":"National Academy of Beauty Arts-Farmington","id":2186},
{"institution":"Missouri College","id":2187},
{"institution":"Missouri School of Barbering & Hairstyling","id":2188},
{"institution":"Missouri Southern State University","id":2189},
{"institution":"Missouri Tech","id":2190},
{"institution":"Missouri Valley College","id":2191},
{"institution":"Missouri Western State University","id":2192},
{"institution":"University of Missouri-Columbia","id":2193},
{"institution":"University of Missouri-Kansas City","id":2194},
{"institution":"Missouri University of Science and Technology","id":2195},
{"institution":"University of Missouri-St Louis","id":2196},
{"institution":"University of Missouri-System Office","id":2197},
{"institution":"Moberly Area Community College","id":2198},
{"institution":"Hillyard Technical Center","id":2199},
{"institution":"National Academy of Beauty Arts-St Louis","id":2200},
{"institution":"Nazarene Theological Seminary","id":2201},
{"institution":"Neosho Beauty College","id":2202},
{"institution":"Nichols Career Center","id":2203},
{"institution":"Northwest Technical School","id":2204},
{"institution":"Truman State University","id":2205},
{"institution":"Northwest Missouri State University","id":2206},
{"institution":"Ozark Christian College","id":2207},
{"institution":"College of the Ozarks","id":2208},
{"institution":"Park University","id":2209},
{"institution":"Stevens Institute of Business & Arts","id":2210},
{"institution":"Poplar Bluff Technical Career Center","id":2211},
{"institution":"Ranken Technical College","id":2212},
{"institution":"Research College of Nursing","id":2213},
{"institution":"Rockhurst University","id":2214},
{"institution":"Rolla Technical Institute","id":2215},
{"institution":"Everest College-Springfield","id":2216},
{"institution":"Saint Louis University","id":2217},
{"institution":"Salem College of Hairstyling","id":2218},
{"institution":"Sanford-Brown College-Fenton","id":2219},
{"institution":"American College of Hair Design Inc","id":2220},
{"institution":"Sikeston Career and Technology Center","id":2221},
{"institution":"Saint Louis Christian College","id":2222},
{"institution":"St Louis College of Pharmacy","id":2223},
{"institution":"Saint Louis Community College","id":2224},
{"institution":"Saint Paul School of Theology","id":2225},
{"institution":"Southwest Baptist University","id":2226},
{"institution":"Missouri State University-West Plains","id":2227},
{"institution":"Mercy Hospital Springfield-School of Radiologic Technology","id":2228},
{"institution":"Saint Luke's College of Health Sciences","id":2229},
{"institution":"St Louis College of Health Careers-St Louis","id":2230},
{"institution":"State Fair Community College","id":2231},
{"institution":"Stephens College","id":2232},
{"institution":"Southeast Missouri State University","id":2233},
{"institution":"Missouri State University-Springfield","id":2234},
{"institution":"Three Rivers Community College","id":2235},
{"institution":"North Central Missouri College","id":2236},
{"institution":"Eldon Career Center","id":2237},
{"institution":"Truman Medical Center School of Nurse Anesthesia","id":2238},
{"institution":"Kansas City University of Medicine and Biosciences","id":2239},
{"institution":"Washington University in St Louis","id":2240},
{"institution":"Webster University","id":2241},
{"institution":"Wentworth Military Academy & Junior College","id":2242},
{"institution":"Westminster College","id":2243},
{"institution":"William Jewell College","id":2244},
{"institution":"William Woods University","id":2245},
{"institution":"Academy of Cosmetology","id":2246},
{"institution":"Blackfeet Community College","id":2247},
{"institution":"Butte Academy of Beauty Culture","id":2248},
{"institution":"Highlands College of Montana Tech","id":2249},
{"institution":"Crevier's School of Cosmetology","id":2250},
{"institution":"Carroll College","id":2251},
{"institution":"Dawson Community College","id":2252},
{"institution":"Chief Dull Knife College","id":2253},
{"institution":"Montana State University-Billings","id":2254},
{"institution":"Flathead Valley Community College","id":2255},
{"institution":"Aaniiih Nakoda College","id":2256},
{"institution":"Fort Peck Community College","id":2257},
{"institution":"Great Falls College Montana State University","id":2258},
{"institution":"University of Great Falls","id":2259},
{"institution":"Helena College University of Montana","id":2260},
{"institution":"Little Big Horn College","id":2261},
{"institution":"Miles Community College","id":2262},
{"institution":"Modern Beauty School Inc","id":2263},
{"institution":"Montana Tech of the University of Montana","id":2264},
{"institution":"Montana State University","id":2265},
{"institution":"The University of Montana","id":2266},
{"institution":"Montana State University-Northern","id":2267},
{"institution":"Rocky Mountain College","id":2268},
{"institution":"Salish Kootenai College","id":2269},
{"institution":"The University of Montana-Western","id":2270},
{"institution":"La'James International College","id":2271},
{"institution":"Bellevue University","id":2272},
{"institution":"Clarkson College","id":2273},
{"institution":"Bryan College of Health Sciences","id":2274},
{"institution":"Central Community College","id":2275},
{"institution":"Chadron State College","id":2276},
{"institution":"College of Hair Design-Downtown","id":2277},
{"institution":"Concordia University-Nebraska","id":2278},
{"institution":"Creighton University","id":2279},
{"institution":"Doane College-Crete","id":2280},
{"institution":"Grace University","id":2281},
{"institution":"Hastings College","id":2282},
{"institution":"Fullen School of Hair Design","id":2283},
{"institution":"Alegent Creighton Health School of Radiologic Technology","id":2284},
{"institution":"Joseph's College","id":2285},
{"institution":"University of Nebraska at Kearney","id":2286},
{"institution":"Kaplan University-Lincoln Campus","id":2287},
{"institution":"Nebraska Methodist College of Nursing & Allied Health","id":2288},
{"institution":"Metropolitan Community College Area","id":2289},
{"institution":"Mid-Plains Community College","id":2290},
{"institution":"Midland University","id":2291},
{"institution":"Nebraska Christian College","id":2292},
{"institution":"University of Nebraska at Omaha","id":2293},
{"institution":"Kaplan University-Omaha Campus","id":2294},
{"institution":"Nebraska Indian Community College","id":2295},
{"institution":"University of Nebraska Medical Center","id":2296},
{"institution":"Nebraska Wesleyan University","id":2297},
{"institution":"University of Nebraska-Lincoln","id":2298},
{"institution":"Northeast Community College","id":2299},
{"institution":"Peru State College","id":2300},
{"institution":"Summit Christian College","id":2301},
{"institution":"College of Saint Mary","id":2302},
{"institution":"Southeast Community College Area","id":2303},
{"institution":"Union College","id":2304},
{"institution":"University of Nebraska-Central Administration System Office","id":2305},
{"institution":"Vatterott College-Spring Valley","id":2306},
{"institution":"Nebraska College of Technical Agriculture","id":2307},
{"institution":"Wayne State College","id":2308},
{"institution":"Regional West Medical Center School of Radiologic Technology","id":2309},
{"institution":"Western Nebraska Community College","id":2310},
{"institution":"York College","id":2311},
{"institution":"Academy of Hair Design-Las Vegas","id":2312},
{"institution":"Career College of Northern Nevada","id":2313},
{"institution":"College of Southern Nevada","id":2314},
{"institution":"The Art Institute of Las Vegas","id":2315},
{"institution":"Everest College-Henderson","id":2316},
{"institution":"Marinello Schools of Beauty-Las Vegas","id":2317},
{"institution":"University of Nevada-Las Vegas","id":2318},
{"institution":"University of Nevada-Reno","id":2319},
{"institution":"Great Basin College","id":2320},
{"institution":"Marinello Schools of Beauty-Reno","id":2321},
{"institution":"Morrison University","id":2322},
{"institution":"Sierra Nevada College","id":2323},
{"institution":"Truckee Meadows Community College","id":2324},
{"institution":"Nevada System of Higher Education-System Office","id":2325},
{"institution":"Western Nevada College","id":2326},
{"institution":"Colby-Sawyer College","id":2327},
{"institution":"Continental Academie of Hair Design-Hudson","id":2328},
{"institution":"Daniel Webster College","id":2329},
{"institution":"Dartmouth College","id":2330},
{"institution":"Empire Beauty School-Laconia","id":2331},
{"institution":"Empire Beauty School-Somersworth","id":2332},
{"institution":"Franklin Pierce University","id":2333},
{"institution":"University of New Hampshire-School of Law","id":2334},
{"institution":"Mount Washington College","id":2335},
{"institution":"Keene Beauty Academy","id":2336},
{"institution":"Lebanon College","id":2337},
{"institution":"College of St Mary Magdalen","id":2338},
{"institution":"Michaels School of Hair Design and Esthetics-Paul Mitchell Partner School","id":2339},
{"institution":"New England College","id":2340},
{"institution":"New England School of Hair Design Inc","id":2341},
{"institution":"Southern New Hampshire University","id":2342},
{"institution":"New Hampshire Institute for Therapeutic Arts","id":2343},
{"institution":"University of New Hampshire-Main Campus","id":2344},
{"institution":"Keene State College","id":2345},
{"institution":"University of New Hampshire at Manchester","id":2346},
{"institution":"Plymouth State University","id":2347},
{"institution":"NHTI-Concord's Community College","id":2348},
{"institution":"White Mountains Community College","id":2349},
{"institution":"River Valley Community College","id":2350},
{"institution":"Lakes Region Community College","id":2351},
{"institution":"Manchester Community College","id":2352},
{"institution":"Nashua Community College","id":2353},
{"institution":"Great Bay Community College","id":2354},
{"institution":"Paul Mitchell the School-Portsmouth","id":2355},
{"institution":"Rivier University","id":2356},
{"institution":"Saint Anselm College","id":2357},
{"institution":"St Joseph School of Nursing","id":2358},
{"institution":"Granite State College","id":2359},
{"institution":"Thomas More College of Liberal Arts","id":2360},
{"institution":"University System of New Hampshire System Office","id":2361},
{"institution":"Eastwick College-Hackensack","id":2362},
{"institution":"Assumption College for Sisters","id":2363},
{"institution":"Atlantic Cape Community College","id":2364},
{"institution":"Fortis Institute-Wayne","id":2365},
{"institution":"Bergen Community College","id":2366},
{"institution":"Berkeley College-Woodland Park","id":2367},
{"institution":"Beth Medrash Govoha","id":2368},
{"institution":"Bloomfield College","id":2369},
{"institution":"Brookdale Community College","id":2370},
{"institution":"Burlington County College","id":2371},
{"institution":"Caldwell College","id":2372},
{"institution":"Camden County College","id":2373},
{"institution":"Centenary College","id":2374},
{"institution":"Adult and Continuing Education-BCTS","id":2375},
{"institution":"Anthem Institute-Parsippany","id":2376},
{"institution":"Lincoln Technical Institute-Edison","id":2377},
{"institution":"Fox Institute of Business-Clifton","id":2378},
{"institution":"Dover Business College","id":2379},
{"institution":"Empire Beauty School-Ocean","id":2380},
{"institution":"Cooper Health System Center for Allied Health Education","id":2381},
{"institution":"County College of Morris","id":2382},
{"institution":"Cumberland County College","id":2383},
{"institution":"DeVry University-New Jersey","id":2384},
{"institution":"Divers Academy International","id":2385},
{"institution":"Drake College of Business-Elizabeth","id":2386},
{"institution":"Drew University","id":2387},
{"institution":"duCret School of Arts","id":2388},
{"institution":"Lincoln Technical Institute-South Plainfield","id":2389},
{"institution":"Essex County College","id":2390},
{"institution":"Empire Beauty School-Union","id":2391},
{"institution":"Fairleigh Dickinson University-Metropolitan Campus","id":2392},
{"institution":"Felician College","id":2393},
{"institution":"Fairleigh Dickinson University-College at Florham","id":2394},
{"institution":"Hohokus School of Trade and Technical Sciences","id":2395},
{"institution":"Georgian Court University","id":2396},
{"institution":"Rowan University","id":2397},
{"institution":"Gloucester County College","id":2398},
{"institution":"Empire Beauty School-Cherry Hill","id":2399},
{"institution":"Harris School of Business-Cherry Hill Campus","id":2400},
{"institution":"Eastwick College-Ramsey","id":2401},
{"institution":"Holy Name Medical Center School of Nursing","id":2402},
{"institution":"Hudson County Community College","id":2403},
{"institution":"New Jersey City University","id":2404},
{"institution":"Joe Kubert School of Cartoon and Graphic Art","id":2405},
{"institution":"Kean University","id":2406},
{"institution":"Harris School of Business-Voorhees Campus","id":2407},
{"institution":"Lincoln Technical Institute-Union","id":2408},
{"institution":"Rizzieri Aveda School for Beauty and Wellness","id":2409},
{"institution":"Mercer County Community College","id":2410},
{"institution":"Middlesex County College","id":2411},
{"institution":"Monmouth University","id":2412},
{"institution":"Montclair State University","id":2413},
{"institution":"Mountainside School of Nursing","id":2414},
{"institution":"Muhlenberg Harold B and Dorothy A Snyder Schools-School of Imaging","id":2415},
{"institution":"Muhlenberg Harold B and Dorothy A Snyder Schools-School of Nursing","id":2416},
{"institution":"Hohokus School-RETS Nutley","id":2417},
{"institution":"Empire Beauty School-Jersey City","id":2418},
{"institution":"New Brunswick Theological Seminary","id":2419},
{"institution":"Robert Fiance Beauty Schools-West New York","id":2420},
{"institution":"New Jersey Institute of Technology","id":2421},
{"institution":"Ocean County College","id":2422},
{"institution":"Omega Institute","id":2423},
{"institution":"P B Cosmetology Education Center","id":2424},
{"institution":"Parisian Beauty School","id":2425},
{"institution":"Passaic County Community College","id":2426},
{"institution":"Pennco Tech-Blackwood","id":2427},
{"institution":"Princeton Theological Seminary","id":2428},
{"institution":"Princeton University","id":2429},
{"institution":"Rabbinical College of America","id":2430},
{"institution":"Ramapo College of New Jersey","id":2431},
{"institution":"Robert Fiance Beauty Schools-Perth Amboy","id":2432},
{"institution":"Rider University","id":2433},
{"institution":"Roman Academy of Beauty Culture","id":2434},
{"institution":"Rutgers University-Camden","id":2435},
{"institution":"Rutgers University-New Brunswick","id":2436},
{"institution":"Rutgers University-Newark","id":2437},
{"institution":"St Francis Medical Center-School of Radiologic Technology","id":2438},
{"institution":"Saint Francis Medical Center School of Nursing","id":2439},
{"institution":"Saint Peter's University","id":2440},
{"institution":"Salem Community College","id":2441},
{"institution":"Seton Hall University","id":2442},
{"institution":"Atlantic Beauty & Spa Academy LLC","id":2443},
{"institution":"College of Saint Elizabeth","id":2444},
{"institution":"Raritan Valley Community College","id":2445},
{"institution":"Stevens Institute of Technology","id":2446},
{"institution":"The Richard Stockton College of New Jersey","id":2447},
{"institution":"Talmudical Academy-New Jersey","id":2448},
{"institution":"Teterboro School of Aeronautics","id":2449},
{"institution":"Thomas Edison State College","id":2450},
{"institution":"The College of New Jersey","id":2451},
{"institution":"Sanford-Brown Institute-Iselin","id":2452},
{"institution":"Union County College","id":2453},
{"institution":"University of Medicine and Dentistry of New Jersey","id":2454},
{"institution":"William Paterson University of New Jersey","id":2455},
{"institution":"Olympian University of Cosmetology","id":2456},
{"institution":"Central New Mexico Community College","id":2457},
{"institution":"Navajo Technical University","id":2458},
{"institution":"De Wolff College Hair Styling and Cosmetology","id":2459},
{"institution":"New Mexico State University-Dona Ana","id":2460},
{"institution":"Clovis Community College","id":2461},
{"institution":"Eastern New Mexico University-Main Campus","id":2462},
{"institution":"Eastern New Mexico University-Roswell Campus","id":2463},
{"institution":"Institute of American Indian and Alaska Native Culture","id":2464},
{"institution":"New Mexico Highlands University","id":2465},
{"institution":"New Mexico Junior College","id":2466},
{"institution":"New Mexico Military Institute","id":2467},
{"institution":"University of New Mexico-Gallup Campus","id":2468},
{"institution":"New Mexico Institute of Mining and Technology","id":2469},
{"institution":"University of New Mexico-Los Alamos Campus","id":2470},
{"institution":"University of New Mexico-Main Campus","id":2471},
{"institution":"New Mexico State University-Alamogordo","id":2472},
{"institution":"New Mexico State University-Carlsbad","id":2473},
{"institution":"New Mexico State University-Grants","id":2474},
{"institution":"New Mexico State University-Main Campus","id":2475},
{"institution":"University of New Mexico-Valencia County Campus","id":2476},
{"institution":"Northern New Mexico College","id":2477},
{"institution":"San Juan College","id":2478},
{"institution":"Santa Fe Community College","id":2479},
{"institution":"Santa Fe University of Art and Design","id":2480},
{"institution":"University of the Southwest","id":2481},
{"institution":"Southwestern College","id":2482},
{"institution":"Southwestern Indian Polytechnic Institute","id":2483},
{"institution":"University of New Mexico-Taos Campus","id":2484},
{"institution":"Mesalands Community College","id":2485},
{"institution":"Vogue College of Cosmetology-Santa Fe","id":2486},
{"institution":"Western New Mexico University","id":2487},
{"institution":"Vaughn College of Aeronautics and Technology","id":2488},
{"institution":"Adelphi University","id":2489},
{"institution":"Adirondack Community College","id":2490},
{"institution":"Globe Institute of Technology","id":2491},
{"institution":"Bryant & Stratton College-Albany","id":2492},
{"institution":"Albany College of Pharmacy and Health Sciences","id":2493},
{"institution":"Albany Law School","id":2494},
{"institution":"Albany Medical College","id":2495},
{"institution":"Alfred University","id":2496},
{"institution":"Allen School-Brooklyn","id":2497},
{"institution":"The Ailey School","id":2498},
{"institution":"American Academy of Dramatic Arts-New York","id":2499},
{"institution":"American Academy McAllister Institute of Funeral Service","id":2500},
{"institution":"Joffrey Ballet School","id":2501},
{"institution":"American Musical and Dramatic Academy","id":2502},
{"institution":"Apex Technical School","id":2503},
{"institution":"Arnot Ogden Medical Center","id":2504},
{"institution":"Associated Beth Rivkah Schools","id":2505},
{"institution":"Austin's School of Spa Technology","id":2506},
{"institution":"Bank Street College of Education","id":2507},
{"institution":"Bard College","id":2508},
{"institution":"Barnard College","id":2509},
{"institution":"Berk Trade and Business School","id":2510},
{"institution":"Berkeley College-New York","id":2511},
{"institution":"Beth Hatalmud Rabbinical College","id":2512},
{"institution":"Beth Hamedrash Shaarei Yosher Institute","id":2513},
{"institution":"Phillips Beth Israel School of Nursing","id":2514},
{"institution":"Hair Design Institute at Fifth Avenue-Brooklyn","id":2515},
{"institution":"Boricua College","id":2516},
{"institution":"Bramson ORT College","id":2517},
{"institution":"Briarcliffe College","id":2518},
{"institution":"Brittany Beauty School","id":2519},
{"institution":"American Beauty School","id":2520},
{"institution":"Brooklyn Law School","id":2521},
{"institution":"SUNY Broome Community College","id":2522},
{"institution":"Bryant & Stratton College-Amherst","id":2523},
{"institution":"Bryant & Stratton College-Syracuse North","id":2524},
{"institution":"Bryant & Stratton College-Syracuse","id":2525},
{"institution":"Bryant & Stratton College-Buffalo","id":2526},
{"institution":"Bryant & Stratton College-Greece","id":2527},
{"institution":"Business Informatics Center Inc","id":2528},
{"institution":"Canisius College","id":2529},
{"institution":"Capri School of Hair Design","id":2530},
{"institution":"St Paul's School of Nursing-Queens","id":2531},
{"institution":"Cayuga County Community College","id":2532},
{"institution":"Cazenovia College","id":2533},
{"institution":"Central Yeshiva Tomchei Tmimim Lubavitz","id":2534},
{"institution":"Cheryl Fells School of Business","id":2535},
{"institution":"Christ the King Seminary","id":2536},
{"institution":"Circle in the Square Theater School","id":2537},
{"institution":"CUNY System Office","id":2538},
{"institution":"Clarkson University","id":2539},
{"institution":"Clinton Community College","id":2540},
{"institution":"Cochran School of Nursing","id":2541},
{"institution":"Colgate Rochester Crozer Divinity School","id":2542},
{"institution":"Colgate University","id":2543},
{"institution":"Metropolitan College of New York","id":2544},
{"institution":"Columbia University in the City of New York","id":2545},
{"institution":"Columbia-Greene Community College","id":2546},
{"institution":"Empire Beauty School-Queens","id":2547},
{"institution":"Concordia College-New York","id":2548},
{"institution":"Continental School of Beauty Culture-Buffalo","id":2549},
{"institution":"Continental School of Beauty Culture-Batavia","id":2550},
{"institution":"Continental School of Beauty Culture-Rochester","id":2551},
{"institution":"Cooper Union for the Advancement of Science and Art","id":2552},
{"institution":"Cornell University","id":2553},
{"institution":"Weill Cornell Medical College","id":2554},
{"institution":"Corning Community College","id":2555},
{"institution":"Crouse Hospital College of Nursing","id":2556},
{"institution":"Culinary Institute of America","id":2557},
{"institution":"CUNY Bernard M Baruch College","id":2558},
{"institution":"CUNY Borough of Manhattan Community College","id":2559},
{"institution":"CUNY Bronx Community College","id":2560},
{"institution":"CUNY Brooklyn College","id":2561},
{"institution":"College of Staten Island CUNY","id":2562},
{"institution":"CUNY City College","id":2563},
{"institution":"CUNY Graduate School and University Center","id":2564},
{"institution":"CUNY Hostos Community College","id":2565},
{"institution":"CUNY Hunter College","id":2566},
{"institution":"CUNY John Jay College of Criminal Justice","id":2567},
{"institution":"CUNY Kingsborough Community College","id":2568},
{"institution":"CUNY LaGuardia Community College","id":2569},
{"institution":"CUNY Lehman College","id":2570},
{"institution":"CUNY Medgar Evers College","id":2571},
{"institution":"CUNY New York City College of Technology","id":2572},
{"institution":"CUNY Queens College","id":2573},
{"institution":"CUNY Queensborough Community College","id":2574},
{"institution":"CUNY School of Law at Queens College","id":2575},
{"institution":"CUNY York College","id":2576},
{"institution":"CVPH Medical Center School of Radiologic Technology","id":2577},
{"institution":"D'Youville College","id":2578},
{"institution":"Daemen College","id":2579},
{"institution":"Yeshiva of Far Rockaway Derech Ayson Rabbinical Seminary","id":2580},
{"institution":"Dominican College of Blauvelt","id":2581},
{"institution":"Dowling College","id":2582},
{"institution":"Dutchess Community College","id":2583},
{"institution":"Belanger School of Nursing","id":2584},
{"institution":"Elmira Business Institute","id":2585},
{"institution":"Elmira College","id":2586},
{"institution":"Erie Community College","id":2587},
{"institution":"Fashion Institute of Technology","id":2588},
{"institution":"Finger Lakes Community College","id":2589},
{"institution":"Five Towns College","id":2590},
{"institution":"Fordham University","id":2591},
{"institution":"The International Culinary Center","id":2592},
{"institution":"Fulton-Montgomery Community College","id":2593},
{"institution":"Gemological Institute of America-New York","id":2594},
{"institution":"The General Theological Seminary","id":2595},
{"institution":"Genesee Community College","id":2596},
{"institution":"Global Business Institute","id":2597},
{"institution":"Global Business Institute","id":2598},
{"institution":"Hamilton College","id":2599},
{"institution":"Hartwick College","id":2600},
{"institution":"Helene Fuld College of Nursing","id":2601},
{"institution":"Herkimer County Community College","id":2602},
{"institution":"Hilbert College","id":2603},
{"institution":"Hobart William Smith Colleges","id":2604},
{"institution":"Hofstra University","id":2605},
{"institution":"Houghton College","id":2606},
{"institution":"Hudson Valley Community College","id":2607},
{"institution":"Hunter Business School","id":2608},
{"institution":"Institute of Design and Construction","id":2609},
{"institution":"Institute of Audio Research","id":2610},
{"institution":"Iona College","id":2611},
{"institution":"Isabella Graham Hart School of Practical Nursing","id":2612},
{"institution":"Island Drafting and Technical Institute","id":2613},
{"institution":"Ithaca College","id":2614},
{"institution":"Jamestown Community College","id":2615},
{"institution":"Jamestown Business College","id":2616},
{"institution":"Jefferson Community College","id":2617},
{"institution":"Jewish Theological Seminary of America","id":2618},
{"institution":"The Juilliard School","id":2619},
{"institution":"SBI Campus-An Affiliate of Sanford-Brown","id":2620},
{"institution":"Kehilath Yakov Rabbinical Seminary","id":2621},
{"institution":"Keuka College","id":2622},
{"institution":"LIM College","id":2623},
{"institution":"Le Moyne College","id":2624},
{"institution":"LIU Brooklyn","id":2625},
{"institution":"LIU Post","id":2626},
{"institution":"LIU-University Center Campus","id":2627},
{"institution":"Long Island Beauty School-Hempstead","id":2628},
{"institution":"Long Island Business Institute","id":2629},
{"institution":"LIU Hudson at Rockland","id":2630},
{"institution":"LIU Brentwood","id":2631},
{"institution":"Machzikei Hadath Rabbinical College","id":2632},
{"institution":"Mandl School-The College of Allied Health","id":2633},
{"institution":"Manhattan College","id":2634},
{"institution":"Manhattan School of Music","id":2635},
{"institution":"Manhattanville College","id":2636},
{"institution":"Maria College of Albany","id":2637},
{"institution":"Marion S Whelan School of Nursing of Geneva General Hospital","id":2638},
{"institution":"Marist College","id":2639},
{"institution":"MarJon School of Beauty ltd-Tonawanda","id":2640},
{"institution":"Marymount Manhattan College","id":2641},
{"institution":"Medaille College","id":2642},
{"institution":"Memorial School of Nursing","id":2643},
{"institution":"Memorial Hospital School of Radiation Therapy Technology","id":2644},
{"institution":"Mercy College","id":2645},
{"institution":"Mesivta Torah Vodaath Rabbinical Seminary","id":2646},
{"institution":"Mesivta of Eastern Parkway-Yeshiva Zichron Meilech","id":2647},
{"institution":"Mesivtha Tifereth Jerusalem of America","id":2648},
{"institution":"Southern Westchester BOCES-Practical Nursing Program","id":2649},
{"institution":"Midway Paris Beauty School","id":2650},
{"institution":"Mildred Elley School-Albany Campus","id":2651},
{"institution":"Mirrer Yeshiva Cent Institute","id":2652},
{"institution":"Modern Welding School","id":2653},
{"institution":"Mohawk Valley Community College","id":2654},
{"institution":"Molloy College","id":2655},
{"institution":"Monroe College","id":2656},
{"institution":"Monroe Community College","id":2657},
{"institution":"Mount Saint Mary College","id":2658},
{"institution":"Dorothea Hopfer School of Nursing-Mt Vernon Hospital","id":2659},
{"institution":"College of Mount Saint Vincent","id":2660},
{"institution":"Icahn School of Medicine at Mount Sinai","id":2661},
{"institution":"Nassau Community College","id":2662},
{"institution":"Nazareth College","id":2663},
{"institution":"Neighborhood Playhouse School of the Theater","id":2664},
{"institution":"The College of New Rochelle","id":2665},
{"institution":"The New School","id":2666},
{"institution":"New School of Radio and Television","id":2667},
{"institution":"New York Chiropractic College","id":2668},
{"institution":"Star Career Academy-New York","id":2669},
{"institution":"New York Institute of English and Business","id":2670},
{"institution":"New York Law School","id":2671},
{"institution":"New York Medical College","id":2672},
{"institution":"New York School for Medical and Dental Assistants","id":2673},
{"institution":"New York Theological Seminary","id":2674},
{"institution":"New York University","id":2675},
{"institution":"Niagara County Community College","id":2676},
{"institution":"Niagara University","id":2677},
{"institution":"Empire Beauty School-Peekskill","id":2678},
{"institution":"North Country Community College","id":2679},
{"institution":"New York College of Podiatric Medicine","id":2680},
{"institution":"New York Institute of Technology","id":2681},
{"institution":"New York School of Interior Design","id":2682},
{"institution":"Nyack College","id":2683},
{"institution":"Ohr Hameir Theological Seminary","id":2684},
{"institution":"Olean Business Institute","id":2685},
{"institution":"Onondaga Community College","id":2686},
{"institution":"Orange County Community College","id":2687},
{"institution":"Otsego Area BOCES-Practical Nursing Program","id":2688},
{"institution":"Pace University-New York","id":2689},
{"institution":"Paul Smiths College of Arts and Science","id":2690},
{"institution":"Continental School of Beauty Culture-West Seneca","id":2691},
{"institution":"Plaza College","id":2692},
{"institution":"Polytechnic Institute of New York University","id":2693},
{"institution":"Davis College","id":2694},
{"institution":"Pratt Institute-Main","id":2695},
{"institution":"Professional Business College","id":2696},
{"institution":"Rabbinical Academy Mesivta Rabbi Chaim Berlin","id":2697},
{"institution":"Rabbinical College Bobover Yeshiva Bnei Zion","id":2698},
{"institution":"Rabbinical College of Ch'san Sofer New York","id":2699},
{"institution":"Rabbinical College Beth Shraga","id":2700},
{"institution":"Rabbinical Seminary M'kor Chaim","id":2701},
{"institution":"Rabbinical College of Long Island","id":2702},
{"institution":"Rabbinical Seminary of America","id":2703},
{"institution":"Rensselaer Polytechnic Institute","id":2704},
{"institution":"Ridley-Lowell Business & Technical Institute-Binghamton","id":2705},
{"institution":"Roberts Wesleyan College","id":2706},
{"institution":"Everest Institute-Rochester","id":2707},
{"institution":"Rochester Institute of Technology","id":2708},
{"institution":"University of Rochester","id":2709},
{"institution":"Rockefeller University","id":2710},
{"institution":"Rockland Community College","id":2711},
{"institution":"Empire Beauty School-Manhattan","id":2712},
{"institution":"The Sage Colleges","id":2713},
{"institution":"St Bernard's School of Theology and Ministry","id":2714},
{"institution":"St Bonaventure University","id":2715},
{"institution":"St Francis College","id":2716},
{"institution":"St Joseph's College of Nursing at St Joseph's Hospital Health Center","id":2717},
{"institution":"St Lawrence University","id":2718},
{"institution":"The College of Saint Rose","id":2719},
{"institution":"St Thomas Aquinas College","id":2720},
{"institution":"Samaritan Hospital School of Nursing","id":2721},
{"institution":"Sarah Lawrence College","id":2722},
{"institution":"Schenectady County Community College","id":2723},
{"institution":"Sh'or Yoshuv Rabbinical College","id":2724},
{"institution":"Siena College","id":2725},
{"institution":"Simmons Institute of Funeral Service Inc","id":2726},
{"institution":"Skidmore College","id":2727},
{"institution":"Saint Joseph's College-New York","id":2728},
{"institution":"Saint Vladimirs Orthodox Theological Seminary","id":2729},
{"institution":"Spanish-American Institute","id":2730},
{"institution":"Faxton-St Luke's Healthcare School of Radiologic Technology","id":2731},
{"institution":"Saint Elizabeth College of Nursing","id":2732},
{"institution":"Saint Elizabeth Medical Center School of Radiography","id":2733},
{"institution":"Saint John Fisher College","id":2734},
{"institution":"St Paul's School of Nursing-Staten Island","id":2735},
{"institution":"St James Mercy Hospital School of Radiologic Science","id":2736},
{"institution":"St John's University-New York","id":2737},
{"institution":"SUNY-System Office","id":2738},
{"institution":"New York Career Institute","id":2739},
{"institution":"Suburban Technical School","id":2740},
{"institution":"Branford Hall Career Institute-Bohemia Campus","id":2741},
{"institution":"Sullivan County Community College","id":2742},
{"institution":"SUNY College of Technology at Alfred","id":2743},
{"institution":"SUNY College of Technology at Canton","id":2744},
{"institution":"SUNY College of Technology at Delhi","id":2745},
{"institution":"SUNY College of Agriculture and Technology at Cobleskill","id":2746},
{"institution":"Farmingdale State College","id":2747},
{"institution":"Morrisville State College","id":2748},
{"institution":"SUNY at Albany","id":2749},
{"institution":"SUNY at Binghamton","id":2750},
{"institution":"University at Buffalo","id":2751},
{"institution":"Stony Brook University","id":2752},
{"institution":"SUNY College of Environmental Science and Forestry","id":2753},
{"institution":"SUNY Institute of Technology at Utica-Rome","id":2754},
{"institution":"SUNY College at Brockport","id":2755},
{"institution":"Buffalo State SUNY","id":2756},
{"institution":"SUNY College at Cortland","id":2757},
{"institution":"SUNY at Fredonia","id":2758},
{"institution":"SUNY College at Geneseo","id":2759},
{"institution":"State University of New York at New Paltz","id":2760},
{"institution":"SUNY Oneonta","id":2761},
{"institution":"SUNY College at Oswego","id":2762},
{"institution":"SUNY College at Potsdam","id":2763},
{"institution":"SUNY at Purchase College","id":2764},
{"institution":"SUNY College of Optometry","id":2765},
{"institution":"SUNY College at Old Westbury","id":2766},
{"institution":"SUNY College at Plattsburgh","id":2767},
{"institution":"SUNY Downstate Medical Center","id":2768},
{"institution":"SUNY Empire State College","id":2769},
{"institution":"SUNY Maritime College","id":2770},
{"institution":"Upstate Medical University","id":2771},
{"institution":"Swedish Institute a College of Health Sciences","id":2772},
{"institution":"Syracuse University","id":2773},
{"institution":"Talmudical Seminary Oholei Torah","id":2774},
{"institution":"Talmudical Institute of Upstate New York","id":2775},
{"institution":"Teachers College at Columbia University","id":2776},
{"institution":"Technical Career Institutes","id":2777},
{"institution":"Tompkins Cortland Community College","id":2778},
{"institution":"Torah Temimah Talmudical Seminary","id":2779},
{"institution":"Touro College","id":2780},
{"institution":"Trocaire College","id":2781},
{"institution":"Excelsior College","id":2782},
{"institution":"Ulster County Community College","id":2783},
{"institution":"Sanford-Brown Institute-New York","id":2784},
{"institution":"Union College","id":2785},
{"institution":"Union Theological Seminary in the City of New York","id":2786},
{"institution":"United Talmudical Seminary","id":2787},
{"institution":"United States Merchant Marine Academy","id":2788},
{"institution":"United States Military Academy","id":2789},
{"institution":"Utica College","id":2790},
{"institution":"Utica School of Commerce","id":2791},
{"institution":"Vassar College","id":2792},
{"institution":"Villa Maria College","id":2793},
{"institution":"School of Visual Arts","id":2794},
{"institution":"Wagner College","id":2795},
{"institution":"Webb Institute","id":2796},
{"institution":"Wells College","id":2797},
{"institution":"The College of Westchester","id":2798},
{"institution":"SUNY Westchester Community College","id":2799},
{"institution":"Westchester School of Beauty Culture","id":2800},
{"institution":"Wood Tobe-Coburn School","id":2801},
{"institution":"Word of Life Bible Institute","id":2802},
{"institution":"Yeshiva Karlin Stolin","id":2803},
{"institution":"Yeshivat Mikdash Melech","id":2804},
{"institution":"Yeshiva Derech Chaim","id":2805},
{"institution":"Yeshiva of Nitra Rabbinical College","id":2806},
{"institution":"Yeshiva Shaar Hatorah","id":2807},
{"institution":"Yeshiva University","id":2808},
{"institution":"Yeshivath Viznitz","id":2809},
{"institution":"Yeshivath Zichron Moshe","id":2810},
{"institution":"College of the Albemarle","id":2811},
{"institution":"The Art Institute of Charlotte","id":2812},
{"institution":"South Piedmont Community College","id":2813},
{"institution":"Appalachian State University","id":2814},
{"institution":"Asheville-Buncombe Technical Community College","id":2815},
{"institution":"Barton College","id":2816},
{"institution":"Beaufort County Community College","id":2817},
{"institution":"Belmont Abbey College","id":2818},
{"institution":"Bennett College","id":2819},
{"institution":"Bladen Community College","id":2820},
{"institution":"Blue Ridge Community College","id":2821},
{"institution":"Brevard College","id":2822},
{"institution":"Brookstone College-Charlotte","id":2823},
{"institution":"Brunswick Community College","id":2824},
{"institution":"Cabarrus College of Health Sciences","id":2825},
{"institution":"Caldwell Community College and Technical Institute","id":2826},
{"institution":"Campbell University","id":2827},
{"institution":"Cape Fear Community College","id":2828},
{"institution":"Carolina School of Broadcasting","id":2829},
{"institution":"Carteret Community College","id":2830},
{"institution":"Catawba College","id":2831},
{"institution":"Catawba Valley Community College","id":2832},
{"institution":"South College-Asheville","id":2833},
{"institution":"Central Carolina Community College","id":2834},
{"institution":"Central Piedmont Community College","id":2835},
{"institution":"Chowan University","id":2836},
{"institution":"Cleveland Community College","id":2837},
{"institution":"Coastal Carolina Community College","id":2838},
{"institution":"Craven Community College","id":2839},
{"institution":"Davidson County Community College","id":2840},
{"institution":"Davidson College","id":2841},
{"institution":"Duke University","id":2842},
{"institution":"Durham Technical Community College","id":2843},
{"institution":"East Carolina University","id":2844},
{"institution":"Edgecombe Community College","id":2845},
{"institution":"Elizabeth City State University","id":2846},
{"institution":"Elon University","id":2847},
{"institution":"Fayetteville Beauty College","id":2848},
{"institution":"Fayetteville Technical Community College","id":2849},
{"institution":"Fayetteville State University","id":2850},
{"institution":"Forsyth Technical Community College","id":2851},
{"institution":"Gardner-Webb University","id":2852},
{"institution":"Gaston College","id":2853},
{"institution":"Greensboro College","id":2854},
{"institution":"Guilford College","id":2855},
{"institution":"Guilford Technical Community College","id":2856},
{"institution":"Halifax Community College","id":2857},
{"institution":"Haywood Community College","id":2858},
{"institution":"Heritage Bible College","id":2859},
{"institution":"High Point University","id":2860},
{"institution":"Isothermal Community College","id":2861},
{"institution":"James Sprunt Community College","id":2862},
{"institution":"Laurel University","id":2863},
{"institution":"Johnson C Smith University","id":2864},
{"institution":"Johnston Community College","id":2865},
{"institution":"Lees-McRae College","id":2866},
{"institution":"Lenoir Community College","id":2867},
{"institution":"Lenoir-Rhyne University","id":2868},
{"institution":"Leons Beauty School Inc","id":2869},
{"institution":"Livingstone College","id":2870},
{"institution":"Louisburg College","id":2871},
{"institution":"Mars Hill University","id":2872},
{"institution":"Martin Community College","id":2873},
{"institution":"Mayland Community College","id":2874},
{"institution":"McDowell Technical Community College","id":2875},
{"institution":"Mercy School of Nursing","id":2876},
{"institution":"Meredith College","id":2877},
{"institution":"Methodist University","id":2878},
{"institution":"Miller-Motte College-Wilmington","id":2879},
{"institution":"Mitchell Community College","id":2880},
{"institution":"Mitchells Hairstyling Academy-Greenville","id":2881},
{"institution":"Mitchells Hairstyling Academy-Wilson","id":2882},
{"institution":"Regina's College of Beauty-Monroe","id":2883},
{"institution":"Montgomery Community College","id":2884},
{"institution":"Montreat College","id":2885},
{"institution":"Mount Olive College","id":2886},
{"institution":"College of Wilmington","id":2887},
{"institution":"Nash Community College","id":2888},
{"institution":"North Carolina A & T State University","id":2889},
{"institution":"University of North Carolina at Asheville","id":2890},
{"institution":"University of North Carolina at Chapel Hill","id":2891},
{"institution":"University of North Carolina at Charlotte","id":2892},
{"institution":"University of North Carolina at Greensboro","id":2893},
{"institution":"North Carolina Central University","id":2894},
{"institution":"University of North Carolina System Office","id":2895},
{"institution":"University of North Carolina School of the Arts","id":2896},
{"institution":"North Carolina State University at Raleigh","id":2897},
{"institution":"North Carolina Wesleyan College","id":2898},
{"institution":"University of North Carolina Wilmington","id":2899},
{"institution":"Pamlico Community College","id":2900},
{"institution":"William Peace University","id":2901},
{"institution":"University of North Carolina at Pembroke","id":2902},
{"institution":"Pfeiffer University","id":2903},
{"institution":"Piedmont International University","id":2904},
{"institution":"Piedmont Community College","id":2905},
{"institution":"Pitt Community College","id":2906},
{"institution":"Queens University of Charlotte","id":2907},
{"institution":"Randolph Community College","id":2908},
{"institution":"Richmond Community College","id":2909},
{"institution":"Mid-Atlantic Christian University","id":2910},
{"institution":"Roanoke-Chowan Community College","id":2911},
{"institution":"Robeson Community College","id":2912},
{"institution":"Rockingham Community College","id":2913},
{"institution":"Rowan-Cabarrus Community College","id":2914},
{"institution":"Saint Augustine's University","id":2915},
{"institution":"Salem College","id":2916},
{"institution":"Sampson Community College","id":2917},
{"institution":"Sandhills Community College","id":2918},
{"institution":"Shaw University","id":2919},
{"institution":"Paul Mitchell the School-Fayetteville","id":2920},
{"institution":"St Andrews University","id":2921},
{"institution":"Empire Beauty School-Matthews","id":2922},
{"institution":"Southeastern Community College","id":2923},
{"institution":"Southwestern Community College","id":2924},
{"institution":"Stanly Community College","id":2925},
{"institution":"Southeastern Baptist Theological Seminary","id":2926},
{"institution":"Surry Community College","id":2927},
{"institution":"Alamance Community College","id":2928},
{"institution":"Tri-County Community College","id":2929},
{"institution":"Vance-Granville Community College","id":2930},
{"institution":"Wake Forest University","id":2931},
{"institution":"Wake Technical Community College","id":2932},
{"institution":"Warren Wilson College","id":2933},
{"institution":"Watts School of Nursing","id":2934},
{"institution":"Wayne Community College","id":2935},
{"institution":"Western Piedmont Community College","id":2936},
{"institution":"Wilkes Community College","id":2937},
{"institution":"Wilson Community College","id":2938},
{"institution":"Wingate University","id":2939},
{"institution":"Carolina Christian College","id":2940},
{"institution":"Winston Salem Barber School","id":2941},
{"institution":"Winston-Salem State University","id":2942},
{"institution":"Western Carolina University","id":2943},
{"institution":"Rasmussen College-North Dakota","id":2944},
{"institution":"Bismarck State College","id":2945},
{"institution":"Dickinson State University","id":2946},
{"institution":"Fort Berthold Community College","id":2947},
{"institution":"Josef's School of Hair Design Inc-Grand Forks","id":2948},
{"institution":"Headquarters Academy of Hair Design Inc","id":2949},
{"institution":"University of Jamestown","id":2950},
{"institution":"Josef's School of Hair Design Inc-Fargo Downtown","id":2951},
{"institution":"Lake Region State College","id":2952},
{"institution":"Cankdeska Cikana Community College","id":2953},
{"institution":"University of Mary","id":2954},
{"institution":"Mayville State University","id":2955},
{"institution":"Sanford College of Nursing","id":2956},
{"institution":"Minot State University","id":2957},
{"institution":"Moler Barber College","id":2958},
{"institution":"University of North Dakota","id":2959},
{"institution":"North Dakota State College of Science","id":2960},
{"institution":"Dakota College at Bottineau","id":2961},
{"institution":"North Dakota State University-Main Campus","id":2962},
{"institution":"Williston State College","id":2963},
{"institution":"JZ Trend Academy Paul Mitchell Partner School","id":2964},
{"institution":"Sitting Bull College","id":2965},
{"institution":"Trinity Bible College","id":2966},
{"institution":"Turtle Mountain Community College","id":2967},
{"institution":"United Tribes Technical College","id":2968},
{"institution":"Valley City State University","id":2969},
{"institution":"ETI Technical College","id":2970},
{"institution":"The Art Institute of Cincinnati","id":2971},
{"institution":"Miami-Jacobs Career College-Independence","id":2972},
{"institution":"Air Force Institute of Technology-Graduate School of Engineering & Management","id":2973},
{"institution":"Akron Institute of Herzing University","id":2974},
{"institution":"University of Akron Main Campus","id":2975},
{"institution":"Akron School of Practical Nursing","id":2976},
{"institution":"University of Akron Wayne College","id":2977},
{"institution":"Allegheny Wesleyan College","id":2978},
{"institution":"Antonelli College-Cincinnati","id":2979},
{"institution":"Apollo Career Center","id":2980},
{"institution":"Art Academy of Cincinnati","id":2981},
{"institution":"Ashland County-West Holmes Career Center","id":2982},
{"institution":"Ashland University","id":2983},
{"institution":"Ashtabula County Technical and Career Campus","id":2984},
{"institution":"Athenaeum of Ohio","id":2985},
{"institution":"Auburn Career Center","id":2986},
{"institution":"Aultman College of Nursing and Health Sciences","id":2987},
{"institution":"Baldwin Wallace University","id":2988},
{"institution":"Belmont College","id":2989},
{"institution":"Bluffton University","id":2990},
{"institution":"Fortis College-Ravenna","id":2991},
{"institution":"Bowling Green State University-Firelands","id":2992},
{"institution":"Bowling Green State University-Main Campus","id":2993},
{"institution":"Bryant & Stratton College-Parma","id":2994},
{"institution":"Buckeye Hills Career Center","id":2995},
{"institution":"Butler Tech-D Russel Lee Career Center","id":2996},
{"institution":"Canton City Schools Adult Career and Technical Education","id":2997},
{"institution":"Capital University","id":2998},
{"institution":"Valley Beauty School","id":2999},
{"institution":"Carousel Beauty College-Middletown","id":3000},
{"institution":"Carousel Beauty College-Dayton","id":3001},
{"institution":"Carousel of Miami Valley Beauty College","id":3002},
{"institution":"Case Western Reserve University","id":3003},
{"institution":"Cedarville University","id":3004},
{"institution":"Central Ohio Technical College","id":3005},
{"institution":"Central School of Practical Nursing","id":3006},
{"institution":"Central State University","id":3007},
{"institution":"Chatfield College","id":3008},
{"institution":"Choffin Career  and Technical Center","id":3009},
{"institution":"The Christ College of Nursing and Health Sciences","id":3010},
{"institution":"Cincinnati Christian University","id":3011},
{"institution":"Cincinnati College of Mortuary Science","id":3012},
{"institution":"University of Cincinnati-Main Campus","id":3013},
{"institution":"Cincinnati State Technical and Community College","id":3014},
{"institution":"University of Cincinnati-Clermont College","id":3015},
{"institution":"University of Cincinnati-Blue Ash College","id":3016},
{"institution":"Ohio Christian University","id":3017},
{"institution":"Clark State Community College","id":3018},
{"institution":"Scott College of Cosmetology","id":3019},
{"institution":"Cleveland Institute of Art","id":3020},
{"institution":"Cleveland Institute of Dental-Medical Assistants-Cleveland","id":3021},
{"institution":"Cleveland Institute of Music","id":3022},
{"institution":"Cleveland State University","id":3023},
{"institution":"Columbiana County Career and Technical Center","id":3024},
{"institution":"Bradford School","id":3025},
{"institution":"Columbus College of Art and Design","id":3026},
{"institution":"Kaplan College-Columbus","id":3027},
{"institution":"Columbus State Community College","id":3028},
{"institution":"Cuyahoga Community College District","id":3029},
{"institution":"Davis College","id":3030},
{"institution":"Dayton Barber College","id":3031},
{"institution":"University of Dayton","id":3032},
{"institution":"Defiance College","id":3033},
{"institution":"Denison University","id":3034},
{"institution":"DeVry University-Ohio","id":3035},
{"institution":"Chancellor University","id":3036},
{"institution":"Eastern Hills Academy of Hair Design","id":3037},
{"institution":"Edison State Community College","id":3038},
{"institution":"EHOVE Career Center","id":3039},
{"institution":"Bryant & Stratton College-Cleveland","id":3040},
{"institution":"Regency Beauty Institute-Cleveland","id":3041},
{"institution":"The University of Findlay","id":3042},
{"institution":"Franklin University","id":3043},
{"institution":"Gerbers Akron Beauty School","id":3044},
{"institution":"Gods Bible School and College","id":3045},
{"institution":"Good Samaritan College of Nursing and Health Science","id":3046},
{"institution":"Great Oaks Institute of Technology and Career Development","id":3047},
{"institution":"Hannah E Mullins School of Practical Nursing","id":3048},
{"institution":"Adult and Community Education-Hudson","id":3049},
{"institution":"Hebrew Union College-Jewish Institute of Religion","id":3050},
{"institution":"Heidelberg University","id":3051},
{"institution":"Hiram College","id":3052},
{"institution":"Hobart Institute of Welding Technology","id":3053},
{"institution":"Hocking College","id":3054},
{"institution":"American School of Technology","id":3055},
{"institution":"Ross Medical Education Center-Cincinnati","id":3056},
{"institution":"International College of Broadcasting","id":3057},
{"institution":"ITT Technical Institute-Dayton","id":3058},
{"institution":"Eastern Gateway Community College","id":3059},
{"institution":"John Carroll University","id":3060},
{"institution":"Hondros College","id":3061},
{"institution":"Casal Aveda Institute","id":3062},
{"institution":"Kent State University at Ashtabula","id":3063},
{"institution":"Kent State University at East Liverpool","id":3064},
{"institution":"Kent State University at Stark","id":3065},
{"institution":"Kent State University at Trumbull","id":3066},
{"institution":"Kent State University at Tuscarawas","id":3067},
{"institution":"Kent State University at Salem","id":3068},
{"institution":"Kent State University at Kent","id":3069},
{"institution":"Kent State University at Geauga","id":3070},
{"institution":"Kenyon College","id":3071},
{"institution":"Kettering College","id":3072},
{"institution":"Lake Erie College","id":3073},
{"institution":"Lakeland Community College","id":3074},
{"institution":"O C Collins Career Center","id":3075},
{"institution":"James A Rhodes State College","id":3076},
{"institution":"Ohio Business College-Sheffield","id":3077},
{"institution":"Ohio Business College-Sandusky","id":3078},
{"institution":"Lorain County Community College","id":3079},
{"institution":"Lourdes University","id":3080},
{"institution":"Malone University","id":3081},
{"institution":"Marietta College","id":3082},
{"institution":"Marion Technical College","id":3083},
{"institution":"Medina County Career Center","id":3084},
{"institution":"Mercy College of Ohio","id":3085},
{"institution":"Methodist Theological School in Ohio","id":3086},
{"institution":"Miami University-Hamilton","id":3087},
{"institution":"Miami University-Middletown","id":3088},
{"institution":"Miami University-Oxford","id":3089},
{"institution":"Miami-Jacobs Career College-Dayton","id":3090},
{"institution":"Moler Hollywood Beauty Academy","id":3091},
{"institution":"Miami Valley Career Technology Center","id":3092},
{"institution":"Mount Carmel College of Nursing","id":3093},
{"institution":"University of Mount Union","id":3094},
{"institution":"Mount Vernon Nazarene University","id":3095},
{"institution":"College of Mount St Joseph","id":3096},
{"institution":"Zane State College","id":3097},
{"institution":"Muskingum University","id":3098},
{"institution":"Fortis College-Cuyahoga Falls","id":3099},
{"institution":"Brown Mackie College-North Canton","id":3100},
{"institution":"National Beauty College","id":3101},
{"institution":"North Central State College","id":3102},
{"institution":"Northern Institute of Cosmetology","id":3103},
{"institution":"Northwest State Community College","id":3104},
{"institution":"Toledo Public Schools Adult and Continuing Education","id":3105},
{"institution":"Notre Dame College","id":3106},
{"institution":"Northeast Ohio Medical University","id":3107},
{"institution":"University of Northwestern Ohio","id":3108},
{"institution":"Oberlin College","id":3109},
{"institution":"Ohio College of Massotherapy Inc","id":3110},
{"institution":"Ohio Technical College","id":3111},
{"institution":"Ohio Dominican University","id":3112},
{"institution":"Kaplan College-Dayton","id":3113},
{"institution":"Ohio Northern University","id":3114},
{"institution":"Ohio State University Agricultural Technical Institute","id":3115},
{"institution":"Ohio State University-Lima Campus","id":3116},
{"institution":"Ohio State University-Mansfield Campus","id":3117},
{"institution":"Ohio State University-Marion Campus","id":3118},
{"institution":"Ohio State University-Newark Campus","id":3119},
{"institution":"Ohio State Beauty Academy","id":3120},
{"institution":"Ohio State College of Barber Styling","id":3121},
{"institution":"Ohio State School of Cosmetology & Experts Barber School","id":3122},
{"institution":"The Spa School","id":3123},
{"institution":"Ohio State School of Cosmetology-Canal Winchester","id":3124},
{"institution":"Ohio State University-Main Campus","id":3125},
{"institution":"Ohio University-Eastern Campus","id":3126},
{"institution":"Ohio University-Chillicothe Campus","id":3127},
{"institution":"Ohio University-Southern Campus","id":3128},
{"institution":"Ohio University-Lancaster Campus","id":3129},
{"institution":"Ohio University-Main Campus","id":3130},
{"institution":"Ohio University-Zanesville Campus","id":3131},
{"institution":"Ohio Valley College of Technology","id":3132},
{"institution":"Trinity Health System School of Nursing","id":3133},
{"institution":"Ohio Wesleyan University","id":3134},
{"institution":"Otterbein University","id":3135},
{"institution":"Owens Community College","id":3136},
{"institution":"Paramount Beauty Academy","id":3137},
{"institution":"Cuyahoga Valley Career Center","id":3138},
{"institution":"Payne Theological Seminary","id":3139},
{"institution":"Pontifical College Josephinum","id":3140},
{"institution":"Professional Skills Institute","id":3141},
{"institution":"Firelands Regional Medical Center School of Nursing","id":3142},
{"institution":"Rabbinical College Telshe","id":3143},
{"institution":"Raphael's School of Beauty Culture Inc-Niles","id":3144},
{"institution":"Fortis College-Centerville","id":3145},
{"institution":"University of Rio Grande","id":3146},
{"institution":"Advertising Art Educational Services DBA School of Advertising Art","id":3147},
{"institution":"Shawnee State University","id":3148},
{"institution":"Sinclair Community College","id":3149},
{"institution":"Gallipolis Career College","id":3150},
{"institution":"Daymar College-New Boston","id":3151},
{"institution":"Daymar College-Jackson","id":3152},
{"institution":"Daymar College-Lancaster","id":3153},
{"institution":"Daymar College-Chillicothe","id":3154},
{"institution":"Brown Mackie College-Cincinnati","id":3155},
{"institution":"Brown Mackie College-Akron","id":3156},
{"institution":"Lincoln College of Technology-Franklin LCT","id":3157},
{"institution":"Lincoln College of Technology-Tri-County","id":3158},
{"institution":"Lincoln College of Technology-Vine Street","id":3159},
{"institution":"Lincoln College of Technology-Dayton","id":3160},
{"institution":"Stark State College","id":3161},
{"institution":"Stautzenberger College-Maumee","id":3162},
{"institution":"Franciscan University of Steubenville","id":3163},
{"institution":"Southern State Community College","id":3164},
{"institution":"Ohio Mid-Western College","id":3165},
{"institution":"Terra State Community College","id":3166},
{"institution":"Tiffin Academy of Hair Design","id":3167},
{"institution":"Tiffin University","id":3168},
{"institution":"University of Toledo","id":3169},
{"institution":"Kaplan Career Institute-Cleveland","id":3170},
{"institution":"Tri-State Bible College","id":3171},
{"institution":"Tri-County Adult Career Center","id":3172},
{"institution":"Tri-Rivers Career Center","id":3173},
{"institution":"Trinity Lutheran Seminary","id":3174},
{"institution":"Trumbull Business College","id":3175},
{"institution":"Union Institute & University","id":3176},
{"institution":"United Theological Seminary","id":3177},
{"institution":"Upper Valley Career Center","id":3178},
{"institution":"Urbana University","id":3179},
{"institution":"Ursuline College","id":3180},
{"institution":"Valley Beauty School","id":3181},
{"institution":"Virginia Marti College of Art and Design","id":3182},
{"institution":"Cut Beauty School","id":3183},
{"institution":"W Howard Nicol School of Practical Nursing","id":3184},
{"institution":"Walsh University","id":3185},
{"institution":"Washington State Community College","id":3186},
{"institution":"Western Hills School of Beauty and Hair Design","id":3187},
{"institution":"Wilberforce University","id":3188},
{"institution":"Wilmington College","id":3189},
{"institution":"Winebrenner Theological Seminary","id":3190},
{"institution":"Wittenberg University","id":3191},
{"institution":"The College of Wooster","id":3192},
{"institution":"Wright State University-Main Campus","id":3193},
{"institution":"Wright State University-Lake Campus","id":3194},
{"institution":"Xavier University","id":3195},
{"institution":"ITT Technical Institute-Youngstown","id":3196},
{"institution":"Youngstown State University","id":3197},
{"institution":"American Broadcasting School-Oklahoma City","id":3198},
{"institution":"Bacone College","id":3199},
{"institution":"Oklahoma Wesleyan University","id":3200},
{"institution":"Southern Nazarene University","id":3201},
{"institution":"Broken Arrow Beauty College-Broken Arrow","id":3202},
{"institution":"Pontotoc Technology Center","id":3203},
{"institution":"Cameron University","id":3204},
{"institution":"Carl Albert State College","id":3205},
{"institution":"Central State Beauty Academy","id":3206},
{"institution":"University of Central Oklahoma","id":3207},
{"institution":"Claremore Beauty College","id":3208},
{"institution":"Connors State College","id":3209},
{"institution":"East Central University","id":3210},
{"institution":"Eastern Oklahoma State College","id":3211},
{"institution":"Redlands Community College","id":3212},
{"institution":"Enid Beauty College","id":3213},
{"institution":"Eves College of Hairstyling","id":3214},
{"institution":"Hillsdale Free Will Baptist College","id":3215},
{"institution":"Hollywood Cosmetology Center","id":3216},
{"institution":"Langston University","id":3217},
{"institution":"Murray State College","id":3218},
{"institution":"Spartan College of Aeronautics and Technology","id":3219},
{"institution":"Northeastern State University","id":3220},
{"institution":"Beauty Technical College Inc","id":3221},
{"institution":"Northern Oklahoma College","id":3222},
{"institution":"Northeastern Oklahoma A&M College","id":3223},
{"institution":"Northwestern Oklahoma State University","id":3224},
{"institution":"Oklahoma State University Center for Health Sciences","id":3225},
{"institution":"Oklahoma Christian University","id":3226},
{"institution":"University of Oklahoma-Health Sciences Center","id":3227},
{"institution":"Oklahoma Panhandle State University","id":3228},
{"institution":"Oklahoma State University-Main Campus","id":3229},
{"institution":"Oklahoma State University-Oklahoma City","id":3230},
{"institution":"Oklahoma Baptist University","id":3231},
{"institution":"Oklahoma City Community College","id":3232},
{"institution":"Oklahoma City University","id":3233},
{"institution":"University of Oklahoma-Norman Campus","id":3234},
{"institution":"Oklahoma School of Photography","id":3235},
{"institution":"Oklahoma State University Institute of Technology","id":3236},
{"institution":"Oral Roberts University","id":3237},
{"institution":"Tulsa Technology Center-Peoria Campus","id":3238},
{"institution":"Platt College-Central OKC","id":3239},
{"institution":"Rogers State University","id":3240},
{"institution":"Rose State College","id":3241},
{"institution":"Saint Gregory's University","id":3242},
{"institution":"Standard Beauty College of Oklahoma","id":3243},
{"institution":"University of Science and Arts of Oklahoma","id":3244},
{"institution":"Seminole State College","id":3245},
{"institution":"Southern School of Beauty Inc","id":3246},
{"institution":"Southeastern Oklahoma State University","id":3247},
{"institution":"Southwestern Christian University","id":3248},
{"institution":"Southwestern Oklahoma State University","id":3249},
{"institution":"Tulsa Community College","id":3250},
{"institution":"Tulsa Welding School-Tulsa","id":3251},
{"institution":"University of Tulsa","id":3252},
{"institution":"Virgil's Beauty College","id":3253},
{"institution":"Indian Capital Technology Center-Tahlequah","id":3254},
{"institution":"Western Oklahoma State College","id":3255},
{"institution":"Woodward Beauty College","id":3256},
{"institution":"High Plains Technology Center","id":3257},
{"institution":"Academy of Hair Design-Salem","id":3258},
{"institution":"Abdill Career College Inc","id":3259},
{"institution":"Paul Mitchell the School-Portland","id":3260},
{"institution":"The Art Institute of Portland","id":3261},
{"institution":"Beau Monde College of Hair Design","id":3262},
{"institution":"Blue Mountain Community College","id":3263},
{"institution":"Central Oregon Community College","id":3264},
{"institution":"Chemeketa Community College","id":3265},
{"institution":"Clackamas Community College","id":3266},
{"institution":"Clatsop Community College","id":3267},
{"institution":"College of Hair Design Careers","id":3268},
{"institution":"Concorde Career College-Portland","id":3269},
{"institution":"Concordia University-Portland","id":3270},
{"institution":"Sumner College","id":3271},
{"institution":"East West College of the Healing Arts","id":3272},
{"institution":"Eastern Oregon University","id":3273},
{"institution":"Phagans School of Hair Design-Portland","id":3274},
{"institution":"New Hope Christian College-Eugene","id":3275},
{"institution":"George Fox University","id":3276},
{"institution":"Phagans Grants Pass College of Beauty","id":3277},
{"institution":"ITT Technical Institute-Portland","id":3278},
{"institution":"Lane Community College","id":3279},
{"institution":"Lewis & Clark College","id":3280},
{"institution":"Linfield College-McMinnville Campus","id":3281},
{"institution":"Linn-Benton Community College","id":3282},
{"institution":"Northwest College-Beaverton","id":3283},
{"institution":"Marylhurst University","id":3284},
{"institution":"Phagans Medford Beauty School","id":3285},
{"institution":"Northwest College-Clackamas","id":3286},
{"institution":"Mount Angel Seminary","id":3287},
{"institution":"Mt Hood Community College","id":3288},
{"institution":"Multnomah University","id":3289},
{"institution":"National College of Natural Medicine","id":3290},
{"institution":"Northwest Nannies Institute","id":3291},
{"institution":"Northwest Christian University","id":3292},
{"institution":"Oregon University System","id":3293},
{"institution":"Oregon Health & Science University","id":3294},
{"institution":"Oregon Institute of Technology","id":3295},
{"institution":"Oregon College of Art and Craft","id":3296},
{"institution":"Oregon State University","id":3297},
{"institution":"University of Oregon","id":3298},
{"institution":"Pacific Northwest College of Art","id":3299},
{"institution":"Pacific University","id":3300},
{"institution":"Phagans Beauty College","id":3301},
{"institution":"Phagans Central Oregon Beauty College","id":3302},
{"institution":"Phagans School of Beauty","id":3303},
{"institution":"Phagans School of Hair Design","id":3304},
{"institution":"Phagans Tigard Beauty School","id":3305},
{"institution":"Portland Community College","id":3306},
{"institution":"Portland State University","id":3307},
{"institution":"University of Portland","id":3308},
{"institution":"Reed College","id":3309},
{"institution":"Rogue Community College","id":3310},
{"institution":"Roseburg Beauty College","id":3311},
{"institution":"Pioneer Pacific College","id":3312},
{"institution":"Springfield College of Beauty","id":3313},
{"institution":"Southern Oregon University","id":3314},
{"institution":"Southwestern Oregon Community College","id":3315},
{"institution":"Treasure Valley Community College","id":3316},
{"institution":"Umpqua Community College","id":3317},
{"institution":"Warner Pacific College","id":3318},
{"institution":"Corban University","id":3319},
{"institution":"College of Cosmetology","id":3320},
{"institution":"Everest College-Portland","id":3321},
{"institution":"Western Seminary","id":3322},
{"institution":"Willamette University","id":3323},
{"institution":"Western Oregon University","id":3324},
{"institution":"University of Western States","id":3325},
{"institution":"Abington Memorial Hospital Dixon School of Nursing","id":3326},
{"institution":"Jolie Hair and Beauty Academy-Hazleton","id":3327},
{"institution":"Keystone Technical Institute","id":3328},
{"institution":"Bryn Athyn College of the New Church","id":3329},
{"institution":"Academy of Vocal Arts","id":3330},
{"institution":"Albright College","id":3331},
{"institution":"All-State Career School-Lester","id":3332},
{"institution":"Community College of Allegheny County","id":3333},
{"institution":"Allegheny College","id":3334},
{"institution":"The Vision Academy","id":3335},
{"institution":"DeSales University","id":3336},
{"institution":"Altoona Beauty School Inc","id":3337},
{"institution":"Alvernia University","id":3338},
{"institution":"Magnolia School","id":3339},
{"institution":"American College of Financial Services","id":3340},
{"institution":"Antonelli Institute","id":3341},
{"institution":"The Art Institutes of York-PA","id":3342},
{"institution":"The Art Institute of Philadelphia","id":3343},
{"institution":"Career Training Academy-New Kensington","id":3344},
{"institution":"The Art Institute of Pittsburgh","id":3345},
{"institution":"Automotive Training Center-Exton","id":3346},
{"institution":"Baptist Bible College & Seminary of Pennsylvania","id":3347},
{"institution":"Community College of Beaver County","id":3348},
{"institution":"Arcadia University","id":3349},
{"institution":"Biblical Theological Seminary","id":3350},
{"institution":"Bidwell Training Center Inc","id":3351},
{"institution":"Bloomsburg University of Pennsylvania","id":3352},
{"institution":"Bradford School","id":3353},
{"institution":"Bryn Mawr College","id":3354},
{"institution":"Bucknell University","id":3355},
{"institution":"Bucks County Community College","id":3356},
{"institution":"Buck's County School of Beauty Culture Inc","id":3357},
{"institution":"Butler Beauty Academy","id":3358},
{"institution":"Butler County Community College","id":3359},
{"institution":"Cabrini College","id":3360},
{"institution":"California University of Pennsylvania","id":3361},
{"institution":"Calvary Baptist Theological Seminary","id":3362},
{"institution":"Cambria-Rowe Business College-Johnstown","id":3363},
{"institution":"Carlow University","id":3364},
{"institution":"Carnegie Mellon University","id":3365},
{"institution":"Cedar Crest College","id":3366},
{"institution":"Central Penn College","id":3367},
{"institution":"Chatham University","id":3368},
{"institution":"Chestnut Hill College","id":3369},
{"institution":"Cheyney University of Pennsylvania","id":3370},
{"institution":"Kaplan Career Institute-Franklin Mills","id":3371},
{"institution":"Citizens School of Nursing","id":3372},
{"institution":"Clarion University of Pennsylvania","id":3373},
{"institution":"YTI Career Institute-Capital Region","id":3374},
{"institution":"Conemaugh Valley Memorial Hospital","id":3375},
{"institution":"Consolidated School of Business-York","id":3376},
{"institution":"Curtis Institute of Music","id":3377},
{"institution":"Dean Institute of Technology","id":3378},
{"institution":"Delaware County Community College","id":3379},
{"institution":"Delaware Valley College","id":3380},
{"institution":"Dickinson College","id":3381},
{"institution":"The Dickinson School of Law of the Pennsylvania State University","id":3382},
{"institution":"Douglas Education Center","id":3383},
{"institution":"Drexel University","id":3384},
{"institution":"Du Bois Business College-Du Bois","id":3385},
{"institution":"Everest Institute-Pittsburgh","id":3386},
{"institution":"Duquesne University","id":3387},
{"institution":"East Stroudsburg University of Pennsylvania","id":3388},
{"institution":"Eastern University","id":3389},
{"institution":"Edinboro University of Pennsylvania","id":3390},
{"institution":"Elizabethtown College","id":3391},
{"institution":"Empire Beauty School-State College","id":3392},
{"institution":"Empire Beauty School-Lebanon","id":3393},
{"institution":"Empire Beauty School-York","id":3394},
{"institution":"Empire Beauty School-Lancaster","id":3395},
{"institution":"Empire Beauty School-Williamsport","id":3396},
{"institution":"Empire Beauty School-Shamokin Dam","id":3397},
{"institution":"Empire Beauty School-Pottsville","id":3398},
{"institution":"Empire Beauty  School-Lehigh Valley","id":3399},
{"institution":"Empire Beauty School-Gwinnett","id":3400},
{"institution":"Empire Beauty School-Wyoming Valley","id":3401},
{"institution":"Empire Beauty School-Warminster","id":3402},
{"institution":"Empire Beauty School-Harrisburg","id":3403},
{"institution":"Empire Beauty School-Reading","id":3404},
{"institution":"Erie Business Center-Erie","id":3405},
{"institution":"Erie Institute of Technology Inc","id":3406},
{"institution":"Evangelical Theological Seminary","id":3407},
{"institution":"Faith Theological Seminary","id":3408},
{"institution":"Aria Health School of Nursing","id":3409},
{"institution":"Franklin and Marshall College","id":3410},
{"institution":"Gannon University","id":3411},
{"institution":"Geneva College","id":3412},
{"institution":"Gettysburg College","id":3413},
{"institution":"Empire Beauty School-Exton","id":3414},
{"institution":"Empire Beauty School-Laurel Springs","id":3415},
{"institution":"Empire Beauty School-NE Philadelphia","id":3416},
{"institution":"Gratz College","id":3417},
{"institution":"Grove City College","id":3418},
{"institution":"Gwynedd Mercy University","id":3419},
{"institution":"Harcum College","id":3420},
{"institution":"Harrisburg Area Community College-Harrisburg","id":3421},
{"institution":"Haverford College","id":3422},
{"institution":"Commonwealth Technical Institute","id":3423},
{"institution":"Holy Family University","id":3424},
{"institution":"Hussian School of Art","id":3425},
{"institution":"Kaplan Career Institute-Pittsburgh","id":3426},
{"institution":"Immaculata University","id":3427},
{"institution":"Indiana University of Pennsylvania-Main Campus","id":3428},
{"institution":"Pennsylvania School of Business","id":3429},
{"institution":"Jameson Health System","id":3430},
{"institution":"Great Lakes Institute of Technology","id":3431},
{"institution":"Jolie Hair and Beauty Academy-Wilkes-Barre","id":3432},
{"institution":"Johnson College","id":3433},
{"institution":"Juniata College","id":3434},
{"institution":"Keystone College","id":3435},
{"institution":"Anthem Institute-Springfield","id":3436},
{"institution":"King's College","id":3437},
{"institution":"Kittanning Beauty School","id":3438},
{"institution":"Kutztown University of Pennsylvania","id":3439},
{"institution":"La Roche College","id":3440},
{"institution":"La Salle University","id":3441},
{"institution":"Lackawanna College","id":3442},
{"institution":"Lafayette College","id":3443},
{"institution":"Lancaster Bible College","id":3444},
{"institution":"Lancaster Theological Seminary","id":3445},
{"institution":"Lancaster School of Cosmetology","id":3446},
{"institution":"Lansdale School of Business","id":3447},
{"institution":"Lansdale School of Cosmetology Inc","id":3448},
{"institution":"Lebanon Valley College","id":3449},
{"institution":"Lehigh Carbon Community College","id":3450},
{"institution":"Berks Technical Institute","id":3451},
{"institution":"Lehigh University","id":3452},
{"institution":"Levittown Beauty Academy","id":3453},
{"institution":"Lincoln Technical Institute-Allentown","id":3454},
{"institution":"Lincoln Technical Institute-Philadelphia","id":3455},
{"institution":"Lincoln University of Pennsylvania","id":3456},
{"institution":"Lock Haven University","id":3457},
{"institution":"St Margaret School of Nursing","id":3458},
{"institution":"Lutheran Theological Seminary at Gettysburg","id":3459},
{"institution":"Lutheran Theological Seminary at Philadelphia","id":3460},
{"institution":"Luzerne County Community College","id":3461},
{"institution":"Lycoming College","id":3462},
{"institution":"Erie Business Center-New Castle","id":3463},
{"institution":"Manor College","id":3464},
{"institution":"Mansfield University of Pennsylvania","id":3465},
{"institution":"Marywood University","id":3466},
{"institution":"Vet Tech Institute","id":3467},
{"institution":"Roxborough Memorial Hospital School of Nursing","id":3468},
{"institution":"Mercyhurst University","id":3469},
{"institution":"Messiah College","id":3470},
{"institution":"Metropolitan Career Center Computer Technology Institute","id":3471},
{"institution":"Millersville University of Pennsylvania","id":3472},
{"institution":"Misericordia University","id":3473},
{"institution":"Montgomery County Community College","id":3474},
{"institution":"Moore College of Art and Design","id":3475},
{"institution":"Moravian College","id":3476},
{"institution":"Mount Aloysius College","id":3477},
{"institution":"Muhlenberg College","id":3478},
{"institution":"Kaplan Career Institute-Philadelphia","id":3479},
{"institution":"Neumann University","id":3480},
{"institution":"New Castle School of Trades","id":3481},
{"institution":"Newport Business Institute-Lower Burrell","id":3482},
{"institution":"Northampton County Area Community College","id":3483},
{"institution":"Pittsburgh's Ohio Valley Hospital School of Nursing","id":3484},
{"institution":"Orleans Technical Institute","id":3485},
{"institution":"Pennsylvania Academy of Cosmetology Arts and Sciences-Johnstown","id":3486},
{"institution":"Salus University","id":3487},
{"institution":"Pennsylvania Institute of Technology","id":3488},
{"institution":"Pennsylvania State University-Penn State Erie-Behrend College","id":3489},
{"institution":"Pennsylvania State University-Penn State Great Valley","id":3490},
{"institution":"Pennsylvania State University-College of Medicine","id":3491},
{"institution":"Pennsylvania State University-Penn State New Kensington","id":3492},
{"institution":"Pennsylvania State University-Penn State Shenango","id":3493},
{"institution":"Pennsylvania State University-Penn State Wilkes-Barre","id":3494},
{"institution":"Pennsylvania State University-Penn State Worthington Scranton","id":3495},
{"institution":"Pennsylvania State System of Higher Education-Central Office","id":3496},
{"institution":"Pennsylvania State University-Penn State Lehigh Valley","id":3497},
{"institution":"Pennsylvania State University-Penn State Altoona","id":3498},
{"institution":"Pennsylvania State University-Penn State Beaver","id":3499},
{"institution":"Pennsylvania State University-Penn State Berks","id":3500},
{"institution":"Pennsylvania State University-Penn State Harrisburg","id":3501},
{"institution":"Pennsylvania State University-Penn State Brandywine","id":3502},
{"institution":"Pennsylvania State University-Penn State Dubois","id":3503},
{"institution":"Pennsylvania State University-Penn State Fayette- Eberly","id":3504},
{"institution":"Pennsylvania State University-Penn State Hazleton","id":3505},
{"institution":"Pennsylvania State University-Main Campus","id":3506},
{"institution":"Pennsylvania State University-Penn State Greater Allegheny","id":3507},
{"institution":"Pennsylvania State University-Penn State Mont Alto","id":3508},
{"institution":"Pennsylvania State University-Penn State Abington","id":3509},
{"institution":"Pennsylvania State University-Penn State Schuylkill","id":3510},
{"institution":"Pennsylvania State University-Penn State York","id":3511},
{"institution":"Pace Institute","id":3512},
{"institution":"Peirce College","id":3513},
{"institution":"Penn Commercial Business/Technical School","id":3514},
{"institution":"Pennco Tech-Bristol","id":3515},
{"institution":"Pennsylvania Academy of Cosmetology Arts and Sciences-Du Bois","id":3516},
{"institution":"Pennsylvania Academy of the Fine Arts","id":3517},
{"institution":"Pennsylvania Gunsmith School","id":3518},
{"institution":"Pennsylvania Institute of Taxidermy Inc","id":3519},
{"institution":"Cortiva Institute-Pennsylvania School of Muscle Therapy","id":3520},
{"institution":"Pennsylvania College of Art and Design","id":3521},
{"institution":"University of Pennsylvania","id":3522},
{"institution":"Philadelphia University","id":3523},
{"institution":"The University of the Arts","id":3524},
{"institution":"Cairn University-Langhorne","id":3525},
{"institution":"Philadelphia College of Osteopathic Medicine","id":3526},
{"institution":"University of the Sciences","id":3527},
{"institution":"Community College of Philadelphia","id":3528},
{"institution":"University of Pittsburgh-Bradford","id":3529},
{"institution":"University of Pittsburgh-Greensburg","id":3530},
{"institution":"University of Pittsburgh-Johnstown","id":3531},
{"institution":"University of Pittsburgh-Pittsburgh Campus","id":3532},
{"institution":"University of Pittsburgh-Titusville","id":3533},
{"institution":"Beaver Falls Beauty Academy","id":3534},
{"institution":"Pittsburgh Institute of Aeronautics","id":3535},
{"institution":"Pittsburgh Institute of Mortuary Science Inc","id":3536},
{"institution":"Pittsburgh Technical Institute","id":3537},
{"institution":"Pittsburgh Theological Seminary","id":3538},
{"institution":"Prism Career Institute-Upper Darby","id":3539},
{"institution":"Point Park University","id":3540},
{"institution":"Joseph F McCloskey School of Nursing at Schuylkill Health","id":3541},
{"institution":"The Salon Professional Academy-Altoona","id":3542},
{"institution":"Reading Area Community College","id":3543},
{"institution":"Reading Hospital School of Health Sciences","id":3544},
{"institution":"Reconstructionist Rabbinical College","id":3545},
{"institution":"Reformed Presbyterian Theological Seminary","id":3546},
{"institution":"The Restaurant School at Walnut Hill College","id":3547},
{"institution":"Kaplan Career Institute-Broomall","id":3548},
{"institution":"Robert Morris University","id":3549},
{"institution":"Rosedale Technical Institute","id":3550},
{"institution":"Rosemont College","id":3551},
{"institution":"Saint Francis University","id":3552},
{"institution":"Saint Joseph's University","id":3553},
{"institution":"Saint Vincent College","id":3554},
{"institution":"Saint Vincent Seminary","id":3555},
{"institution":"Schuylkill Technology Center","id":3556},
{"institution":"University of Scranton","id":3557},
{"institution":"Seton Hill University","id":3558},
{"institution":"Heritage Valley Sewickley School of Nursing","id":3559},
{"institution":"University of Pittsburgh Medical Center-Shadyside School of Nursing","id":3560},
{"institution":"Sharon Regional Health System School of Nursing","id":3561},
{"institution":"Laurel Technical Institute","id":3562},
{"institution":"Shippensburg University of Pennsylvania","id":3563},
{"institution":"Slippery Rock University of Pennsylvania","id":3564},
{"institution":"Saint Charles Borromeo Seminary-Overbrook","id":3565},
{"institution":"South Hills Beauty Academy Inc","id":3566},
{"institution":"South Hills School of Business & Technology","id":3567},
{"institution":"St Lukes Hospital School of Nursing","id":3568},
{"institution":"Stroudsburg School of Cosmetology","id":3569},
{"institution":"Susquehanna University","id":3570},
{"institution":"Swarthmore College","id":3571},
{"institution":"Thaddeus Stevens College of Technology","id":3572},
{"institution":"Talmudical Yeshiva of Philadelphia","id":3573},
{"institution":"Temple University","id":3574},
{"institution":"Theological Seminary of the Reformed Episcopal Church","id":3575},
{"institution":"Thiel College","id":3576},
{"institution":"Thomas Jefferson University","id":3577},
{"institution":"Fortis Institute-Erie","id":3578},
{"institution":"Triangle Tech Inc-Erie","id":3579},
{"institution":"Triangle Tech Inc-Pittsburgh","id":3580},
{"institution":"Triangle Tech Inc-Greensburg","id":3581},
{"institution":"Triangle Tech Inc-Dubois","id":3582},
{"institution":"Trinity Episcopal School for Ministry","id":3583},
{"institution":"Ursinus College","id":3584},
{"institution":"Valley Forge Christian College","id":3585},
{"institution":"Valley Forge Military College","id":3586},
{"institution":"Venus Beauty Academy","id":3587},
{"institution":"Villanova University","id":3588},
{"institution":"Washington Hospital School of Nursing","id":3589},
{"institution":"Washington Hospital School of Radiography","id":3590},
{"institution":"Washington & Jefferson College","id":3591},
{"institution":"Waynesburg University","id":3592},
{"institution":"Welder Training and Testing Institute","id":3593},
{"institution":"West Chester University of Pennsylvania","id":3594},
{"institution":"Western Pennsylvania Hospital School of Nursing","id":3595},
{"institution":"Sanford-Brown Institute-Pittsburgh","id":3596},
{"institution":"Westminster College","id":3597},
{"institution":"Westminster Theological Seminary","id":3598},
{"institution":"Westmoreland County Community College","id":3599},
{"institution":"Widener University-Main Campus","id":3600},
{"institution":"Wilkes University","id":3601},
{"institution":"Newport Business Institute-Williamsport","id":3602},
{"institution":"Wilson College","id":3603},
{"institution":"Yeshivath Beth Moshe","id":3604},
{"institution":"York College Pennsylvania","id":3605},
{"institution":"YTI Career Institute-York","id":3606},
{"institution":"Yorktowne Business Institute","id":3607},
{"institution":"Empire Beauty School-Providence","id":3608},
{"institution":"Brown University","id":3609},
{"institution":"Bryant University","id":3610},
{"institution":"Johnson & Wales University-Providence","id":3611},
{"institution":"Sanford-Brown Institute-Cranston","id":3612},
{"institution":"New England Institute of Technology","id":3613},
{"institution":"New England Tractor Trailer Training School of Rhode Island","id":3614},
{"institution":"Newport School of Hairdressing-Main Campus","id":3615},
{"institution":"Providence College","id":3616},
{"institution":"Rhode Island College","id":3617},
{"institution":"Community College of Rhode Island","id":3618},
{"institution":"University of Rhode Island","id":3619},
{"institution":"Rhode Island School of Design","id":3620},
{"institution":"Roger Williams University","id":3621},
{"institution":"Salve Regina University","id":3622},
{"institution":"St Joseph School of Nursing","id":3623},
{"institution":"Empire Beauty School-Warwick","id":3624},
{"institution":"Northpoint Bible College","id":3625},
{"institution":"Aiken Technical College","id":3626},
{"institution":"Allen University","id":3627},
{"institution":"Anderson University","id":3628},
{"institution":"Charleston Southern University","id":3629},
{"institution":"Technical College of the Lowcountry","id":3630},
{"institution":"Benedict College","id":3631},
{"institution":"Bob Jones University","id":3632},
{"institution":"Southern Wesleyan University","id":3633},
{"institution":"Charleston Cosmetology Institute","id":3634},
{"institution":"College of Charleston","id":3635},
{"institution":"Charzanne Beauty College","id":3636},
{"institution":"Northeastern Technical College","id":3637},
{"institution":"Citadel Military College of South Carolina","id":3638},
{"institution":"Claflin University","id":3639},
{"institution":"Clemson University","id":3640},
{"institution":"Clinton College","id":3641},
{"institution":"Coker College","id":3642},
{"institution":"Columbia International University","id":3643},
{"institution":"Columbia College","id":3644},
{"institution":"Converse College","id":3645},
{"institution":"Denmark Technical College","id":3646},
{"institution":"Erskine College","id":3647},
{"institution":"Florence-Darlington Technical College","id":3648},
{"institution":"Forrest College","id":3649},
{"institution":"Francis Marion University","id":3650},
{"institution":"Furman University","id":3651},
{"institution":"Cathedral Bible College","id":3652},
{"institution":"Greenville Technical College","id":3653},
{"institution":"Horry-Georgetown Technical College","id":3654},
{"institution":"Kenneth Shuler School of Cosmetology-North Augusta","id":3655},
{"institution":"Kenneth Shuler School of Cosmetology and Nails-Columbia","id":3656},
{"institution":"Lander University","id":3657},
{"institution":"Limestone College","id":3658},
{"institution":"Lenoir-Rhyne University-Lutheran Theological Southern Seminary","id":3659},
{"institution":"Medical University of South Carolina","id":3660},
{"institution":"Midlands Technical College","id":3661},
{"institution":"Morris College","id":3662},
{"institution":"Newberry College","id":3663},
{"institution":"North Greenville University","id":3664},
{"institution":"Orangeburg Calhoun Technical College","id":3665},
{"institution":"Piedmont Technical College","id":3666},
{"institution":"Presbyterian College","id":3667},
{"institution":"University of South Carolina-Aiken","id":3668},
{"institution":"University of South Carolina-Beaufort","id":3669},
{"institution":"University of South Carolina-Columbia","id":3670},
{"institution":"University of South Carolina-Lancaster","id":3671},
{"institution":"University of South Carolina-Salkehatchie","id":3672},
{"institution":"University of South Carolina-Sumter","id":3673},
{"institution":"University of South Carolina-Union","id":3674},
{"institution":"Coastal Carolina University","id":3675},
{"institution":"South Carolina State University","id":3676},
{"institution":"University of South Carolina-Upstate","id":3677},
{"institution":"Sherman College of Straight Chiropractic","id":3678},
{"institution":"Spartanburg Methodist College","id":3679},
{"institution":"Spartanburg Community College","id":3680},
{"institution":"Central Carolina Technical College","id":3681},
{"institution":"Sumter Beauty College","id":3682},
{"institution":"Tri-County Technical College","id":3683},
{"institution":"Trident Technical College","id":3684},
{"institution":"Voorhees College","id":3685},
{"institution":"Williamsburg Technical College","id":3686},
{"institution":"Winthrop University","id":3687},
{"institution":"Wofford College","id":3688},
{"institution":"York Technical College","id":3689},
{"institution":"Augustana College","id":3690},
{"institution":"Black Hills Beauty College","id":3691},
{"institution":"Black Hills State University","id":3692},
{"institution":"Kilian Community College","id":3693},
{"institution":"Dakota State University","id":3694},
{"institution":"Dakota Wesleyan University","id":3695},
{"institution":"Headlines Academy Inc","id":3696},
{"institution":"Lake Area Technical Institute","id":3697},
{"institution":"Avera McKennan Hospital School of Radiologic Technology","id":3698},
{"institution":"Mitchell Technical Institute","id":3699},
{"institution":"Mount Marty College","id":3700},
{"institution":"National American University-Rapid City","id":3701},
{"institution":"National American University-Sioux Falls","id":3702},
{"institution":"Sioux Falls Seminary","id":3703},
{"institution":"Northern State University","id":3704},
{"institution":"Oglala Lakota College","id":3705},
{"institution":"Presentation College","id":3706},
{"institution":"Avera Sacred Heart Hospital","id":3707},
{"institution":"South Dakota School of Mines and Technology","id":3708},
{"institution":"South Dakota State University","id":3709},
{"institution":"Sinte Gleska University","id":3710},
{"institution":"University of Sioux Falls","id":3711},
{"institution":"Sanford Medical Center","id":3712},
{"institution":"Sisseton Wahpeton College","id":3713},
{"institution":"Southeast Technical Institute","id":3714},
{"institution":"Stewart School","id":3715},
{"institution":"University of South Dakota","id":3716},
{"institution":"Western Dakota Technical Institute","id":3717},
{"institution":"American Baptist College","id":3718},
{"institution":"Aquinas College","id":3719},
{"institution":"Arnolds Beauty School","id":3720},
{"institution":"Tennessee College of Applied Technology-Athens","id":3721},
{"institution":"Austin Peay State University","id":3722},
{"institution":"Baptist Memorial College of Health Sciences","id":3723},
{"institution":"Belmont University","id":3724},
{"institution":"Bethel University","id":3725},
{"institution":"Bryan College-Dayton","id":3726},
{"institution":"Carson-Newman University","id":3727},
{"institution":"Chattanooga State Community College","id":3728},
{"institution":"Christian Brothers University","id":3729},
{"institution":"Pentecostal Theological Seminary","id":3730},
{"institution":"Cleveland State Community College","id":3731},
{"institution":"Columbia State Community College","id":3732},
{"institution":"Concorde Career College-Memphis","id":3733},
{"institution":"Tennessee College of Applied Technology-Covington","id":3734},
{"institution":"Cumberland University","id":3735},
{"institution":"Lipscomb University","id":3736},
{"institution":"Tennessee College of Applied Technology-Dickson","id":3737},
{"institution":"Daymar Institute-Nashville","id":3738},
{"institution":"Dyersburg State Community College","id":3739},
{"institution":"East Tennessee State University","id":3740},
{"institution":"Chattanooga College Medical Dental and Technical Careers","id":3741},
{"institution":"Tennessee College of Applied Technology-Elizabethton","id":3742},
{"institution":"Emmanuel Christian Seminary","id":3743},
{"institution":"Fayetteville College of Cosmetology Arts and Sciences","id":3744},
{"institution":"Fisk University","id":3745},
{"institution":"Welch College","id":3746},
{"institution":"Freed-Hardeman University","id":3747},
{"institution":"Tennessee College of Applied Technology-Harriman","id":3748},
{"institution":"Tennessee College of Applied Technology-Hartsville","id":3749},
{"institution":"Hiwassee College","id":3750},
{"institution":"Tennessee College of Applied Technology-Hohenwald","id":3751},
{"institution":"Tennessee College of Applied Technology-Jacksboro","id":3752},
{"institution":"Jackson State Community College","id":3753},
{"institution":"John A Gupton College","id":3754},
{"institution":"Johnson University","id":3755},
{"institution":"Paul Mitchell the School-Nashville","id":3756},
{"institution":"King University","id":3757},
{"institution":"South College","id":3758},
{"institution":"Knoxville Institute of Hair Design","id":3759},
{"institution":"Lane College","id":3760},
{"institution":"Le Moyne-Owen College","id":3761},
{"institution":"Lee University","id":3762},
{"institution":"Lincoln Memorial University","id":3763},
{"institution":"Tennessee College of Applied Technology-Livingston","id":3764},
{"institution":"Martin Methodist College","id":3765},
{"institution":"Maryville College","id":3766},
{"institution":"Tennessee College of Applied Technology-McKenzie","id":3767},
{"institution":"Genesis Career College-Cookeville","id":3768},
{"institution":"Meharry Medical College","id":3769},
{"institution":"Memphis College of Art","id":3770},
{"institution":"Tennessee College of Applied Technology-Memphis","id":3771},
{"institution":"University of Memphis","id":3772},
{"institution":"Memphis Theological Seminary","id":3773},
{"institution":"Victory University","id":3774},
{"institution":"Middle Tennessee State University","id":3775},
{"institution":"Middle Tennessee School of Anesthesia Inc","id":3776},
{"institution":"Milligan College","id":3777},
{"institution":"Tennessee College of Applied Technology-Morristown","id":3778},
{"institution":"Motlow State Community College","id":3779},
{"institution":"Tennessee College of Applied Technology-Murfreesboro","id":3780},
{"institution":"Lincoln College of Technology-Nashville","id":3781},
{"institution":"Nashville College of Medical Careers","id":3782},
{"institution":"Nashville State Community College","id":3783},
{"institution":"Tennessee College of Applied Technology-Newbern","id":3784},
{"institution":"O'More College of Design","id":3785},
{"institution":"Tennessee College of Applied Technology-Paris","id":3786},
{"institution":"Plaza Beauty School","id":3787},
{"institution":"Tennessee College of Applied Technology-Pulaski","id":3788},
{"institution":"Rhodes College","id":3789},
{"institution":"Tennessee College of Applied Technology-Ripley","id":3790},
{"institution":"Roane State Community College","id":3791},
{"institution":"Tennessee College of Applied Technology-Crump","id":3792},
{"institution":"Southwest Tennessee Community College","id":3793},
{"institution":"Tennessee College of Applied Technology-Shelbyville","id":3794},
{"institution":"Sewanee-The University of the South","id":3795},
{"institution":"Tennessee College of Applied Technology-Oneida-Huntsville","id":3796},
{"institution":"Tennessee College of Applied Technology-Crossville","id":3797},
{"institution":"Tennessee College of Applied Technology-McMinnville","id":3798},
{"institution":"Tennessee College of Applied Technology-Jackson","id":3799},
{"institution":"Tennessee College of Applied Technology-Knoxville","id":3800},
{"institution":"Tennessee College of Applied Technology-Whiteville","id":3801},
{"institution":"Pellissippi State Community College","id":3802},
{"institution":"Southern Adventist University","id":3803},
{"institution":"Southern College of Optometry","id":3804},
{"institution":"Tennessee Wesleyan College","id":3805},
{"institution":"The University of Tennessee-Chattanooga","id":3806},
{"institution":"The University of Tennessee-Knoxville","id":3807},
{"institution":"The University of Tennessee-Martin","id":3808},
{"institution":"Fountainhead College of Technology","id":3809},
{"institution":"Tennessee School of Beauty of Knoxville Inc","id":3810},
{"institution":"Tennessee State University","id":3811},
{"institution":"Tennessee Technological University","id":3812},
{"institution":"Tennessee Temple University","id":3813},
{"institution":"Trevecca Nazarene University","id":3814},
{"institution":"Northeast State Community College","id":3815},
{"institution":"Tusculum College","id":3816},
{"institution":"Union University","id":3817},
{"institution":"New Concepts School of Cosmetology","id":3818},
{"institution":"Vanderbilt University","id":3819},
{"institution":"Volunteer Beauty Academy-Dyersburg","id":3820},
{"institution":"Volunteer State Community College","id":3821},
{"institution":"Walters State Community College","id":3822},
{"institution":"West Tennessee Business College","id":3823},
{"institution":"William Moore College of Technology","id":3824},
{"institution":"Abilene Christian University","id":3825},
{"institution":"Alamo Community College District Central Office","id":3826},
{"institution":"Alvin Community College","id":3827},
{"institution":"Amarillo College","id":3828},
{"institution":"Amberton University","id":3829},
{"institution":"American Commercial College-San Angelo","id":3830},
{"institution":"American Commercial College-Abilene","id":3831},
{"institution":"American Commercial College-Odessa","id":3832},
{"institution":"Angelina College","id":3833},
{"institution":"Angelo State University","id":3834},
{"institution":"Arlington Baptist College","id":3835},
{"institution":"Arlington Career Institute","id":3836},
{"institution":"The Art Institute of Houston","id":3837},
{"institution":"Austin College","id":3838},
{"institution":"Austin Community College District","id":3839},
{"institution":"Austin Presbyterian Theological Seminary","id":3840},
{"institution":"Baldwin Beauty School-South Austin","id":3841},
{"institution":"Baptist Health System School of Health Professions","id":3842},
{"institution":"Baptist Missionary Association Theological Seminary","id":3843},
{"institution":"Texas A & M University Health Science Center","id":3844},
{"institution":"Baylor College of Medicine","id":3845},
{"institution":"Baylor University","id":3846},
{"institution":"Coastal Bend College","id":3847},
{"institution":"Blinn College","id":3848},
{"institution":"Remington College-Dallas Campus","id":3849},
{"institution":"Vet Tech Institute of Houston","id":3850},
{"institution":"Brazosport College","id":3851},
{"institution":"Brookhaven College","id":3852},
{"institution":"Capitol City Trade and Technical School","id":3853},
{"institution":"Capitol City Careers","id":3854},
{"institution":"Cedar Valley College","id":3855},
{"institution":"Central Texas College","id":3856},
{"institution":"Charles and Sues School of Hair Design","id":3857},
{"institution":"Cisco College","id":3858},
{"institution":"Clarendon College","id":3859},
{"institution":"Concordia University-Texas","id":3860},
{"institution":"Conlee's College of Cosmetology","id":3861},
{"institution":"North Central Texas College","id":3862},
{"institution":"Texas A & M University-Corpus Christi","id":3863},
{"institution":"Cosmetology Career Center LLC","id":3864},
{"institution":"Court Reporting Institute of Dallas","id":3865},
{"institution":"Dallas Baptist University","id":3866},
{"institution":"Dallas Christian College","id":3867},
{"institution":"Dallas County Community College District","id":3868},
{"institution":"Dallas Institute of Funeral Service","id":3869},
{"institution":"Dallas Theological Seminary","id":3870},
{"institution":"University of Dallas","id":3871},
{"institution":"Del Mar College","id":3872},
{"institution":"DeVry University-Texas","id":3873},
{"institution":"Career Point College","id":3874},
{"institution":"East Texas Baptist University","id":3875},
{"institution":"Texas A & M University-Texarkana","id":3876},
{"institution":"Texas A & M University-Commerce","id":3877},
{"institution":"Eastfield College","id":3878},
{"institution":"El Centro College","id":3879},
{"institution":"El Paso Community College","id":3880},
{"institution":"Western Technical College","id":3881},
{"institution":"Western Technical College","id":3882},
{"institution":"Episcopal Theological Seminary of the Southwest","id":3883},
{"institution":"Exposito School of Hair Design","id":3884},
{"institution":"South University-The Art Institute of Dallas","id":3885},
{"institution":"Fort Worth Beauty School","id":3886},
{"institution":"Frank Phillips College","id":3887},
{"institution":"Franklin Beauty School","id":3888},
{"institution":"Galveston College","id":3889},
{"institution":"Grayson College","id":3890},
{"institution":"Hallmark College","id":3891},
{"institution":"Hardin-Simmons University","id":3892},
{"institution":"Trinity Valley Community College","id":3893},
{"institution":"Hill College","id":3894},
{"institution":"Houston Baptist University","id":3895},
{"institution":"University of Houston-Clear Lake","id":3896},
{"institution":"Houston Community College","id":3897},
{"institution":"University of Houston-Downtown","id":3898},
{"institution":"University of Houston-Victoria","id":3899},
{"institution":"University of Houston","id":3900},
{"institution":"Howard College","id":3901},
{"institution":"Howard Payne University","id":3902},
{"institution":"Huston-Tillotson University","id":3903},
{"institution":"University of the Incarnate Word","id":3904},
{"institution":"International Business College-El Paso","id":3905},
{"institution":"International Business College-El Paso","id":3906},
{"institution":"International Business College-El Paso","id":3907},
{"institution":"ITT Technical Institute-Arlington","id":3908},
{"institution":"ITT Technical Institute-Houston West","id":3909},
{"institution":"Jacksonville College-Main Campus","id":3910},
{"institution":"Jarvis Christian College","id":3911},
{"institution":"KD College Conservatory of Film and Dramatic Arts","id":3912},
{"institution":"Kilgore College","id":3913},
{"institution":"Lamar University","id":3914},
{"institution":"Lamar State College-Orange","id":3915},
{"institution":"Lamar State College-Port Arthur","id":3916},
{"institution":"Laredo Beauty College Inc","id":3917},
{"institution":"Laredo Community College","id":3918},
{"institution":"Texas A & M International University","id":3919},
{"institution":"Lee College","id":3920},
{"institution":"LeTourneau University","id":3921},
{"institution":"Lincoln College of Technology-Grand Prairie","id":3922},
{"institution":"Lubbock Hair Academy","id":3923},
{"institution":"Lubbock Christian University","id":3924},
{"institution":"College of the Mainland","id":3925},
{"institution":"University of Mary Hardin-Baylor","id":3926},
{"institution":"McLennan Community College","id":3927},
{"institution":"McMurry University","id":3928},
{"institution":"Covenant School of Nursing and Allied Health","id":3929},
{"institution":"Metroplex Beauty School","id":3930},
{"institution":"Midland College","id":3931},
{"institution":"Midwestern State University","id":3932},
{"institution":"Mims Classic Beauty College","id":3933},
{"institution":"Wade College","id":3934},
{"institution":"Southern Careers Institute-Austin","id":3935},
{"institution":"Mountain View College","id":3936},
{"institution":"Everest Institute-San Antonio","id":3937},
{"institution":"Tint School of Makeup and Cosmetology-Irving","id":3938},
{"institution":"Navarro College","id":3939},
{"institution":"Neilson Beauty College","id":3940},
{"institution":"Lone Star College System","id":3941},
{"institution":"North Lake College","id":3942},
{"institution":"University of North Texas","id":3943},
{"institution":"Northeast Texas Community College","id":3944},
{"institution":"Northwood University-Texas","id":3945},
{"institution":"Oblate School of Theology","id":3946},
{"institution":"Ocean Corporation","id":3947},
{"institution":"Odessa College","id":3948},
{"institution":"Ogle School Hair Skin Nails-Arlington","id":3949},
{"institution":"Ogle School Hair Skin Nails-Ft Worth","id":3950},
{"institution":"Our Lady of the Lake University","id":3951},
{"institution":"The University of Texas-Pan American","id":3952},
{"institution":"The University of Texas at Brownsville","id":3953},
{"institution":"Panola College","id":3954},
{"institution":"Paris Junior College","id":3955},
{"institution":"Paul Quinn College","id":3956},
{"institution":"Prairie View A & M University","id":3957},
{"institution":"Ranger College","id":3958},
{"institution":"Aviation Institute of Maintenance-Houston","id":3959},
{"institution":"Rice University","id":3960},
{"institution":"Richland College","id":3961},
{"institution":"Kaplan College-McAllen","id":3962},
{"institution":"Saint Edward's University","id":3963},
{"institution":"St Philip's College","id":3964},
{"institution":"University of St Thomas","id":3965},
{"institution":"Sam Houston State University","id":3966},
{"institution":"San Antonio College","id":3967},
{"institution":"San Jacinto Community College","id":3968},
{"institution":"Schreiner University","id":3969},
{"institution":"Seguin Beauty School-Seguin","id":3970},
{"institution":"St Mary's University","id":3971},
{"institution":"South Plains College","id":3972},
{"institution":"South Texas Barber College Inc","id":3973},
{"institution":"South Texas College of Law","id":3974},
{"institution":"South Texas Vo-Tech Institute","id":3975},
{"institution":"South Texas Vocational Technical Institute-McAllen","id":3976},
{"institution":"Southern Methodist University","id":3977},
{"institution":"Southwest School of Business and Technical Careers-San Antonio","id":3978},
{"institution":"Southwest Institute of Technology","id":3979},
{"institution":"Southwest Texas Junior College","id":3980},
{"institution":"Southwestern Assemblies of God University","id":3981},
{"institution":"Southwestern University","id":3982},
{"institution":"Stephen F Austin State University","id":3983},
{"institution":"Texas State University","id":3984},
{"institution":"Southwestern Adventist University","id":3985},
{"institution":"Southwestern Christian College","id":3986},
{"institution":"Sul Ross State University","id":3987},
{"institution":"Tarleton State University","id":3988},
{"institution":"Tarrant County College District","id":3989},
{"institution":"Temple College","id":3990},
{"institution":"University of Texas Southwestern Medical Center","id":3991},
{"institution":"The University of Texas Health Science Center at San Antonio","id":3992},
{"institution":"The University of Texas Medical Branch","id":3993},
{"institution":"Texas State Technical College-System","id":3994},
{"institution":"Texas State Technical College-Waco","id":3995},
{"institution":"Texarkana College","id":3996},
{"institution":"Texas A & M University-Kingsville","id":3997},
{"institution":"Texas A & M University-Galveston","id":3998},
{"institution":"Texas A & M University-College Station","id":3999},
{"institution":"Texas A & M University-System Office","id":4000},
{"institution":"The University of Texas at Arlington","id":4001},
{"institution":"The University of Texas at Austin","id":4002},
{"institution":"The University of Texas at Dallas","id":4003},
{"institution":"The University of Texas at El Paso","id":4004},
{"institution":"The University of Texas at Tyler","id":4005},
{"institution":"Texas Chiropractic College Foundation Inc","id":4006},
{"institution":"Texas Christian University","id":4007},
{"institution":"Texas College","id":4008},
{"institution":"University of North Texas Health Science Center","id":4009},
{"institution":"Texas Lutheran University","id":4010},
{"institution":"The University of Texas of the Permian Basin","id":4011},
{"institution":"The University of Texas at San Antonio","id":4012},
{"institution":"Texas School of Business-North Campus","id":4013},
{"institution":"Texas Southern University","id":4014},
{"institution":"The University of Texas System Office","id":4015},
{"institution":"Texas Tech University","id":4016},
{"institution":"Texas Vocational Schools Inc","id":4017},
{"institution":"Texas Wesleyan University","id":4018},
{"institution":"Texas Woman's University","id":4019},
{"institution":"Trinity University","id":4020},
{"institution":"The University of Texas Health Science Center at Houston","id":4021},
{"institution":"Texas State Technical College-Harlingen","id":4022},
{"institution":"Texas State Technical College-West Texas","id":4023},
{"institution":"Texas Tech University Health Sciences Center","id":4024},
{"institution":"Tyler Junior College","id":4025},
{"institution":"University of Houston-System Administration","id":4026},
{"institution":"Universal Technical Institute of Texas Inc.","id":4027},
{"institution":"University of Cosmetology Arts & Sciences-McAllen","id":4028},
{"institution":"Vernon College","id":4029},
{"institution":"Victoria Beauty College Inc","id":4030},
{"institution":"Victoria College","id":4031},
{"institution":"Vogue College of Cosmetology-McAllen","id":4032},
{"institution":"Wayland Baptist University","id":4033},
{"institution":"Weatherford College","id":4034},
{"institution":"West Texas A & M University","id":4035},
{"institution":"Western Texas College","id":4036},
{"institution":"Wharton County Junior College","id":4037},
{"institution":"Wiley College","id":4038},
{"institution":"AmeriTech College-Provo","id":4039},
{"institution":"Bridgerland Applied Technology College","id":4040},
{"institution":"Brigham Young University-Provo","id":4041},
{"institution":"Brigham Young University-Hawaii","id":4042},
{"institution":"Broadview University-West Jordan","id":4043},
{"institution":"Cameo College of Essential Beauty","id":4044},
{"institution":"Sherman Kendall Academy-Salt Lake City","id":4045},
{"institution":"Sherman Kendall Academy-Midvale","id":4046},
{"institution":"Davis Applied Technology College","id":4047},
{"institution":"Dixie State University","id":4048},
{"institution":"Evans Hairstyling College-Cedar City","id":4049},
{"institution":"Evans Hairstyling College-St George","id":4050},
{"institution":"Paul Mitchell the School-Salt Lake City","id":4051},
{"institution":"Eagle Gate College-Murray","id":4052},
{"institution":"ITT Technical Institute-Murray","id":4053},
{"institution":"Latter-day Saints Business College","id":4054},
{"institution":"Everest College-Salt Lake City","id":4055},
{"institution":"Ogden-Weber Applied Technology College","id":4056},
{"institution":"Snow College","id":4057},
{"institution":"Southern Utah University","id":4058},
{"institution":"Stevens-Henager College-Ogden","id":4059},
{"institution":"Stevens-Henager College of Business-Provo","id":4060},
{"institution":"Uintah Basin Applied Technology College","id":4061},
{"institution":"Utah State University","id":4062},
{"institution":"Utah Valley University","id":4063},
{"institution":"Salt Lake Community College","id":4064},
{"institution":"University of Utah","id":4065},
{"institution":"Weber State University","id":4066},
{"institution":"Westminster College","id":4067},
{"institution":"Bennington College","id":4068},
{"institution":"Burlington College","id":4069},
{"institution":"Castleton State College","id":4070},
{"institution":"Champlain College","id":4071},
{"institution":"Community College of Vermont","id":4072},
{"institution":"Goddard College","id":4073},
{"institution":"Green Mountain College","id":4074},
{"institution":"Johnson State College","id":4075},
{"institution":"Lyndon State College","id":4076},
{"institution":"Marlboro College","id":4077},
{"institution":"Middlebury College","id":4078},
{"institution":"New England Culinary Institute","id":4079},
{"institution":"Norwich University","id":4080},
{"institution":"O'Briens Aveda Institute","id":4081},
{"institution":"Saint Michael's College","id":4082},
{"institution":"SIT Graduate Institute","id":4083},
{"institution":"College of St Joseph","id":4084},
{"institution":"Southern Vermont College","id":4085},
{"institution":"Sterling College","id":4086},
{"institution":"Vermont Law School","id":4087},
{"institution":"Vermont State Colleges-Office of the Chancellor","id":4088},
{"institution":"Vermont Technical College","id":4089},
{"institution":"University of Vermont","id":4090},
{"institution":"Bar Palma Beauty Careers Academy","id":4091},
{"institution":"Advanced Technology Institute","id":4092},
{"institution":"Averett University","id":4093},
{"institution":"Blue Ridge Community College","id":4094},
{"institution":"Bluefield College","id":4095},
{"institution":"Bridgewater College","id":4096},
{"institution":"College of William and Mary","id":4097},
{"institution":"Regent University","id":4098},
{"institution":"Central School of Practical Nursing","id":4099},
{"institution":"Central Virginia Community College","id":4100},
{"institution":"Christopher Newport University","id":4101},
{"institution":"Suffolk Beauty Academy","id":4102},
{"institution":"Bryant & Stratton College-Virginia Beach","id":4103},
{"institution":"Bryant & Stratton College-Richmond","id":4104},
{"institution":"Jefferson College of Health Sciences","id":4105},
{"institution":"Dabney S Lancaster Community College","id":4106},
{"institution":"Danville Community College","id":4107},
{"institution":"Eastern Virginia Medical School","id":4108},
{"institution":"Centura College-Virginia Beach","id":4109},
{"institution":"Emory & Henry College","id":4110},
{"institution":"Eastern Mennonite University","id":4111},
{"institution":"Eastern Shore Community College","id":4112},
{"institution":"Ferrum College","id":4113},
{"institution":"George Mason University","id":4114},
{"institution":"Germanna Community College","id":4115},
{"institution":"Hampden-Sydney College","id":4116},
{"institution":"Hampton University","id":4117},
{"institution":"Hollins University","id":4118},
{"institution":"J Sargeant Reynolds Community College","id":4119},
{"institution":"James Madison University","id":4120},
{"institution":"John Tyler Community College","id":4121},
{"institution":"Everest College-Newport News","id":4122},
{"institution":"Liberty University","id":4123},
{"institution":"Longwood University","id":4124},
{"institution":"Lord Fairfax Community College","id":4125},
{"institution":"Lynchburg College","id":4126},
{"institution":"Lynchburg General Hospital School of Nursing","id":4127},
{"institution":"Mary Baldwin College","id":4128},
{"institution":"University of Mary Washington","id":4129},
{"institution":"Marymount University","id":4130},
{"institution":"Danville Regional Medical Center School of Health Professions","id":4131},
{"institution":"Mountain Empire Community College","id":4132},
{"institution":"American National University","id":4133},
{"institution":"New River Community College","id":4134},
{"institution":"Sentara College of Health Sciences","id":4135},
{"institution":"Tidewater Tech-Trades","id":4136},
{"institution":"Norfolk State University","id":4137},
{"institution":"Northern Virginia Community College","id":4138},
{"institution":"Old Dominion University","id":4139},
{"institution":"Patrick Henry Community College","id":4140},
{"institution":"Paul D Camp Community College","id":4141},
{"institution":"Southside Regional Medical Center Professional Schools","id":4142},
{"institution":"Miller-Motte Technical College-Lynchburg","id":4143},
{"institution":"Piedmont Virginia Community College","id":4144},
{"institution":"Radford University","id":4145},
{"institution":"Sylvain Melloul International Hair Academy","id":4146},
{"institution":"Randolph-Macon College","id":4147},
{"institution":"Randolph College","id":4148},
{"institution":"Rappahannock Community College","id":4149},
{"institution":"Fortis College-Norfolk","id":4150},
{"institution":"Richard Bland College of the College of William and Mary","id":4151},
{"institution":"Bon Secours Memorial College of Nursing","id":4152},
{"institution":"University of Richmond","id":4153},
{"institution":"Riverside School of Health Careers","id":4154},
{"institution":"Roanoke College","id":4155},
{"institution":"Bon Secours St Mary's Hospital School of Medical Imaging","id":4156},
{"institution":"Saint Pauls College","id":4157},
{"institution":"Shenandoah University","id":4158},
{"institution":"Southern Virginia University","id":4159},
{"institution":"Southside Virginia Community College","id":4160},
{"institution":"Southwest Virginia Community College","id":4161},
{"institution":"Springfield Beauty Academy","id":4162},
{"institution":"Staunton School of Cosmetology","id":4163},
{"institution":"Strayer University-Virginia","id":4164},
{"institution":"Sweet Briar College","id":4165},
{"institution":"Thomas Nelson Community College","id":4166},
{"institution":"Tidewater Community College","id":4167},
{"institution":"Union Presbyterian Seminary","id":4168},
{"institution":"The University of Virginia's College at Wise","id":4169},
{"institution":"Virginia Highlands Community College","id":4170},
{"institution":"Virginia Intermont College","id":4171},
{"institution":"Virginia Polytechnic Institute and State University","id":4172},
{"institution":"Virginia Western Community College","id":4173},
{"institution":"Virginia Commonwealth University","id":4174},
{"institution":"University of Virginia-Main Campus","id":4175},
{"institution":"Virginia Military Institute","id":4176},
{"institution":"Virginia School of Hair Design","id":4177},
{"institution":"Virginia University of Lynchburg","id":4178},
{"institution":"Virginia State University","id":4179},
{"institution":"Virginia Union University","id":4180},
{"institution":"Virginia Wesleyan College","id":4181},
{"institution":"Wards Corner Beauty Academy-Norfolk","id":4182},
{"institution":"Washington and Lee University","id":4183},
{"institution":"Sanford-Brown College-Tysons Corner","id":4184},
{"institution":"Washington County Adult Skill Center","id":4185},
{"institution":"Woodrow Wilson Rehabilitation Center","id":4186},
{"institution":"Wytheville Community College","id":4187},
{"institution":"Beauty Academy","id":4188},
{"institution":"The Art Institute of Seattle","id":4189},
{"institution":"Evergreen Beauty and Barber College-Bellevue","id":4190},
{"institution":"Bellevue College","id":4191},
{"institution":"Bellingham Beauty School","id":4192},
{"institution":"Bellingham Technical College","id":4193},
{"institution":"Big Bend Community College","id":4194},
{"institution":"Everest College-Bremerton","id":4195},
{"institution":"Everest College-Renton","id":4196},
{"institution":"Central Washington University","id":4197},
{"institution":"Centralia College","id":4198},
{"institution":"City University of Seattle","id":4199},
{"institution":"Lucas Marc Academy","id":4200},
{"institution":"Clark College","id":4201},
{"institution":"Clover Park Technical College","id":4202},
{"institution":"Columbia Basin College","id":4203},
{"institution":"Cornish College of the Arts","id":4204},
{"institution":"Divers Institute of Technology","id":4205},
{"institution":"Eastern Washington University","id":4206},
{"institution":"Edmonds Community College","id":4207},
{"institution":"Everett Community College","id":4208},
{"institution":"Paroba College of Cosmetology","id":4209},
{"institution":"The Evergreen State College","id":4210},
{"institution":"Pierce College at Fort Steilacoom","id":4211},
{"institution":"Glen Dow Academy of Hair Design","id":4212},
{"institution":"Gonzaga University","id":4213},
{"institution":"Grays Harbor College","id":4214},
{"institution":"Green River Community College","id":4215},
{"institution":"Toni & Guy Hairdressing Academy-Shoreline","id":4216},
{"institution":"Heritage University","id":4217},
{"institution":"Highline Community College","id":4218},
{"institution":"Interface College-Spokane","id":4219},
{"institution":"International Air and Hospitality Academy","id":4220},
{"institution":"ITT Technical Institute-Seattle","id":4221},
{"institution":"ITT Technical Institute-Spokane Valley","id":4222},
{"institution":"Bastyr University","id":4223},
{"institution":"Gene Juarez Academy of Beauty-Mountlake Terrace","id":4224},
{"institution":"Bates Technical College","id":4225},
{"institution":"Lake Washington Institute of Technology","id":4226},
{"institution":"Lower Columbia College","id":4227},
{"institution":"Trinity Lutheran College","id":4228},
{"institution":"Cascade Beauty College","id":4229},
{"institution":"BJ's Beauty & Barber College","id":4230},
{"institution":"Northwest Hair Academy","id":4231},
{"institution":"Seattle Community College-North Campus","id":4232},
{"institution":"Northwest University","id":4233},
{"institution":"Olympic College","id":4234},
{"institution":"Perry Technical Institute","id":4235},
{"institution":"Pacific Lutheran University","id":4236},
{"institution":"Peninsula College","id":4237},
{"institution":"University of Puget Sound","id":4238},
{"institution":"Renton Technical College","id":4239},
{"institution":"Saint Martin's University","id":4240},
{"institution":"Seattle Community College-South Campus","id":4241},
{"institution":"Seattle Central College","id":4242},
{"institution":"Everest College-Seattle","id":4243},
{"institution":"Seattle Pacific University","id":4244},
{"institution":"Seattle University","id":4245},
{"institution":"Shoreline Community College","id":4246},
{"institution":"Skagit Valley College","id":4247},
{"institution":"South Puget Sound Community College","id":4248},
{"institution":"Spokane Community College","id":4249},
{"institution":"Spokane Falls Community College","id":4250},
{"institution":"Stylemaster College of Hair Design","id":4251},
{"institution":"Tacoma Community College","id":4252},
{"institution":"The Hair School","id":4253},
{"institution":"Walla Walla Community College","id":4254},
{"institution":"Walla Walla University","id":4255},
{"institution":"Washington State University","id":4256},
{"institution":"University of Washington-Seattle Campus","id":4257},
{"institution":"Wenatchee Valley College","id":4258},
{"institution":"Everest College-Vancouver","id":4259},
{"institution":"Western Washington University","id":4260},
{"institution":"Whatcom Community College","id":4261},
{"institution":"Whitman College","id":4262},
{"institution":"Whitworth University","id":4263},
{"institution":"Yakima Valley Community College","id":4264},
{"institution":"Alderson Broaddus University","id":4265},
{"institution":"Appalachian Beauty School","id":4266},
{"institution":"Appalachian Bible College","id":4267},
{"institution":"B M Spurr School of Practical Nursing","id":4268},
{"institution":"Ben Franklin Career Center","id":4269},
{"institution":"Bethany College","id":4270},
{"institution":"Bluefield State College","id":4271},
{"institution":"Cabell County Career Technology Center","id":4272},
{"institution":"Charleston School of Beauty Culture","id":4273},
{"institution":"University of Charleston","id":4274},
{"institution":"Clarksburg Beauty Academy and School of Massage Therapy","id":4275},
{"institution":"Concord University","id":4276},
{"institution":"Davis & Elkins College","id":4277},
{"institution":"Fairmont State University","id":4278},
{"institution":"Glenville State College","id":4279},
{"institution":"HRDE-Stanley Technical Institute-Clarksburg","id":4280},
{"institution":"Huntington Junior College","id":4281},
{"institution":"James Rumsey Technical Institute","id":4282},
{"institution":"Ralph R Willis Career and Technical Center","id":4283},
{"institution":"Human Resource Development and Employment Inc","id":4284},
{"institution":"Marshall University","id":4285},
{"institution":"Mercer County Technical Education Center","id":4286},
{"institution":"Meredith Manor International Equestrian Center","id":4287},
{"institution":"Monongalia County Technical Education Center","id":4288},
{"institution":"Morgantown Beauty College Inc","id":4289},
{"institution":"Mountain State College","id":4290},
{"institution":"Everest Institute-Cross Lanes","id":4291},
{"institution":"Opportunities Industrialization Center","id":4292},
{"institution":"Ohio Valley University","id":4293},
{"institution":"West Virginia University at Parkersburg","id":4294},
{"institution":"Potomac State College of West Virginia University","id":4295},
{"institution":"Academy of Careers and Technology","id":4296},
{"institution":"Salem International University","id":4297},
{"institution":"Shepherd University","id":4298},
{"institution":"Southern West Virginia Community and Technical College","id":4299},
{"institution":"Fred W Eberle Technical Center","id":4300},
{"institution":"West Virginia School of Osteopathic Medicine","id":4301},
{"institution":"West Virginia State University","id":4302},
{"institution":"West Virginia University Hospital Departments of Rad Tech and Nutrition","id":4303},
{"institution":"West Liberty University","id":4304},
{"institution":"West Virginia University Institute of Technology","id":4305},
{"institution":"West Virginia Wesleyan College","id":4306},
{"institution":"West Virginia Business College-Wheeling","id":4307},
{"institution":"West Virginia Junior College-Charleston","id":4308},
{"institution":"West Virginia Junior College-Morgantown","id":4309},
{"institution":"International Beauty School 4","id":4310},
{"institution":"West Virginia Northern Community College","id":4311},
{"institution":"West Virginia University","id":4312},
{"institution":"Wheeling Jesuit University","id":4313},
{"institution":"Wood County School of Practical Nursing","id":4314},
{"institution":"Advanced Institute of Hair Design-Glendale","id":4315},
{"institution":"VICI Aveda Institute","id":4316},
{"institution":"Alverno College","id":4317},
{"institution":"Madison Area Technical College","id":4318},
{"institution":"Bellin College","id":4319},
{"institution":"Beloit College","id":4320},
{"institution":"Blackhawk Technical College","id":4321},
{"institution":"Cardinal Stritch University","id":4322},
{"institution":"Carroll University","id":4323},
{"institution":"Carthage College","id":4324},
{"institution":"Columbia College of Nursing","id":4325},
{"institution":"Concordia University-Wisconsin","id":4326},
{"institution":"Edgewood College","id":4327},
{"institution":"Fox Valley Technical College","id":4328},
{"institution":"Gateway Technical College","id":4329},
{"institution":"Gill-Tech Academy of Hair Design","id":4330},
{"institution":"The Salon Professional Academy-Eau Claire","id":4331},
{"institution":"ITT Technical Institute-Greenfield","id":4332},
{"institution":"DeVry University-Wisconsin","id":4333},
{"institution":"Lakeland College","id":4334},
{"institution":"Lakeshore Technical College","id":4335},
{"institution":"Lawrence University","id":4336},
{"institution":"Maranatha Baptist University","id":4337},
{"institution":"Marian University","id":4338},
{"institution":"Marquette University","id":4339},
{"institution":"Empire Beauty School-Madison","id":4340},
{"institution":"Medical College of Wisconsin","id":4341},
{"institution":"Mid-State Technical College","id":4342},
{"institution":"Milwaukee Area Technical College","id":4343},
{"institution":"Milwaukee Institute of Art & Design","id":4344},
{"institution":"Milwaukee School of Engineering","id":4345},
{"institution":"Moraine Park Technical College","id":4346},
{"institution":"Mount Mary University","id":4347},
{"institution":"Nashotah House","id":4348},
{"institution":"Nicolet Area Technical College","id":4349},
{"institution":"Northcentral Technical College","id":4350},
{"institution":"Northeast Wisconsin Technical College","id":4351},
{"institution":"Northland International University","id":4352},
{"institution":"Northland College","id":4353},
{"institution":"Ripon College","id":4354},
{"institution":"Sacred Heart School of Theology","id":4355},
{"institution":"Saint Norbert College","id":4356},
{"institution":"Silver Lake College of the Holy Family","id":4357},
{"institution":"State College of Beauty Culture Inc","id":4358},
{"institution":"Southwest Wisconsin Technical College","id":4359},
{"institution":"Bryant & Stratton College-Milwaukee","id":4360},
{"institution":"University of Wisconsin Colleges","id":4361},
{"institution":"Viterbo University","id":4362},
{"institution":"Chippewa Valley Technical College","id":4363},
{"institution":"Waukesha County Technical College","id":4364},
{"institution":"Western Technical College","id":4365},
{"institution":"University of Wisconsin-Whitewater","id":4366},
{"institution":"Wisconsin Indianhead Technical College","id":4367},
{"institution":"Wisconsin School of Professional Psychology","id":4368},
{"institution":"Paul Mitchell the School-Green Bay","id":4369},
{"institution":"University of Wisconsin-Eau Claire","id":4370},
{"institution":"University of Wisconsin-Green Bay","id":4371},
{"institution":"University of Wisconsin-La Crosse","id":4372},
{"institution":"Wisconsin Lutheran College","id":4373},
{"institution":"University of Wisconsin-Oshkosh","id":4374},
{"institution":"University of Wisconsin-Parkside","id":4375},
{"institution":"Herzing University-Madison","id":4376},
{"institution":"University of Wisconsin-Stout","id":4377},
{"institution":"University of Wisconsin-Superior","id":4378},
{"institution":"University of Wisconsin-System Administration","id":4379},
{"institution":"University of Wisconsin-Madison","id":4380},
{"institution":"University of Wisconsin-Milwaukee","id":4381},
{"institution":"University of Wisconsin-Platteville","id":4382},
{"institution":"University of Wisconsin-River Falls","id":4383},
{"institution":"University of Wisconsin-Stevens Point","id":4384},
{"institution":"Casper College","id":4385},
{"institution":"Central Wyoming College","id":4386},
{"institution":"Eastern Wyoming College","id":4387},
{"institution":"Laramie County Community College","id":4388},
{"institution":"Northwest College","id":4389},
{"institution":"Sheridan College","id":4390},
{"institution":"Western Wyoming Community College","id":4391},
{"institution":"Cheeks International Academy of Beauty Culture-Cheyenne","id":4392},
{"institution":"Wyotech-Laramie","id":4393},
{"institution":"University of Wyoming","id":4394},
{"institution":"American Samoa Community College","id":4395},
{"institution":"Guam Community College","id":4396},
{"institution":"University of Guam","id":4397},
{"institution":"Northern Marianas College","id":4398},
{"institution":"Institute of Beauty Careers","id":4399},
{"institution":"Educational Technical College-Recinto de Bayamon","id":4400},
{"institution":"American University of Puerto Rico","id":4401},
{"institution":"American University of Puerto Rico","id":4402},
{"institution":"American Educational College","id":4403},
{"institution":"Antilles School of Technical Careers","id":4404},
{"institution":"Universidad Adventista de las Antillas","id":4405},
{"institution":"Atlantic University College","id":4406},
{"institution":"Bayamon Central University","id":4407},
{"institution":"Columbia Centro Universitario-Caguas","id":4408},
{"institution":"Carlos Albizu University-San Juan","id":4409},
{"institution":"Caribbean University-Bayamon","id":4410},
{"institution":"Caribbean University-Carolina","id":4411},
{"institution":"Pontifical Catholic University of Puerto Rico-Arecibo","id":4412},
{"institution":"Pontifical Catholic University of Puerto Rico-Ponce","id":4413},
{"institution":"Centro de Estudios Multidisciplinarios-San Juan","id":4414},
{"institution":"Universidad Teologica del Caribe","id":4415},
{"institution":"Colegio Universitario de San Juan","id":4416},
{"institution":"Universidad Metropolitana","id":4417},
{"institution":"Puerto Rico Conservatory of Music","id":4418},
{"institution":"Center for Advanced Studies On Puerto Rico and the Caribbean","id":4419},
{"institution":"EDP University of Puerto Rico Inc-San Sebastian","id":4420},
{"institution":"Emma's Beauty Academy-Mayaguez","id":4421},
{"institution":"Escuela de Artes Plasticas de Puerto Rico","id":4422},
{"institution":"Instituto Tecnologico de Puerto Rico-Recinto de Manati","id":4423},
{"institution":"Sistema Universitario Ana G Mendez","id":4424},
{"institution":"Huertas College","id":4425},
{"institution":"Humacao Community College","id":4426},
{"institution":"ICPR Junior College-Arecibo","id":4427},
{"institution":"ICPR Junior College-Mayaguez","id":4428},
{"institution":"Instituto de Educacion Tecnica Ocupacional La Reine-Manati","id":4429},
{"institution":"Hispanic American College","id":4430},
{"institution":"Instituto de Banca y Comercio Inc","id":4431},
{"institution":"Instituto Tecnologico de Puerto Rico-Recinto de Guayama","id":4432},
{"institution":"Instituto Tecnologico de Puerto Rico-Recinto de Ponce","id":4433},
{"institution":"Instituto Tecnologico de Puerto Rico-Recinto de San Juan","id":4434},
{"institution":"Inter American University of Puerto Rico-San German","id":4435},
{"institution":"Inter American University of Puerto Rico-Aguadilla","id":4436},
{"institution":"Inter American University of Puerto Rico-Arecibo","id":4437},
{"institution":"Inter American University of Puerto Rico-Barranquitas","id":4438},
{"institution":"Inter American University of Puerto Rico-Metro","id":4439},
{"institution":"Inter American University of Puerto Rico-Ponce","id":4440},
{"institution":"Inter American University of Puerto Rico-Central Office","id":4441},
{"institution":"Inter American University of Puerto Rico-Fajardo","id":4442},
{"institution":"Inter American University of Puerto Rico-Guayama","id":4443},
{"institution":"Inter American University of Puerto Rico-Bayamon","id":4444},
{"institution":"Inter American University of Puerto Rico-School of Law","id":4445},
{"institution":"International Technical College","id":4446},
{"institution":"Liceo de Arte y Tecnologia","id":4447},
{"institution":"MBTI Business Training Institute","id":4448},
{"institution":"Modern Hairstyling Institute-Bayamon","id":4449},
{"institution":"Modern Hairstyling Institute-Arecibo","id":4450},
{"institution":"National University College-Bayamon","id":4451},
{"institution":"National University College-Arecibo","id":4452},
{"institution":"Ponce Paramedical College Inc","id":4453},
{"institution":"Ponce School of Medicine and Health Sciences","id":4454},
{"institution":"University of Puerto Rico-Aguadilla","id":4455},
{"institution":"University of Puerto Rico-Arecibo","id":4456},
{"institution":"University of Puerto Rico-Bayamon","id":4457},
{"institution":"University of Puerto Rico-Carolina","id":4458},
{"institution":"University of Puerto Rico-Cayey","id":4459},
{"institution":"University of Puerto Rico-Central Administration","id":4460},
{"institution":"University of Puerto Rico-Humacao","id":4461},
{"institution":"University of Puerto Rico-Utuado","id":4462},
{"institution":"University of Puerto Rico-Mayaguez","id":4463},
{"institution":"University of Puerto Rico-Medical Sciences","id":4464},
{"institution":"University of Puerto Rico-Ponce","id":4465},
{"institution":"University of Puerto Rico-Rio Piedras","id":4466},
{"institution":"Universidad Del Este","id":4467},
{"institution":"Ryder Memorial School for Practical Nursing","id":4468},
{"institution":"Rogies School of Beauty Culture","id":4469},
{"institution":"Universidad del Sagrado Corazon","id":4470},
{"institution":"Seminario Evangelico de Puerto Rico","id":4471},
{"institution":"Universidad Central Del Caribe","id":4472},
{"institution":"Universidad Politecnica de Puerto Rico","id":4473},
{"institution":"Pontifical Catholic University of Puerto Rico-Mayaguez","id":4474},
{"institution":"Universidad Del Turabo","id":4475},
{"institution":"College of Micronesia-FSM","id":4476},
{"institution":"Palau Community College","id":4477},
{"institution":"University of the Virgin Islands","id":4478},
{"institution":"Stanford University","id":4479},
{"institution":"Purdue University-Main Campus","id":4480},
{"institution":"Blue Hills Regional Technical School","id":4481},
{"institution":"Parker University","id":4482},
{"institution":"EDP Univeristy of Puerto Rico Inc-San Juan","id":4483},
{"institution":"ICPR Junior College-General Institutional","id":4484},
{"institution":"ITT Technical Institute-Sylmar","id":4485},
{"institution":"ITT Technical Institute-Westminster","id":4486},
{"institution":"Widener University-Delaware Campus","id":4487},
{"institution":"City College-Fort Lauderdale","id":4488},
{"institution":"Meridian College","id":4489},
{"institution":"Georgia Perimeter College","id":4490},
{"institution":"Georgia Piedmont Technical College","id":4491},
{"institution":"Toni & Guy Hairdressing Academy-Coeur D Alene","id":4492},
{"institution":"Cortiva Institute-Chicago","id":4493},
{"institution":"ETI School of Skilled Trades","id":4494},
{"institution":"Lincoln College of Technology-Florence","id":4495},
{"institution":"Trend Setters' Academy of Beauty Culture-Elizabethtown","id":4496},
{"institution":"Traxlers School of Hair","id":4497},
{"institution":"Infinity Career College","id":4498},
{"institution":"Vatterott College-Berkeley","id":4499},
{"institution":"Metro Business College-Rolla","id":4500},
{"institution":"Metro Business College-Jefferson City","id":4501},
{"institution":"Warren County Community College","id":4502},
{"institution":"St John's College","id":4503},
{"institution":"Yeshiva and Kollel Harbotzas Torah","id":4504},
{"institution":"Bais Medrash Elyon","id":4505},
{"institution":"Antioch University-Los Angeles","id":4506},
{"institution":"Antioch University-Santa Barbara","id":4507},
{"institution":"Antioch University-New England","id":4508},
{"institution":"Antioch University-Seattle","id":4509},
{"institution":"Antioch University-Midwest","id":4510},
{"institution":"Mid-America Christian University","id":4511},
{"institution":"Platt College-Tulsa","id":4512},
{"institution":"Ponca City Beauty College","id":4513},
{"institution":"Francis Tuttle Technology Center","id":4514},
{"institution":"Career Point College","id":4515},
{"institution":"Central Technology Center","id":4516},
{"institution":"Carrington College-Portland","id":4517},
{"institution":"Career Technology Center of Lackawanna County","id":4518},
{"institution":"Kaplan Career Institute-Nashville","id":4519},
{"institution":"Kaplan College-El Paso","id":4520},
{"institution":"Houston Graduate School of Theology","id":4521},
{"institution":"Palo Alto College","id":4522},
{"institution":"Sebring Career Schools-Huntsville","id":4523},
{"institution":"University of Cosmetology Arts & Sciences-San Antonio Perrin","id":4524},
{"institution":"Central Texas Beauty College-Round Rock","id":4525},
{"institution":"Empire Beauty School-Manitowoc","id":4526},
{"institution":"Unification Theological Seminary","id":4527},
{"institution":"Athens Technical College","id":4528},
{"institution":"Maricopa Skill Center","id":4529},
{"institution":"Carrington College California-San Leandro","id":4530},
{"institution":"Headmasters School of Hair Design","id":4531},
{"institution":"Razzle Dazzle College of Hair Design Inc","id":4532},
{"institution":"Beckfield College-Florence","id":4533},
{"institution":"University of Louisiana-System Administration","id":4534},
{"institution":"Dorsey Business Schools-Wayne","id":4535},
{"institution":"Ecumenical Theological Seminary","id":4536},
{"institution":"Ross Medical Education Center-Saginaw","id":4537},
{"institution":"Sussex County Community College","id":4538},
{"institution":"Landmark College","id":4539},
{"institution":"National American University-Independence","id":4540},
{"institution":"Mississippi Community College Board","id":4541},
{"institution":"Yeshiva Gedolah of Greater Detroit","id":4542},
{"institution":"Austin Graduate School of Theology","id":4543},
{"institution":"Collin County Community College District","id":4544},
{"institution":"Owensboro Community and Technical College","id":4545},
{"institution":"Heads West Kentucky Beauty College","id":4546},
{"institution":"Jenny Lea Academy of Cosmetology","id":4547},
{"institution":"Empire Beauty School-Hurstborne","id":4548},
{"institution":"Southeast School of Cosmetology","id":4549},
{"institution":"Cloyd's Beauty School 3 Inc","id":4550},
{"institution":"Moore Norman Technology Center","id":4551},
{"institution":"Fayette County Career & Technical Institute Practical Nursing Program","id":4552},
{"institution":"Franklin County Career and Technology Center","id":4553},
{"institution":"Paul Mitchell the School-Knoxville","id":4554},
{"institution":"Ogle School Hair Skin Nails-Hurst","id":4555},
{"institution":"Okefenokee Technical College","id":4556},
{"institution":"Fashion Institute of Design & Merchandising-San Diego","id":4557},
{"institution":"National American University-Albuquerque","id":4558},
{"institution":"Tennessee College of Applied Technology-Nashville","id":4559},
{"institution":"ECPI University","id":4560},
{"institution":"Ms Robert's Academy of Beauty Culture-Villa Park","id":4561},
{"institution":"Anthem Institute-Jersey City","id":4562},
{"institution":"Paul Mitchell the School-Houston","id":4563},
{"institution":"ATI Career Training Center-Dallas","id":4564},
{"institution":"TDDS Technical Institute","id":4565},
{"institution":"Fortis Institute-Forty Fort","id":4566},
{"institution":"Laurel Business Institute","id":4567},
{"institution":"Antonelli Medical and Professional Institute","id":4568},
{"institution":"Aviation Institute of Maintenance-Philadelphia","id":4569},
{"institution":"Sanford-Brown Institute-Trevose","id":4570},
{"institution":"Fortis Institute-Mulberry","id":4571},
{"institution":"Carrington College-Phoenix Westside","id":4572},
{"institution":"Dorsey Business Schools-Roseville","id":4573},
{"institution":"The Salon Professional Academy-Williston","id":4574},
{"institution":"Moler-Pickens Beauty Academy","id":4575},
{"institution":"Red River Technology Center","id":4576},
{"institution":"Kaplan Career Institute-Harrisburg","id":4577},
{"institution":"ITT Technical Institute-Newburgh","id":4578},
{"institution":"Southern Union State Community College","id":4579},
{"institution":"South University-Columbia","id":4580},
{"institution":"Everest University-Brandon","id":4581},
{"institution":"Clinton Technical School","id":4582},
{"institution":"Consolidated School of Business-Lancaster","id":4583},
{"institution":"Greene County Career and Technology Center","id":4584},
{"institution":"Lac Courte Oreilles Ojibwa Community College","id":4585},
{"institution":"University of Wisconsin Extension","id":4586},
{"institution":"Diesel Driving Academy-Shreveport","id":4587},
{"institution":"Pima Medical Institute-Mesa","id":4588},
{"institution":"Platt College-Los Angeles","id":4589},
{"institution":"Platt College-Aurora","id":4590},
{"institution":"Kaplan University-Davenport Campus","id":4591},
{"institution":"Kaplan University-Cedar Falls Campus","id":4592},
{"institution":"Paul Mitchell the School-Boise","id":4593},
{"institution":"Christian Life College","id":4594},
{"institution":"Knowledge Systems Institute","id":4595},
{"institution":"Educators of Beauty College of Cosmetology-Rockford","id":4596},
{"institution":"ITT Technical Institute-Arlington Heights","id":4597},
{"institution":"Northwestern College-Southwestern Campus","id":4598},
{"institution":"Tulsa Technology Center-Lemley Campus","id":4599},
{"institution":"Tulsa Technology Center-Riverside Campus","id":4600},
{"institution":"Tulsa Technology Center-Broken Arrow Campus","id":4601},
{"institution":"Douglas J Aveda Institute","id":4602},
{"institution":"ITT Technical Institute-Troy","id":4603},
{"institution":"Ross Medical Education Center-Brighton","id":4604},
{"institution":"Ross Medical Education Center-Ann Arbor","id":4605},
{"institution":"Taylortown School of Beauty Inc","id":4606},
{"institution":"Pike-Lincoln Technical Center","id":4607},
{"institution":"Pennsylvania Institute of Health and Technology","id":4608},
{"institution":"Skyline College-Roanoke","id":4609},
{"institution":"Bethel Seminary-San Diego","id":4610},
{"institution":"St Charles Community College","id":4611},
{"institution":"Brandman University","id":4612},
{"institution":"New College of Florida","id":4613},
{"institution":"Montana Bible College","id":4614},
{"institution":"Arizona Academy of Beauty-North","id":4615},
{"institution":"Empire Beauty School-Prescott","id":4616},
{"institution":"Le Cordon Bleu College of Culinary Arts-Scottsdale","id":4617},
{"institution":"Conservatory of Recording Arts and Sciences","id":4618},
{"institution":"Adrian's College of Beauty Turlock","id":4619},
{"institution":"California Career School","id":4620},
{"institution":"San Joaquin Valley College-Fresno","id":4621},
{"institution":"Rolf Institute of Structural Integration","id":4622},
{"institution":"Heritage College-Denver","id":4623},
{"institution":"Empire Beauty School-Highland","id":4624},
{"institution":"Sunstate Academy","id":4625},
{"institution":"Elizabeth Grady School of Esthetics and Massage Therapy","id":4626},
{"institution":"Ross Medical Education Center-Port Huron","id":4627},
{"institution":"Capri Institute of Hair Design-Clifton","id":4628},
{"institution":"Raphael's School of Beauty Culture Inc-Alliance","id":4629},
{"institution":"Metro Technology Centers","id":4630},
{"institution":"Heald College-Roseville","id":4631},
{"institution":"Daymar College-Bowling Green","id":4632},
{"institution":"Everest College-Everett","id":4633},
{"institution":"Luna Community College","id":4634},
{"institution":"Grabber School of Hair Design","id":4635},
{"institution":"Yeshivah Gedolah Rabbinical College","id":4636},
{"institution":"University of the District of Columbia-David A Clarke School of Law","id":4637},
{"institution":"Raphael's School of Beauty Culture Inc-Brunswick","id":4638},
{"institution":"Caribbean University-Ponce","id":4639},
{"institution":"Caribbean University-Vega Baja","id":4640},
{"institution":"University of Advancing Technology","id":4641},
{"institution":"Paradise Valley Community College","id":4642},
{"institution":"Chandler-Gilbert Community College","id":4643},
{"institution":"St John's Hospital School of Clinical Lab Science","id":4644},
{"institution":"Madison Media Institute","id":4645},
{"institution":"Sanford-Brown Institute-Landover","id":4646},
{"institution":"Sanford-Brown Institute-White Plains","id":4647},
{"institution":"Sanford-Brown Institute-Garden City","id":4648},
{"institution":"Great Plains Technology Center","id":4649},
{"institution":"Montebello Beauty College","id":4650},
{"institution":"Roane-Jackson Technical Center","id":4651},
{"institution":"Total Cosmetology Training Center","id":4652},
{"institution":"Iverson Business School","id":4653},
{"institution":"Lawrence County Career and Technical Center-Practical Nursing Program","id":4654},
{"institution":"Pioneer Technology Center","id":4655},
{"institution":"Portage Lakes Career Center","id":4656},
{"institution":"Eastern College of Health Vocations-New Orleans","id":4657},
{"institution":"Bell Mar Beauty College","id":4658},
{"institution":"Cannella School of Hair Design-Chicago","id":4659},
{"institution":"Caddo Kiowa Technology Center","id":4660},
{"institution":"Kaplan College-San Antonio-San Pedro","id":4661},
{"institution":"Oswego County BOCES","id":4662},
{"institution":"Le Cordon Bleu College of Culinary Arts-Austin","id":4663},
{"institution":"The Art Institute of New York City","id":4664},
{"institution":"Paris II Educational Center","id":4665},
{"institution":"International School of Skin Nailcare & Massage Therapy","id":4666},
{"institution":"Palomar Institute of Cosmetology","id":4667},
{"institution":"Southern Oklahoma Technology Center","id":4668},
{"institution":"Vista College","id":4669},
{"institution":"Autry Technology Center","id":4670},
{"institution":"Greene County Vocational School District","id":4671},
{"institution":"Canadian Valley Technology Center","id":4672},
{"institution":"Wilkes-Barre Area Career and Technical Center Practical Nursing","id":4673},
{"institution":"Mayo Graduate School","id":4674},
{"institution":"American Beauty Institute","id":4675},
{"institution":"Meridian Technology Center","id":4676},
{"institution":"Waynesville Career Center","id":4677},
{"institution":"Connecticut Institute of Hair Design","id":4678},
{"institution":"Capitol School of Hairstyling and Esthetics","id":4679},
{"institution":"Morris County Vocational School District","id":4680},
{"institution":"Ross Medical Education Center-Roosevelt Park","id":4681},
{"institution":"Ross Medical Education Center-Kentwood","id":4682},
{"institution":"South Florida Bible College and Theological Seminary","id":4683},
{"institution":"Cloyd's Barber School 2 Inc","id":4684},
{"institution":"Universal Technical Institute of Illinois Inc","id":4685},
{"institution":"Pennsylvania College of Technology","id":4686},
{"institution":"Commonwealth Institute of Funeral Service","id":4687},
{"institution":"Delta College of Arts & Technology","id":4688},
{"institution":"InterCoast Colleges-Orange","id":4689},
{"institution":"Stone Child College","id":4690},
{"institution":"New York Academy of Art","id":4691},
{"institution":"Suffolk County Community College","id":4692},
{"institution":"Las Positas College","id":4693},
{"institution":"Blades School of Hair Design","id":4694},
{"institution":"Altamaha Technical College","id":4695},
{"institution":"Ogeechee Technical College","id":4696},
{"institution":"Remington College-Mobile Campus","id":4697},
{"institution":"Everest College-Aurora","id":4698},
{"institution":"Southwest Florida College","id":4699},
{"institution":"Mercy-St Luke's School of Radiologic Technology","id":4700},
{"institution":"ITT Technical Institute-Norwood","id":4701},
{"institution":"Southwest Acupuncture College-Santa Fe","id":4702},
{"institution":"Northwest Technology Center-Alva","id":4703},
{"institution":"Mercer County Career Center","id":4704},
{"institution":"ITT Technical Institute-Knoxville","id":4705},
{"institution":"ITT Technical Institute-Austin","id":4706},
{"institution":"ITT Technical Institute-Houston North","id":4707},
{"institution":"DeVry University's Keller Graduate School of Management-Arizona","id":4708},
{"institution":"California State University-San Marcos","id":4709},
{"institution":"Argosy University-Hawaii","id":4710},
{"institution":"DeVry University's Keller Graduate School of Management-Missouri","id":4711},
{"institution":"Baptist Theological Seminary at Richmond","id":4712},
{"institution":"Kaplan College-Modesto","id":4713},
{"institution":"Kaplan University-Des Moines Campus","id":4714},
{"institution":"Hairmasters Institute of Cosmetology","id":4715},
{"institution":"Hair Arts Academy","id":4716},
{"institution":"Pinnacle Career Institute-Lawrence","id":4717},
{"institution":"Interactive College of Technology-Newport","id":4718},
{"institution":"Career Technical College-Monroe","id":4719},
{"institution":"Empire Beauty School-Lowell","id":4720},
{"institution":"Ross Medical Education Center-Taylor","id":4721},
{"institution":"McNally Smith College of Music","id":4722},
{"institution":"Allied Health Careers","id":4723},
{"institution":"National Tractor Trailer School Inc-Liverpool","id":4724},
{"institution":"Interactive Learning Systems-Houston","id":4725},
{"institution":"Star Career Academy�Brick","id":4726},
{"institution":"New Castle Beauty Academy","id":4727},
{"institution":"NorthWest Arkansas Community College","id":4728},
{"institution":"Madera Beauty College","id":4729},
{"institution":"Colorado Heights University","id":4730},
{"institution":"Brewster Technical Center","id":4731},
{"institution":"Hodges University","id":4732},
{"institution":"Everest University-Lakeland","id":4733},
{"institution":"Argosy University-Atlanta","id":4734},
{"institution":"University of St Augustine for Health Sciences","id":4735},
{"institution":"Capital Area School of Practical Nursing","id":4736},
{"institution":"Hair Professionals Career College","id":4737},
{"institution":"Alexandria School of Scientific Therapeutics","id":4738},
{"institution":"Empire Beauty School-Bloomfield","id":4739},
{"institution":"Southwest Technology Center","id":4740},
{"institution":"Western Area Career and Technology Center","id":4741},
{"institution":"Daymar Institute-Clarksville","id":4742},
{"institution":"Nossi College of Art","id":4743},
{"institution":"Seguin Beauty School-New Braunfels","id":4744},
{"institution":"ITT Technical Institute-Norfolk","id":4745},
{"institution":"Pima Medical Institute-Seattle","id":4746},
{"institution":"Mineral County Vocational Technical Center","id":4747},
{"institution":"Hypnosis Motivation Institute","id":4748},
{"institution":"Los Angeles ORT College-Los Angeles Campus","id":4749},
{"institution":"Everest College-West Los Angeles","id":4750},
{"institution":"Thanh Le College School of Cosmetology","id":4751},
{"institution":"Southeastern Technical College","id":4752},
{"institution":"Massachusetts School of Law","id":4753},
{"institution":"ITT Technical Institute-Albuquerque","id":4754},
{"institution":"Community Services Division-Alliance City","id":4755},
{"institution":"Universal Technical Institute-Auto Motorcycle & Marine Mechanics Institute Division-Orlando","id":4756},
{"institution":"D G Erwin Technical Center","id":4757},
{"institution":"Rosel School of Cosmetology","id":4758},
{"institution":"Worsham College of Mortuary Science","id":4759},
{"institution":"Bryan University","id":4760},
{"institution":"Oregon College of Oriental Medicine","id":4761},
{"institution":"Central Pennsylvania Institute of Science and Technology","id":4762},
{"institution":"Northwest Educational Center","id":4763},
{"institution":"Sebring Career Schools-Houston","id":4764},
{"institution":"PCI Health Training Center","id":4765},
{"institution":"Windham Memorial Hospital-Radiologic Technology Program","id":4766},
{"institution":"Bethel Seminary of the East","id":4767},
{"institution":"Miami-Jacobs Career College-Columbus","id":4768},
{"institution":"Bryant & Stratton College-Eastlake","id":4769},
{"institution":"Hamrick School","id":4770},
{"institution":"Alabama State College of Barber Styling","id":4771},
{"institution":"Fortis College-Mobile","id":4772},
{"institution":"Arkansas Beauty School","id":4773},
{"institution":"Everest College-Ontario","id":4774},
{"institution":"Downey Adult School","id":4775},
{"institution":"Heald College-Stockton","id":4776},
{"institution":"Heald College-Hayward","id":4777},
{"institution":"CRU Institute","id":4778},
{"institution":"Modern Technology School","id":4779},
{"institution":"National Career Education","id":4780},
{"institution":"Everest College-Anaheim","id":4781},
{"institution":"Thuy Princess Beauty College","id":4782},
{"institution":"North-West College-Glendale","id":4783},
{"institution":"Everest College-City of Industry","id":4784},
{"institution":"Fremont College","id":4785},
{"institution":"Pomona Unified School District Adult and Career Education","id":4786},
{"institution":"University of Phoenix-Online Campus","id":4787},
{"institution":"University of Phoenix-San Diego Campus","id":4788},
{"institution":"Universal College of Beauty Inc-Los Angeles 2","id":4789},
{"institution":"IBMC College","id":4790},
{"institution":"Xenon International Academy-Denver","id":4791},
{"institution":"Academy of Cosmetology","id":4792},
{"institution":"ITT Technical Institute-Lake Mary","id":4793},
{"institution":"Sunstate Academy","id":4794},
{"institution":"Heritage Institute-Jacksonville","id":4795},
{"institution":"Fortis College-Smyrna","id":4796},
{"institution":"Remington College-Honolulu Campus","id":4797},
{"institution":"Vatterott College-Des Moines","id":4798},
{"institution":"Brown Mackie College-Quad Cities","id":4799},
{"institution":"ETI School of Skilled Trades","id":4800},
{"institution":"Pivot Point Academy-Bloomingdale","id":4801},
{"institution":"Xenon International Academy-Kansas City","id":4802},
{"institution":"Baton Rouge College","id":4803},
{"institution":"Diesel Driving Academy-Baton Rouge","id":4804},
{"institution":"Jonesville Beauty School","id":4805},
{"institution":"New England Hair Academy","id":4806},
{"institution":"Rob Roy Academy-Worcester","id":4807},
{"institution":"Upper Cape Cod Regional Technical School","id":4808},
{"institution":"All-State Career-Baltimore","id":4809},
{"institution":"New England School of Communications","id":4810},
{"institution":"Everest Institute-Kalamazoo","id":4811},
{"institution":"Adler Graduate School","id":4812},
{"institution":"Lebanon Technology and Career Center","id":4813},
{"institution":"Academy of Hair Design-Jackson","id":4814},
{"institution":"KC's School of Hair Design","id":4815},
{"institution":"Mitchells Hairstyling Academy-Goldsboro","id":4816},
{"institution":"Lincoln Technical Institute-Moorestown","id":4817},
{"institution":"Monmouth County Vocational School District","id":4818},
{"institution":"Kaplan College-Las Vegas","id":4819},
{"institution":"Beauty School of Middletown","id":4820},
{"institution":"Bryant & Stratton College-Southtowns","id":4821},
{"institution":"Continental School of Beauty Culture-Olean","id":4822},
{"institution":"National Tractor Trailer School Inc-Buffalo","id":4823},
{"institution":"Yeshiva Gedolah Imrei Yosef D'spinka","id":4824},
{"institution":"Cleveland Institute of Dental-Medical Assistants-Mentor","id":4825},
{"institution":"Cleveland Institute of Dental-Medical Assistants-Lyndhurst","id":4826},
{"institution":"Knox County Career Center","id":4827},
{"institution":"Madison Adult Career Center","id":4828},
{"institution":"Remington College-Cleveland Campus","id":4829},
{"institution":"Brown Mackie College-Findlay","id":4830},
{"institution":"Toledo Academy of Beauty Culture-East","id":4831},
{"institution":"U S Grant Joint Vocational School","id":4832},
{"institution":"CC's Cosmetology College","id":4833},
{"institution":"Chisholm Trail Technology Center","id":4834},
{"institution":"Gordon Cooper Technology Center","id":4835},
{"institution":"Kiamichi Technology Center-Atoka","id":4836},
{"institution":"Kiamichi Technology Center-Durant","id":4837},
{"institution":"Kiamichi Technology Center-Hugo","id":4838},
{"institution":"Kiamichi Technology Center-McAlester","id":4839},
{"institution":"Kiamichi Technology Center-Idabel","id":4840},
{"institution":"Kiamichi Technology Center-Poteau","id":4841},
{"institution":"Kiamichi Technology Center-Spiro","id":4842},
{"institution":"Kiamichi Technology Center-Talihina","id":4843},
{"institution":"Le Cordon Bleu College of Culinary Arts-Portland","id":4844},
{"institution":"YTI Career Institute-Altoona","id":4845},
{"institution":"Eastern Center for Arts and Technology","id":4846},
{"institution":"Empire Beauty School-Center City Philadelphia","id":4847},
{"institution":"Oakbridge Academy of Arts","id":4848},
{"institution":"Sanford-Brown Institute-Wilkins Township","id":4849},
{"institution":"Centro de Estudios Multidisciplinarios-Humacao","id":4850},
{"institution":"Colegio Mayor de Tecnologia Inc","id":4851},
{"institution":"Institucion Chaviano de Mayaguez","id":4852},
{"institution":"EDIC College","id":4853},
{"institution":"Liceo de Arte-Dise-O y Comercio","id":4854},
{"institution":"Universal Technology College of Puerto Rico","id":4855},
{"institution":"Academy of Hair Technology","id":4856},
{"institution":"Mr Wayne's School of Unisex Hair Design","id":4857},
{"institution":"Southern Institute of Cosmetology","id":4858},
{"institution":"Queen City College","id":4859},
{"institution":"College of the Marshall Islands","id":4860},
{"institution":"American National College","id":4861},
{"institution":"ITT Technical Institute-San Antonio","id":4862},
{"institution":"Remington College-Fort Worth Campus","id":4863},
{"institution":"University of Cosmetology Arts & Sciences-San Antonio Jamar","id":4864},
{"institution":"School of Automotive Machinists","id":4865},
{"institution":"SW School of Business and Technical Careers-Cosmetology","id":4866},
{"institution":"SW School of Business and Technical Careers","id":4867},
{"institution":"Texas College of Cosmetology-Abilene","id":4868},
{"institution":"Vista College-Online","id":4869},
{"institution":"Centura College-Newport News","id":4870},
{"institution":"Centura College-Norfolk","id":4871},
{"institution":"Virginia Beach City Public Schools School of Practical Nursing","id":4872},
{"institution":"Northwest College of Art & Design","id":4873},
{"institution":"University of Washington-Bothell Campus","id":4874},
{"institution":"University of Washington-Tacoma Campus","id":4875},
{"institution":"Huntington School of Beauty Culture-Main Campus","id":4876},
{"institution":"Valley College-Beckley","id":4877},
{"institution":"Valley College-Martinsburg","id":4878},
{"institution":"Valley College-Princeton","id":4879},
{"institution":"Bellus Academy-Poway","id":4880},
{"institution":"Capstone College","id":4881},
{"institution":"Central Coast College","id":4882},
{"institution":"ITT Technical Institute-Torrance","id":4883},
{"institution":"Pacific College of Oriental Medicine-San Diego","id":4884},
{"institution":"Artistic Nails and Beauty Academy-Tampa","id":4885},
{"institution":"National Aviation Academy of Tampa Bay","id":4886},
{"institution":"Sanford-Brown Institute-Tampa","id":4887},
{"institution":"Cain's Barber College Inc","id":4888},
{"institution":"Lawton Career Institute-Oak Park Campus","id":4889},
{"institution":"Madison Oneida BOCES-Practical Nursing Program","id":4890},
{"institution":"Astrodome Career Centers","id":4891},
{"institution":"Center for Advanced Legal Studies","id":4892},
{"institution":"Houston Training School-Main Campus","id":4893},
{"institution":"Remington College-Houston Campus","id":4894},
{"institution":"Bay Mills Community College","id":4895},
{"institution":"Fond du Lac Tribal and Community College","id":4896},
{"institution":"Northwest Indian College","id":4897},
{"institution":"Marinello Schools of Beauty-Provo","id":4898},
{"institution":"Provo College","id":4899},
{"institution":"Paul Mitchell the School-Provo","id":4900},
{"institution":"University of Phoenix-Utah Campus","id":4901},
{"institution":"Utah College of Massage Therapy-Salt Lake City","id":4902},
{"institution":"CET-Coachella","id":4903},
{"institution":"CET-Oxnard","id":4904},
{"institution":"CET-Santa Maria","id":4905},
{"institution":"CET-Rancho Temecula","id":4906},
{"institution":"Colorado School of Traditional Chinese Medicine","id":4907},
{"institution":"Massage Therapy Institute of Colorado","id":4908},
{"institution":"Michigan College of Beauty-Troy","id":4909},
{"institution":"Ogle School Hair Skin Nails-Dallas","id":4910},
{"institution":"Phoenix Seminary","id":4911},
{"institution":"Faust Institute of Cosmetology-Spirit Lake","id":4912},
{"institution":"Seattle Vocational Institute","id":4913},
{"institution":"Baker College of Port Huron","id":4914},
{"institution":"Empire Beauty School-Hyannis","id":4915},
{"institution":"Brookstone College-Greensboro","id":4916},
{"institution":"Cheeks International Academy of Beauty Culture-Fort Collins","id":4917},
{"institution":"Cheeks International Academy of Beauty Culture-Greeley","id":4918},
{"institution":"Colorado School of Healing Arts","id":4919},
{"institution":"Remington College-Colorado Springs Campus","id":4920},
{"institution":"Empire Beauty School-Arvada","id":4921},
{"institution":"Westwood College-Denver South","id":4922},
{"institution":"Kaplan College-Denver","id":4923},
{"institution":"Healing Arts Institute","id":4924},
{"institution":"ITT Technical Institute-San Bernardino","id":4925},
{"institution":"Los Angeles ORT College-Van Nuys Campus","id":4926},
{"institution":"Texas School of Business-Southwest","id":4927},
{"institution":"University of Phoenix-Puerto Rico Campus","id":4928},
{"institution":"Mt Diablo Adult Education","id":4929},
{"institution":"Royale College of Beauty","id":4930},
{"institution":"Westech College","id":4931},
{"institution":"Greater Lowell Technical School","id":4932},
{"institution":"Academy of Hair Design-Pearl","id":4933},
{"institution":"King's College","id":4934},
{"institution":"Miller-Motte Technical College-Clarksville","id":4935},
{"institution":"Genesis Career College-Lebanon","id":4936},
{"institution":"Kaplan College-Dallas","id":4937},
{"institution":"Star College of Cosmetology 2","id":4938},
{"institution":"Southwest Collegiate Institute for the Deaf","id":4939},
{"institution":"Fortis College-Richmond","id":4940},
{"institution":"Midwest College of Oriental Medicine-Racine","id":4941},
{"institution":"Hacienda La Puente Adult Education","id":4942},
{"institution":"La Belle Beauty Academy","id":4943},
{"institution":"Advanced Technical Centers","id":4944},
{"institution":"Hawaii Community College","id":4945},
{"institution":"Regency Beauty Institute-Winston-Salem","id":4946},
{"institution":"Robert Fiance Beauty Schools-North Plainfield","id":4947},
{"institution":"Long Island Beauty School-Hauppauge","id":4948},
{"institution":"Buckeye Joint Vocational School","id":4949},
{"institution":"Sandusky Career Center","id":4950},
{"institution":"Greater Altoona Career & Technology Center","id":4951},
{"institution":"Northeastern Hospital School of Nursing","id":4952},
{"institution":"Central Texas Beauty College-Temple","id":4953},
{"institution":"Dance Theatre of Harlem Inc","id":4954},
{"institution":"Erie 2 Chautauqua Cattaraugus BOCES-Practical Nursing Program","id":4955},
{"institution":"Empire Beauty School-Brooklyn","id":4956},
{"institution":"Houston Training School-Gulfgate","id":4957},
{"institution":"Star Career Academy-Philadelphia","id":4958},
{"institution":"Antonelli College-Hattiesburg","id":4959},
{"institution":"Eastern New Mexico University-Ruidoso Campus","id":4960},
{"institution":"Newbridge College-Santa Ana","id":4961},
{"institution":"Kaplan College-Stockton","id":4962},
{"institution":"The Bryman School of Arizona","id":4963},
{"institution":"Beacon College","id":4964},
{"institution":"Acupuncture and Integrative Medicine College-Berkeley","id":4965},
{"institution":"Estrella Mountain Community College","id":4966},
{"institution":"Heartland Community College","id":4967},
{"institution":"University of the Potomac-Washington DC Campus","id":4968},
{"institution":"Rabbi Jacob Joseph School","id":4969},
{"institution":"Sanford-Brown Institute-Ft Lauderdale","id":4970},
{"institution":"The Salon Professional Academy-Melbourne","id":4971},
{"institution":"Fortis Institute-Scranton","id":4972},
{"institution":"Milan Institute of Cosmetology-Amarillo","id":4973},
{"institution":"Everglades University","id":4974},
{"institution":"Seminar L'moros Bais Yaakov","id":4975},
{"institution":"Herzing University-Winter Park","id":4976},
{"institution":"Coral Ridge Nurses Assistant Training School Inc","id":4977},
{"institution":"Emil Fries Piano Hospital and Training Center","id":4978},
{"institution":"Fairview Beauty Academy","id":4979},
{"institution":"Florida College of Natural Health-Pompano Beach","id":4980},
{"institution":"National College-Nashville","id":4981},
{"institution":"College of Biblical Studies-Houston","id":4982},
{"institution":"Inner State Beauty School","id":4983},
{"institution":"Virginia College-Pensacola","id":4984},
{"institution":"Mid-EastCTC-Adult Education","id":4985},
{"institution":"Everest University-South Orlando","id":4986},
{"institution":"Polaris Career Center","id":4987},
{"institution":"P&A Scholars Beauty School","id":4988},
{"institution":"Rensselaer BOCES-Practical Nursing Program","id":4989},
{"institution":"ASM Beauty World Academy","id":4990},
{"institution":"The Salter School-Tewksbury Campus","id":4991},
{"institution":"Sanford-Brown College-Collinsville","id":4992},
{"institution":"Sharps Academy of Hair Styling","id":4993},
{"institution":"ICC Technical Institute","id":4994},
{"institution":"College of Health Care Professions-Northwest","id":4995},
{"institution":"School of Professional Horticulture at the New York Botanical Garden","id":4996},
{"institution":"Fortis College-Houston","id":4997},
{"institution":"Volunteer Beauty Academy-Madison","id":4998},
{"institution":"Watkins College of Art Design & Film","id":4999},
{"institution":"Chicago ORT Technical Institute","id":5000},
{"institution":"Alhambra Beauty College","id":5001},
{"institution":"American Auto Institute","id":5002},
{"institution":"Asian American International Beauty College","id":5003},
{"institution":"Avance Beauty College","id":5004},
{"institution":"Colton-Redlands-Yucaipa Regional Occupational Program","id":5005},
{"institution":"Copper Mountain Community College","id":5006},
{"institution":"Diversified Vocational College","id":5007},
{"institution":"Bristol University","id":5008},
{"institution":"UEI College-Fresno","id":5009},
{"institution":"Mt Sierra College","id":5010},
{"institution":"Wyotech-Long Beach","id":5011},
{"institution":"Santiago Canyon College","id":5012},
{"institution":"Southern California Institute of Technology","id":5013},
{"institution":"Soka University of America","id":5014},
{"institution":"Summit College","id":5015},
{"institution":"Advance Beauty College","id":5016},
{"institution":"Career Colleges of America","id":5017},
{"institution":"World Mission University","id":5018},
{"institution":"Yo San University of Traditional Chinese Medicine","id":5019},
{"institution":"Coast Career Institute","id":5020},
{"institution":"Cannella School of Hair Design-Blue Island","id":5021},
{"institution":"Delta College Inc","id":5022},
{"institution":"Colorado Technical University-Sioux Falls","id":5023},
{"institution":"Southwest University of Visual Arts-Albuquerque","id":5024},
{"institution":"Tri-State Cosmetology Institute","id":5025},
{"institution":"Widener University-Harrisburg Campus","id":5026},
{"institution":"Volunteer Beauty Academy-System Office","id":5027},
{"institution":"Volunteer Beauty Academy-Nashville","id":5028},
{"institution":"Frontier Community College","id":5029},
{"institution":"Lincoln Trail College","id":5030},
{"institution":"Wabash Valley College","id":5031},
{"institution":"American Institute of Interior Design","id":5032},
{"institution":"Brookline College-Tempe","id":5033},
{"institution":"Baker College of Auburn Hills","id":5034},
{"institution":"Baker College of Clinton Township","id":5035},
{"institution":"Inter American University of Puerto Rico-School of Optometry","id":5036},
{"institution":"Schiller International University","id":5037},
{"institution":"Vatterott College-Springfield","id":5038},
{"institution":"Vatterott College-Joplin","id":5039},
{"institution":"Vatterott College-Kansas City","id":5040},
{"institution":"Coconino Community College","id":5041},
{"institution":"University System of Maryland-Research Centers","id":5042},
{"institution":"Sanford-Brown College-Houston","id":5043},
{"institution":"Sanford-Brown Institute-Jacksonville","id":5044},
{"institution":"Sanford-Brown College-Dallas","id":5045},
{"institution":"Baker College of Cadillac","id":5046},
{"institution":"Emma's Beauty Academy-Juana Diaz","id":5047},
{"institution":"Franklin Academy","id":5048},
{"institution":"Columbia Centro Universitario-Yauco","id":5049},
{"institution":"Pima Medical Institute-Denver","id":5050},
{"institution":"Fortis College-Miami","id":5051},
{"institution":"ASA College","id":5052},
{"institution":"Ohio State School of Cosmetology-Westerville","id":5053},
{"institution":"Nationwide Beauty Academy","id":5054},
{"institution":"Yeshivas Novominsk","id":5055},
{"institution":"Southern Careers Institute-Corpus Christi","id":5056},
{"institution":"Kiamichi Technology Center-Stigler","id":5057},
{"institution":"Rabbinical College of Ohr Shimon Yisroel","id":5058},
{"institution":"Carroll Community College","id":5059},
{"institution":"University of Phoenix-Southern Arizona Campus","id":5060},
{"institution":"University of Phoenix-Colorado Campus","id":5061},
{"institution":"University of Phoenix-Albuquerque Campus","id":5062},
{"institution":"University of Phoenix-Bay Area Campus","id":5063},
{"institution":"University of Phoenix-Southern California Campus","id":5064},
{"institution":"Galen College of Nursing-Tampa Bay","id":5065},
{"institution":"Galen College of Nursing-San Antonio","id":5066},
{"institution":"Southeastern Beauty School-Columbus North Lumpkin","id":5067},
{"institution":"Southeastern Beauty School-Columbus Midtown","id":5068},
{"institution":"Sarasota School of Massage Therapy","id":5069},
{"institution":"Westwood College-Dupage","id":5070},
{"institution":"Wright Career College","id":5071},
{"institution":"Daymar College-Louisville","id":5072},
{"institution":"Sanford-Brown College-Hazelwood","id":5073},
{"institution":"Schuyler Steuben Chemung Tioga Allegany BOCES","id":5074},
{"institution":"Delaware Chenango Madison Otsego BOCES-Practical Nursing Program","id":5075},
{"institution":"DCI Career Institute","id":5076},
{"institution":"Central Susquehanna Intermediate Unit LPN Career","id":5077},
{"institution":"Myotherapy College of Utah","id":5078},
{"institution":"Graham Webb International Academy of Hair","id":5079},
{"institution":"City College-Gainesville","id":5080},
{"institution":"Arizona State University-West","id":5081},
{"institution":"Connecticut Center for Massage Therapy-Westport","id":5082},
{"institution":"ITT Technical Institute-Jacksonville","id":5083},
{"institution":"G Skin & Beauty Institute","id":5084},
{"institution":"Cosmetology Training Center","id":5085},
{"institution":"Stage One-The Hair School","id":5086},
{"institution":"Empire Beauty School-Malden","id":5087},
{"institution":"Minnesota School of Business-Brooklyn Center","id":5088},
{"institution":"ITT Technical Institute-Omaha","id":5089},
{"institution":"Omaha School of Massage and Healthcare of Herzing University","id":5090},
{"institution":"Xenon International Academy-Omaha","id":5091},
{"institution":"Capri Institute of Hair Design-Brick","id":5092},
{"institution":"Capri Institute of Hair Design-Kenilworth","id":5093},
{"institution":"Capri Institute of Hair Design-Paramus","id":5094},
{"institution":"Orlo School of Hair Design and Cosmetology","id":5095},
{"institution":"Delaware Area Career Center","id":5096},
{"institution":"Eastland-Fairfield Career and Technical Schools","id":5097},
{"institution":"Empire Beauty School-Cincinnati","id":5098},
{"institution":"Mahoning County Career and Technical Center","id":5099},
{"institution":"Ohio Hi Point Joint Vocational School District","id":5100},
{"institution":"Pickaway Ross Joint Vocational School District","id":5101},
{"institution":"Pioneer Career and Technology Center","id":5102},
{"institution":"Raphael's School of Beauty Culture Inc-Boardman","id":5103},
{"institution":"Scioto County Career Technical Center","id":5104},
{"institution":"Trumbull Career & Technical Center","id":5105},
{"institution":"Northwest Technology Center-Fairview","id":5106},
{"institution":"Lake Erie College of Osteopathic Medicine","id":5107},
{"institution":"University of Cosmetology Arts & Sciences-Harlingen","id":5108},
{"institution":"Gene Juarez Academy of Beauty-Federal Way","id":5109},
{"institution":"Empire Beauty School-Appleton","id":5110},
{"institution":"Empire Beauty School-Green Bay","id":5111},
{"institution":"Warren County Career Center","id":5112},
{"institution":"Brown Mackie College-Fort Wayne","id":5113},
{"institution":"Paul Mitchell the School-Wichita","id":5114},
{"institution":"Pinnacle Institute of Cosmetology","id":5115},
{"institution":"Career Training Academy-Monroeville","id":5116},
{"institution":"Southern Careers Institute-Pharr","id":5117},
{"institution":"Texas State Technical College-Marshall","id":5118},
{"institution":"Florida National University-Main Campus","id":5119},
{"institution":"Georgia Institute of Cosmetology","id":5120},
{"institution":"Nouvelle Institute","id":5121},
{"institution":"ITT Technical Institute-Fort Lauderdale","id":5122},
{"institution":"Franklin Technology-MSSU","id":5123},
{"institution":"Carson City Beauty Academy","id":5124},
{"institution":"Professional Technical Institution Inc","id":5125},
{"institution":"Erikson Institute","id":5126},
{"institution":"South Texas College","id":5127},
{"institution":"Tennessee Board of Regents","id":5128},
{"institution":"Pike County Joint Vocational School District","id":5129},
{"institution":"Lorain County Joint Vocational School District","id":5130},
{"institution":"Roger Williams University School of Law","id":5131},
{"institution":"Everest Institute-Kendall","id":5132},
{"institution":"California State University-Monterey Bay","id":5133},
{"institution":"Sanford-Brown College-St Peters","id":5134},
{"institution":"Colorado Technical University-Kansas City","id":5135},
{"institution":"Heald College-Salinas","id":5136},
{"institution":"Academy of Somatic Healing Arts","id":5137},
{"institution":"Heritage College-Oklahoma City","id":5138},
{"institution":"Ann Arbor Institute of Massage Therapy","id":5139},
{"institution":"Brown Mackie College-Atlanta","id":5140},
{"institution":"Bryant & Stratton College-Henrietta","id":5141},
{"institution":"The Art Institute of California-Argosy University Hollywood","id":5142},
{"institution":"Sage College","id":5143},
{"institution":"Anthem College-Denver","id":5144},
{"institution":"Lincoln Technical Institute-Hartford","id":5145},
{"institution":"Ohio Center for Broadcasting-Valley View","id":5146},
{"institution":"EINE Inc","id":5147},
{"institution":"Laurus Technical Institute","id":5148},
{"institution":"Illinois School of Health Careers-Chicago Campus","id":5149},
{"institution":"Marian Health Careers Center-Los Angeles Campus","id":5150},
{"institution":"Cleveland Clinic Health System-School of Diagnostic Imaging","id":5151},
{"institution":"National Institute of Massotherapy","id":5152},
{"institution":"Academy for Nursing and Health Occupations","id":5153},
{"institution":"Shawsheen Valley Regional Vocational Technical School","id":5154},
{"institution":"Shear Ego International School of Hair Design","id":5155},
{"institution":"Remington College-Memphis Campus","id":5156},
{"institution":"Star Truck Driving School-Hickory Hills","id":5157},
{"institution":"Fayette Institute of Technology","id":5158},
{"institution":"Professional Beauty School","id":5159},
{"institution":"Capella University","id":5160},
{"institution":"College of Menominee Nation","id":5161},
{"institution":"Leech Lake Tribal College","id":5162},
{"institution":"Anthem College-Sacramento","id":5163},
{"institution":"Southwest Acupuncture College-Albuquerque","id":5164},
{"institution":"Crimson Technical College","id":5165},
{"institution":"Stone Academy-Waterbury","id":5166},
{"institution":"East San Gabriel Valley Regional Occupational Program","id":5167},
{"institution":"Yukon Beauty College Inc","id":5168},
{"institution":"ITT Technical Institute-Little Rock","id":5169},
{"institution":"ITT Technical Institute-Oxnard","id":5170},
{"institution":"ITT Technical Institute-Louisville","id":5171},
{"institution":"ITT Technical Institute-Greenville","id":5172},
{"institution":"ITT Technical Institute-Cordova","id":5173},
{"institution":"Cairn University-Wisconsin Wilderness","id":5174},
{"institution":"Toni & Guy Hairdressing Academy-Atlanta","id":5175},
{"institution":"Mitchell's Hair Styling Academy-Raleigh","id":5176},
{"institution":"Salon Academy","id":5177},
{"institution":"Artistic Academy of Hair Design","id":5178},
{"institution":"Motoring Technical Training Institute","id":5179},
{"institution":"Baker College of Jackson","id":5180},
{"institution":"Phagans Newport Academy of Cosmetology Careers","id":5181},
{"institution":"Mech-Tech College","id":5182},
{"institution":"Baldwin Beauty School-North Austin","id":5183},
{"institution":"ITT Technical Institute-Everett","id":5184},
{"institution":"Jean Madeline Aveda Institute","id":5185},
{"institution":"Cosmetology Schools Inc","id":5186},
{"institution":"ITT Technical Institute-Bessemer","id":5187},
{"institution":"ITT Technical Institute-Orland Park","id":5188},
{"institution":"Pacific College of Oriental Medicine-New York","id":5189},
{"institution":"Baker College Center for Graduate Studies","id":5190},
{"institution":"Johnson & Wales University-North Miami","id":5191},
{"institution":"ITT Technical Institute-Pittsburgh","id":5192},
{"institution":"Tricoci University of Beauty Culture-Indianapolis","id":5193},
{"institution":"Trine University-Regional/Non-Traditional Campuses","id":5194},
{"institution":"Pennsylvania Highlands Community College","id":5195},
{"institution":"Nevada Career Institute","id":5196},
{"institution":"Phillips Theological Seminary","id":5197},
{"institution":"Empire Beauty School-Hanover","id":5198},
{"institution":"Industrial Management Training Institute","id":5199},
{"institution":"Faris Technical Institute Inc","id":5200},
{"institution":"Four-D College","id":5201},
{"institution":"Allen School-Jamaica","id":5202},
{"institution":"Premiere Career College","id":5203},
{"institution":"Tennessee Academy of Cosmetology-Stage Road","id":5204},
{"institution":"The University of Texas MD Anderson Cancer Center","id":5205},
{"institution":"University of Phoenix-Las Vegas Campus","id":5206},
{"institution":"Carousel Beauty College-Springfield","id":5207},
{"institution":"Carousel Beauty College-Kettering","id":5208},
{"institution":"Paul Mitchell the School-Huntsville","id":5209},
{"institution":"Estes Institute of Cosmetology Arts and Science","id":5210},
{"institution":"Baldwin Park Adult & Community Education","id":5211},
{"institution":"Ventura Adult and Continuing Education","id":5212},
{"institution":"Marinello Schools of Beauty-Sacramento","id":5213},
{"institution":"Albert I Prince Technical High School","id":5214},
{"institution":"Bullard-Havens Technical High School","id":5215},
{"institution":"Howell Cheney Technical High School","id":5216},
{"institution":"W F Kaynor Technical High School","id":5217},
{"institution":"Platt Technical High School","id":5218},
{"institution":"College of Business and Technology-Kendall","id":5219},
{"institution":"City College-Altamonte Springs","id":5220},
{"institution":"Augusta Area Dietetic Internship-University Hospital","id":5221},
{"institution":"Empire Beauty School-Dunwoody","id":5222},
{"institution":"Michael's School of Beauty","id":5223},
{"institution":"Cobb Beauty College Inc","id":5224},
{"institution":"Career Center of Southern Illinois","id":5225},
{"institution":"Xenon International Academy-Wichita","id":5226},
{"institution":"Camelot College","id":5227},
{"institution":"Mr Bernard's School of Hair Fashion Inc","id":5228},
{"institution":"Hastings Beauty School","id":5229},
{"institution":"Kirksville Area Technical Center","id":5230},
{"institution":"South Central Career Center","id":5231},
{"institution":"Saline County Career Center","id":5232},
{"institution":"Warrensburg Area Career Center","id":5233},
{"institution":"Nevada Regional Technical Center","id":5234},
{"institution":"Cass Career Center","id":5235},
{"institution":"Applied Technology Services","id":5236},
{"institution":"Central College of Cosmetology","id":5237},
{"institution":"Southeast Missouri Hospital College of Nursing and Health Sciences","id":5238},
{"institution":"Dallas County Technical Center","id":5239},
{"institution":"Messenger College","id":5240},
{"institution":"Tennessee Academy of Cosmetology-Shelby Drive","id":5241},
{"institution":"Cheveux School of Hair Design","id":5242},
{"institution":"Durham Beauty Academy","id":5243},
{"institution":"Union County Vocational Technical School","id":5244},
{"institution":"Prism Career Institute-Philadelphia","id":5245},
{"institution":"Clinton Essex Warren Washington BOCES-Practical Nursing Program","id":5246},
{"institution":"Jefferson Lewis BOCES-Practical Nursing Program","id":5247},
{"institution":"Rochester General Hospital School of Medical Technology","id":5248},
{"institution":"Veeb Nassau County School of Practical Nursing","id":5249},
{"institution":"Syracuse City Schools Practical Nursing Program","id":5250},
{"institution":"Cattaraugus Allegany BOCES-Practical Nursing Program","id":5251},
{"institution":"Orange Ulster BOCES-Practical Nursing Program","id":5252},
{"institution":"Wayne Finger Lakes BOCES-Practical Nursing Program","id":5253},
{"institution":"Broome Delaware Tioga BOCES-Practical Nursing Program","id":5254},
{"institution":"Washington Saratoga Warren Hamilton Essex BOCES-Practical Nursing Program","id":5255},
{"institution":"Albany BOCES-Adult Practical Nursing Program","id":5256},
{"institution":"Ulster County BOCES-Practical Nursing Program","id":5257},
{"institution":"Eastern Suffolk BOCES-Practical Nursing Program","id":5258},
{"institution":"Rockland County BOCES-Practical Nursing Program","id":5259},
{"institution":"New York College of Health Professions","id":5260},
{"institution":"Career Institute of Health and Technology","id":5261},
{"institution":"Charles Stuart School of Diamond Setting","id":5262},
{"institution":"Penta County Joint Vocational School","id":5263},
{"institution":"Career and Technology Education Centers of Licking County","id":5264},
{"institution":"Willoughby-Eastlake School of Practical Nursing","id":5265},
{"institution":"Washington County Career Center-Adult Technical Training","id":5266},
{"institution":"Ohio Center for Broadcasting-Cincinnati","id":5267},
{"institution":"Vantage Career Center","id":5268},
{"institution":"Tri County Technology Center","id":5269},
{"institution":"Indian Capital Technology Center-Muskogee","id":5270},
{"institution":"Western Technology Center","id":5271},
{"institution":"Mid-America Technology Center","id":5272},
{"institution":"Northeast Technology Center-Pryor","id":5273},
{"institution":"Eastern Oklahoma County Technology Center","id":5274},
{"institution":"Wes Watkins Technology Center","id":5275},
{"institution":"Technical Institute of Cosmetology Arts and Sciences","id":5276},
{"institution":"York County School of Technology-Adult & Continuing Education","id":5277},
{"institution":"Chester County Intermediate Unit","id":5278},
{"institution":"Jefferson County Dubois Area Vocational Technical Practical Nursing Program","id":5279},
{"institution":"Greater Johnstown Career and Technology Center","id":5280},
{"institution":"Mifflin-Juniata Career and Technology Center","id":5281},
{"institution":"Hanover Public School District Practical Nursing Program","id":5282},
{"institution":"Lenape Technical School Practical Nursing Program","id":5283},
{"institution":"Venango County Area Vocational Technical School","id":5284},
{"institution":"Lancaster County Career and Technology Center","id":5285},
{"institution":"Lebanon County Area Vocational Technical School","id":5286},
{"institution":"Indiana County Technology Center","id":5287},
{"institution":"Northern Tier Career Center","id":5288},
{"institution":"Clarion County Career Center Practical Nursing Program","id":5289},
{"institution":"Hazleton Area Career Center","id":5290},
{"institution":"All-State Career School-Pittsburgh","id":5291},
{"institution":"Carlos F Daniels Area Vocational School","id":5292},
{"institution":"Serbias Technical College","id":5293},
{"institution":"Colegio Tecnologico y Comercial de Puerto Rico","id":5294},
{"institution":"Quality Technical and Beauty College","id":5295},
{"institution":"D'Mart Institute","id":5296},
{"institution":"Automeca Technical College-Aguadilla","id":5297},
{"institution":"Fortis Institute-Cookeville","id":5298},
{"institution":"North Central Institute","id":5299},
{"institution":"Pipo Academy of Hair Design","id":5300},
{"institution":"Tint School of Makeup and Cosmetology-Dallas","id":5301},
{"institution":"United Technical Center","id":5302},
{"institution":"Charles H McCann Technical School","id":5303},
{"institution":"Ailano School of Cosmetology","id":5304},
{"institution":"Carsten Institute of Cosmetology","id":5305},
{"institution":"Central California School","id":5306},
{"institution":"Mercy Hospital School of Practical Nursing-Plantation General Hospital","id":5307},
{"institution":"Marion County Community Technical and Adult Education Center","id":5308},
{"institution":"Rivertown School of Beauty Barber Skin Care and Nails","id":5309},
{"institution":"Atlanta Institute of Music","id":5310},
{"institution":"Illinois Center for Broadcasting","id":5311},
{"institution":"Marion Community Schools-Tucker Career & Technology Center","id":5312},
{"institution":"St Louis Hair Academy","id":5313},
{"institution":"New Dimensions School of Hair Design","id":5314},
{"institution":"Adirondack Beauty School","id":5315},
{"institution":"Forbes Road Career and Technology Center","id":5316},
{"institution":"Jna Institute of Culinary Arts","id":5317},
{"institution":"Cortiva Institute-Seattle","id":5318},
{"institution":"Putnam Career and Technical Center","id":5319},
{"institution":"Argosy University-Washington DC","id":5320},
{"institution":"Crawford County Career and Technical Center Practical Nursing Program","id":5321},
{"institution":"University of Phoenix-Sacramento Valley Campus","id":5322},
{"institution":"DeVry University's Keller Graduate School of Management-Georgia","id":5323},
{"institution":"Texas College of Cosmetology-San Angelo","id":5324},
{"institution":"Beauty Schools of America-Hialeah","id":5325},
{"institution":"Automeca Technical College-Bayamon","id":5326},
{"institution":"Automeca Technical College-Caguas","id":5327},
{"institution":"Automeca Technical College-Ponce","id":5328},
{"institution":"Everest University-Melbourne","id":5329},
{"institution":"Centura College-Chesapeake","id":5330},
{"institution":"University of Phoenix-Hawaii Campus","id":5331},
{"institution":"Florida College of Natural Health-Miami","id":5332},
{"institution":"Prism Career Institute-Cherry Hill","id":5333},
{"institution":"Amerian National College","id":5334},
{"institution":"Francois D College of Hair Skin and Nails","id":5335},
{"institution":"Southwest College of Naturopathic Medicine & Health Sciences","id":5336},
{"institution":"Escuela de Peritos Electricistas de Isabela Inc","id":5337},
{"institution":"Virginia College-Birmingham","id":5338},
{"institution":"Virginia College-Huntsville","id":5339},
{"institution":"Yeshiva D'monsey Rabbinical College","id":5340},
{"institution":"Lincoln Technical Institute-Mahwah","id":5341},
{"institution":"Career Technical Institute","id":5342},
{"institution":"Northwest Vista College","id":5343},
{"institution":"ITT Technical Institute-Getzville","id":5344},
{"institution":"Oconee Fall Line Technical College","id":5345},
{"institution":"York County Community College","id":5346},
{"institution":"Northeast Technology Center-Afton","id":5347},
{"institution":"Western Suffolk BOCES","id":5348},
{"institution":"Sanford-Brown College-Atlanta","id":5349},
{"institution":"Sanford-Brown College-Middleburg Heights","id":5350},
{"institution":"Arkansas State University-Mountain Home","id":5351},
{"institution":"Columbia Gorge Community College","id":5352},
{"institution":"Arizona State University-Polytechnic","id":5353},
{"institution":"Colleen O'Haras Beauty Academy","id":5354},
{"institution":"Bakke Graduate University","id":5355},
{"institution":"Tillamook Bay Community College","id":5356},
{"institution":"Arizona Board of Regents","id":5357},
{"institution":"Argosy University-Schaumburg","id":5358},
{"institution":"DeVry University's Keller Graduate School of Management-California","id":5359},
{"institution":"Manhattan School of Computer Technology","id":5360},
{"institution":"University of Phoenix-Louisiana Campus","id":5361},
{"institution":"University of Phoenix-Detroit Campus","id":5362},
{"institution":"Brown Mackie College-Hopkinsville","id":5363},
{"institution":"Indian Capital Technology Center-Sallisaw","id":5364},
{"institution":"Indian Capital Technology Center-Stilwell","id":5365},
{"institution":"Globelle Technical Institute","id":5366},
{"institution":"Hairitage Hair Academy","id":5367},
{"institution":"Houghton Lake Institute of Cosmetology","id":5368},
{"institution":"Arizona College-Glendale","id":5369},
{"institution":"Vanguard-Sentinel Adult Career and Technology Center","id":5370},
{"institution":"House of Heavilin Beauty College-Raymore","id":5371},
{"institution":"Living Arts College","id":5372},
{"institution":"New York Conservatory for Dramatic Arts","id":5373},
{"institution":"Eastern International College-Jersey City","id":5374},
{"institution":"Academia Serrant Inc","id":5375},
{"institution":"San Joaquin Valley College-Fresno Aviation","id":5376},
{"institution":"Champion Institute of Cosmetology","id":5377},
{"institution":"Pacific College","id":5378},
{"institution":"American College of Healthcare","id":5379},
{"institution":"Compu-Med Vocational Careers Corp","id":5380},
{"institution":"Professional Electrical School Inc","id":5381},
{"institution":"American Commercial College-Wichita Falls","id":5382},
{"institution":"Midwestern University-Glendale","id":5383},
{"institution":"Oregon Coast Community College","id":5384},
{"institution":"Le Cordon Bleu College of Culinary Arts-Pasadena","id":5385},
{"institution":"Gwinnett College-Sandy Springs","id":5386},
{"institution":"Central Mass School of Massage and Therapy Inc","id":5387},
{"institution":"Lincoln Technical Institute-Brockton","id":5388},
{"institution":"Mildred Elley-Pittsfield Campus","id":5389},
{"institution":"Embry-Riddle Aeronautical University-Worldwide","id":5390},
{"institution":"Computer Systems Institute","id":5391},
{"institution":"Merkaz Bnos-Business School","id":5392},
{"institution":"Lia Schorr Institute of Cosmetic Skin Care Training","id":5393},
{"institution":"ITT Technical Institute-Webster","id":5394},
{"institution":"CET-Alexandria","id":5395},
{"institution":"CET-Durham","id":5396},
{"institution":"Aviation Institute of Maintenance-Chesapeake","id":5397},
{"institution":"Centura College-Richmond Main","id":5398},
{"institution":"Technical Education Center-Osceola","id":5399},
{"institution":"Miami Ad School-Miami Beach","id":5400},
{"institution":"Green Country Technology Center","id":5401},
{"institution":"Nova College de Puerto Rico","id":5402},
{"institution":"Styles and Profiles Beauty College","id":5403},
{"institution":"John Wesley International Barber and Beauty College","id":5404},
{"institution":"Platt College-Lawton","id":5405},
{"institution":"Southeastern College-Greenacres","id":5406},
{"institution":"Ottawa University-Milwaukee","id":5407},
{"institution":"Argosy University-Tampa","id":5408},
{"institution":"The Illinois Institute of Art-Schaumburg","id":5409},
{"institution":"Star Truck Driving School-Bensenville","id":5410},
{"institution":"Cambria-Rowe Business College-Indiana","id":5411},
{"institution":"Facultad de Derecho Eugenio Maria de Hostos","id":5412},
{"institution":"Klamath Community College","id":5413},
{"institution":"San Bernardino Community College District","id":5414},
{"institution":"The Art Institute of Phoenix","id":5415},
{"institution":"Aerosim Flight Academy","id":5416},
{"institution":"American College of Acupuncture and Oriental Med","id":5417},
{"institution":"AOMA Graduate School of Integrative Medicine","id":5418},
{"institution":"Arlington Medical Institute","id":5419},
{"institution":"Urban College of Boston","id":5420},
{"institution":"ITT Technical Institute-Henderson","id":5421},
{"institution":"Anthem College-Minnesota","id":5422},
{"institution":"Colorado Technical University-Greenwood Village","id":5423},
{"institution":"Branford Hall Career Institute-Windsor Campus","id":5424},
{"institution":"Heald College-Portland","id":5425},
{"institution":"Golf Academy of America-Altamonte Springs","id":5426},
{"institution":"Golf Academy of America-Phoenix","id":5427},
{"institution":"Everest College-Merrillville","id":5428},
{"institution":"Strayer University-Maryland","id":5429},
{"institution":"Empire Beauty School-Bordentown","id":5430},
{"institution":"Empire Beauty School-Monroeville","id":5431},
{"institution":"Southern Careers Institute-San Antonio","id":5432},
{"institution":"Virginia School of Massage","id":5433},
{"institution":"ITT Technical Institute-Miami","id":5434},
{"institution":"Cayce Reilly School of Massotherapy","id":5435},
{"institution":"CET-Sacramento","id":5436},
{"institution":"ITT Technical Institute-Harrisburg","id":5437},
{"institution":"ITT Technical Institute-Tarentum","id":5438},
{"institution":"ITT Technical Institute-Norwood","id":5439},
{"institution":"ITT Technical Institute-Strongsville","id":5440},
{"institution":"Prism Career Institute-West Atlantic City","id":5441},
{"institution":"Everest Institute-Marietta","id":5442},
{"institution":"The Creative Center","id":5443},
{"institution":"Studio Jewelers","id":5444},
{"institution":"Praxis Institute","id":5445},
{"institution":"American College of Traditional Chinese Medicine","id":5446},
{"institution":"Herndon Career Center","id":5447},
{"institution":"San Juan Bautista School of Medicine","id":5448},
{"institution":"Texas Health and Science University","id":5449},
{"institution":"Carver Career Center","id":5450},
{"institution":"New Hampshire Institute of Art","id":5451},
{"institution":"StenoTech Career Institute-Fairfield","id":5452},
{"institution":"Cayuga Onondaga BOCES-Practical Nursing Program","id":5453},
{"institution":"Clearfield County Career and Technology Center","id":5454},
{"institution":"Colegio de Cinematograf�a Artes y Television","id":5455},
{"institution":"Delaware County Technical School-Practical Nursing Program","id":5456},
{"institution":"Everest College-Torrance","id":5457},
{"institution":"The Institute for Health Education","id":5458},
{"institution":"Mid-Del Technology Center","id":5459},
{"institution":"BIR Training Center","id":5460},
{"institution":"New York Institute of Massage Inc","id":5461},
{"institution":"Jolie Hair and Beauty Academy-Ludlow","id":5462},
{"institution":"Dutchess BOCES-Practical Nursing Program","id":5463},
{"institution":"Escuela Tecnica de Electricidad","id":5464},
{"institution":"Institute of Technology Inc","id":5465},
{"institution":"Garnet Career Center","id":5466},
{"institution":"Bristol Technical Education Center","id":5467},
{"institution":"Genesee Valley BOCES-Practical Nursing Program","id":5468},
{"institution":"Hamilton Fulton Montgomery BOCES-Practical Nursing Program","id":5469},
{"institution":"Henrico County-Saint Marys Hospital School of Practical Nursing","id":5470},
{"institution":"Herkimer County BOCES-Practical Nursing Program","id":5471},
{"institution":"International Beauty College","id":5472},
{"institution":"Dewey University-Hato Rey","id":5473},
{"institution":"Cape Coral Institute of Technology","id":5474},
{"institution":"Massachusetts General Hospital Dietetic Internship","id":5475},
{"institution":"Mercy Hospital School of Nursing","id":5476},
{"institution":"American Beauty Academy","id":5477},
{"institution":"Northland Career Center","id":5478},
{"institution":"Norwich Technical High School","id":5479},
{"institution":"Ocean County Vocational-Technical School","id":5480},
{"institution":"Putnam Westchester BOCES-Practical Nursing Program","id":5481},
{"institution":"Sullivan County BOCES-Practical Nursing Program","id":5482},
{"institution":"Kaplan College-San Antonio-Ingram","id":5483},
{"institution":"Trinity College of Puerto Rico","id":5484},
{"institution":"Virginia Sewing Machines and School Center","id":5485},
{"institution":"Vogue Beauty and Barber School","id":5486},
{"institution":"Yeshiva of the Telshe Alumni","id":5487},
{"institution":"Northeast Technology Center-Kansas","id":5488},
{"institution":"Kenneth Shuler School of Cosmetology-Rock Hill","id":5489},
{"institution":"South Orange County Community College District","id":5490},
{"institution":"DeVry College of New York","id":5491},
{"institution":"DeVry University-Indiana","id":5492},
{"institution":"University of Phoenix-Western Washington Campus","id":5493},
{"institution":"University of Phoenix-Central Florida Campus","id":5494},
{"institution":"University of Phoenix-Oregon Campus","id":5495},
{"institution":"CET-El Paso","id":5496},
{"institution":"Kaplan College-Riverside","id":5497},
{"institution":"Appalachian School of Law","id":5498},
{"institution":"LIU Hudson at Westchester","id":5499},
{"institution":"Platt College-Ontario","id":5500},
{"institution":"Delaware College of Art and Design","id":5501},
{"institution":"The Art Institute of California-Argosy University Los Angeles","id":5502},
{"institution":"American University of Health Sciences","id":5503},
{"institution":"Career Networks Institute","id":5504},
{"institution":"Monty Tech","id":5505},
{"institution":"CHOICE High School and Technical Center","id":5506},
{"institution":"Lincoln Technical Institute-Lincoln","id":5507},
{"institution":"Wayne County Schools Career Center","id":5508},
{"institution":"Blessing Hospital School of Medical Laboratory Technology","id":5509},
{"institution":"Carolinas College of Health Sciences","id":5510},
{"institution":"Lester E Cox Medical Center-School of Medical Technology","id":5511},
{"institution":"Western Governors University","id":5512},
{"institution":"Santa Barbara Business College-Ventura","id":5513},
{"institution":"Sullivan and Cogliano Training Center","id":5514},
{"institution":"Herzing University-Kenner","id":5515},
{"institution":"Spencerian College-Lexington","id":5516},
{"institution":"Florida Gulf Coast University","id":5517},
{"institution":"Seacoast Career Schools-Sanford Campus","id":5518},
{"institution":"Little Priest Tribal College","id":5519},
{"institution":"National Labor College","id":5520},
{"institution":"ITT Technical Institute-Richardson","id":5521},
{"institution":"South Louisiana Community College","id":5522},
{"institution":"HRDE-Stanley Technical Institute-Parkersburg","id":5523},
{"institution":"Pima Medical Institute-Chula Vista","id":5524},
{"institution":"Lincoln College of Technology-Marietta","id":5525},
{"institution":"Academy of Hair Design-Lufkin","id":5526},
{"institution":"Academy of Hair Design-Beaumont","id":5527},
{"institution":"Baltimore School of Massage-Linthicum","id":5528},
{"institution":"Charter College-Canyon Country","id":5529},
{"institution":"Toni & Guy Hairdressing Academy-Worcester","id":5530},
{"institution":"Northwest HVAC/R Training Center","id":5531},
{"institution":"L T International Beauty School","id":5532},
{"institution":"Michigan Jewish Institute","id":5533},
{"institution":"Myotherapy Institute","id":5534},
{"institution":"Florida College of Integrative Medicine","id":5535},
{"institution":"New Professions Technical Institute","id":5536},
{"institution":"Strand College of Hair Design","id":5537},
{"institution":"Cosmetology Career Institute","id":5538},
{"institution":"Branford Hall Career Institute-Southington Campus","id":5539},
{"institution":"City College-Miami","id":5540},
{"institution":"ITT Technical Institute-Arnold","id":5541},
{"institution":"ITT Technical Institute-Oak Brook","id":5542},
{"institution":"ITT Technical Institute-Albany","id":5543},
{"institution":"ITT Technical Institute-Liverpool","id":5544},
{"institution":"Ilisagvik College","id":5545},
{"institution":"The Community College of Baltimore County","id":5546},
{"institution":"Golf Academy of America-Myrtle Beach","id":5547},
{"institution":"Florida Coastal School of Law","id":5548},
{"institution":"White Earth Tribal and Community College","id":5549},
{"institution":"Centura College-North Charleston","id":5550},
{"institution":"Blue Cliff College-Metairie","id":5551},
{"institution":"Creative Images Institute of Cosmetology-North Dayton","id":5552},
{"institution":"Dewey University-Carolina","id":5553},
{"institution":"Yeshiva College of the Nations Capital","id":5554},
{"institution":"University of Phoenix-South Florida Campus","id":5555},
{"institution":"University of Phoenix-North Florida Campus","id":5556},
{"institution":"University of Phoenix-West Florida Campus","id":5557},
{"institution":"University of Phoenix-Maryland Campus","id":5558},
{"institution":"University of Phoenix-Oklahoma City Campus","id":5559},
{"institution":"University of Phoenix-Tulsa Campus","id":5560},
{"institution":"Louisiana State University Health Sciences Center-Shreveport","id":5561},
{"institution":"Central Career Institute LLC","id":5562},
{"institution":"Franklin Career Institute","id":5563},
{"institution":"Montessori Institute of Milwaukee","id":5564},
{"institution":"Hair Academy II","id":5565},
{"institution":"Pryor Beauty College","id":5566},
{"institution":"Argosy University-Phoenix","id":5567},
{"institution":"Mr Leon's School of Hair Design-Lewiston","id":5568},
{"institution":"Vatterott College-St Joseph","id":5569},
{"institution":"Vatterott College-Sunset Hills","id":5570},
{"institution":"Anthem College-Orlando","id":5571},
{"institution":"Southwest Acupuncture College-Boulder","id":5572},
{"institution":"Utah College of Massage Therapy-Utah Valley","id":5573},
{"institution":"River Parishes Community College","id":5574},
{"institution":"Watson School of Biological Sciences at Cold Spring Harbor Laboratory","id":5575},
{"institution":"Los Angeles Film School","id":5576},
{"institution":"Argosy University-Orange County","id":5577},
{"institution":"Dewey University-Bayamon","id":5578},
{"institution":"National American University-Bloomington","id":5579},
{"institution":"A.B.I. School of Barbering & Cosmetology of Chelsea Inc.","id":5580},
{"institution":"Century College","id":5581},
{"institution":"FINE Mortuary College","id":5582},
{"institution":"International Baptist College and Seminary","id":5583},
{"institution":"The Medical Arts School","id":5584},
{"institution":"Fortis College-Foley","id":5585},
{"institution":"Medical Training College","id":5586},
{"institution":"Professional Training Centers","id":5587},
{"institution":"Toni & Guy Hairdressing Academy-Carrollton","id":5588},
{"institution":"University of Connecticut-Tri-Campus","id":5589},
{"institution":"University of Connecticut-Avery Point","id":5590},
{"institution":"University of Connecticut-Stamford","id":5591},
{"institution":"ITT Technical Institute-Saint Rose","id":5592},
{"institution":"ITT Technical Institute-Richmond","id":5593},
{"institution":"Vatterott College-Oklahoma City","id":5594},
{"institution":"University of Management and Technology","id":5595},
{"institution":"Baton Rouge Community College","id":5596},
{"institution":"ITT Technical Institute-Lathrop","id":5597},
{"institution":"International Yacht Restoration School","id":5598},
{"institution":"Linfield College-School of Nursing","id":5599},
{"institution":"Linfield College-Adult Degree Program","id":5600},
{"institution":"DeVry University's Keller Graduate School of Management-Virginia","id":5601},
{"institution":"DeVry University's Keller Graduate School of Management-Wisconsin","id":5602},
{"institution":"Academy of Massage Therapy","id":5603},
{"institution":"Star Career Academy-Syosset","id":5604},
{"institution":"Midwest Technical Institute-Springfield","id":5605},
{"institution":"Advanced Barber College and Hair Design","id":5606},
{"institution":"The College of Health Care Professions-Austin","id":5607},
{"institution":"The English Center","id":5608},
{"institution":"ICDC College","id":5609},
{"institution":"Monteclaro Escuela de Hoteleria y Artes Culinarias","id":5610},
{"institution":"New Community Workforce Development Center","id":5611},
{"institution":"Dallas Nursing Institute","id":5612},
{"institution":"Professional Golfers Career College","id":5613},
{"institution":"Richmond School of Health and Technology","id":5614},
{"institution":"Milan Institute-San Antonio Ingram","id":5615},
{"institution":"Westwood College-Anaheim","id":5616},
{"institution":"Rudae's School of Beauty Culture-Ft Wayne","id":5617},
{"institution":"InterCoast Colleges-Burbank","id":5618},
{"institution":"Carrington College California-Antioch","id":5619},
{"institution":"Carrington College California-San Jose","id":5620},
{"institution":"Lincoln Technical Institute-Cromwell","id":5621},
{"institution":"Stevens-Henager College-Murray","id":5622},
{"institution":"Brookline College-Tucson","id":5623},
{"institution":"Everest Institute-Jonesboro","id":5624},
{"institution":"McCann School of Business & Technology","id":5625},
{"institution":"Carrington College California-Pleasant Hill","id":5626},
{"institution":"Ridley-Lowell Business & Technical Institute-Poughkeepsie","id":5627},
{"institution":"Florida College of Natural Health-Maitland","id":5628},
{"institution":"Florida College of Natural Health-Bradenton","id":5629},
{"institution":"Modern Hairstyling Institute-Carolina","id":5630},
{"institution":"University of Phoenix-Southern Colorado Campus","id":5631},
{"institution":"University of Phoenix-West Michigan Campus","id":5632},
{"institution":"Stratford University","id":5633},
{"institution":"Universal Technical Institute of California Inc","id":5634},
{"institution":"American InterContinental University-Atlanta","id":5635},
{"institution":"American InterContinental University-South Florida","id":5636},
{"institution":"University of Phoenix-Philadelphia Campus","id":5637},
{"institution":"University of Phoenix-Pittsburgh Campus","id":5638},
{"institution":"Everest Institute-Decatur","id":5639},
{"institution":"Everest College-Chesapeake","id":5640},
{"institution":"Everest Institute-Greenspoint","id":5641},
{"institution":"Rancho Santiago Community College District Office","id":5642},
{"institution":"The Academy of Hair Design Six","id":5643},
{"institution":"Eastern West Virginia Community and Technical College","id":5644},
{"institution":"Aviation Institute of Maintenance-Indianapolis","id":5645},
{"institution":"Branford Hall Career Institute-Springfield Campus","id":5646},
{"institution":"Remington College-Little Rock Campus","id":5647},
{"institution":"Newbridge College-San Diego East","id":5648},
{"institution":"Everest University-Jacksonville","id":5649},
{"institution":"IVAEM College","id":5650},
{"institution":"Midwest College of Oriental Medicine-Chicago","id":5651},
{"institution":"Lincoln Technical Institute-Hamden","id":5652},
{"institution":"Argosy University-Seattle","id":5653},
{"institution":"Carrington College-Spokane","id":5654},
{"institution":"Texas School of Business-Friendswood","id":5655},
{"institution":"ITT Technical Institute-Wilmington","id":5656},
{"institution":"Pierce College at Puyallup","id":5657},
{"institution":"Texas Tech University System Administration","id":5658},
{"institution":"DeVry University-Florida","id":5659},
{"institution":"Cascadia Community College","id":5660},
{"institution":"University of Phoenix-St Louis Campus","id":5661},
{"institution":"Johnson & Wales University-Denver","id":5662},
{"institution":"University of Phoenix-Cleveland Campus","id":5663},
{"institution":"Du Bois Business College-Huntingdon","id":5664},
{"institution":"Du Bois Business College-Oil City","id":5665},
{"institution":"DeVry University-Maryland","id":5666},
{"institution":"CBD College","id":5667},
{"institution":"East West College of Natural Medicine","id":5668},
{"institution":"Infinity Career College","id":5669},
{"institution":"Lincoln Technical Institute-Fern Park","id":5670},
{"institution":"Atlantic Institute of Oriental Medicine","id":5671},
{"institution":"ATS Institute of Technology","id":5672},
{"institution":"Bennett Career Institute","id":5673},
{"institution":"Blue Cliff College-Shreveport","id":5674},
{"institution":"Blue Cliff College-Lafayette","id":5675},
{"institution":"Quest College","id":5676},
{"institution":"Central Florida Institute","id":5677},
{"institution":"Community Care College","id":5678},
{"institution":"Marinello Schools of Beauty-Topeka","id":5679},
{"institution":"Crossroads Bible College","id":5680},
{"institution":"Erie 1 BOCES","id":5681},
{"institution":"Fayette Beauty Academy","id":5682},
{"institution":"HDS Truck Driving Institute","id":5683},
{"institution":"International Training Careers","id":5684},
{"institution":"King's University","id":5685},
{"institution":"Lacy Cosmetology School-Aiken","id":5686},
{"institution":"Fortis College-Baton Rouge","id":5687},
{"institution":"My Le's Beauty College","id":5688},
{"institution":"New Concept Massage and Beauty School","id":5689},
{"institution":"New York College of Traditional Chinese Medicine","id":5690},
{"institution":"Fortis College-Orange Park","id":5691},
{"institution":"Northeastern Seminary","id":5692},
{"institution":"Bryan College-Gold River","id":5693},
{"institution":"Omega Institute of Cosmetology","id":5694},
{"institution":"Pacific Islands University","id":5695},
{"institution":"PCI College","id":5696},
{"institution":"Rosedale Bible College","id":5697},
{"institution":"American Career College at St Francis","id":5698},
{"institution":"Seattle Institute of Oriental Medicine","id":5699},
{"institution":"Shawnee Beauty College","id":5700},
{"institution":"Acupuncture and Massage College","id":5701},
{"institution":"Southeastern School of Cosmetology","id":5702},
{"institution":"Universal Therapeutic Massage Institute","id":5703},
{"institution":"Upper Valley Educators Institute","id":5704},
{"institution":"Keck Graduate Institute","id":5705},
{"institution":"Aviation Institute of Maintenance-Atlanta","id":5706},
{"institution":"National Beauty College","id":5707},
{"institution":"Star Career Academy-Egg Harbor","id":5708},
{"institution":"Empire Beauty School-Kennesaw","id":5709},
{"institution":"Anthem Career College-Nashville","id":5710},
{"institution":"Anthem College-Irving","id":5711},
{"institution":"Antioch University-System Administration","id":5712},
{"institution":"Westwood College-River Oaks","id":5713},
{"institution":"ITT Technical Institute-Green Bay","id":5714},
{"institution":"Career Training Academy-Pittsburgh","id":5715},
{"institution":"Huntingdon County Career and Technology Center","id":5716},
{"institution":"Missouri College of Cosmetology North","id":5717},
{"institution":"New York Automotive and Diesel Institute","id":5718},
{"institution":"Remington College-Baton Rouge Campus","id":5719},
{"institution":"Everest College-Ontario Metro","id":5720},
{"institution":"Kaplan College-Beaumont","id":5721},
{"institution":"Kaplan College-Laredo","id":5722},
{"institution":"The Art Institute of Washington","id":5723},
{"institution":"Everest Institute-Dearborn","id":5724},
{"institution":"Centura College-Richmond Westend","id":5725},
{"institution":"New Saint Andrews College","id":5726},
{"institution":"Arkansas State University-Newport","id":5727},
{"institution":"Marlboro College-Graduate School","id":5728},
{"institution":"University of Phoenix-Boston Campus","id":5729},
{"institution":"University of Phoenix-Dallas Campus","id":5730},
{"institution":"University of Phoenix-Houston Campus","id":5731},
{"institution":"University of Phoenix-Milwaukee Campus","id":5732},
{"institution":"University of Phoenix-Idaho Campus","id":5733},
{"institution":"Westwood College-Inland Empire","id":5734},
{"institution":"DeVry University-Virginia","id":5735},
{"institution":"DeVry University-Washington","id":5736},
{"institution":"DeVry University's Keller Graduate School of Management-Ohio","id":5737},
{"institution":"DeVry University's Keller Graduate School of Management-Texas","id":5738},
{"institution":"DeVry University-Colorado","id":5739},
{"institution":"Louisiana Delta Community College","id":5740},
{"institution":"ITT Technical Institute-Levittown","id":5741},
{"institution":"Atenas College","id":5742},
{"institution":"Southwest Skill Center-Campus of Estrella Mountain Community College","id":5743},
{"institution":"Onondaga Cortland Madison BOCES","id":5744},
{"institution":"National American University-Ellsworth AFB Extension","id":5745},
{"institution":"National American University-Albuquerque West","id":5746},
{"institution":"National American University-Brooklyn Center","id":5747},
{"institution":"Interactive Learning Systems-Pasadena","id":5748},
{"institution":"Pillar College","id":5749},
{"institution":"Miami Ad School-Minneapolis","id":5750},
{"institution":"Miami Ad School-San Francisco","id":5751},
{"institution":"Oregon State University-Cascades Campus","id":5752},
{"institution":"Milan Institute-Boise","id":5753},
{"institution":"Milan Institute-Amarillo","id":5754},
{"institution":"Vatterott College-Dividend","id":5755},
{"institution":"Vatterott College-Tulsa","id":5756},
{"institution":"Vatterott College-Wichita","id":5757},
{"institution":"Star Career Academy-Newark","id":5758},
{"institution":"Southern University Law Center","id":5759},
{"institution":"International Academy of Design and Technology-Orlando","id":5760},
{"institution":"Lincoln Technical Institute-Lowell","id":5761},
{"institution":"Everest College-Vancouver Massage Therapy","id":5762},
{"institution":"Texas Barber Colleges and Hairstyling Schools","id":5763},
{"institution":"Everest Institute-Hobby","id":5764},
{"institution":"Miller-Motte Technical College-North Charleston","id":5765},
{"institution":"American Career College-Anaheim","id":5766},
{"institution":"Saginaw Chippewa Tribal College","id":5767},
{"institution":"Wyo Tech-Blairsville","id":5768},
{"institution":"Richmont Graduate University","id":5769},
{"institution":"The Seattle School of Theology & Psychology","id":5770},
{"institution":"DeVry University's Keller Graduate School of Management-Washington","id":5771},
{"institution":"Northwest College-Hillsboro","id":5772},
{"institution":"San Diego State University-Imperial Valley Campus","id":5773},
{"institution":"American Broadcasting School-Arlington","id":5774},
{"institution":"Miami-Jacobs Career College-Sharonville","id":5775},
{"institution":"East Valley Institute of Technology","id":5776},
{"institution":"West Coast Ultrasound Institute","id":5777},
{"institution":"Irell & Manella Graduate School of Biological Sciences at City of Hope","id":5778},
{"institution":"Design's School of Cosmetology","id":5779},
{"institution":"Paul Mitchell the School-Santa Barbara","id":5780},
{"institution":"University of the Rockies","id":5781},
{"institution":"Eli Whitney Technical High School","id":5782},
{"institution":"Vinal Technical High School","id":5783},
{"institution":"Windham Technical High School","id":5784},
{"institution":"Harris School of Business-Wilmington Campus","id":5785},
{"institution":"Ultimate Medical Academy-Clearwater","id":5786},
{"institution":"Florida Barber Academy","id":5787},
{"institution":"Jacksonville Beauty Institute","id":5788},
{"institution":"M-DCPS The English Center","id":5789},
{"institution":"Florida Education Institute","id":5790},
{"institution":"Superior Career Institute","id":5791},
{"institution":"Institute of Technical Arts","id":5792},
{"institution":"National Graduate School of Quality Management","id":5793},
{"institution":"Texas County Technical College","id":5794},
{"institution":"Aviation Institute of Maintenance-Kansas City","id":5795},
{"institution":"Blue Cliff College-Gulfport","id":5796},
{"institution":"Apex School of Theology","id":5797},
{"institution":"Institute for Therapeutic Massage","id":5798},
{"institution":"Metropolitan Learning Institute","id":5799},
{"institution":"Orleans Niagara BOCES-Practical Nursing Program","id":5800},
{"institution":"Yeshiva Shaarei Torah of Rockland","id":5801},
{"institution":"Leon Studio One School of Hair Design","id":5802},
{"institution":"St Joseph's Medical Center School of Radiography","id":5803},
{"institution":"American Institute of Alternative Medicine","id":5804},
{"institution":"Brown Aveda Institute-Mentor","id":5805},
{"institution":"Institute of Technology Inc","id":5806},
{"institution":"Susquehanna County Career and Technology Center","id":5807},
{"institution":"Centro de Capacitacion y Asesoramiento Vetelba","id":5808},
{"institution":"Universidad Pentecostal Mizpa","id":5809},
{"institution":"Caribbean Forensic and Technical College","id":5810},
{"institution":"Concorde Career College-Grand Prairie","id":5811},
{"institution":"Kussad Institute of Court Reporting","id":5812},
{"institution":"Lamar Institute of Technology","id":5813},
{"institution":"MJ's Beauty Academy Inc","id":5814},
{"institution":"Aviation Institute of Maintenance-Dallas","id":5815},
{"institution":"Healing Mountain Massage School","id":5816},
{"institution":"Career Training Solutions","id":5817},
{"institution":"Rudy & Kelly Academy of Hair and Nails","id":5818},
{"institution":"South Branch Career and Technical Center","id":5819},
{"institution":"Nevada State College","id":5820},
{"institution":"Virginia College-Jackson","id":5821},
{"institution":"Virginia College-Austin","id":5822},
{"institution":"California State University-Channel Islands","id":5823},
{"institution":"ITT Technical Institute-Springfield","id":5824},
{"institution":"ITT Technical Institute-Chantilly","id":5825},
{"institution":"The Art Institute of California-Argosy University Orange County","id":5826},
{"institution":"Franklin W Olin College of Engineering","id":5827},
{"institution":"Lincoln Technical Institute-Northeast Philadelphia","id":5828},
{"institution":"Lincoln Technical Institute-Center City Philadelphia","id":5829},
{"institution":"Won Institute of Graduate Studies","id":5830},
{"institution":"Lincoln Technical Institute-Paramus","id":5831},
{"institution":"Pima Medical Institute-Colorado Springs","id":5832},
{"institution":"University of Phoenix-Kansas City Campus","id":5833},
{"institution":"University of Phoenix-Atlanta Campus","id":5834},
{"institution":"University of Phoenix-Chicago Campus","id":5835},
{"institution":"University of Phoenix-Nashville Campus","id":5836},
{"institution":"University of Phoenix-Northern Virginia Campus","id":5837},
{"institution":"David's Academy of Beauty","id":5838},
{"institution":"Birthwise Midwifery School","id":5839},
{"institution":"Argosy University-Dallas","id":5840},
{"institution":"Le Cordon Bleu College of Culinary Arts-Orlando","id":5841},
{"institution":"Paul Mitchell the School-Costa Mesa","id":5842},
{"institution":"Ave Maria School of Law","id":5843},
{"institution":"Everest College-Arlington","id":5844},
{"institution":"Tulsa Welding School-Jacksonville","id":5845},
{"institution":"ITT Technical Institute-Canton","id":5846},
{"institution":"ITT Technical Institute-Plymouth Meeting","id":5847},
{"institution":"Pennsylvania College of Health Sciences","id":5848},
{"institution":"DeVry University-North Carolina","id":5849},
{"institution":"West Virginia Junior College-Bridgeport","id":5850},
{"institution":"Antioch University-PhD Program in Leadership and Change","id":5851},
{"institution":"Vatterott College-Cleveland","id":5852},
{"institution":"St Louis College of Health Careers-Fenton","id":5853},
{"institution":"Interactive College of Technology-Gainesville","id":5854},
{"institution":"San Joaquin Valley College-Ontario","id":5855},
{"institution":"Anthem Institute-Cherry Hill","id":5856},
{"institution":"Westwood College-Ft Worth","id":5857},
{"institution":"Westwood College-Dallas","id":5858},
{"institution":"Alaska Christian College","id":5859},
{"institution":"Grantham University","id":5860},
{"institution":"Minnesota School of Business-Plymouth","id":5861},
{"institution":"Carrington College-Albuquerque","id":5862},
{"institution":"NASCAR Technical Institute","id":5863},
{"institution":"Everest Institute-Tigard","id":5864},
{"institution":"University of the Potomac-VA Campus","id":5865},
{"institution":"Anthem Institute-Las Vegas","id":5866},
{"institution":"Milan Institute-Palm Desert","id":5867},
{"institution":"Stenotype Institute of Jacksonville Inc-Orlando","id":5868},
{"institution":"National American University-Overland Park","id":5869},
{"institution":"Everest Institute-Austin","id":5870},
{"institution":"Everest College-Burr Ridge","id":5871},
{"institution":"Medvance Institute-West Palm","id":5872},
{"institution":"Tohono O'Odham Community College","id":5873},
{"institution":"Everest College-Dallas","id":5874},
{"institution":"Edward Via College of Osteopathic Medicine","id":5875},
{"institution":"The Salter School-Malden Campus","id":5876},
{"institution":"DeVry University-Pennsylvania","id":5877},
{"institution":"Pacific College of Oriental Medicine-Chicago","id":5878},
{"institution":"Ronny J's Barber Styling","id":5879},
{"institution":"Southwest Institute of Healing Arts","id":5880},
{"institution":"InterCoast Colleges-Riverside","id":5881},
{"institution":"Ottawa University-Jeffersonville","id":5882},
{"institution":"University of Antelope Valley","id":5883},
{"institution":"Birthingway College of Midwifery","id":5884},
{"institution":"Blue Water College of Cosmetology Inc","id":5885},
{"institution":"Pima Medical Institute-Albuquerque West","id":5886},
{"institution":"Brighton Center's Center for Employment Training","id":5887},
{"institution":"California Healing Arts College","id":5888},
{"institution":"Dallas Barber & Stylist College","id":5889},
{"institution":"NorthShore University HealthSystem School of Nurse Anesthesia","id":5890},
{"institution":"Faith Evangelical College & Seminary","id":5891},
{"institution":"Family of Faith College","id":5892},
{"institution":"Hood Theological Seminary","id":5893},
{"institution":"Colegio Educativo Tecnologico Industrial Inc","id":5894},
{"institution":"International Technological University","id":5895},
{"institution":"Irene's Myomassology Institute","id":5896},
{"institution":"Jay's Technical Institute","id":5897},
{"institution":"Mr John's School of Cosmetology Esthetics & Nails-Jacksonville","id":5898},
{"institution":"NTMA Training Centers of Southern California","id":5899},
{"institution":"P C Age-Jersey City","id":5900},
{"institution":"P C Age-Edison","id":5901},
{"institution":"American Institute-Margate","id":5902},
{"institution":"South Florida Institute of Technology","id":5903},
{"institution":"Southeastern Institute-Charleston","id":5904},
{"institution":"Southeastern College-Jacksonville","id":5905},
{"institution":"Toni & Guy Hairdressing Academy-Colorado Springs","id":5906},
{"institution":"Trend Barber College","id":5907},
{"institution":"Illinois CareerPath Institute","id":5908},
{"institution":"Valley Grande Institute for Academic Studies","id":5909},
{"institution":"West Coast University-Los Angeles","id":5910},
{"institution":"Williamson Christian College","id":5911},
{"institution":"International Barber & Style College","id":5912},
{"institution":"Illinois Eastern Community College-System Office","id":5913},
{"institution":"Triangle Tech Inc-Sunbury","id":5914},
{"institution":"DigiPen Institute of Technology","id":5915},
{"institution":"California College of Vocational Careers","id":5916},
{"institution":"Fortis Institute-Fort Lauderdale","id":5917},
{"institution":"University of Phoenix-Cincinnati Campus","id":5918},
{"institution":"University of Phoenix-Wichita Campus","id":5919},
{"institution":"University of Phoenix-Charlotte Campus","id":5920},
{"institution":"Connecticut Center for Massage Therapy-Groton","id":5921},
{"institution":"Pierpont Community and Technical College","id":5922},
{"institution":"ITT Technical Institute-Duluth","id":5923},
{"institution":"ITT Technical Institute-Hilliard","id":5924},
{"institution":"University of Phoenix-Indianapolis Campus","id":5925},
{"institution":"Career Institute of Health and Technology","id":5926},
{"institution":"Dewey University-Hato Rey","id":5927},
{"institution":"InterCoast Colleges-West Covina","id":5928},
{"institution":"American College of Healthcare Sciences","id":5929},
{"institution":"Texas School of Business-East","id":5930},
{"institution":"Le Cordon Bleu College of Culinary Arts-Atlanta","id":5931},
{"institution":"Colorado Media School","id":5932},
{"institution":"Paul Mitchell the School-Rhode Island","id":5933},
{"institution":"Miller-Motte Technical College-Chattanooga","id":5934},
{"institution":"Milan Institute-Sparks","id":5935},
{"institution":"Empire Beauty School-Framingham","id":5936},
{"institution":"Westwood College-Chicago Loop","id":5937},
{"institution":"Interactive Learning Systems-North Houston","id":5938},
{"institution":"Bexley Hall Episcopal Seminary","id":5939},
{"institution":"University of North Texas System","id":5940},
{"institution":"Anthem College-Fenton","id":5941},
{"institution":"Everest Institute-Norcross","id":5942},
{"institution":"Strayer University-Tennessee","id":5943},
{"institution":"Strayer University-Pennsylvania","id":5944},
{"institution":"Kaplan College-Brownsville","id":5945},
{"institution":"Kaplan College-Corpus Christi","id":5946},
{"institution":"School of Health","id":5947},
{"institution":"Everest College-Arlington","id":5948},
{"institution":"University of Phoenix-Columbus Georgia Campus","id":5949},
{"institution":"University of Phoenix-Memphis Campus","id":5950},
{"institution":"University of Phoenix-Columbus Ohio Campus","id":5951},
{"institution":"University of Phoenix-Little Rock Campus","id":5952},
{"institution":"Toni & Guy Hairdressing Academy-Boise","id":5953},
{"institution":"Charlie's Guard-Detective Bureau and Academy Inc","id":5954},
{"institution":"PCI Academy-Plymouth","id":5955},
{"institution":"DeVry University's Keller Graduate School of Management-Pennsylvania","id":5956},
{"institution":"DeVry University-Nevada","id":5957},
{"institution":"Everest College-Tacoma","id":5958},
{"institution":"DeVry University-Oregon","id":5959},
{"institution":"National University College-Rio Grande","id":5960},
{"institution":"Creative Images Institute of Cosmetology-South Dayton","id":5961},
{"institution":"Westwood College-Houston South","id":5962},
{"institution":"Fortis Institute-Jacksonville","id":5963},
{"institution":"Brookline College-Albuquerque","id":5964},
{"institution":"Byzantine Catholic Seminary of Saints Cyril and Methodius","id":5965},
{"institution":"Remington College-Cleveland West Campus","id":5966},
{"institution":"Northcentral University","id":5967},
{"institution":"Colorado Technical University-Online","id":5968},
{"institution":"Baker College of Allen Park","id":5969},
{"institution":"Bayamon Community College","id":5970},
{"institution":"Harris School of Business-Dover Campus","id":5971},
{"institution":"Folsom Lake College","id":5972},
{"institution":"LeGrand Institute of Cosmetology Inc","id":5973},
{"institution":"Everest Institute-Detroit","id":5974},
{"institution":"Daymar Institute-Murfreesboro","id":5975},
{"institution":"Cambridge Technical Institute","id":5976},
{"institution":"Empire Beauty School-Midlothian","id":5977},
{"institution":"Empire Beauty School-Owings Mills","id":5978},
{"institution":"Empire Beauty School-West Mifflin","id":5979},
{"institution":"Bold Beauty Academy","id":5980},
{"institution":"ATI College-Norwalk","id":5981},
{"institution":"Advance Science Institute","id":5982},
{"institution":"Advanced College","id":5983},
{"institution":"Advance Tech College","id":5984},
{"institution":"Advanced Training Associates","id":5985},
{"institution":"American Advanced Technicians Institute","id":5986},
{"institution":"Anamarc College-El Paso Central","id":5987},
{"institution":"Baptist University of the Americas","id":5988},
{"institution":"The Beauty Institute","id":5989},
{"institution":"Beis Medrash Heichal Dovid","id":5990},
{"institution":"Bellefonte Academy of Beauty","id":5991},
{"institution":"Blue Cliff Career College","id":5992},
{"institution":"Career Beauty College","id":5993},
{"institution":"Paul Mitchell the School-Gastonia","id":5994},
{"institution":"CES College","id":5995},
{"institution":"Computer Tutor Business and Technical Institute","id":5996},
{"institution":"Court Reporting Institute of Louisiana","id":5997},
{"institution":"Auguste Escoffier School of Culinary Arts-Austin","id":5998},
{"institution":"Culinary Institute Inc","id":5999},
{"institution":"Dade Medical College-Miami","id":6000},
{"institution":"Escuela Hotelera de San Juan","id":6001},
{"institution":"Expertise Cosmetology Institute","id":6002},
{"institution":"Paul Mitchell the School-Monroe","id":6003},
{"institution":"Healing Arts Institute","id":6004},
{"institution":"Health Works Institute","id":6005},
{"institution":"In Session Arts of Cosmetology Beauty School","id":6006},
{"institution":"Moore Career College","id":6007},
{"institution":"Industrial Technical College","id":6008},
{"institution":"Institute of Beauty Occupation and Technology Course","id":6009},
{"institution":"Institute of Clinical Acupuncture & Oriental Med","id":6010},
{"institution":"Institute of Hair Design","id":6011},
{"institution":"MedTech Institute-Atlanta Campus","id":6012},
{"institution":"Jones International University","id":6013},
{"institution":"Leston College","id":6014},
{"institution":"Lynndale Fundamentals of Beauty School","id":6015},
{"institution":"Medical Professional Institute","id":6016},
{"institution":"New Life Theological Seminary","id":6017},
{"institution":"Nightingale College","id":6018},
{"institution":"Omnitech Institute","id":6019},
{"institution":"PITC Institute","id":6020},
{"institution":"Skinworks School of Advanced Skincare","id":6021},
{"institution":"Southeastern Institute-Columbia","id":6022},
{"institution":"Technical Learning Centers Inc","id":6023},
{"institution":"Total Look School of Cosmetology & Massage Therapy","id":6024},
{"institution":"The Art Institute of Tucson","id":6025},
{"institution":"Turning Point Beauty College","id":6026},
{"institution":"Unitech Training Academy-Lafayette","id":6027},
{"institution":"Mountwest Community and Technical College","id":6028},
{"institution":"Centura College-Columbia","id":6029},
{"institution":"Flagler College-Tallahassee","id":6030},
{"institution":"Kanawha Valley Community and Technical College","id":6031},
{"institution":"American InterContinental University-Online","id":6032},
{"institution":"Westwood College-Atlanta Midtown","id":6033},
{"institution":"ITT Technical Institute-Eden Prairie","id":6034},
{"institution":"Virginia College-Mobile","id":6035},
{"institution":"Le Cordon Bleu College of Culinary Arts-Las Vegas","id":6036},
{"institution":"International Academy of Design and Technology-Troy","id":6037},
{"institution":"American InterContinental University-Houston","id":6038},
{"institution":"Sanford-Brown College-Houston North Loop","id":6039},
{"institution":"University of California-Merced","id":6040},
{"institution":"Everest College-Fort Worth","id":6041},
{"institution":"Remington College-North Houston Campus","id":6042},
{"institution":"Paul Mitchell the School-Orlando","id":6043},
{"institution":"Regency Beauty Institute-Maplewood","id":6044},
{"institution":"Pima Medical Institute-Las Vegas","id":6045},
{"institution":"Remington College-Nashville Campus","id":6046},
{"institution":"Platt College-North OKC","id":6047},
{"institution":"Central Methodist University-College of Graduate and Extended Studies","id":6048},
{"institution":"Westwood College-Northlake","id":6049},
{"institution":"University of Phoenix-Jersey City Campus","id":6050},
{"institution":"University of Phoenix-Minneapolis St Paul Campus","id":6051},
{"institution":"Kaplan College-Fort Worth","id":6052},
{"institution":"Kaplan College-Midland","id":6053},
{"institution":"Kaplan College-Lubbock","id":6054},
{"institution":"Carrington College California-Emeryville","id":6055},
{"institution":"North-West College-Riverside","id":6056},
{"institution":"Everest Institute-Chelsea","id":6057},
{"institution":"Everest Institute-Eagan","id":6058},
{"institution":"University of Phoenix-Louisville Campus","id":6059},
{"institution":"DeVry University-Minnesota","id":6060},
{"institution":"Kaplan University-Council Bluffs Campus","id":6061},
{"institution":"Everest University-Orange Park","id":6062},
{"institution":"Wyotech-West Sacramento","id":6063},
{"institution":"Everest Institute-Bissonnet","id":6064},
{"institution":"Everest College-McLean","id":6065},
{"institution":"Colegio Tecnico de Electricidad Galloza","id":6066},
{"institution":"Empire Beauty School-St Paul","id":6067},
{"institution":"Arthur's Beauty School Inc-Pine Bluff","id":6068},
{"institution":"Arthur's Beauty School Inc-Conway","id":6069},
{"institution":"Vatterott College-St Charles","id":6070},
{"institution":"Anthem Institute-North Brunswick","id":6071},
{"institution":"Anthem Career College-Memphis","id":6072},
{"institution":"Anthem College-Kansas City","id":6073},
{"institution":"Spa Tech Institute-Ipswich","id":6074},
{"institution":"Spa Tech Institute-Westboro","id":6075},
{"institution":"Spa Tech Institute-Plymouth","id":6076},
{"institution":"Milan Institute of Cosmetology-San Antonio Walzem","id":6077},
{"institution":"Bridgemont Community and Technical College","id":6078},
{"institution":"Wards Corner Beauty Academy-Virginia Beach","id":6079},
{"institution":"Neumont University","id":6080},
{"institution":"Johnson & Wales University-Charlotte","id":6081},
{"institution":"University of Phoenix-Central Valley Campus","id":6082},
{"institution":"L'Ecole Culinaire-St Louis","id":6083},
{"institution":"Roseman University of Health Sciences","id":6084},
{"institution":"Fortis Institute-Port Saint Lucie","id":6085},
{"institution":"Fortis Institute-Miami","id":6086},
{"institution":"Aviation Institute of Maintenance-Manassas","id":6087},
{"institution":"Everest College-North Aurora","id":6088},
{"institution":"Cosmetology School of Arts and Sciences","id":6089},
{"institution":"Universal Technical Institute of Pennsylvania Inc","id":6090},
{"institution":"Heritage College-Kansas City","id":6091},
{"institution":"Kenneth Shuler School of Cosmetology-Spartanburg","id":6092},
{"institution":"Kenneth Shuler School of Cosmetology-Columbia","id":6093},
{"institution":"Institute for the Psychological Sciences","id":6094},
{"institution":"Pivot Point Academy-Chicago","id":6095},
{"institution":"Lincoln Technical Institute-Shelton","id":6096},
{"institution":"Minnesota School of Business-Waite Park","id":6097},
{"institution":"Minnesota School of Business-Shakopee","id":6098},
{"institution":"Northwest Career College","id":6099},
{"institution":"Soma Institute-The National School of Clinical Massage Therapy","id":6100},
{"institution":"Xtreme Career Institute","id":6101},
{"institution":"Marinello Schools of Beauty-Niantic","id":6102},
{"institution":"American Academy of Acupuncture and Oriental Medicine","id":6103},
{"institution":"Arizona School of Acupuncture and Oriental Medicine","id":6104},
{"institution":"Ave Maria University","id":6105},
{"institution":"Blue Sky School of Professional Massage and Therapeutic Bodywork","id":6106},
{"institution":"Buchanan Beauty College","id":6107},
{"institution":"CALC Institute of Technology","id":6108},
{"institution":"Cambridge Junior College-Yuba City","id":6109},
{"institution":"Cambridge Institute of Allied Health & Technology","id":6110},
{"institution":"Career Care Institute","id":6111},
{"institution":"ATA Career Education","id":6112},
{"institution":"Career Quest Learning Centers-Lansing","id":6113},
{"institution":"Central State Massage Academy","id":6114},
{"institution":"Community Christian College","id":6115},
{"institution":"Crossett School of Cosmetology","id":6116},
{"institution":"Culpeper Cosmetology Training Center","id":6117},
{"institution":"Day Spa Career College","id":6118},
{"institution":"Eastern School of Acupuncture and Traditional Medicine","id":6119},
{"institution":"Ecclesia College","id":6120},
{"institution":"Employment Solutions-College for Technical Education","id":6121},
{"institution":"European Massage Therapy School-Skokie","id":6122},
{"institution":"Evergreen Beauty and Barber College-Everett","id":6123},
{"institution":"Paul Mitchell the School-Great Lakes","id":6124},
{"institution":"Healthcare Training Institute","id":6125},
{"institution":"John D Rockefeller IV Career Center","id":6126},
{"institution":"Ladera Career Paths Training Centers","id":6127},
{"institution":"Los Angeles Music Academy","id":6128},
{"institution":"Maple Springs Baptist Bible College and Seminary","id":6129},
{"institution":"MCI Institute of Technology","id":6130},
{"institution":"Oregon Career & Technology Center","id":6131},
{"institution":"Performance Training Institute","id":6132},
{"institution":"Pacific Coast Trade School","id":6133},
{"institution":"Centura Institute","id":6134},
{"institution":"Precision Manufacturing Institute","id":6135},
{"institution":"Professional Massage Training Center","id":6136},
{"institution":"Regency School of Hair Design","id":6137},
{"institution":"Renaissance College-Massage Program","id":6138},
{"institution":"Rosslyn Training Academy of Cosmetology","id":6139},
{"institution":"SAE Institute of Technology-Nashville","id":6140},
{"institution":"eClips School of Cosmetology and Barbering","id":6141},
{"institution":"Shear Academy","id":6142},
{"institution":"Southern Technical College","id":6143},
{"institution":"Stanbridge College","id":6144},
{"institution":"Styletrends Barber and Hairstyling Academy","id":6145},
{"institution":"Universal Career School","id":6146},
{"institution":"Universal College of Healing Arts","id":6147},
{"institution":"Uta Mesivta of Kiryas Joel","id":6148},
{"institution":"W L Bonner College","id":6149},
{"institution":"Harrisburg University of Science and Technology","id":6150},
{"institution":"The Art Institute of Ohio-Cincinnati","id":6151},
{"institution":"Stevens-Henager College-Logan","id":6152},
{"institution":"University of Phoenix-Cheyenne Campus","id":6153},
{"institution":"University of Phoenix-Springfield  Campus","id":6154},
{"institution":"University of Phoenix-Des Moines Campus","id":6155},
{"institution":"University of Phoenix-San Antonio Campus","id":6156},
{"institution":"University of Phoenix-Austin Campus","id":6157},
{"institution":"University of Phoenix-Richmond-Virginia Beach Campus","id":6158},
{"institution":"University of Phoenix-Raleigh Campus","id":6159},
{"institution":"Blue Ridge Community and Technical College","id":6160},
{"institution":"Lincoln Technical Institute-Suffield","id":6161},
{"institution":"International Academy of Design and Technology-Henderson","id":6162},
{"institution":"International Academy of Design and Technology-Seattle","id":6163},
{"institution":"International Academy of Design and Technology-Nashville","id":6164},
{"institution":"Le Cordon Bleu College of Culinary Arts-Miami","id":6165},
{"institution":"Le Cordon Bleu College of Culinary Arts-Minneapolis","id":6166},
{"institution":"Anthem College-Portland","id":6167},
{"institution":"Career Technical College-Shreveport","id":6168},
{"institution":"ITT Technical Institute-Kansas City","id":6169},
{"institution":"ITT Technical Institute-Kennesaw","id":6170},
{"institution":"ITT Technical Institute-Owings Mills","id":6171},
{"institution":"ITT Technical Institute-Warrensville Heights","id":6172},
{"institution":"Union Graduate College","id":6173},
{"institution":"Colorado State University-System Office","id":6174},
{"institution":"Chambersburg Beauty School","id":6175},
{"institution":"Daytona College","id":6176},
{"institution":"Delta Technical College","id":6177},
{"institution":"United States University","id":6178},
{"institution":"Westwood College-Arlington Ballston","id":6179},
{"institution":"Kaplan College-Bakersfield","id":6180},
{"institution":"Kaplan College-Fresno","id":6181},
{"institution":"Empire Beauty School-Avondale","id":6182},
{"institution":"Empire Beauty School-North Tucson","id":6183},
{"institution":"Empire Beauty School-Littleton","id":6184},
{"institution":"Empire Beauty School-Aurora","id":6185},
{"institution":"Everest Institute-Gahanna","id":6186},
{"institution":"Everest College-Merrionette Park","id":6187},
{"institution":"Everest College-Earth City","id":6188},
{"institution":"Everest College-Mesa","id":6189},
{"institution":"Everest Institute-Silver Spring","id":6190},
{"institution":"Alvareitas College of Cosmetology-Belleville","id":6191},
{"institution":"AmeriTech College-Draper","id":6192},
{"institution":"Argosy University-Los Angeles","id":6193},
{"institution":"Brown Mackie College-Miami","id":6194},
{"institution":"Regency Beauty Institute-Darien","id":6195},
{"institution":"Regency Beauty Institute-Aurora","id":6196},
{"institution":"Regency Beauty Institute-Madison","id":6197},
{"institution":"Regency Beauty Institute-Ridgedale","id":6198},
{"institution":"San Joaquin Valley College-Modesto","id":6199},
{"institution":"Fortis College-Largo","id":6200},
{"institution":"Platt College-Moore","id":6201},
{"institution":"Platt College-Dallas","id":6202},
{"institution":"Universal Technical Institute of Massachusetts Inc","id":6203},
{"institution":"Eagle Gate College-Layton","id":6204},
{"institution":"Carsten Institute of Cosmetology","id":6205},
{"institution":"Academy of Hair Design-Jasper","id":6206},
{"institution":"Expression College for Digital Arts","id":6207},
{"institution":"Unitech Training Academy-West Monroe","id":6208},
{"institution":"Daymar College-Bellevue","id":6209},
{"institution":"DeVry University-Oklahoma","id":6210},
{"institution":"Salter School-Fall River","id":6211},
{"institution":"Seacoast Career School-Manchester Campus","id":6212},
{"institution":"Instituto de Educacion Tecnica Ocupacional La Reine-Aguadilla","id":6213},
{"institution":"New River Community and Technical College","id":6214},
{"institution":"The College of Health Care Professions-Southwest Houston","id":6215},
{"institution":"Eagle Gate College-Salt Lake City","id":6216},
{"institution":"Kaplan College-Palm Springs","id":6217},
{"institution":"University of Phoenix-Savannah Campus","id":6218},
{"institution":"University of Phoenix-Northern Nevada Campus","id":6219},
{"institution":"Minnesota School of Business-Rochester","id":6220},
{"institution":"Georgia Gwinnett College","id":6221},
{"institution":"Phoenix Institute of Herbal Medicine & Acupuncture","id":6222},
{"institution":"Tucson College of Beauty","id":6223},
{"institution":"California Career College","id":6224},
{"institution":"Palladium Technical Academy","id":6225},
{"institution":"Palace Beauty College","id":6226},
{"institution":"National Polytechnic College","id":6227},
{"institution":"American Career College-Ontario","id":6228},
{"institution":"Asher College","id":6229},
{"institution":"American Institute of Massage Therapy","id":6230},
{"institution":"University of East-West Medicine","id":6231},
{"institution":"Blake Austin College","id":6232},
{"institution":"Mojave Barber College","id":6233},
{"institution":"Aviator College of Aeronautical Science and Technology","id":6234},
{"institution":"Total International Career Institute","id":6235},
{"institution":"Trendsetters School of Beauty & Barbering","id":6236},
{"institution":"CDA Technical Institute","id":6237},
{"institution":"American Institute of Beauty","id":6238},
{"institution":"North Florida Cosmetology Institute Inc","id":6239},
{"institution":"Florida Academy of Health & Beauty","id":6240},
{"institution":"Augusta School of Massage","id":6241},
{"institution":"ATA College","id":6242},
{"institution":"SUM Bible College and Theological Seminary","id":6243},
{"institution":"Compass Career College","id":6244},
{"institution":"Hair Expressions Academy","id":6245},
{"institution":"Nuvo College of Cosmetology","id":6246},
{"institution":"WellSpring School of Allied Health-Kansas City","id":6247},
{"institution":"Hair Academy 110","id":6248},
{"institution":"Healing Touch Career College","id":6249},
{"institution":"The Salon Professional Academy-Fargo","id":6250},
{"institution":"Massage Therapy Trainining Institute","id":6251},
{"institution":"Institute of Professional Careers","id":6252},
{"institution":"Elite Academy of Beauty Arts","id":6253},
{"institution":"Aveda Institute-New York","id":6254},
{"institution":"Brookline College-Oklahoma City","id":6255},
{"institution":"Northwest Regional Technology Institute","id":6256},
{"institution":"National Massage Therapy Institute","id":6257},
{"institution":"MyrAngel Beauty Institute","id":6258},
{"institution":"Institute of Hair Design","id":6259},
{"institution":"Tennessee Career Institute","id":6260},
{"institution":"Texas Health School","id":6261},
{"institution":"Mai-trix Beauty College","id":6262},
{"institution":"North West Beauty School","id":6263},
{"institution":"Maximum Style Tec School of Cosmetology","id":6264},
{"institution":"Careers Unlimited","id":6265},
{"institution":"Mountainland Applied Technology College","id":6266},
{"institution":"Avi Career Training","id":6267},
{"institution":"Yakima Beauty School","id":6268},
{"institution":"Mountain State School of Massage","id":6269},
{"institution":"Doane College-Lincoln Grand Island and Master","id":6270},
{"institution":"Shorter University-College of Adult & Professional Programs","id":6271},
{"institution":"Arkansas State University-System Office","id":6272},
{"institution":"The Art Institute of Indianapolis","id":6273},
{"institution":"Eastern International College-Belleville","id":6274},
{"institution":"San Joaquin Valley College-Rancho Cordova","id":6275},
{"institution":"San Joaquin Valley College-Central Administrative Office","id":6276},
{"institution":"Illinois School of Health Careers-O'Hare Campus","id":6277},
{"institution":"MedTech College","id":6278},
{"institution":"Broken Arrow Beauty College-Tulsa","id":6279},
{"institution":"Universal Technical Institute of Northern California Inc","id":6280},
{"institution":"ITT Technical Institute-Charlotte South","id":6281},
{"institution":"ITT Technical Institute-Clovis","id":6282},
{"institution":"ITT Technical Institute-Dunmore","id":6283},
{"institution":"ITT Technical Institute-Swartz Creek","id":6284},
{"institution":"ITT Technical Institute-Lexington","id":6285},
{"institution":"ITT Technical Institute-Maumee","id":6286},
{"institution":"ITT Technical Institute-Oklahoma City","id":6287},
{"institution":"ITT Technical Institute-Tulsa","id":6288},
{"institution":"University of Phoenix-Omaha Campus","id":6289},
{"institution":"University of Phoenix-Northwest Arkansas Campus","id":6290},
{"institution":"University of Phoenix-Madison Campus","id":6291},
{"institution":"University of Phoenix-Columbia Campus","id":6292},
{"institution":"The Art Institute of California-Argosy University Inland Empire","id":6293},
{"institution":"National American University-Zona Rosa","id":6294},
{"institution":"West Hills College-Lemoore","id":6295},
{"institution":"Stautzenberger College-Brecksville","id":6296},
{"institution":"Stone Academy-East Hartford","id":6297},
{"institution":"Westwood College-Annandale","id":6298},
{"institution":"West Hills Community College District","id":6299},
{"institution":"Unitech Training Academy-Houma","id":6300},
{"institution":"Kaplan College-Milwaukee","id":6301},
{"institution":"Miller-Motte Technical College-Madison","id":6302},
{"institution":"Rasmussen College-Illinois","id":6303},
{"institution":"International Institute for Restorative Practices","id":6304},
{"institution":"Educational Technical College-Recinto de Coamo","id":6305},
{"institution":"Educational Technical College-Recinto de san Sebastian","id":6306},
{"institution":"Argosy University-Denver","id":6307},
{"institution":"College America-Colorado Springs","id":6308},
{"institution":"College America-Fort Collins","id":6309},
{"institution":"Automotive Training Center-Warminster","id":6310},
{"institution":"Sanford-Brown College-West Allis","id":6311},
{"institution":"Empire Beauty School-Milwaukee","id":6312},
{"institution":"The Robert B Miller College","id":6313},
{"institution":"University of Phoenix-Fairfield County Campus","id":6314},
{"institution":"University of Phoenix-Harrisburg Campus","id":6315},
{"institution":"University of South Florida-St Petersburg","id":6316},
{"institution":"Triangle Tech Inc-Bethlehem","id":6317},
{"institution":"DeVry University-Utah","id":6318},
{"institution":"Arizona State University-Downtown Phoenix","id":6319},
{"institution":"Milan Institute-Clovis","id":6320},
{"institution":"Branford Hall Career Institute-Albany Campus","id":6321},
{"institution":"Harris School of Business-Linwood Campus","id":6322},
{"institution":"The Art Institute of Tennessee-Nashville","id":6323},
{"institution":"Strayer University-Florida","id":6324},
{"institution":"Pima Medical Institute-Renton","id":6325},
{"institution":"College of Business and Technology-Flagler","id":6326},
{"institution":"College of Business and Technology-Hialeah","id":6327},
{"institution":"Miller-Motte College-Cary","id":6328},
{"institution":"Miami-Jacobs Career College-Springboro","id":6329},
{"institution":"Dewey University-Juana D�az","id":6330},
{"institution":"Dewey University-Fajardo","id":6331},
{"institution":"Dewey University-Arroyo","id":6332},
{"institution":"Regency Beauty Institute-Independence","id":6333},
{"institution":"Regency Beauty Institute-St Peters","id":6334},
{"institution":"Regency Beauty Institute-Peoria","id":6335},
{"institution":"Regency Beauty Institute-Greenwood","id":6336},
{"institution":"Regency Beauty Institute-Mehlville","id":6337},
{"institution":"Regency Beauty Institute-Duluth","id":6338},
{"institution":"Regency Beauty Institute-Fairview Heights","id":6339},
{"institution":"Regency Beauty Institute-Champaign","id":6340},
{"institution":"Regency Beauty Institute-Olathe","id":6341},
{"institution":"The College of Health Care Professions-San Antonio","id":6342},
{"institution":"Everest Institute-South Plainfield","id":6343},
{"institution":"Kaplan Career Institute-Detroit","id":6344},
{"institution":"Daymar College-Madisonville","id":6345},
{"institution":"Brittany Beauty School","id":6346},
{"institution":"Lincoln Technical Institute-Whitestone","id":6347},
{"institution":"American Public University System","id":6348},
{"institution":"Huntsville Bible College","id":6349},
{"institution":"Beaumont Adult School","id":6350},
{"institution":"Make-up Designory","id":6351},
{"institution":"Video Symphony EnterTraining Inc","id":6352},
{"institution":"Gnomon School of Visual Effects","id":6353},
{"institution":"Coachella Valley Beauty College","id":6354},
{"institution":"Career College of California","id":6355},
{"institution":"Valley College of Medical Careers","id":6356},
{"institution":"Academy of Natural Therapy Inc","id":6357},
{"institution":"Academy Di Capelli-School of Cosmetology","id":6358},
{"institution":"Delaware Learning Institute of Cosmetology","id":6359},
{"institution":"Dragon Rises College of Oriental Medicine","id":6360},
{"institution":"Palm Beach Academy of Health & Beauty","id":6361},
{"institution":"SABER College","id":6362},
{"institution":"Taylor College","id":6363},
{"institution":"Pensacola School of Massage Therapy & Health Careers","id":6364},
{"institution":"Hawaii College of Oriental Medicine","id":6365},
{"institution":"Ideal Beauty Academy","id":6366},
{"institution":"Success Schools","id":6367},
{"institution":"Louisiana Culinary Institute","id":6368},
{"institution":"Bais Medrash Toras Chesed","id":6369},
{"institution":"Center for Natural Wellness School of Massage Therapy","id":6370},
{"institution":"Ace Computer Training Center","id":6371},
{"institution":"New Age Training","id":6372},
{"institution":"Dayton School of Medical Massage","id":6373},
{"institution":"Northcoast Medical Training Academy","id":6374},
{"institution":"American Institute of Medical Technology","id":6375},
{"institution":"Carib Technological Institute","id":6376},
{"institution":"Visible Music College","id":6377},
{"institution":"CCI Training Center-Arlington","id":6378},
{"institution":"Champion Beauty College","id":6379},
{"institution":"Professional Careers Institute","id":6380},
{"institution":"Southeast Texas Career Institute","id":6381},
{"institution":"Southwest Applied Technology Center","id":6382},
{"institution":"Academy of Cosmetology","id":6383},
{"institution":"Milwaukee Career College","id":6384},
{"institution":"University of the West","id":6385},
{"institution":"American College of Education","id":6386},
{"institution":"South University-Tampa","id":6387},
{"institution":"Milan Institute of Cosmetology-San Antonio Military","id":6388},
{"institution":"Appalachian College of Pharmacy","id":6389},
{"institution":"Averett University-Non-Traditional Programs","id":6390},
{"institution":"Paul Mitchell the School-Tampa","id":6391},
{"institution":"Paul Mitchell the School-San Diego","id":6392},
{"institution":"Paul Mitchell the School-Michigan","id":6393},
{"institution":"Paul Mitchell the School-Sherman Oaks","id":6394},
{"institution":"Paul Mitchell the School-Mclean","id":6395},
{"institution":"Miami-Jacobs Career College-Troy","id":6396},
{"institution":"ATI College-Santa Ana","id":6397},
{"institution":"Blue Cliff College-Houma","id":6398},
{"institution":"The Art Institute of Salt Lake City","id":6399},
{"institution":"Fortis College-Columbus","id":6400},
{"institution":"Fortis College-Cincinnati","id":6401},
{"institution":"Fortis Institute-Baltimore","id":6402},
{"institution":"The Art Institute of Charleston","id":6403},
{"institution":"The Art Institute of California-Argosy University Sacramento","id":6404},
{"institution":"Blue Cliff College-Fayetteville","id":6405},
{"institution":"Blue Cliff College-Alexandria","id":6406},
{"institution":"StenoTech Career Institute-Piscataway","id":6407},
{"institution":"Anthem College-Brookfield","id":6408},
{"institution":"Ultimate Medical Academy-Tampa","id":6409},
{"institution":"Beauty Schools of America-North Miami Beach","id":6410},
{"institution":"Virginia College-Biloxi","id":6411},
{"institution":"ITT Technical Institute-St Petersburg","id":6412},
{"institution":"ITT Technical Institute-Baton Rouge","id":6413},
{"institution":"ITT Technical Institute-Columbia","id":6414},
{"institution":"ITT Technical Institute-Wichita","id":6415},
{"institution":"ITT Technical Institute-Atlanta","id":6416},
{"institution":"ITT Technical Institute-Mobile","id":6417},
{"institution":"ITT Technical Institute-Chattanooga","id":6418},
{"institution":"ITT Technical Institute-South Bend","id":6419},
{"institution":"Virginia College-School of Business and Health-Chattanooga","id":6420},
{"institution":"Strayer University-Delaware","id":6421},
{"institution":"Brite Divinity School","id":6422},
{"institution":"Strayer University-Alabama","id":6423},
{"institution":"Brown Aveda Institute-Rocky River","id":6424},
{"institution":"Clary Sage College","id":6425},
{"institution":"Broadview University-Layton","id":6426},
{"institution":"International Academy of Design and Technology-Sacramento","id":6427},
{"institution":"University of Phoenix-Birmingham Campus","id":6428},
{"institution":"International Academy of Design and Technology-San Antonio","id":6429},
{"institution":"University of Phoenix-Augusta Campus","id":6430},
{"institution":"University of Phoenix-Washington DC Campus","id":6431},
{"institution":"University of Phoenix-Chattanooga Campus","id":6432},
{"institution":"DeVry University-Michigan","id":6433},
{"institution":"DeVry University-Tennessee","id":6434},
{"institution":"Argosy University-Inland Empire","id":6435},
{"institution":"Argosy University-Nashville","id":6436},
{"institution":"Argosy University-San Diego","id":6437},
{"institution":"Rasmussen College-Wisconsin","id":6438},
{"institution":"Empire Beauty School-Lisle","id":6439},
{"institution":"Empire Beauty School-Richmond","id":6440},
{"institution":"Empire Beauty School-North Hills","id":6441},
{"institution":"Empire Beauty School-Concord","id":6442},
{"institution":"Empire Beauty School-Arlington Heights","id":6443},
{"institution":"Lexington Healing Arts Academy","id":6444},
{"institution":"Empire Beauty School-Hooksett","id":6445},
{"institution":"The Institute of Beauty and Wellness","id":6446},
{"institution":"InterCoast Colleges-Carson","id":6447},
{"institution":"InterCoast Career Institute-South Portland","id":6448},
{"institution":"Carrington College California-Stockton","id":6449},
{"institution":"Carrington College California-Citrus Heights","id":6450},
{"institution":"Euphoria Institute of Beauty Arts & Sciences-Summerlin","id":6451},
{"institution":"Euphoria Institute of Beauty Arts & Sciences-Green Valley","id":6452},
{"institution":"Empire Beauty School-Portsmouth","id":6453},
{"institution":"LIU Riverhead","id":6454},
{"institution":"Newbridge College-Long Beach","id":6455},
{"institution":"Regency Beauty Institute-Lakewood","id":6456},
{"institution":"Regency Beauty Institute-Westminster","id":6457},
{"institution":"Regency Beauty Institute-Avon","id":6458},
{"institution":"Regency Beauty Institute-Joliet","id":6459},
{"institution":"Regency Beauty Institute-Rockford","id":6460},
{"institution":"Regency Beauty Institute-Greenfield","id":6461},
{"institution":"Regency Beauty Institute-Metro Center","id":6462},
{"institution":"Regency Beauty Institute-East Tucson","id":6463},
{"institution":"Regency Beauty Institute-Tri-County","id":6464},
{"institution":"Regency Beauty Institute-Eastgate","id":6465},
{"institution":"Regency Beauty Institute-Topeka","id":6466},
{"institution":"Columbia Southern University","id":6467},
{"institution":"Arizona Summit Law School","id":6468},
{"institution":"Arizona Culinary Institute","id":6469},
{"institution":"InfoTech Career College","id":6470},
{"institution":"Trident University International","id":6471},
{"institution":"Coastline Beauty College","id":6472},
{"institution":"Career College Consultants","id":6473},
{"institution":"San Diego College","id":6474},
{"institution":"Montessori Casa International","id":6475},
{"institution":"Branford Academy of Hair and Cosmetology","id":6476},
{"institution":"Academy of Massage and Bodywork","id":6477},
{"institution":"Digital Media Arts College","id":6478},
{"institution":"Academy for Five Element Acupuncture","id":6479},
{"institution":"Academy of Career Training","id":6480},
{"institution":"Management Resources Institute","id":6481},
{"institution":"Paul Mitchell the School-Miami","id":6482},
{"institution":"Wolford College","id":6483},
{"institution":"Aveda Institute-South Florida","id":6484},
{"institution":"Aveda Institute-Tallahassee","id":6485},
{"institution":"Shear Excellence Hair Academy","id":6486},
{"institution":"Carlson College of Massage Therapy","id":6487},
{"institution":"Bio-Chi Institute of Massage Therapy","id":6488},
{"institution":"SOLEX Medical Academy","id":6489},
{"institution":"MyComputerCareer.com-Indianapolis","id":6490},
{"institution":"Z Hair Academy","id":6491},
{"institution":"DiGrigoli School of Cosmetology","id":6492},
{"institution":"Millennium Training Institute","id":6493},
{"institution":"Gallery College of Beauty","id":6494},
{"institution":"Lakewood School of Therapeutic Massage","id":6495},
{"institution":"The Salon Spa Academy","id":6496},
{"institution":"Trend Setters School of Cosmetology","id":6497},
{"institution":"Academy of Hair Design-Springfield","id":6498},
{"institution":"Corinth Academy of Cosmetology","id":6499},
{"institution":"Southeastern Institute-Charlotte","id":6500},
{"institution":"Gentle Healing School of Massage","id":6501},
{"institution":"Yeshivas Be'er Yitzchok","id":6502},
{"institution":"Yeshiva Toras Chaim","id":6503},
{"institution":"Talmudical Seminary of Bobov","id":6504},
{"institution":"New York Methodist Hospital Center for Allied Health Education","id":6505},
{"institution":"Institute of Allied Medical Professions-New York","id":6506},
{"institution":"Micropower Career Institute","id":6507},
{"institution":"Monroe 2 Orleans BOCES-Center for Workforce Development","id":6508},
{"institution":"Aveda Fredric's Institute-Cincinnati","id":6509},
{"institution":"MyComputerCareer.com-TechSkills","id":6510},
{"institution":"CDE Career Institute","id":6511},
{"institution":"Instituto Educativo Premier","id":6512},
{"institution":"Charleston School of Law","id":6513},
{"institution":"Elite College of Cosmetology","id":6514},
{"institution":"Memphis Institute of Barbering","id":6515},
{"institution":"Manuel and Theresa's School of Hair Design-Bryan","id":6516},
{"institution":"Southwest University at El Paso","id":6517},
{"institution":"Paul Mitchell the School-Austin","id":6518},
{"institution":"Aveda Institute-Provo","id":6519},
{"institution":"Dixie Applied Technology College","id":6520},
{"institution":"Centura College-Alexandria","id":6521},
{"institution":"Southeast Culinary & Hospitality College","id":6522},
{"institution":"Dominion School of Hair Design","id":6523},
{"institution":"Victoria's Academy of Cosmetology","id":6524},
{"institution":"Inland Massage Institute","id":6525},
{"institution":"Sunnyside Beauty Academy","id":6526},
{"institution":"Wisconsin Academy","id":6527},
{"institution":"The Art Institute of Pittsburgh-Online Division","id":6528},
{"institution":"University of South Florida-Sarasota-Manatee","id":6529},
{"institution":"Brown Mackie College-Indianapolis","id":6530},
{"institution":"College America-Cheyenne","id":6531},
{"institution":"Paul Mitchell the School-San Antonio","id":6532},
{"institution":"Centro de Estudios Multidisciplinarios-Bayamon","id":6533},
{"institution":"Bryant & Stratton College-Wauwatosa","id":6534},
{"institution":"Minnesota School of Business-Blaine","id":6535},
{"institution":"The Art Institute of Michigan","id":6536},
{"institution":"Lacy Cosmetology School-Lexington","id":6537},
{"institution":"Lacy Cosmetology School-Goose Creek","id":6538},
{"institution":"The Art Institute of Austin","id":6539},
{"institution":"The Art Institute of California-Argosy University-Silicon Valley","id":6540},
{"institution":"Remington College-Houston Southeast Campus","id":6541},
{"institution":"Remington College-Shreveport Campus","id":6542},
{"institution":"National American University-Austin","id":6543},
{"institution":"National American University-Wichita","id":6544},
{"institution":"Lawton Career Institute-Warren Main Campus","id":6545},
{"institution":"Fortis College-Landover","id":6546},
{"institution":"Jenny Lea Academy of Cosmetology and Aesthetics","id":6547},
{"institution":"ITT Technical Institute-Cary","id":6548},
{"institution":"ITT Technical Institute-Madison","id":6549},
{"institution":"ITT Technical Institute-Clive","id":6550},
{"institution":"ITT Technical Institute-Columbus","id":6551},
{"institution":"ITT Technical Institute-Phoenix","id":6552},
{"institution":"ITT Technical Institute-Madison","id":6553},
{"institution":"ITT Technical Institute-High Point","id":6554},
{"institution":"The Hair Academy","id":6555},
{"institution":"The Art Institutes International�Kansas City","id":6556},
{"institution":"The Art Institute of Raleigh-Durham","id":6557},
{"institution":"Le Cordon Bleu College of Culinary Arts-Sacramento","id":6558},
{"institution":"Le Cordon Bleu College of Culinary Arts-Seattle","id":6559},
{"institution":"Le Cordon Bleu College of Culinary Arts-Cambridge","id":6560},
{"institution":"Le Cordon Bleu College of Culinary Arts-Dallas","id":6561},
{"institution":"Ross Medical Education Center-New Baltimore","id":6562},
{"institution":"Ross College-Sylvania","id":6563},
{"institution":"Argosy University-Salt Lake City","id":6564},
{"institution":"Escuela De Troqueleria Y Herramentaje","id":6565},
{"institution":"Virginia College-Montgomery","id":6566},
{"institution":"Fortis Institute-Nashville","id":6567},
{"institution":"Beckfield College-Tri-County","id":6568},
{"institution":"Community Technology Learning Center of Portage","id":6569},
{"institution":"David-Curtis School of Floral Design","id":6570},
{"institution":"Dental Assistant Pro LLC-Columbus","id":6571},
{"institution":"Dental Assistant Pro-Lebanon","id":6572},
{"institution":"Iverson Institute","id":6573},
{"institution":"Galen College of Nursing-Cincinnati","id":6574},
{"institution":"Harmony Path School of Massage Therapy","id":6575},
{"institution":"Institute of Therapeutic Massage","id":6576},
{"institution":"Pinnacle Career Institute-North Kansas City","id":6577},
{"institution":"Strayer University-Kentucky","id":6578},
{"institution":"Strayer University-North Carolina","id":6579},
{"institution":"Strayer University-New Jersey","id":6580},
{"institution":"International Culinary Arts and Sciences Institute","id":6581},
{"institution":"Kaplan College-Cincinnati","id":6582},
{"institution":"National College-Dayton","id":6583},
{"institution":"National College-Youngstown","id":6584},
{"institution":"National College-Cincinnati","id":6585},
{"institution":"National College-Stow","id":6586},
{"institution":"Harris School of Business-Hamilton Campus","id":6587},
{"institution":"Empire Beauty School-Eden Prairie","id":6588},
{"institution":"Ohio Business College-Hilliard","id":6589},
{"institution":"Ohio Center for Broadcasting-Columbus","id":6590},
{"institution":"Ohio Medical Career Center","id":6591},
{"institution":"Ohio Technical College-PowerSport Institute","id":6592},
{"institution":"Reflexology Certification Institute","id":6593},
{"institution":"Toledo Restaurant Training Center","id":6594},
{"institution":"Dade Medical College-Miami Lakes","id":6595},
{"institution":"The King�s College","id":6596},
{"institution":"DeVry University-Kentucky","id":6597},
{"institution":"Chamberlain College of Nursing-Administrative Office","id":6598},
{"institution":"Chamberlain College of Nursing-Illinois","id":6599},
{"institution":"Chamberlain College of Nursing-Ohio","id":6600},
{"institution":"Chamberlain College of Nursing-Arizona","id":6601},
{"institution":"Career Quest Learning Centers-Jackson","id":6602},
{"institution":"Regency Beauty Institute-Fort Collins","id":6603},
{"institution":"Regency Beauty Institute-Tolleson","id":6604},
{"institution":"Regency Beauty Institute-Pasadena","id":6605},
{"institution":"Regency Beauty Institute-Dayton","id":6606},
{"institution":"Regency Beauty Institute-Akron","id":6607},
{"institution":"Regency Beauty Institute-Detroit Southgate","id":6608},
{"institution":"Regency Beauty Institute-Flint","id":6609},
{"institution":"Regency Beauty Institute-Grand Rapids","id":6610},
{"institution":"Regency Beauty Institute-Cypresswood","id":6611},
{"institution":"Bryan University","id":6612},
{"institution":"Regency Beauty Institute-Austin","id":6613},
{"institution":"Regency Beauty Institute-Mesa","id":6614},
{"institution":"Regency Beauty Institute-Nashville","id":6615},
{"institution":"Regency Beauty Institute-Charlotte","id":6616},
{"institution":"Regency Beauty Institute-North Olmsted","id":6617},
{"institution":"Regency Beauty Institute-Columbus","id":6618},
{"institution":"Regency Beauty Institute-Elgin","id":6619},
{"institution":"The Salon Professional Academy-Appleton","id":6620},
{"institution":"Milan Institute of Cosmetology-Fairfield","id":6621},
{"institution":"Milan Institute of Cosmetology-Reno","id":6622},
{"institution":"Milan Institute of Cosmetology-Visalia","id":6623},
{"institution":"Ottawa University-Online","id":6624},
{"institution":"Broadview University-Orem","id":6625},
{"institution":"Paul Mitchell the School-Esani","id":6626},
{"institution":"Institute of Production and Recording","id":6627},
{"institution":"Virginia College-Greenville","id":6628},
{"institution":"Paul Mitchell the School-Birmingham","id":6629},
{"institution":"Taft University System","id":6630},
{"institution":"Mayfield College","id":6631},
{"institution":"International Polytechnic Institute","id":6632},
{"institution":"Academy of Esthetics and Cosmetology","id":6633},
{"institution":"BioHealth College","id":6634},
{"institution":"International Professional School of Bodywork","id":6635},
{"institution":"Borner's Barber College","id":6636},
{"institution":"Paul Mitchell the School-Pasadena","id":6637},
{"institution":"San Francisco Institute of Esthetics and Cosmetology","id":6638},
{"institution":"Laurus College","id":6639},
{"institution":"Healing Hands School of Holistic Health","id":6640},
{"institution":"Career Development Institute","id":6641},
{"institution":"Auguste Escoffier School of Culinary Arts-Boulder","id":6642},
{"institution":"Aspen University","id":6643},
{"institution":"Institute of Taoist Education and Acupuncture","id":6644},
{"institution":"National Beauty College","id":6645},
{"institution":"Denver School of Nursing","id":6646},
{"institution":"Cambridge Institute of Health & Technology","id":6647},
{"institution":"Cozmo The School","id":6648},
{"institution":"Allied Health Institute","id":6649},
{"institution":"North Florida Academy","id":6650},
{"institution":"Professional Hands Institute","id":6651},
{"institution":"Celebrity School of Beauty","id":6652},
{"institution":"Paul Mitchell the School-Atlanta","id":6653},
{"institution":"Evans Hairstyling College-Rexburg","id":6654},
{"institution":"Hair Academy Paul Mitchell Partner School","id":6655},
{"institution":"University of Aesthetics-Chicago","id":6656},
{"institution":"University of Aesthetics-Downers Grove","id":6657},
{"institution":"Tricoci University of Beauty Culture-Chicago","id":6658},
{"institution":"Tricoci University of Beauty Culture-Glendale Heights","id":6659},
{"institution":"Tricoci University of Beauty Culture-Peoria","id":6660},
{"institution":"Tricoci University of Beauty Culture-Rockford","id":6661},
{"institution":"Frederick School of Cosmetology","id":6662},
{"institution":"Omega Studios' School of Applied Recording Arts & Sciences","id":6663},
{"institution":"Focus-Hope Information Technologies Center","id":6664},
{"institution":"International Cosmetology Academy","id":6665},
{"institution":"French Academy of Cosmetology","id":6666},
{"institution":"Healing Arts Center","id":6667},
{"institution":"Paul Mitchell the School-Springfield","id":6668},
{"institution":"Urshan Graduate School of Theology","id":6669},
{"institution":"Mississippi Institute of Aesthetics Nails & Cosmetology","id":6670},
{"institution":"College of Western Idaho","id":6671},
{"institution":"Aveda Institute-Chapel Hill","id":6672},
{"institution":"Charlotte School of Law","id":6673},
{"institution":"Daoist Traditions College of Chinese Medical Arts","id":6674},
{"institution":"Total Image Beauty Academy","id":6675},
{"institution":"Jersey College","id":6676},
{"institution":"American Institute of Medical Sciences & Education","id":6677},
{"institution":"Casal Institute of Nevada","id":6678},
{"institution":"Career School of NY","id":6679},
{"institution":"John Paolo's Xtreme Beauty Institute-Goldwell Product Artistry","id":6680},
{"institution":"Ann Marie's World of Beauty School","id":6681},
{"institution":"Yeshiva of Machzikai Hadas","id":6682},
{"institution":"Aveda Institute-Columbus","id":6683},
{"institution":"Paul Mitchell the School-Cincinnati","id":6684},
{"institution":"Cutter's Edge School of Cosmetology","id":6685},
{"institution":"Hands on Therapy","id":6686},
{"institution":"MediaTech Institute-Dallas","id":6687},
{"institution":"PCCenter","id":6688},
{"institution":"Aveda Institute-San Antonio","id":6689},
{"institution":"Skin Institute","id":6690},
{"institution":"Skin Science Institute","id":6691},
{"institution":"Global Health College","id":6692},
{"institution":"Pacific Northwest University of Health Sciences","id":6693},
{"institution":"Visions in Hair Design Institute of Cosmetology","id":6694},
{"institution":"Brensten Education","id":6695},
{"institution":"The Chicago School of Professional Psychology at Irvine","id":6696},
{"institution":"California InterContinental University","id":6697},
{"institution":"Fortis College-Dothan","id":6698},
{"institution":"Fortis Institute-Pensacola","id":6699},
{"institution":"Fortis College-Montgomery","id":6700},
{"institution":"Fortis College School of Cosmetology","id":6701},
{"institution":"Woodland Community College","id":6702},
{"institution":"Dorsey Business Schools-Farmington Hills","id":6703},
{"institution":"Dorsey Business Schools-Roseville Culinary Academy","id":6704},
{"institution":"Vatterott College-Appling Farms","id":6705},
{"institution":"Minnesota School of Business-Moorhead","id":6706},
{"institution":"Brown Mackie College-Boise","id":6707},
{"institution":"Brown Mackie College-Tulsa","id":6708},
{"institution":"Fortis Institute-Birmingham","id":6709},
{"institution":"Daymar College-Scottsville","id":6710},
{"institution":"Miller-Motte College-Greenville","id":6711},
{"institution":"The Chicago School of Professional Psychology at Los Angeles","id":6712},
{"institution":"Metro Business College-Arnold","id":6713},
{"institution":"The Chicago School of Professional Psychology at Westwood","id":6714},
{"institution":"Cardiac and Vascular Institute of Ultrasound","id":6715},
{"institution":"Virginia College-Jacksonville","id":6716},
{"institution":"Studio Academy of Beauty","id":6717},
{"institution":"California Nurses Educational Institute","id":6718},
{"institution":"Central Nursing College","id":6719},
{"institution":"RWM Fiber Optics","id":6720},
{"institution":"Paul Mitchell the School-Sacramento","id":6721},
{"institution":"Providence Christian College","id":6722},
{"institution":"Summit Salon & Beauty School","id":6723},
{"institution":"Oxford Academy of Hair Design","id":6724},
{"institution":"Institute of World Politics","id":6725},
{"institution":"Pontifical John Paul II Institute for Studies on Marriage and Family","id":6726},
{"institution":"Medical Institute of Palm Beach","id":6727},
{"institution":"Lake Lanier School of Massage","id":6728},
{"institution":"Oliver Finley Academy of Cosmetology","id":6729},
{"institution":"National Career College","id":6730},
{"institution":"Aveda Institute-Chicago","id":6731},
{"institution":"Tricoci University of Beauty Culture-Libertyville","id":6732},
{"institution":"Tricoci University of Beauty Culture-Bridgeview","id":6733},
{"institution":"The Temple-A Paul Mitchell Partner School","id":6734},
{"institution":"Center for Massage & Natural Health","id":6735},
{"institution":"Academy of Hair Design-Oklahoma City","id":6736},
{"institution":"Education and Technology Institute","id":6737},
{"institution":"Pulse Beauty Academy-A Paul Mitchell Partner School","id":6738},
{"institution":"Liberty Technical College","id":6739},
{"institution":"Toni & Guy Hairdressing Academy-Cranston","id":6740},
{"institution":"South Texas Training Center","id":6741},
{"institution":"Columbia College","id":6742},
{"institution":"Vermont College of Fine Arts","id":6743},
{"institution":"Advanced Welding Institute","id":6744},
{"institution":"The Art Institute of Washington-Dulles","id":6745},
{"institution":"Paul Mitchell the School-Memphis","id":6746},
{"institution":"Pima Medical Institute-East Valley","id":6747},
{"institution":"Virginia College-Charleston","id":6748},
{"institution":"The Ohio Academy Paul Mitchell Partner School-Cleveland","id":6749},
{"institution":"The Ohio Academy Paul Mitchell Partner School-Columbus","id":6750},
{"institution":"Georgia Beauty Academy","id":6751},
{"institution":"Stautzenberger Institute-Allen Park","id":6752},
{"institution":"Empire Beauty School-Lauderhill","id":6753},
{"institution":"Empire Beauty School-Spring Lake Park","id":6754},
{"institution":"MediaTech Institute-Austin","id":6755},
{"institution":"MediaTech Institute-Houston","id":6756},
{"institution":"Fortis College-Phoenix","id":6757},
{"institution":"Miller-Motte College-Raleigh","id":6758},
{"institution":"Lamson Institute","id":6759},
{"institution":"Euphoria Institute of Beauty Arts & Sciences-Las Vegas","id":6760},
{"institution":"Lincoln College of Technology-Toledo","id":6761},
{"institution":"College of Hair Design-East Campus","id":6762},
{"institution":"International Academy of Design and Technology-Online","id":6763},
{"institution":"Le Cordon Bleu College of Culinary Arts-St Louis","id":6764},
{"institution":"Sanford-Brown College-San Antonio","id":6765},
{"institution":"Heritage College-Wichita","id":6766},
{"institution":"Heritage College-Little Rock","id":6767},
{"institution":"Argosy University-Phoenix Online Division","id":6768},
{"institution":"MedTech College-Greenwood Campus","id":6769},
{"institution":"MedTech College-Ft Wayne Campus","id":6770},
{"institution":"MedTech College-Lexington Campus","id":6771},
{"institution":"ITT Technical Institute-Madison","id":6772},
{"institution":"ITT Technical Institute-Springfield","id":6773},
{"institution":"ITT Technical Institute-Huntington","id":6774},
{"institution":"ITT Technical Institute-Concord","id":6775},
{"institution":"ITT Technical Institute-Fort Myers","id":6776},
{"institution":"ITT Technical Institute-Charlotte North","id":6777},
{"institution":"Fortis College-Salt Lake City","id":6778},
{"institution":"Polytechnic University of Puerto Rico-Miami","id":6779},
{"institution":"Polytechnic University of Puerto Rico-Orlando","id":6780},
{"institution":"Ross Medical Education Center-Fort Wayne","id":6781},
{"institution":"Ross Medical Education Center-Portage","id":6782},
{"institution":"The Commonwealth Medical College","id":6783},
{"institution":"Josef's School of Hair Design Inc-Fargo West","id":6784},
{"institution":"Northeast Technology Center-Claremore","id":6785},
{"institution":"Salon Success Academy-Fontana","id":6786},
{"institution":"Salon Success Academy-Redlands","id":6787},
{"institution":"Brown Mackie College-Phoenix","id":6788},
{"institution":"Strayer University-West Virginia","id":6789},
{"institution":"Strayer University-Utah","id":6790},
{"institution":"Strayer University-Ohio","id":6791},
{"institution":"Regency Beauty Institute-Tinley Park","id":6792},
{"institution":"Regency Beauty Institute-Columbia","id":6793},
{"institution":"Regency Beauty Institute-South Bend","id":6794},
{"institution":"Regency Beauty Institute-Detroit Lakeside","id":6795},
{"institution":"Regency Beauty Institute-Fort Myers","id":6796},
{"institution":"Regency Beauty Institute-Arlington","id":6797},
{"institution":"Regency Beauty Institute-Durham","id":6798},
{"institution":"Minnesota School of Business-Elk River","id":6799},
{"institution":"Brown Mackie College-Greenville","id":6800},
{"institution":"Globe University-Sioux Falls","id":6801},
{"institution":"Globe University-Eau Claire","id":6802},
{"institution":"Vanguard College of Cosmetology-Baton Rouge","id":6803},
{"institution":"Globe University-Minneapolis","id":6804},
{"institution":"San Joaquin Valley College-Hesperia","id":6805},
{"institution":"Beauty Schools of America-Homestead","id":6806},
{"institution":"ATI Career Training Center-Dallas","id":6807},
{"institution":"South Texas Vocational Technical Institute-Brownsville","id":6808},
{"institution":"South Texas Vocational Technical Institute-Corpus Christi","id":6809},
{"institution":"L'Ecole Culinaire-Memphis","id":6810},
{"institution":"University of Minnesota-Rochester","id":6811},
{"institution":"Health And Style Institute","id":6812},
{"institution":"CBT College-Cutler Bay","id":6813},
{"institution":"Provo College�American Fork","id":6814},
{"institution":"National College-Columbus","id":6815},
{"institution":"Kaplan Career Institute-Boston","id":6816},
{"institution":"Herzing University-Toledo","id":6817},
{"institution":"Capri Beauty College","id":6818},
{"institution":"Milan Institute of Cosmetology-Concord","id":6819},
{"institution":"Milan Institute-Bakersfield","id":6820},
{"institution":"Homestead Schools","id":6821},
{"institution":"Carrington College-Las Vegas","id":6822},
{"institution":"Carrington College-Reno","id":6823},
{"institution":"Chamberlain College of Nursing-Florida","id":6824},
{"institution":"Remington College-Columbia Campus","id":6825},
{"institution":"Remington College of Nursing Orlando","id":6826},
{"institution":"Washington Barber College Inc","id":6827},
{"institution":"InterCoast Colleges-Elk Grove","id":6828},
{"institution":"Universal Barber College","id":6829},
{"institution":"Horizon University","id":6830},
{"institution":"San Diego Culinary Institute","id":6831},
{"institution":"Beyond 21st Century Beauty Academy","id":6832},
{"institution":"Franklin Career College","id":6833},
{"institution":"Academy for Jewish Religion-California","id":6834},
{"institution":"Hollywood Beauty College","id":6835},
{"institution":"Angeles College","id":6836},
{"institution":"Angeles Institute","id":6837},
{"institution":"Paul Mitchell the School-Temecula","id":6838},
{"institution":"European Academy of Cosmetology and Hairdressing","id":6839},
{"institution":"International Institute of Cosmetology","id":6840},
{"institution":"Florida School of Traditional Midwifery","id":6841},
{"institution":"Walton Career Development Center","id":6842},
{"institution":"Immokalee Technical Center","id":6843},
{"institution":"American Academy of Cosmetology","id":6844},
{"institution":"University of Fort Lauderdale","id":6845},
{"institution":"Aviation Institute of Maintenance-Orlando","id":6846},
{"institution":"The Salon Professional Academy-Tampa","id":6847},
{"institution":"The Salon Professional Academy-Gainesville","id":6848},
{"institution":"The Salon Professional Academy-The Villages","id":6849},
{"institution":"The Hair Academy Inc","id":6850},
{"institution":"Profile Institute of Barber-Styling","id":6851},
{"institution":"The Process Institute of Cosmetology","id":6852},
{"institution":"New Hope Christian College-Honolulu","id":6853},
{"institution":"D & L Academy of Hair Design","id":6854},
{"institution":"Master Educators Beauty School","id":6855},
{"institution":"Ambria College of Nursing","id":6856},
{"institution":"Midwestern Career College","id":6857},
{"institution":"Paul Mitchell the School-Normal","id":6858},
{"institution":"Innovations Design Academy","id":6859},
{"institution":"Aveda Fredric's Institute-Indianapolis","id":6860},
{"institution":"The Salon Professional Academy-Anderson","id":6861},
{"institution":"Eric Fisher Academy","id":6862},
{"institution":"A & W Healthcare Educators","id":6863},
{"institution":"My Le's Beauty College","id":6864},
{"institution":"Blackstone Valley Vocational Regional School District","id":6865},
{"institution":"Southern Worcester County Regional Voc School District","id":6866},
{"institution":"L'esprit Academy","id":6867},
{"institution":"Marketti Academy of Cosmetology","id":6868},
{"institution":"CenterPoint Massage and Shiatsu Therapy School and Clinic","id":6869},
{"institution":"Avalon School of Cosmetology","id":6870},
{"institution":"American Business and Technology University","id":6871},
{"institution":"City Vision College","id":6872},
{"institution":"Transformed Barber and Cosmetology Academy","id":6873},
{"institution":"Bitterroot School of Cosmetology","id":6874},
{"institution":"Atlanta Beauty & Barber Academy","id":6875},
{"institution":"The Salon Professional Academy-Great Falls","id":6876},
{"institution":"Park West Barber School","id":6877},
{"institution":"National Career Institute","id":6878},
{"institution":"Toni & Guy Hairdressing Academy-Albuquerque","id":6879},
{"institution":"International Academy of Style","id":6880},
{"institution":"EDP School of Computer Programming","id":6881},
{"institution":"New York Medical Career Training Center","id":6882},
{"institution":"Institute of Culinary Education","id":6883},
{"institution":"New Life Business Institute","id":6884},
{"institution":"The Salon Professional Academy-Tonawanda","id":6885},
{"institution":"Onondaga School of Therapeutic Massage-Syracuse","id":6886},
{"institution":"Academy of Cosmetology and Esthetics NYC","id":6887},
{"institution":"Finger Lakes School of Massage","id":6888},
{"institution":"Four County Career Center","id":6889},
{"institution":"Vanity School of Cosmetology","id":6890},
{"institution":"Tolles Career and Technical Center","id":6891},
{"institution":"The Salon Professional Academy-Perrysburg","id":6892},
{"institution":"Portland Actors Conservatory","id":6893},
{"institution":"Somerset County Technology Center","id":6894},
{"institution":"Metro Beauty Academy","id":6895},
{"institution":"Barone Beauty Academy","id":6896},
{"institution":"Academy for Careers and Technology","id":6897},
{"institution":"Aiken School of Cosmetology","id":6898},
{"institution":"Love Beauty School Inc","id":6899},
{"institution":"Professional Career Training Institute","id":6900},
{"institution":"Cannon Institute of Higher Learning","id":6901},
{"institution":"Cardiotech Ultrasound School","id":6902},
{"institution":"DuVall's School of Cosmetology","id":6903},
{"institution":"Advanced Beauty College","id":6904},
{"institution":"Texas Beauty College","id":6905},
{"institution":"Avenue Five Institute","id":6906},
{"institution":"Corpus Christi Beauty Academy","id":6907},
{"institution":"The Salon Professional Academy-Lewisville","id":6908},
{"institution":"Renaissance Academie","id":6909},
{"institution":"Bethel College","id":6910},
{"institution":"Institute of Advanced Medical Esthetics","id":6911},
{"institution":"Gary Manuel Aveda Institute","id":6912},
{"institution":"Northwest School of Wooden Boat Building","id":6913},
{"institution":"Bainbridge Graduate Institute","id":6914},
{"institution":"The Salon Professional Academy-Onalaska","id":6915},
{"institution":"Regency Beauty Institute-North Tucson","id":6916},
{"institution":"Kaplan College-Chula Vista","id":6917},
{"institution":"InterCoast Colleges-Roseville","id":6918},
{"institution":"West Coast University-Orange County","id":6919},
{"institution":"West Coast University-Ontario","id":6920},
{"institution":"Kaplan College-Pembroke Pines","id":6921},
{"institution":"Dade Medical College-Homestead","id":6922},
{"institution":"Virginia College-Augusta","id":6923},
{"institution":"Midwest Technical Institute-East Peoria","id":6924},
{"institution":"Paul Mitchell the School-Chicago","id":6925},
{"institution":"Kaplan College-Indianapolis","id":6926},
{"institution":"Regency Beauty Institute-Evansville","id":6927},
{"institution":"Regency Beauty Institute-Wichita","id":6928},
{"institution":"Regency Beauty Institute-Shreveport","id":6929},
{"institution":"Regency Beauty Institute-Springfield","id":6930},
{"institution":"Regency Beauty Institute-Canton","id":6931},
{"institution":"Regency Beauty Institute-Spartanburg","id":6932},
{"institution":"Regency Beauty Institute-Chattanooga","id":6933},
{"institution":"Regency Beauty Institute-Mesquite","id":6934},
{"institution":"Kaplan Career Institute-Dearborn","id":6935},
{"institution":"Dorsey Business Schools-Waterford Pontiac","id":6936},
{"institution":"Regina's College of Beauty-High Point","id":6937},
{"institution":"Miller-Motte College-Fayetteville","id":6938},
{"institution":"Drake College of Business-Newark","id":6939},
{"institution":"European Massage Therapy School-Las Vegas","id":6940},
{"institution":"Harrison College-Grove City","id":6941},
{"institution":"Miller-Motte Technical College-Columbus","id":6942},
{"institution":"National University College-Ponce","id":6943},
{"institution":"Fortis Institute-Grand Prairie","id":6944},
{"institution":"Paul Mitchell the School-St George","id":6945},
{"institution":"The Art Institute of Virginia Beach","id":6946},
{"institution":"Bryant & Stratton College-Bayshore","id":6947},
{"institution":"Gerstner Sloan-Kettering Graduate School of Biomedical Sciences","id":6948},
{"institution":"Daymar College-Online","id":6949},
{"institution":"Virginia College-Columbia","id":6950},
{"institution":"Sanford-Brown College-Dearborn","id":6951},
{"institution":"Sanford-Brown College-Grand Rapids","id":6952},
{"institution":"Sanford-Brown College-Indianapolis","id":6953},
{"institution":"Sanford-Brown College-Hillside","id":6954},
{"institution":"Sanford-Brown College-Tinley Park","id":6955},
{"institution":"Sanford-Brown College-Phoenix","id":6956},
{"institution":"Sanford-Brown Institute-Orlando","id":6957},
{"institution":"Fortis College-Indianapolis","id":6958},
{"institution":"Kaplan College-Arlington","id":6959},
{"institution":"Kaplan College-Charlotte","id":6960},
{"institution":"Kaplan College-Jacksonville","id":6961},
{"institution":"Globe University-Madison East","id":6962},
{"institution":"Minnesota School of Business-Lakeville","id":6963},
{"institution":"Globe University�Green Bay","id":6964},
{"institution":"Globe University�Madison West","id":6965},
{"institution":"Globe University�Wausau","id":6966},
{"institution":"Dade Medical College-Hollywood","id":6967},
{"institution":"Empire Beauty School-E Memphis","id":6968},
{"institution":"Empire Beauty School-Nashville","id":6969},
{"institution":"Empire Beauty School-Jackson","id":6970},
{"institution":"Empire Beauty School-Springfield","id":6971},
{"institution":"Empire Beauty School-Paramus","id":6972},
{"institution":"Empire Beauty School-Speedway","id":6973},
{"institution":"Empire Beauty School-Morrow","id":6974},
{"institution":"Strayer University-Arkansas","id":6975},
{"institution":"Strayer University-Georgia","id":6976},
{"institution":"Strayer University-Louisiana","id":6977},
{"institution":"Strayer University-Mississippi","id":6978},
{"institution":"Strayer University-South Carolina","id":6979},
{"institution":"Strayer University-Texas","id":6980},
{"institution":"The Art Institute of San Antonio","id":6981},
{"institution":"Globe University-La Crosse","id":6982},
{"institution":"The Hair Design School-S Memphis","id":6983},
{"institution":"The Hair Design School-Charlotte","id":6984},
{"institution":"The Hair Design School-Durham","id":6985},
{"institution":"The Hair Design School-E Greensboro","id":6986},
{"institution":"The Hair Design School-Winston-Salem","id":6987},
{"institution":"Empire Beauty School-West Palm","id":6988},
{"institution":"Empire Beauty School-Pineville","id":6989},
{"institution":"The Hair Design School-N Memphis","id":6990},
{"institution":"Broadview Entertainment Arts University","id":6991},
{"institution":"Bryant & Stratton College-Hampton","id":6992},
{"institution":"Paul Mitchell the School-Phoenix","id":6993},
{"institution":"All Beauty College","id":6994},
{"institution":"Northern California Institute of Cosmetology Inc","id":6995},
{"institution":"Golden State College of Court Reporting","id":6996},
{"institution":"Preferred College of Nursing-Los Angeles","id":6997},
{"institution":"Trinity Vocational Center","id":6998},
{"institution":"SICE Paul Mitchell Partner School","id":6999},
{"institution":"Cosmo Beauty Academy","id":7000},
{"institution":"Paul Mitchell the School-Fresno","id":7001},
{"institution":"Unitek College","id":7002},
{"institution":"Gurnick Academy of Medical Arts","id":7003},
{"institution":"Southern California University SOMA","id":7004},
{"institution":"Paul Mitchell the School-East Bay","id":7005},
{"institution":"South University�Richmond","id":7006},
{"institution":"South University�Virginia Beach","id":7007},
{"institution":"Aveda Institute-Denver","id":7008},
{"institution":"Paul Mitchell the School-Colorado Springs","id":7009},
{"institution":"The Salon Professional Academy-Colorado Springs","id":7010},
{"institution":"Paul Mitchell the School-Delaware","id":7011},
{"institution":"Future-Tech Institute","id":7012},
{"institution":"Marchman Technical Education Center","id":7013},
{"institution":"Eureka Institute of Health and Beauty","id":7014},
{"institution":"The Salon Professional Academy-Ft Myers","id":7015},
{"institution":"SOLEX College","id":7016},
{"institution":"Unity Cosmetology College","id":7017},
{"institution":"Universal Spa Training Academy","id":7018},
{"institution":"The Salon Professional Academy-Evansville","id":7019},
{"institution":"Tri County Regional Vocational Technical High School","id":7020},
{"institution":"Compass College of Cinematic Arts","id":7021},
{"institution":"Nova Academy of Cosmetology","id":7022},
{"institution":"Victory Trade School","id":7023},
{"institution":"Starting Points Inc","id":7024},
{"institution":"The Lab-Paul Mitchell Partner School","id":7025},
{"institution":"SAE Institute of Technology-New York","id":7026},
{"institution":"John Paolo's Xtreme Beauty Institute-Goldwell Product Artistry","id":7027},
{"institution":"The Artisan College of Cosmetology","id":7028},
{"institution":"Sage School of Massage","id":7029},
{"institution":"Lawyer's Assistant School of Dallas","id":7030},
{"institution":"ABC Beauty Academy","id":7031},
{"institution":"Salon & Spa Institute","id":7032},
{"institution":"American Beauty Academy","id":7033},
{"institution":"The Salon Professional Academy-Tacoma","id":7034},
{"institution":"Academy of Cosmetology","id":7035},
{"institution":"The Academy Waukesha","id":7036},
{"institution":"ITT Technical Institute-Merrillville","id":7037},
{"institution":"ITT Technical Institute-Tallahassee","id":7038},
{"institution":"ITT Technical Institute-Salem","id":7039},
{"institution":"ITT Technical Institute-Akron","id":7040},
{"institution":"ITT Technical Institute-Cedar Rapids","id":7041},
{"institution":"ITT Technical Institute-Corona","id":7042},
{"institution":"ITT Technical Institute-Johnson City","id":7043},
{"institution":"ITT Technical Institute-DeSoto","id":7044},
{"institution":"ITT Technical Institute-North Charleston","id":7045},
{"institution":"ITT Technical Institute-Aurora","id":7046},
{"institution":"ITT Technical Institute-West Covina","id":7047},
{"institution":"ITT Technical Institute-Culver City","id":7048},
{"institution":"ITT Technical Institute-Dearborn","id":7049},
{"institution":"ITT Technical Institute-Las Vegas","id":7050},
{"institution":"Touro University Worldwide","id":7051},
{"institution":"Touro University California","id":7052},
{"institution":"The Chicago School of Professional Psychology at Washington DC","id":7053},
{"institution":"Anamarc College-Santa Teresa","id":7054},
{"institution":"Touro University Nevada","id":7055},
{"institution":"National College-Willoughby Hills","id":7056},
{"institution":"Herzing University-Kenosha","id":7057},
{"institution":"Herzing University-Brookfield","id":7058},
{"institution":"Heald College-Modesto","id":7059},
{"institution":"Fortis Institute-Houston","id":7060},
{"institution":"Southern Careers Institute-Brownsville","id":7061},
{"institution":"Southern Careers Institute-Corpus Christi 2","id":7062},
{"institution":"Southern Careers Institute-Harlingen","id":7063},
{"institution":"Strayer University-Global Region","id":7064},
{"institution":"Regina's College of Beauty-Charlotte","id":7065},
{"institution":"Kenneth Shuler School of Cosmetology-Florence","id":7066},
{"institution":"Fortis College-Columbia","id":7067},
{"institution":"Brown Mackie College-Albuquerque","id":7068},
{"institution":"Brown Mackie College-St Louis","id":7069},
{"institution":"Avant Gard The School","id":7070},
{"institution":"Toni & Guy Hairdressing Academy-Bellingham","id":7071},
{"institution":"California University of Management and Sciences","id":7072},
{"institution":"Ross Medical Education Center-Davison","id":7073},
{"institution":"Ross Medical Education Center-Granger","id":7074},
{"institution":"Ross Medical Education Center-Niles","id":7075},
{"institution":"Ross Medical Education Center-Canton","id":7076},
{"institution":"Pima Medical Institute-Houston","id":7077},
{"institution":"Illinois Center for Broadcasting�Chicago Campus","id":7078},
{"institution":"South Texas Vocational Technical Institute-San Antonio","id":7079},
{"institution":"Concorde Career Institute-Dallas","id":7080},
{"institution":"Concorde Career Institute-Orlando","id":7081},
{"institution":"Concorde Career Institute-San Antonio","id":7082},
{"institution":"Ecotech Institute","id":7083},
{"institution":"Johnson & Wales University-Online","id":7084},
{"institution":"Everest College-Fort Worth South","id":7085},
{"institution":"Everest College-Santa Ana","id":7086},
{"institution":"Geisinger-Lewistown Hospital School of Nursing","id":7087},
{"institution":"Moreno Valley College","id":7088},
{"institution":"Hair Design Institute at Fifth Avenue-New York","id":7089},
{"institution":"Onondaga School of Therapeutic Massage-Rochester","id":7090},
{"institution":"Norco College","id":7091},
{"institution":"Daymar College-Louisville","id":7092},
{"institution":"Dorsey Business Schools-Saginaw","id":7093},
{"institution":"Milan Institute of Cosmetology-La Quinta","id":7094},
{"institution":"Milan Institute-Nampa","id":7095},
{"institution":"Milan Institute of Cosmetology-El Paso","id":7096},
{"institution":"Universal Technical Institute - Dallas Fort Worth","id":7097},
{"institution":"Finger Lakes School of Massage","id":7098},
{"institution":"Park Avenue School of Cosmetology","id":7099},
{"institution":"Salter School of Nursing and Allied Health","id":7100},
{"institution":"Salter School-New Bedford","id":7101},
{"institution":"Harris School of Business-Upper Darby Campus","id":7102},
{"institution":"Stratford School of Aviation Maintenance Technicians","id":7103},
{"institution":"Brown Mackie College-San Antonio","id":7104},
{"institution":"Hollywood Institute of Beauty Careers","id":7105},
{"institution":"Cortiva Institute-New Jersey","id":7106},
{"institution":"Cambridge Junior College-Woodland","id":7107},
{"institution":"Anthem College-Atlanta","id":7108},
{"institution":"ICPR Junior College-Manati","id":7109},
{"institution":"Western Beauty Institute","id":7110},
{"institution":"Ridley-Lowell Business & Technical Institute-Danbury","id":7111},
{"institution":"Artistic Nails and Beauty Academy-Lakeland","id":7112},
{"institution":"Paul Mitchell the School-Murfreesboro","id":7113},
{"institution":"Allied American University","id":7114},
{"institution":"American Sentinel University","id":7115},
{"institution":"Tribeca Flashpoint Media Arts Academy","id":7116},
{"institution":"Hawaii Medical College","id":7117},
{"institution":"Carolina College of Hair Design","id":7118},
{"institution":"Virginia College-Baton Rouge","id":7119},
{"institution":"Remington College-Heathrow Campus","id":7120},
{"institution":"College of Massage Therapy","id":7121},
{"institution":"Miller-Motte College-Jacksonville","id":7122},
{"institution":"Miller-Motte Technical College-Augusta","id":7123},
{"institution":"Miller-Motte Technical College-Conway","id":7124},
{"institution":"Virginia College-Macon","id":7125},
{"institution":"Virginia College-Spartanburg","id":7126},
{"institution":"Aveda Institute-Portland","id":7127},
{"institution":"Chamberlain College of Nursing-Virginia","id":7128},
{"institution":"Stevens-Henager College-Boise","id":7129},
{"institution":"The Salon Professional Academy-Cincinnati","id":7130},
{"institution":"Penrose Academy","id":7131},
{"institution":"Virginia College-Richmond","id":7132},
{"institution":"Skin Institute","id":7133},
{"institution":"Northwest Institute of Literary Arts","id":7134},
{"institution":"Body Therapy Institute","id":7135},
{"institution":"Beauty Academy of South Florida","id":7136},
{"institution":"Ogle School Hair Skin Nails-North Dallas","id":7137},
{"institution":"The Salon Professional Academy-North Little Rock","id":7138},
{"institution":"The Salon Professional Academy-St Charles","id":7139},
{"institution":"American Institute","id":7140},
{"institution":"Mildred Elley-New York Campus","id":7141},
{"institution":"National Paralegal College","id":7142},
{"institution":"Carolina College of Biblical Studies","id":7143},
{"institution":"Star Career Academy-Audubon","id":7144},
{"institution":"St Luke University","id":7145},
{"institution":"Northeast Technology Center-System Office","id":7146},
{"institution":"Allstate Hairstyling & Barber College","id":7147},
{"institution":"Oxford Graduate School","id":7148},
{"institution":"Jung Tao School of Classical Chinese Medicine","id":7149},
{"institution":"New York Film Academy","id":7150},
{"institution":"The Collective School Of Music","id":7151},
{"institution":"International College of Cosmetology","id":7152},
{"institution":"GP Institute of Cosmetology","id":7153},
{"institution":"Bella Capelli Academy","id":7154},
{"institution":"Institute of Medical Careers","id":7155},
{"institution":"Toni & Guy Hairdressing Academy-Modesto","id":7156},
{"institution":"Georgia Christian University","id":7157},
{"institution":"Flagler Technical Institute","id":7158},
{"institution":"Galaxy Medical College","id":7159},
{"institution":"American Medical Sciences Center","id":7160},
{"institution":"Mandalyn Academy","id":7161},
{"institution":"Jose Maria Vargas University","id":7162},
{"institution":"Laird Institute of Spa Therapy","id":7163},
{"institution":"D A Dorsey Educational Center","id":7164},
{"institution":"Keweenaw Bay Ojibwa Community College","id":7165},
{"institution":"Meridian Institute of Surgical Assisting","id":7166},
{"institution":"Mauna Loa Helicopters","id":7167},
{"institution":"Acaydia School of Aesthetics","id":7168},
{"institution":"Manhattan Institute","id":7169},
{"institution":"Cosmetic Arts Institute","id":7170},
{"institution":"Holistic Massage Training Institute","id":7171},
{"institution":"Diamonds Cosmetology College","id":7172},
{"institution":"Tramy Beauty School","id":7173},
{"institution":"Scholars Cosmetology University","id":7174},
{"institution":"Northern Virginia School of Therapeutic Massage","id":7175},
{"institution":"Paul Mitchell the School-St Louis","id":7176},
{"institution":"Advanced Training Institute","id":7177},
{"institution":"Cosmopolitan Beauty and Tech School","id":7178},
{"institution":"The Salon Professional Academy-Elgin","id":7179},
{"institution":"CCIC Beauty College","id":7180},
{"institution":"Salon 496 Barber Academy","id":7181},
{"institution":"Shepherds Theological Seminary","id":7182},
{"institution":"Taylor Andrews Academy of Hair Design-West Jordan","id":7183},
{"institution":"Santa Ana Beauty Academy","id":7184},
{"institution":"Grace College of Divinity","id":7185},
{"institution":"Cosmetology College of Franklin County","id":7186},
{"institution":"Rochester School of Hair Design","id":7187},
{"institution":"Aveda Institute-New Mexico","id":7188},
{"institution":"Nashville Barber and Style Academy","id":7189},
{"institution":"American Trade School","id":7190},
{"institution":"Estelle Skin Care and Spa Institute","id":7191},
{"institution":"Paul Mitchell the School-Honolulu","id":7192},
{"institution":"Elite Cosmetology School","id":7193},
{"institution":"Academy of Aesthetic Arts","id":7194},
{"institution":"American Technical Institute","id":7195},
{"institution":"Twin Rivers Adult School","id":7196},
{"institution":"Bergin University of Canine Studies","id":7197},
{"institution":"Aveda Institute-Boise","id":7198},
{"institution":"Progressive Training Centers","id":7199},
{"institution":"American Health Institute","id":7200},
{"institution":"Pima Medical Institute-South Denver","id":7201},
{"institution":"Real Barbers College","id":7202},
{"institution":"Capilo School of Hair Design","id":7203},
{"institution":"Pure Aesthetics","id":7204},
{"institution":"Lindsey Institute of Cosmetology","id":7205},
{"institution":"The Salon Professional Academy-Iowa City","id":7206},
{"institution":"Buckner Barber School","id":7207},
{"institution":"Simmons College of Kentucky","id":7208},
{"institution":"Atelier Esthetique Institute of Esthetics","id":7209},
{"institution":"Ashdown College of Health Sciences","id":7210},
{"institution":"Advanced College of Cosmetology","id":7211},
{"institution":"North American University","id":7212},
{"institution":"Entourage Institute of Beauty and Esthetics","id":7213},
{"institution":"United Beauty College","id":7214},
{"institution":"Cinta Aveda Institute","id":7215},
{"institution":"Long Island Nail & Skin Care Institute","id":7216},
{"institution":"Mesivta Keser Torah","id":7217},
{"institution":"Southern California Health Institute","id":7218},
{"institution":"Best Care Training Institute","id":7219},
{"institution":"First Class Cosmetology School","id":7220},
{"institution":"Millennia Atlantic University","id":7221},
{"institution":"Abcott Institute","id":7222},
{"institution":"The Salon Professional Academy-Kenosha","id":7223},
{"institution":"Paul Mitchell the School-Indianapolis","id":7224},
{"institution":"Taylor Andrews Academy-St George","id":7225},
{"institution":"Seymour Beauty Academy","id":7226},
{"institution":"Protege Academy","id":7227},
{"institution":"Colorado Academy of Veterinary Technology","id":7228},
{"institution":"Northeast Technical Institute","id":7229},
{"institution":"Salinas Beauty College Inc","id":7230},
{"institution":"Academy for Salon Professionals","id":7231},
{"institution":"Elite School of Cosmetology","id":7232},
{"institution":"Paul Mitchell the School-Las Vegas","id":7233},
{"institution":"The Salon Professional Academy-Kokomo","id":7234},
{"institution":"Hair Academy","id":7235},
{"institution":"Florida Academy","id":7236},
{"institution":"Institute for Doctoral Studies in the Visual Arts","id":7237},
{"institution":"State Career College","id":7238},
{"institution":"Academy for Salon Professionals","id":7239},
{"institution":"Paul Mitchell The School-Spokane","id":7240},
{"institution":"DeVry University's Keller Graduate School of Management-Maryland","id":7241},
{"institution":"DeVry University's Keller Graduate School of Management-North Carolina","id":7242},
{"institution":"DeVry University's Keller Graduate School of Management-Florida","id":7243},
{"institution":"DeVry College of New York's Keller Graduate School of Management","id":7244},
{"institution":"DeVry University's Keller Graduate School of Management-Indiana","id":7245},
{"institution":"DeVry University's Keller Graduate School of Management-Nevada","id":7246},
{"institution":"DeVry University's Keller Graduate School of Management-Colorado","id":7247},
{"institution":"DeVry University's Keller Graduate School of Management-Minnesota","id":7248},
{"institution":"DeVry University's Keller Graduate School of Management-Oklahoma","id":7249},
{"institution":"DeVry University's Keller Graduate School of Management-Utah","id":7250},
{"institution":"DeVry University's Keller Graduate School of Management-Tennessee","id":7251},
{"institution":"DeVry University's Keller Graduate School of Management-Michigan","id":7252},
{"institution":"DeVry University's Keller Graduate School of Management-Kentucky","id":7253},
{"institution":"DeVry University's Keller Graduate School of Management-New Jersey","id":7254},
{"institution":"DeVry University's Keller Graduate School of Management-Oregon","id":7255},
{"institution":"Lincoln College of New England-Hartford","id":7256},
{"institution":"Lincoln College of Technology-Columbus","id":7257},
{"institution":"Lincoln College of Technology-Cleveland","id":7258},
{"institution":"Northwestern Institute of Health and Technology","id":7259},
{"institution":"J Renee Career Facilitation","id":7260},
{"institution":"National Personal Training Institute of Colorado","id":7261},
{"institution":"National Personal Training Institute of Colorado","id":7262},
{"institution":"International College of Beauty Arts & Sciences","id":7263},
{"institution":"The Salon Professional Academy-Shorewood","id":7264},
{"institution":"John Paul the Great Catholic University","id":7265},
{"institution":"ITT Technical Institute-Durham","id":7266},
{"institution":"ITT Technical Institute-Hanover","id":7267},
{"institution":"University of Phoenix-Jackson Campus","id":7268},
{"institution":"University of Phoenix-Delaware Campus","id":7269},
{"institution":"Everest College-Milwaukee","id":7270},
{"institution":"Independence University","id":7271},
{"institution":"National American University-Tulsa","id":7272},
{"institution":"National American University-Centennial","id":7273},
{"institution":"National American University-Lee's Summit","id":7274},
{"institution":"National American University-Colorado Springs South","id":7275},
{"institution":"Globe University-Appleton","id":7276},
{"institution":"Salter College-Chicopee","id":7277},
{"institution":"Chamberlain College of Nursing-Missouri","id":7278},
{"institution":"Chamberlain College of Nursing-Texas","id":7279},
{"institution":"ITT Technical Institute-Oakland","id":7280},
{"institution":"Continental School of Beauty Culture-Mattydale","id":7281},
{"institution":"Everest College-Bedford Park","id":7282},
{"institution":"Fortis College-Akron","id":7283},
{"institution":"Carrington College California-Pomona","id":7284},
{"institution":"Regency Beauty Institute-Copperwood","id":7285},
{"institution":"Regency Beauty Institute-Little Rock","id":7286},
{"institution":"Regency Beauty Institute-Baltimore Golden Ring","id":7287},
{"institution":"Regency Beauty Institute-El Paso","id":7288},
{"institution":"Regency Beauty Institute-Jacksonville Regency","id":7289},
{"institution":"Regency Beauty Institute-Knoxville","id":7290},
{"institution":"Regency Beauty Institute-Fayetteville","id":7291},
{"institution":"Regency Beauty Institute-Lansing","id":7292},
{"institution":"Regency Beauty Institute-Jacksonville Orange Park","id":7293},
{"institution":"Regency Beauty Institute-Crystal Lake","id":7294},
{"institution":"Regency Beauty Institute-Manassas","id":7295},
{"institution":"Regency Beauty Institute-Castleton","id":7296},
{"institution":"Regency Beauty Institute-North Nashville","id":7297},
{"institution":"Regency Beauty Institute-Round Rock","id":7298},
{"institution":"Regency Beauty Institute-Merrillville","id":7299},
{"institution":"Regency Beauty Institute-Roanoke","id":7300},
{"institution":"Regency Beauty Institute-Toledo","id":7301},
{"institution":"Regency Beauty Institute-SW Houston","id":7302},
{"institution":"Everest College-Atlanta West","id":7303},
{"institution":"Broadview University-Boise","id":7304},
{"institution":"Advanced Institute of Hair Design-Madison","id":7305},
{"institution":"Paul Mitchell The School-Columbia","id":7306},
{"institution":"Paul Mitchell The School-Charleston","id":7307},
{"institution":"Everest College-Melrose Park","id":7308},
{"institution":"Kenneth Shuler School of Cosmetology-Greenville","id":7309},
{"institution":"South University-Accelerated Graduate Programs","id":7310},
{"institution":"South University-The Art Institute of Fort Worth","id":7311},
{"institution":"South University-Novi","id":7312},
{"institution":"Northwest College-Eugene","id":7313},
{"institution":"Northwest College-Tualatin","id":7314},
{"institution":"Harrison College-Morrisville","id":7315},
{"institution":"Trend Setters' Academy of Beauty Culture-Louisville","id":7316},
{"institution":"National University College-Caguas","id":7317},
{"institution":"Beauty Schools of America","id":7318},
{"institution":"CET-Soledad","id":7319},
{"institution":"MarJon School of Beauty ltd-Lockport","id":7320},
{"institution":"Houston Training Schools-Southwest","id":7321},
{"institution":"Vanguard College of Cosmetology-Metairie","id":7322},
{"institution":"New England Tractor Trailer Training School of Massachusetts","id":7323},
{"institution":"Heritage College-Columbus","id":7324},
{"institution":"Utah College of Massage Therapy-Vegas","id":7325},
{"institution":"Utah College of Massage Therapy-Tempe","id":7326},
{"institution":"Utah College of Massage Therapy-Phoenix","id":7327},
{"institution":"Utah College of Massage Therapy-Westminster","id":7328},
{"institution":"Utah College of Massage Therapy-Aurora","id":7329},
{"institution":"Sanford-Brown College-Portland","id":7330},
{"institution":"Tint School of Makeup and Cosmetology-Grand Prairie","id":7331},
{"institution":"Dewey University-Yabucoa","id":7332},
{"institution":"Dewey University-Hatillo","id":7333},
{"institution":"Dewey University-Manati","id":7334},
{"institution":"Sanford-Brown College-Skokie","id":7335},
{"institution":"Baltimore School of Massage-York","id":7336},
{"institution":"Allen School-Phoenix","id":7337},
{"institution":"Oklahoma Technical College","id":7338},
{"institution":"Midwest Technical Institute-Moline","id":7339},
{"institution":"Star Career Academy-Clifton","id":7340},
{"institution":"Unitech Training Academy-Alexandria","id":7341},
{"institution":"Unitech Training Academy-Lake Charles","id":7342},
{"institution":"Aveda Institute-Los Angeles","id":7343},
{"institution":"Career Quest Learning Center-Kalamazoo","id":7344},
{"institution":"Buchanan Beauty College","id":7345},
{"institution":"WellSpring School of Allied Health-Lawrence","id":7346},
{"institution":"The Art Institute of Wisconsin","id":7347},
{"institution":"Brown Mackie College-Birmingham","id":7348},
{"institution":"Regina's College of Beauty","id":7349},
{"institution":"New York Film Academy","id":7350},
{"institution":"Manuel and Theresa's School of Hair Design-Victoria","id":7351},
{"institution":"Coast Career Institute","id":7352},
{"institution":"Tricoci University of Beauty Culture-Chicago NE","id":7353},
{"institution":"ITT Technical Institute-Bradenton","id":7354},
{"institution":"ITT Technical Institute-Phoenix West","id":7355},
{"institution":"ITT Technical Institute-Brooklyn Center","id":7356},
{"institution":"ITT Technical Institute-Orlando","id":7357},
{"institution":"ITT Technical Institute-Waco","id":7358},
{"institution":"ITT Technical Institute-Myrtle Beach","id":7359},
{"institution":"University of Phoenix-Knoxville Campus","id":7360},
{"institution":"Azusa Pacific Online University","id":7361},
{"institution":"Minneapolis Media Institute","id":7362},
{"institution":"College America-Phoenix","id":7363},
{"institution":"CollegeAmerica-Stevens Henager College","id":7364},
{"institution":"Avalon School of Cosmetology-Phoenix","id":7365},
{"institution":"Avalon School of Cosmetology-Layton","id":7366},
{"institution":"Hair Design Institute","id":7367},
{"institution":"Hair Design Institute","id":7368},
{"institution":"University of Phoenix-Baton Rouge Campus","id":7369},
{"institution":"University of Phoenix-Lafayette Campus","id":7370},
{"institution":"University of Phoenix-Shreveport-Bossier Campus","id":7371},
{"institution":"All-State Career School-Allied Health Campus","id":7372},
{"institution":"Aveda Institute-Des Moines","id":7373},
{"institution":"Fortis Institute-Lawrenceville","id":7374},
{"institution":"Relay Graduate School of Education","id":7375},
{"institution":"Brown Mackie College-Oklahoma City","id":7376},
{"institution":"Sanford-Brown College-Austin","id":7377},
{"institution":"Miller-Motte Technical College-Macon","id":7378},
{"institution":"Miller-Motte Technical College-Gulfport","id":7379},
{"institution":"International Academy of Medical Reflexology","id":7380},
{"institution":"International Culinary Center-California","id":7381},
{"institution":"South University�Savannah Online","id":7382},
{"institution":"Fortis College-Montgomery","id":7383},
{"institution":"San Joaquin Valley College-Temecula","id":7384},
{"institution":"Rio Grande Bible Institute","id":7385},
{"institution":"Miller-Motte Technical College-Roanoke","id":7386},
{"institution":"Whitworth University-Adult Degree Programs","id":7387},
{"institution":"The Santa Barbara and Ventura Colleges of Law�Santa Barbara","id":7388},
{"institution":"The University of America","id":7389},
{"institution":"Kaplan College-Chesapeake","id":7390},
{"institution":"American Broadcasting School-Online Program","id":7391},
{"institution":"Springfield College-School of Human Services","id":7392},
{"institution":"Marian Health Careers Center-Van Nuys Campus","id":7393},
{"institution":"Brown College-Brooklyn Center","id":7394},
{"institution":"Milan Institute-Las Vegas","id":7395},
{"institution":"Williamson Free School of Mechanical Trades","id":7396},
{"institution":"Virginia College-Savannah","id":7397},
{"institution":"Virginia College-Columbus","id":7398},
{"institution":"Donna's Academy of Hair Design","id":7399},
{"institution":"New York School of Esthetics & Day Spa","id":7400},
{"institution":"Panache Academy of Beauty","id":7401},
{"institution":"Finger Lakes Health College of Nursing","id":7402},
{"institution":"ASI Career Institute","id":7403},
{"institution":"Chicago School for Piano Technology","id":7404},
{"institution":"Midwest Technical Institute-Ridgeland","id":7405},
{"institution":"Christine Valmy International School of Esthetics & Cosmetology","id":7406},
{"institution":"City College-Hollywood","id":7407},
{"institution":"Paul Mitchell the School-Arlington","id":7408},
{"institution":"Rocky Mountain University of Health Professions","id":7409},
{"institution":"MediaTech Institute-Oceanside","id":7410},
{"institution":"Christie's Education","id":7411},
{"institution":"Prince Institute-Great Lakes","id":7412},
{"institution":"Palmetto Beauty School","id":7413},
{"institution":"Paul Mitchell the School-Fort Myers","id":7414},
{"institution":"Paul Mitchell The School-Raleigh","id":7415},
{"institution":"Stella and Charles Guttman Community College","id":7416},
{"institution":"Lil Lou's Barber College","id":7417},
{"institution":"Bryan University","id":7418},
{"institution":"Virginia College-Tulsa","id":7419},
{"institution":"Criswell College","id":7420},
{"institution":"Virginia College-Knoxville","id":7421},
{"institution":"Columbia Academy of Cosmetology","id":7422},
{"institution":"Academy of Couture Art","id":7423},
{"institution":"Global Institute","id":7424},
{"institution":"Shear Learning Academy of Cosmetology","id":7425},
{"institution":"University of Cosmetology Arts & Sciences-La Joya","id":7426},
{"institution":"Preferred College of Nursing-Van Nuys","id":7427},
{"institution":"Bryant & Stratton College-Akron","id":7428},
{"institution":"Annenberg School of Nursing","id":7429},
{"institution":"American Medical Academy","id":7430},
{"institution":"Tooele Applied Technology College","id":7431},
{"institution":"Chamberlain College of Nursing-Georgia","id":7432},
{"institution":"Chamberlain College of Nursing-Indiana","id":7433},
{"institution":"Carrington College-Mesquite","id":7434},
{"institution":"Universidad Internacional Iberoamericana","id":7435},
{"institution":"Sessions College for Professional Design","id":7436},
{"institution":"Southeastern Technical Careers Institute Inc","id":7437},
{"institution":"Virginia College-Shreveport Bossier City","id":7438},
{"institution":"Strayer University-Wisconsin","id":7439},
{"institution":"Strayer University-Indiana","id":7440},
{"institution":"Tulsa Welding School-Jacksonville","id":7441},
{"institution":"Herzing University","id":7442},
{"institution":"Regency Beauty Institute-Hoover","id":7443},
{"institution":"Regency Beauty Institute-San Antonio","id":7444},
{"institution":"Regency Beauty Institute-Carrollwood","id":7445},
{"institution":"Regency Beauty Institute-Clearwater","id":7446},
{"institution":"Regency Beauty Institute-Newport News","id":7447},
{"institution":"Regency Beauty Institute-Lewisville","id":7448},
{"institution":"Regency Beauty Institute-Dallas","id":7449},
{"institution":"Regency Beauty Institute-Plano","id":7450},
{"institution":"Empire Beauty School-Virginia Beach","id":7451},
{"institution":"Sanford-Brown College-Columbus","id":7452},
{"institution":"Ross Medical Education Center-Morgantown","id":7453},
{"institution":"Paul Mitchell the School-Ogden","id":7454},
{"institution":"CollegeAmerica-Colorado Springs South","id":7455},
{"institution":"Everest Institute-Bensalem","id":7456},
{"institution":"Golf Academy of America-Farmers Branch","id":7457},
{"institution":"Utah College of Massage Therapy-Dallas","id":7458},
{"institution":"Miami Ad School-New York","id":7459},
{"institution":"Lacy Cosmetology School-West Ashley","id":7460},
{"institution":"Dade Medical College-West Palm Beach","id":7461},
{"institution":"Dade Medical College-Jacksonville","id":7462},
{"institution":"WestMed College","id":7463},
{"institution":"Larry's Barber College","id":7464},
{"institution":"Futura Career Institute","id":7465},
{"institution":"Access Careers","id":7466},
{"institution":"Flair Beauty College","id":7467},
{"institution":"Eternity Cosmetology School","id":7468},
{"institution":"Tri-State Institute of Hair Design","id":7469},
{"institution":"Vogue College of Cosmetology-San Antonio Fredericksburg","id":7470},
{"institution":"Vogue College of Cosmetology","id":7471},
{"institution":"Tomorrow's Image Barber Academy of Virginia","id":7472},
{"institution":"Excel Learning Center","id":7473},
{"institution":"College of International Esthetics Inc","id":7474},
{"institution":"Medical Allied Career Center","id":7475},
{"institution":"Bais HaMedrash and Mesivta of Baltimore","id":7476},
{"institution":"Long Island Barber Institute","id":7477},
{"institution":"Toni & Guy Hairdressing Academy-Toledo","id":7478},
{"institution":"American Institute of Medical Sonography","id":7479},
{"institution":"Destination Academy for Spa and Salon Professionals","id":7480},
{"institution":"Classic Cooking Academy","id":7481},
{"institution":"School of Court Reporting","id":7482},
{"institution":"Curve Metric School of Hair Design","id":7483},
{"institution":"Athena Career Academy","id":7484},
{"institution":"Yeshiva Gedolah Zichron Leyma","id":7485},
{"institution":"The Barber School","id":7486},
{"institution":"Be'er Yaakov Talmudic Seminary","id":7487},
{"institution":"RGV Careers","id":7488},
{"institution":"Alexander Academy","id":7489},
{"institution":"LeMelange Academy of Hair","id":7490},
{"institution":"Aveda Institute-Tucson","id":7491},
{"institution":"Velvet Touch Academy of Cosmetology","id":7492},
{"institution":"Ambiance Beauty & Barber Academy Inc","id":7493},
{"institution":"Diamond Beauty College","id":7494},
{"institution":"NCP College of Nursing-South San Francisco","id":7495},
{"institution":"University Academy of Hair Design","id":7496},
{"institution":"Florida Institute of Recording Sound and Technology","id":7497},
{"institution":"New Dimensions Beauty Academy Inc","id":7498},
{"institution":"Jolei's Hair Institute","id":7499},
{"institution":"Paul Mitchell the School-Reno","id":7500},
{"institution":"Boise Barber College","id":7501},
{"institution":"Austin Kade Academy","id":7502},
{"institution":"Aesthetic Science Institute","id":7503},
{"institution":"Elaine Sterling Institute","id":7504},
{"institution":"Wade Gordon Hairdressing Academy","id":7505},
{"institution":"Mitsu Sato Hair Academy","id":7506},
{"institution":"SAE Institute of Technology-Los Angeles","id":7507},
{"institution":"Florida Vocational Institute","id":7508},
{"institution":"SAE Institute of Technology-Atlanta","id":7509},
{"institution":"Academy di Firenze","id":7510},
{"institution":"SAE Institute of Technology-Miami","id":7511},
{"institution":"Colorado State University-Global Campus","id":7512},
{"institution":"Paul Mitchell the School-Overland Park","id":7513},
{"institution":"Medspa Academies","id":7514},
{"institution":"Brighton Institute of Cosmetology","id":7515},
{"institution":"Strayer University-Minnesota","id":7516},
{"institution":"Strayer University-Illinois","id":7517},
{"institution":"West Coast University-Dallas","id":7518},
{"institution":"Stevens-Henager College-St George","id":7519},
{"institution":"National American University-Bellevue","id":7520},
{"institution":"National American University-Burnsville","id":7521},
{"institution":"National American University-Mesquite","id":7522},
{"institution":"Empire Beauty School-Savannah","id":7523},
{"institution":"Ogle School Hair Skin Nails-Denton","id":7524},
{"institution":"Empire Beauty School-West Greensboro","id":7525},
{"institution":"California College San Diego","id":7526},
{"institution":"California College San Diego","id":7527},
{"institution":"Old Town Barber College-Topeka","id":7528},
{"institution":"Jolie Hair and Beauty Academy-Northfield","id":7529},
{"institution":"Hays Academy of Hair Design","id":7530},
{"institution":"Bellus Academy","id":7531},
{"institution":"Cortiva Institute-Seattle","id":7532},
{"institution":"MotoRing Technical Training Institute","id":7533},
{"institution":"Leon Studio One School of Hair Design & Career Training Center","id":7534},
{"institution":"Columbia College","id":7535},
{"institution":"NCP College of Nursing-Hayward","id":7536},
{"institution":"ITT Technical Institute-Philadelphia","id":7537},
{"institution":"ITT Technical Institute-Marlton","id":7538},
{"institution":"ITT Technical Institute-Germantown","id":7539},
{"institution":"ITT Technical Institute-Grand Rapids","id":7540},
{"institution":"ITT Technical Institute-Southfield","id":7541},
{"institution":"ITT Technical Institute-West Palm Beach","id":7542},
{"institution":"ITT Technical Institute-Salem","id":7543},
{"institution":"ITT Technical Institute-Deerfield Beach","id":7544},
{"institution":"ITT Technical Institute-Indianapolis East","id":7545},
{"institution":"ITT Technical Institute-Douglasville","id":7546},
{"institution":"ITT Technical Institute-Overland Park","id":7547},
{"institution":"ITT Technical Institute-Springfield","id":7548},
{"institution":"ITT Technical Institute-West Chester","id":7549},
{"institution":"University of Phoenix-McAllen Campus","id":7550},
{"institution":"Pennsylvania State University-World Campus","id":7551},
{"institution":"Medical Career Institute","id":7552},
{"institution":"Advance Beauty Techs Academy","id":7553},
{"institution":"Longs Peak Academy","id":7554},
{"institution":"Brand College","id":7555},
{"institution":"Hinton Barber College","id":7556},
{"institution":"Advanced Career Institute","id":7557},
{"institution":"Digital Film Academy","id":7558},
{"institution":"MyComputerCareer.com-Raleigh","id":7559},
{"institution":"Kaplan University-Augusta Campus","id":7560},
{"institution":"South University-Austin","id":7561},
{"institution":"South University-Cleveland","id":7562},
{"institution":"Bryant & Stratton College-Online","id":7563},
{"institution":"Virginia College-Florence","id":7564},
{"institution":"Ogle School Hair Skin Nails-San Antonio","id":7565},
{"institution":"Elizabethtown College School of Continuing and Professional Studies","id":7566},
{"institution":"Fortis College-Cutler Bay","id":7567},
{"institution":"Anamarc College-El Paso East","id":7568},
{"institution":"Ideal Beauty Academy","id":7569},
{"institution":"Minnesota School of Cosmetology-Plymouth Campus","id":7570},
{"institution":"Mercyhurst University-North East Campus","id":7571},
{"institution":"Warner Pacific College Adult Degree Program","id":7572},
{"institution":"Platt College-Riverside","id":7573},
{"institution":"Savannah Law School","id":7574},
{"institution":"Empire Beauty School-Glen Burnie","id":7575},
{"institution":"Everest College-Woodbridge","id":7576},
{"institution":"Everest College-Kansas City","id":7577},
{"institution":"International School of Cosmetology-Toni & Guy Hairdressing Academy","id":7578},
{"institution":"InterCoast Career Institute-Salem","id":7579},
{"institution":"International Sports Sciences Association","id":7580},
{"institution":"Abraham Lincoln University","id":7581},
{"institution":"National College-Canton","id":7582},
{"institution":"Shiloh University","id":7583},
{"institution":"Court Reporting Institute of Arlington","id":7584},
{"institution":"Vatterott College-Fairview Heights","id":7585},
{"institution":"Ross Medical Education Center-Dayton","id":7586},
{"institution":"Ross Medical Education Center-Ontario","id":7587},
{"institution":"Ross Medical Education Center-Bowling Green","id":7588},
{"institution":"Florida Institute of Technology-Online","id":7589},
{"institution":"Rasmussen College-Kansas","id":7590},
{"institution":"NewCourtland Education Center","id":7591},
{"institution":"Columbia Institute","id":7592},
{"institution":"Wadsworth Center-NY State Dept of Health","id":7593},
{"institution":"EMS Training Institute","id":7594},
{"institution":"School of Missionary Aviation Technology","id":7595},
{"institution":"Ace Cosmetology and Barber Training Center","id":7596},
{"institution":"Salt Lake Baptist College","id":7597},
{"institution":"Manthano Christian College","id":7598},
{"institution":"California Miramar University","id":7599},
{"institution":"Rocky Vista University","id":7600},
{"institution":"Virginia College-Greensboro","id":7601},
{"institution":"The College of Health Care Professions-Dallas","id":7602},
{"institution":"The College of Health Care Professions-Fort Worth","id":7603},
{"institution":"Shear Finesse Hairstyling Academy","id":7604},
{"institution":"Redstone College-Denver East","id":7605},
{"institution":"Academy of Salon Professionals","id":7606},
{"institution":"CyberTex Institute of Technology","id":7607},
{"institution":"UEI College-Santa Cruz","id":7608},
{"institution":"New England Tractor Trailer Training School of CT-Bridgeport","id":7609},
{"institution":"Gemini School of Visual Arts & Communication","id":7610},
{"institution":"Paul Mitchell the School-Woodbridge","id":7611},
{"institution":"College of the Muscogee Nation","id":7612},
{"institution":"The Salon Professional Academy-Huntsville","id":7613},
{"institution":"Midwives College of Utah","id":7614},
{"institution":"Aspen Beauty Academy of Laurel","id":7615},
{"institution":"M T Training Center","id":7616},
{"institution":"Berks Career & Technology Center","id":7617},
{"institution":"Future Generations Graduate School","id":7618},
{"institution":"Carthage R9 School District-Carthage Technical Center","id":7619},
{"institution":"Grace Mission University","id":7620},
{"institution":"World A Cuts Barber Institute","id":7621},
{"institution":"Photographic Center Northwest","id":7622},
{"institution":"PCCTI IT and Healthcare","id":7623},
{"institution":"Sotheby's Institute of Art-NY","id":7624},
{"institution":"Top Nails & Hair Beauty School","id":7625},
{"institution":"Radians College","id":7626},
{"institution":"Santa Ana Beauty College","id":7627},
{"institution":"Shepherds College","id":7628},
{"institution":"Woodruff Medical Training and Testing","id":7629},
{"institution":"Helms College","id":7630},
{"institution":"American Academy of Personal Training","id":7631},
{"institution":"Technology Center","id":7632},
{"institution":"Ukiah Adult School","id":7633},
{"institution":"Riverside County Office of Education","id":7634},
{"institution":"Institute of Health Sciences","id":7635},
{"institution":"Mid-America Baptist Theological Seminary","id":7636},
{"institution":"Mid-South Christian College","id":7637},
{"institution":"Advanced Computing Institute","id":7638},
{"institution":"New York Institute of Beauty","id":7639},
{"institution":"Ultrasound Medical Institute","id":7640},
{"institution":"Beautiful You School of Nail Technology","id":7641},
{"institution":"Harmon's Beauty School","id":7642},
{"institution":"Xavier College School of Nursing","id":7643},
{"institution":"Lawrence & Company College of Cosmetology","id":7644},
{"institution":"Cactus Academy","id":7645},
{"institution":"Kaizen Beauty Academy","id":7646},
{"institution":"Southern Texas Careers Academy","id":7647},
{"institution":"Sharp Edgez Barber Institute","id":7648},
{"institution":"Salon Professional Academy","id":7649},
{"institution":"Cosmotech School of Cosmetology","id":7650},
{"institution":"Prestige Health & Beauty Sciences Academy","id":7651},
{"institution":"Grace College of Barbering","id":7652},
{"institution":"California Career Institute","id":7653},
{"institution":"Cosmo Factory Cosmetology Academy","id":7654},
{"institution":"Grace School of Theology","id":7655},
{"institution":"Yeshiva Gedolah Kesser Torah","id":7656},
{"institution":"Universal Training Institute","id":7657},
{"institution":"Yeshiva Yesodei Hatorah","id":7658},
{"institution":"Rizzieri Institute","id":7659},
{"institution":"Bonnie Joseph Academy of Cosmetology and Barbering","id":7660},
{"institution":"Healthcare Training Institute","id":7661},
{"institution":"W Academy of Salon and Spa","id":7662},
{"institution":"Boca Beauty Academy","id":7663},
{"institution":"United Medical and Business Institute","id":7664},
{"institution":"Paul Mitchell the School-Jersey Shore","id":7665},
{"institution":"City Pointe Beauty Academy","id":7666},
{"institution":"Chrysm Institute of Esthetics","id":7667},
{"institution":"Sanford-Burnham Medical Research Institute","id":7668},
{"institution":"National Personal Training Institute of Columbus","id":7669},
{"institution":"Bly's School of Cosmetology","id":7670},
{"institution":"Toni & Guy Hairdressing Academy-Manteca","id":7671},
{"institution":"Belle Academy of Cosmetology","id":7672},
{"institution":"Strayer University-Missouri","id":7673},
{"institution":"Dorsey Business Schools-Lansing","id":7674},
{"institution":"ITT Technical Institute-Pensacola","id":7675},
{"institution":"ITT Technical Institute-San Antonio East","id":7676},
{"institution":"MedTech Institute-Orlando Campus","id":7677},
{"institution":"Tulsa Welding School-Tulsa","id":7678},
{"institution":"Empire Beauty School-Augusta","id":7679},
{"institution":"South University-High Point","id":7680},
{"institution":"Empire Beauty School-Vernon Hills","id":7681},
{"institution":"Empire Beauty School-Stone Park","id":7682},
{"institution":"Court Reporting Institute of St Louis","id":7683},
{"institution":"San Joaquin Valley College-Lancaster","id":7684},
{"institution":"San Joaquin Valley College-San Diego","id":7685},
{"institution":"Ohio Business College-Dayton","id":7686},
{"institution":"Empire Beauty School-Rochester","id":7687},
{"institution":"American Career College-Long Beach","id":7688},
{"institution":"Ross Medical Education Center-Kokomo","id":7689},
{"institution":"Ross Medical Education Center-Erlanger","id":7690},
{"institution":"Ross Medical Education Center-Charleston","id":7691},
{"institution":"L'Ecole Culinaire-Kansas City","id":7692},
{"institution":"Utah College of Massage Therapy-Houston","id":7693},
{"institution":"InterCoast Colleges-Fairfield","id":7694},
{"institution":"Vatterott College-ex'treme Institute by Nelly-St Louis","id":7695},
{"institution":"Personal Fitness & Nutrition Center","id":7696},
{"institution":"Southern Careers Institute-San Antonio","id":7697},
{"institution":"College of Business and Technology-Miami Gardens","id":7698},
{"institution":"Milan Institute of Cosmetology-Nampa","id":7699},
{"institution":"Milan Institute-Merced","id":7700},
{"institution":"Midwest Technical Institute-Springfield","id":7701},
{"institution":"Finger Lakes School of Massage","id":7702},
{"institution":"Georgia Regents University","id":7703},
{"institution":"Middle Georgia State College","id":7704},
{"institution":"Trenz Beauty Academy","id":7705},
{"institution":"Paul Mitchell the School-Denver","id":7706},
{"institution":"Academy of Interactive Entertainment","id":7707},
{"institution":"Arrojo Cosmetology School","id":7708},
{"institution":"Cosmetology Academy of Texarkana","id":7709},
{"institution":"Virginia Baptist College","id":7710},
{"institution":"Lynnes Welding Training","id":7711},
{"institution":"Aviation Institute of Maintenance-Las Vegas","id":7712},
{"institution":"Tulsa Technology Center-Owasso Campus","id":7713},
{"institution":"Tulsa Technology Center-Sand Springs Campus","id":7714},
{"institution":"National American University-Austin South","id":7715},
{"institution":"National American University-Lewisville","id":7716},
{"institution":"National American University-Georgetown","id":7717},
{"institution":"National American University-Richardson","id":7718},
{"institution":"National American University-Rochester","id":7719},
{"institution":"National American University-Weldon Spring","id":7720},
{"institution":"National American University-Wichita West","id":7721},
{"institution":"National American University-Indianapolis","id":7722},
{"institution":"National American University-Tigard","id":7723},
{"institution":"The Art Institute of St Louis","id":7724},
{"institution":"DeVry College of New York","id":7725},
{"institution":"DeVry University-Arizona","id":7726},
{"institution":"DeVry University-California","id":7727},
{"institution":"DeVry University-Colorado","id":7728},
{"institution":"DeVry University-Florida","id":7729},
{"institution":"DeVry University-Georgia","id":7730},
{"institution":"DeVry University-Illinois","id":7731},
{"institution":"DeVry University-Indiana","id":7732},
{"institution":"DeVry University-Kentucky","id":7733},
{"institution":"DeVry University-Maryland","id":7734},
{"institution":"DeVry University-Michigan","id":7735},
{"institution":"DeVry University-Minnesota","id":7736},
{"institution":"DeVry University-Missouri","id":7737},
{"institution":"DeVry University-Nevada","id":7738},
{"institution":"DeVry University-New Jersey","id":7739},
{"institution":"DeVry University-North Carolina","id":7740},
{"institution":"DeVry University-Ohio","id":7741},
{"institution":"DeVry University-Oklahoma","id":7742},
{"institution":"DeVry University-Oregon","id":7743},
{"institution":"DeVry University-Pennsylvania","id":7744},
{"institution":"DeVry University-Tennessee","id":7745},
{"institution":"DeVry University-Texas","id":7746},
{"institution":"DeVry University-Utah","id":7747},
{"institution":"DeVry University-Virginia","id":7748},
{"institution":"DeVry University-Washington","id":7749},
{"institution":"DeVry University-Wisconsin","id":7750},
{"institution":"University of North Georgia","id":7751},
{"institution":"South Georgia State College","id":7752},
{"institution":"Northeastern University Global Network","id":7753},
{"institution":"Georgia Military College-Distance Learning Campuses","id":7754},
{"institution":"Florida Polytechnic University","id":7755},
{"institution":"Brown Mackie College-Dallas","id":7756},
{"institution":"American Institute-Toms River","id":7757},
{"institution":"Texas Covenant Education","id":7758},
{"institution":"Arizona College-Mesa","id":7759},
{"institution":"American College of Healthcare","id":7760},
{"institution":"Antioch College","id":7761},
{"institution":"Texas A & M University-Central Texas","id":7762},
{"institution":"Central Georgia Technical College","id":7763},
{"institution":"Barber School of Pittsburgh","id":7764},
{"institution":"EDMC Central Administrative Office","id":7765},
{"institution":"The Salon Professional Academy-South Plainfield","id":7766},
{"institution":"Arizona State University-Skysong","id":7767},
{"institution":"Louisiana Delta Community College","id":7768}]