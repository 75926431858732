import {Link} from 'react-router-dom'
import './vetsource.css';

export function Vetsource(props){

    let vetsourceTypeClassName = "vetsource-type ";
    let hasVetsourceType = true;

    switch (props.vetsourceType) {
        case "Event":
            vetsourceTypeClassName += "event";
            break;
        case "Facility":
            vetsourceTypeClassName += "facility";
            break;
        case "Resource":
            vetsourceTypeClassName += "resource";
            break;
        default:
            hasVetsourceType = false;
    }

    return(
        <Link className = "vetsource" to="/details" target="_blank" rel="noopener noreferrer">
            <div className = "top-left">
            {hasVetsourceType && <p className={vetsourceTypeClassName}>{props.vetsourceType}</p>} 
                <h2>{props.title}</h2>
                <p className='date'>{props.date}</p>
                <p className='address'>{props.address}</p>        
            </div>
            <div className = "bottom">
                <p>{props.description}</p>
                {/* <button>See details</button> */}
            </div>
            <div className = "top-right">
                <div className='image-thumbnail' style={{ backgroundImage: `url(${props.picture})` }}></div>
                {/* <img className = "vetpic"
                    src = {props.picture}
                />     */}
            </div>
        </Link>
    );
}