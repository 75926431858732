import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import {cities} from '../../../../util/data/cities';
import * as filterValuesUtils from '../../../../util/filterValuesUtils';

export function CitiesFilters(props){

    const handleOnSearch = (string, results) => {
        // onSearch will have as the first callback parameter
        // the string searched and for the second the results.
        console.log(string, results)
    }

    const handleOnHover = (result) => {
        // the item hovered
        console.log(result)
    }

    const handleOnSelect = (item) => {
        let newFilterValues = filterValuesUtils.addCity(item, props.activeFilterValues);
        props.setActiveFilterValues(newFilterValues);
    }

    const handleOnFocus = () => {
        console.log('Focused')
    }

    const formatCity = (item) => {
        return (
            <>
            <p style={{ display: 'block', textAlign: 'left' }}>{item.city}, {item.state}</p>
            </>
        )
    }

    const removeActiveFilter = (filterValue) => {
        console.log("Remove filter " + filterValue);
        const indexToRemove = props.activeFilterValues.cities.indexOf(filterValue);
        let newFilterValues = JSON.parse(JSON.stringify(props.activeFilterValues));
        newFilterValues.cities.splice(indexToRemove, 1);
        props.setActiveFilterValues(newFilterValues);
      };

    const activeCityButtons = props.activeFilterValues.cities.map((filterValue) =>
        <button key={filterValue} className = "activeFilterBubble" onClick={() => removeActiveFilter(filterValue)}> {filterValue} </button>
    );

    return(
        <>
            <p>Search for city name:</p>
            <ReactSearchAutocomplete
                fuseOptions={{ keys: ["city"] }}
                resultStringKeyName="cityState"
                items={cities}
                onSearch={handleOnSearch}
                onHover={handleOnHover}
                onSelect={handleOnSelect}
                onFocus={handleOnFocus}
                autoFocus
                formatResult={formatCity}
            />

            <ul className='activeSearchFilters'>
                {activeCityButtons}
            </ul>
        </>
    );
}