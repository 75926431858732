import '../../sidebar/sidebar.css';
import {states} from '../../../../util/data/states';



export function StatesFilters(props){

    const toggleFilter = (checkboxId) => {
        console.log("Clicked");
        console.log(document.getElementById(checkboxId).checked);
        //TODO: modern js has a better way to deep copy arrays, need to find it again
        let newFilterValues = JSON.parse(JSON.stringify(props.activeFilterValues));
        const checkboxText = document.getElementById(checkboxId).parentElement.innerText;
        if (document.getElementById(checkboxId).checked) {
            newFilterValues.states.push(checkboxText);
        } else {
            const indexToRemove = props.activeFilterValues.states.indexOf(checkboxText);
            newFilterValues.states.splice(indexToRemove, 1);
        }
        props.setActiveFilterValues(newFilterValues);
    }

    const isFilterApplied = (filterName) => {
        if (props.activeFilterValues.states.includes(filterName)){
            return true;
        }
        return false;
   }

    const stateKeys = Object.keys(states);
    const filterStateKeys = stateKeys.map((filterValue) =>
        <label key = {filterValue} className='container'> {filterValue} 
            <input
                type="checkbox"
                onChange={() => toggleFilter(filterValue + "checkbox")}
                id= {filterValue + "checkbox"}
                checked={isFilterApplied(filterValue)}
            />
            <span className="checkmark"></span>
        </label>
    );

    return(
        <>
            {filterStateKeys}
        </>
    );
}