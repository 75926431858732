import '../../sidebar/sidebar.css';
import {useState} from 'react'
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRange } from 'react-date-range';

export function DateFilters(props){
    const [state, setState] = useState([
        {
          startDate: new Date(),
          endDate: null,
          key: 'selection'
        }
      ]);

    const setDate = (item) => {
        console.log("-------------This is the Date Array!-----------------");
        console.log(item);
        setState([item.selection]);
        let newFilterValues = JSON.parse(JSON.stringify(props.activeFilterValues));
        newFilterValues.startDate = item.selection.startDate;
        newFilterValues.endDate = item.selection.endDate;
        props.setActiveFilterValues(newFilterValues);
    }

    return(
        <>
            <DateRange
                editableDateInputs={true}
                onChange={item => setDate(item)}
                moveRangeOnFirstSelection={false}
                ranges={state}
                className='calendarStyle'
            /> 
        </>
    );
}