import { SideBar } from "../sidebar/SideBar";
import { useState } from "react";
import './filterbar.css';
import * as sortOrderConstants from '../filterbar/fileWithConstants';

export function Filterbar(props){
  const [filterType, setFilterType] = useState("");
  const filterBtn = function(filterBtn){
    setFilterType(filterBtn);
    document.getElementById("sideBarFilterContainerId").classList.remove("closeSideBar");
    console.log(filterType);
  }

  const removeFilterFromArray = (filterValue, filterArrayName) => {
    console.log("Remove filter " + filterValue);
    const indexToRemove = props.activeFilterValues[filterArrayName].indexOf(filterValue);
    let newFilterValues = JSON.parse(JSON.stringify(props.activeFilterValues));
    newFilterValues[filterArrayName].splice(indexToRemove, 1);
    props.setActiveFilterValues(newFilterValues);
  }

  const removeSortActiveFilter = () => {
    console.log("Remove sort filter");
    let newFilterValues = JSON.parse(JSON.stringify(props.activeFilterValues));
    newFilterValues.sortOrder = sortOrderConstants.SORTORDER_RECENTLY_UPDATED;
    props.setActiveFilterValues(newFilterValues);
  }

  const removeDateActiveFilter = () => {
    console.log("------------------- Temporary Function ------------------------------"); // Delete write I write appropraite function.
    console.log("Remove date filter");
    let newFilterValues = JSON.parse(JSON.stringify(props.activeFilterValues));
    newFilterValues.startDate = "";
    newFilterValues.endDate = "";
    props.setActiveFilterValues(newFilterValues);
  }

  let dateFinal = (startDateUneditied, endDateUneditied) => {

    let startDate = startDateUneditied.substr(0,16);
    let endDate = endDateUneditied.substr(0,16);

    if (startDate.localeCompare(endDate) === 0){
      return startDate;
    }else {
      return startDate + "to " + endDate;
    }
  }

  function getDateButton(){
    if ((props.activeFilterValues.startDate === "") && (props.activeFilterValues.endDate === "")){
      return <></>;
    }else{
      return <button key={"dateFilterBubble"} className= "activeFilterBubble" onClick={() => removeDateActiveFilter()}> {dateFinal(props.activeFilterValues.startDate.toString(), props.activeFilterValues.endDate.toString())} </button>;
    } 
  }

  function getSortButton(){
    if (props.activeFilterValues.sortOrder === sortOrderConstants.SORTORDER_RECENTLY_UPDATED){
      return <></>;
    }else{
      return <button key={"sortFilterBubble"} className= "activeFilterBubble" onClick={() => removeSortActiveFilter()}> {props.activeFilterValues.sortOrder} </button>;
    } 
  }

  const activeStateButtons = props.activeFilterValues.states.map((filterValue) =>
    <button key={filterValue} className = "activeFilterBubble" onClick={() => removeFilterFromArray(filterValue, "states")}> {filterValue} </button>
  );

  const activeCityButtons = props.activeFilterValues.cities.map((filterValue) =>
    <button key={filterValue} className = "activeFilterBubble" onClick={() => removeFilterFromArray(filterValue, "cities")}> {filterValue} </button>
  );

  const activeUniversityButtons = props.activeFilterValues.institutions.map((filterValue) =>
    <button key={filterValue} className = "activeFilterBubble" onClick={() => removeFilterFromArray(filterValue, "institutions")}> {filterValue} </button>
  );

  const activeCountyButtons = props.activeFilterValues.counties.map((filterValue) =>
  <button key={filterValue} className = "activeFilterBubble" onClick={() => removeFilterFromArray(filterValue, "counties")}> {filterValue} </button>
  );

  const activeVetsourceButtons = props.activeFilterValues.vetsources.map((filterValue) =>
  <button key={filterValue} className = "activeFilterBubble" onClick={() => removeFilterFromArray(filterValue, "vetsources")}> {filterValue} </button>
  );

  const activeSortButtons = getSortButton();
  
  const activeDateButtons = getDateButton();

  //TODO: This should be true or false depending on whether any filters are active
  let hasActiveFilters = document.getElementById("activeFiltersContainer")?.childElementCount;

  return(
    <>
      <div className={"filterBarContainer" + (hasActiveFilters ? "" : " noActiveFilters")}>

        <div className="filterOptions scrollMenu">
          <button onClick={() => filterBtn("Sort")} className = "filterOptionBubble">Sort</button>
          <button onClick={() => filterBtn("Date")} className = "filterOptionBubble">Date</button>
          <button onClick={() => filterBtn("Vetsource")} className = "filterOptionBubble">Vetsource Type</button>
          <button onClick={() => filterBtn("University")} className = "filterOptionBubble">University</button>
          <button onClick={() => filterBtn("States")} className = "filterOptionBubble">States</button>
          <button onClick={() => filterBtn("Cities")} className = "filterOptionBubble">Cities</button>
          <button onClick={() => filterBtn("County")} className = "filterOptionBubble">County</button>
        </div>

        {/* <div className="hamburgerStack">☰</div> */}
        <div id="activeFiltersContainer" className="activeFilters scrollMenu">
          {activeSortButtons}
          {activeDateButtons}
          {activeVetsourceButtons}
          {activeUniversityButtons}
          {activeStateButtons}
          {activeCityButtons}
          {activeCountyButtons}
        </div>

      </div>
      <SideBar 
        description = {filterType}
        activeFilterValues = {props.activeFilterValues} 
        setActiveFilterValues = {props.setActiveFilterValues}/>
    </>
      
    
  );
}