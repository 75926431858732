import '../../sidebar/sidebar.css';
import * as sortOrderConstants from '../../filterbar/fileWithConstants';


export function SortOrderFilters(props){
    const toggleFilter = (value) => {
        console.log("Clicked Radio");
        //console.log(document.getElementById(checkboxId).checked);
        //TODO: modern js has a better way to deep copy arrays, need to find it again
        let newFilterValues = JSON.parse(JSON.stringify(props.activeFilterValues)); 

        if (value !== newFilterValues.sortOrder) {
            newFilterValues.sortOrder = value;
        }
        props.setActiveFilterValues(newFilterValues);
    }

    const sortRadioButtons = sortOrderConstants.SORTORDER_ARRAY.map((sortOrderName) => {
        return <label key = {sortOrderName} className='container'>
            <input
                type='radio'
                name='react-radio-btn'
                value = {sortOrderName}
                onClick={() => toggleFilter(sortOrderName)}
            /> {sortOrderName}
        </label>
    });

    return(
        <>
            {sortRadioButtons}
        </>
    );
}