import './MentalHealth.css';
//import {Resource} from '../../components/resource/resource'
//import {Navbar} from '../../components/navbar/navbar'
import {Link} from 'react-router-dom' // Will probably use this as a go back button?

const linkStyle = {
    color: 'royalblue'
  };

function MentalHealth() {

    return (
        <>
            
            <div className='mentalHealthBanner'>
            </div>
            <h6  className='mentalHealthLatinbox'>
                Tu solus non
            </h6>
            <div className='fixed-width-container'>
                <p className='mentalHealthSenctenceLinks'>
                    If you need to talk to someone right now, dial 988 then press 1. <br/><br/> This will take you to the Veteran's
                    Crisis Line. You can also go to the link here {' '}
                        <a href='https://www.veteranscrisisline.net/get-help-now/call/' target='_blank' style={linkStyle} rel="noreferrer" >
                        Veteran's Crisis Line
                        </a>
                        <br/><br/>
                    Here is a organization called "My Veteran Passion". They have a discord link if you'd like to be around
                    other veterans talking.
                    <br/><br/>
                    They also have many other resources, and a great resouce to contact if you're concerned
                    about someone who is a veteran {' '}
                        <a href='https://myveteranpassion.org/' target='_blank' style={linkStyle} rel="noreferrer" >
                        My Veteran Passion (MVP)
                        </a>
                        <br/><br/>
                    If you are concerned about a veteran, but are not one yourself, please visit these resources.
                    <br/><br/>
                    Know you are not alone.
                </p>
            </div>
            <Link className="backToHomeLink" to="/">{'  '}Home Page{' '}</Link>
        </>
    );
}

export default MentalHealth;
