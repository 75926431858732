import {Vetsource} from '../../components/vetsource/Vetsource'
import {Link} from 'react-router-dom'
import './home.css';

const linkStyle = {
  color: 'royalblue'
};

function Home() {
  return (
    <>           
      <h1 className='vetsources-title orange-background'>Vetsources</h1>

      <div className='banner'> 
        <div className='talkNow'>
          <Link to="/mentalHealth" style={linkStyle}>Do you need to talk to someone right now?</Link>
        </div>
      </div>

      <h2 className="featuredHeader">Featured</h2>

      <div className="vetsources-container">
        <Vetsource 
            title="Some Title" 
            address="5555 E Somewhere St" 
            date="08/27/2022"
            description="Some description for this event that has a realistic length to it"
            picture={"https://images.squarespace-cdn.com/content/6114c313c704e77895eae698/1629256490529-T7JWMCQ98PYOOWYSM3ZJ/homeaid-san-deigo-hawthorne-veteran-and-family-resource-center.jpg?format=1500w&content-type=image%2Fjpeg"}
            vetsourceType="Facility"
        />
        <Vetsource 
            title="Some Unusually Long Title To Test Length Limits" 
            address="12399 SE Really Long Street Name for Test Purposes Trwy NW Unit 1" 
            date="08/27/2022"
            description="Some description for this event that has a realistic length to it except actually this description is overly long and even though we probably won't allow the description to be so huge it's going to be anyways for testing purposes."
            picture={"https://images.unsplash.com/photo-1480714378408-67cf0d13bc1b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxjb2xsZWN0aW9uLXBhZ2V8MXwxNjYzMTR8fGVufDB8fHx8&w=1000&q=80"}
            vetsourceType="Event"
        />
        <Vetsource 
            title="Some Title with a realistic length and such" 
            address="5555 E Somewhere St" 
            date="08/27/2022"
            description="Some description for this event that has a realistic length to it, it describes the event in some detail and encourages the reader to click the item to learn more."
            picture={"https://laney.edu/veteran_affairs/wp-content/uploads/sites/147/2020/08/new-website-veterans-day-1.jpg"}
            vetsourceType="Resource"
        />
        <Vetsource 
            title="Some Title" 
            address="5555 E Somewhere St" 
            date="08/27/2022"
            description="Some description for this event that has a realistic length to it"
            picture={"https://images.squarespace-cdn.com/content/6114c313c704e77895eae698/1629256490529-T7JWMCQ98PYOOWYSM3ZJ/homeaid-san-deigo-hawthorne-veteran-and-family-resource-center.jpg?format=1500w&content-type=image%2Fjpeg"}
            vetsourceType="Facility"
        />
      </div>

      <Link className="seeAllEventsLink" to="/search">See All Vetsources</Link>

  </>
  );
}

export default Home;
