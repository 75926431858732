
function Partners() {
    return (
        <>
            
            <h1>Partners Page</h1>
        </>        
    );
  }

export default Partners;
