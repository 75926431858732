export const counties =
[{"county":"Autauga County","state":"Alabama","id":0},
{"county":"Baldwin County","state":"Alabama","id":1},
{"county":"Barbour County","state":"Alabama","id":2},
{"county":"Bibb County","state":"Alabama","id":3},
{"county":"Blount County","state":"Alabama","id":4},
{"county":"Bullock County","state":"Alabama","id":5},
{"county":"Butler County","state":"Alabama","id":6},
{"county":"Calhoun County","state":"Alabama","id":7},
{"county":"Chambers County","state":"Alabama","id":8},
{"county":"Cherokee County","state":"Alabama","id":9},
{"county":"Chilton County","state":"Alabama","id":10},
{"county":"Choctaw County","state":"Alabama","id":11},
{"county":"Clarke County","state":"Alabama","id":12},
{"county":"Clay County","state":"Alabama","id":13},
{"county":"Cleburne County","state":"Alabama","id":14},
{"county":"Coffee County","state":"Alabama","id":15},
{"county":"Colbert County","state":"Alabama","id":16},
{"county":"Conecuh County","state":"Alabama","id":17},
{"county":"Coosa County","state":"Alabama","id":18},
{"county":"Covington County","state":"Alabama","id":19},
{"county":"Crenshaw County","state":"Alabama","id":20},
{"county":"Cullman County","state":"Alabama","id":21},
{"county":"Dale County","state":"Alabama","id":22},
{"county":"Dallas County","state":"Alabama","id":23},
{"county":"DeKalb County","state":"Alabama","id":24},
{"county":"Elmore County","state":"Alabama","id":25},
{"county":"Escambia County","state":"Alabama","id":26},
{"county":"Etowah County","state":"Alabama","id":27},
{"county":"Fayette County","state":"Alabama","id":28},
{"county":"Franklin County","state":"Alabama","id":29},
{"county":"Geneva County","state":"Alabama","id":30},
{"county":"Greene County","state":"Alabama","id":31},
{"county":"Hale County","state":"Alabama","id":32},
{"county":"Henry County","state":"Alabama","id":33},
{"county":"Houston County","state":"Alabama","id":34},
{"county":"Jackson County","state":"Alabama","id":35},
{"county":"Jefferson County","state":"Alabama","id":36},
{"county":"Lamar County","state":"Alabama","id":37},
{"county":"Lauderdale County","state":"Alabama","id":38},
{"county":"Lawrence County","state":"Alabama","id":39},
{"county":"Lee County","state":"Alabama","id":40},
{"county":"Limestone County","state":"Alabama","id":41},
{"county":"Lowndes County","state":"Alabama","id":42},
{"county":"Macon County","state":"Alabama","id":43},
{"county":"Madison County","state":"Alabama","id":44},
{"county":"Marengo County","state":"Alabama","id":45},
{"county":"Marion County","state":"Alabama","id":46},
{"county":"Marshall County","state":"Alabama","id":47},
{"county":"Mobile County","state":"Alabama","id":48},
{"county":"Monroe County","state":"Alabama","id":49},
{"county":"Montgomery County","state":"Alabama","id":50},
{"county":"Morgan County","state":"Alabama","id":51},
{"county":"Perry County","state":"Alabama","id":52},
{"county":"Pickens County","state":"Alabama","id":53},
{"county":"Pike County","state":"Alabama","id":54},
{"county":"Randolph County","state":"Alabama","id":55},
{"county":"Russell County","state":"Alabama","id":56},
{"county":"St. Clair County","state":"Alabama","id":57},
{"county":"Shelby County","state":"Alabama","id":58},
{"county":"Sumter County","state":"Alabama","id":59},
{"county":"Talladega County","state":"Alabama","id":60},
{"county":"Tallapoosa County","state":"Alabama","id":61},
{"county":"Tuscaloosa County","state":"Alabama","id":62},
{"county":"Walker County","state":"Alabama","id":63},
{"county":"Washington County","state":"Alabama","id":64},
{"county":"Wilcox County","state":"Alabama","id":65},
{"county":"Winston County","state":"Alabama","id":66},
{"county":"Aleutians East Borough","state":"Alaska","id":67},
{"county":"Aleutians West Census Area","state":"Alaska","id":68},
{"county":"Anchorage, Municipality of","state":"Alaska","id":69},
{"county":"Bethel Census Area","state":"Alaska","id":70},
{"county":"Bristol Bay Borough","state":"Alaska","id":71},
{"county":"Denali Borough","state":"Alaska","id":72},
{"county":"Dillingham Census Area","state":"Alaska","id":73},
{"county":"Fairbanks North Star Borough","state":"Alaska","id":74},
{"county":"Haines Borough","state":"Alaska","id":75},
{"county":"Hoonah-Angoon Census Area","state":"Alaska","id":76},
{"county":"Juneau, City and Borough","state":"Alaska","id":77},
{"county":"Kenai Peninsula Borough","state":"Alaska","id":78},
{"county":"Ketchikan Gateway Borough","state":"Alaska","id":79},
{"county":"Kodiak Island Borough","state":"Alaska","id":80},
{"county":"Lake and Peninsula Borough","state":"Alaska","id":81},
{"county":"Matanuska-Susitna Borough","state":"Alaska","id":82},
{"county":"Nome Census Area","state":"Alaska","id":83},
{"county":"North Slope Borough","state":"Alaska","id":84},
{"county":"Northwest Arctic Borough","state":"Alaska","id":85},
{"county":"Petersburg Census Area","state":"Alaska","id":86},
{"county":"Prince of Wales-Hyder Census Area","state":"Alaska","id":87},
{"county":"Sitka, City and Borough of","state":"Alaska","id":88},
{"county":"Skagway Borough, Municipality of","state":"Alaska","id":89},
{"county":"Southeast Fairbanks Census Area","state":"Alaska","id":90},
{"county":"Valdez-Cordova Census Area","state":"Alaska","id":91},
{"county":"Wade Hampton Census Area","state":"Alaska","id":92},
{"county":"Wrangell, City and Borough of","state":"Alaska","id":93},
{"county":"Yakutat, City and Borough of","state":"Alaska","id":94},
{"county":"Yukon-Koyukuk Census Area","state":"Alaska","id":95},
{"county":"Apache County","state":"Arizona","id":96},
{"county":"Cochise County","state":"Arizona","id":97},
{"county":"Coconino County","state":"Arizona","id":98},
{"county":"Gila County","state":"Arizona","id":99},
{"county":"Graham County","state":"Arizona","id":100},
{"county":"Greenlee County","state":"Arizona","id":101},
{"county":"La Paz County","state":"Arizona","id":102},
{"county":"Maricopa County","state":"Arizona","id":103},
{"county":"Mohave County","state":"Arizona","id":104},
{"county":"Navajo County","state":"Arizona","id":105},
{"county":"Pima County","state":"Arizona","id":106},
{"county":"Pinal County","state":"Arizona","id":107},
{"county":"Santa Cruz County","state":"Arizona","id":108},
{"county":"Yavapai County","state":"Arizona","id":109},
{"county":"Yuma County","state":"Arizona","id":110},
{"county":"Arkansas County","state":"Arkansas","id":111},
{"county":"Ashley County","state":"Arkansas","id":112},
{"county":"Baxter County","state":"Arkansas","id":113},
{"county":"Benton County","state":"Arkansas","id":114},
{"county":"Boone County","state":"Arkansas","id":115},
{"county":"Bradley County","state":"Arkansas","id":116},
{"county":"Calhoun County","state":"Arkansas","id":117},
{"county":"Carroll County","state":"Arkansas","id":118},
{"county":"Chicot County","state":"Arkansas","id":119},
{"county":"Clark County","state":"Arkansas","id":120},
{"county":"Clay County","state":"Arkansas","id":121},
{"county":"Cleburne County","state":"Arkansas","id":122},
{"county":"Cleveland County","state":"Arkansas","id":123},
{"county":"Columbia County","state":"Arkansas","id":124},
{"county":"Conway County","state":"Arkansas","id":125},
{"county":"Craighead County","state":"Arkansas","id":126},
{"county":"Crawford County","state":"Arkansas","id":127},
{"county":"Crittenden County","state":"Arkansas","id":128},
{"county":"Cross County","state":"Arkansas","id":129},
{"county":"Dallas County","state":"Arkansas","id":130},
{"county":"Desha County","state":"Arkansas","id":131},
{"county":"Drew County","state":"Arkansas","id":132},
{"county":"Faulkner County","state":"Arkansas","id":133},
{"county":"Franklin County","state":"Arkansas","id":134},
{"county":"Fulton County","state":"Arkansas","id":135},
{"county":"Garland County","state":"Arkansas","id":136},
{"county":"Grant County","state":"Arkansas","id":137},
{"county":"Greene County","state":"Arkansas","id":138},
{"county":"Hempstead County","state":"Arkansas","id":139},
{"county":"Hot Spring County","state":"Arkansas","id":140},
{"county":"Howard County","state":"Arkansas","id":141},
{"county":"Independence County","state":"Arkansas","id":142},
{"county":"Izard County","state":"Arkansas","id":143},
{"county":"Jackson County","state":"Arkansas","id":144},
{"county":"Jefferson County","state":"Arkansas","id":145},
{"county":"Johnson County","state":"Arkansas","id":146},
{"county":"Lafayette County","state":"Arkansas","id":147},
{"county":"Lawrence County","state":"Arkansas","id":148},
{"county":"Lee County","state":"Arkansas","id":149},
{"county":"Lincoln County","state":"Arkansas","id":150},
{"county":"Little River County","state":"Arkansas","id":151},
{"county":"Logan County","state":"Arkansas","id":152},
{"county":"Lonoke County","state":"Arkansas","id":153},
{"county":"Madison County","state":"Arkansas","id":154},
{"county":"Marion County","state":"Arkansas","id":155},
{"county":"Miller County","state":"Arkansas","id":156},
{"county":"Mississippi County","state":"Arkansas","id":157},
{"county":"Monroe County","state":"Arkansas","id":158},
{"county":"Montgomery County","state":"Arkansas","id":159},
{"county":"Nevada County","state":"Arkansas","id":160},
{"county":"Newton County","state":"Arkansas","id":161},
{"county":"Ouachita County","state":"Arkansas","id":162},
{"county":"Perry County","state":"Arkansas","id":163},
{"county":"Phillips County","state":"Arkansas","id":164},
{"county":"Pike County","state":"Arkansas","id":165},
{"county":"Poinsett County","state":"Arkansas","id":166},
{"county":"Polk County","state":"Arkansas","id":167},
{"county":"Pope County","state":"Arkansas","id":168},
{"county":"Prairie County","state":"Arkansas","id":169},
{"county":"Pulaski County","state":"Arkansas","id":170},
{"county":"Randolph County","state":"Arkansas","id":171},
{"county":"St. Francis County","state":"Arkansas","id":172},
{"county":"Saline County","state":"Arkansas","id":173},
{"county":"Scott County","state":"Arkansas","id":174},
{"county":"Searcy County","state":"Arkansas","id":175},
{"county":"Sebastian County","state":"Arkansas","id":176},
{"county":"Sevier County","state":"Arkansas","id":177},
{"county":"Sharp County","state":"Arkansas","id":178},
{"county":"Stone County","state":"Arkansas","id":179},
{"county":"Union County","state":"Arkansas","id":180},
{"county":"Van Buren County","state":"Arkansas","id":181},
{"county":"Washington County","state":"Arkansas","id":182},
{"county":"White County","state":"Arkansas","id":183},
{"county":"Woodruff County","state":"Arkansas","id":184},
{"county":"Yell County","state":"Arkansas","id":185},
{"county":"Alameda County","state":"California","id":186},
{"county":"Alpine County","state":"California","id":187},
{"county":"Amador County","state":"California","id":188},
{"county":"Butte County","state":"California","id":189},
{"county":"Calaveras County","state":"California","id":190},
{"county":"Colusa County","state":"California","id":191},
{"county":"Contra Costa County","state":"California","id":192},
{"county":"Del Norte County","state":"California","id":193},
{"county":"El Dorado County","state":"California","id":194},
{"county":"Fresno County","state":"California","id":195},
{"county":"Glenn County","state":"California","id":196},
{"county":"Humboldt County","state":"California","id":197},
{"county":"Imperial County","state":"California","id":198},
{"county":"Inyo County","state":"California","id":199},
{"county":"Kern County","state":"California","id":200},
{"county":"Kings County","state":"California","id":201},
{"county":"Lake County","state":"California","id":202},
{"county":"Lassen County","state":"California","id":203},
{"county":"Los Angeles County","state":"California","id":204},
{"county":"Madera County","state":"California","id":205},
{"county":"Marin County","state":"California","id":206},
{"county":"Mariposa County","state":"California","id":207},
{"county":"Mendocino County","state":"California","id":208},
{"county":"Merced County","state":"California","id":209},
{"county":"Modoc County","state":"California","id":210},
{"county":"Mono County","state":"California","id":211},
{"county":"Monterey County","state":"California","id":212},
{"county":"Napa County","state":"California","id":213},
{"county":"Nevada County","state":"California","id":214},
{"county":"Orange County","state":"California","id":215},
{"county":"Placer County","state":"California","id":216},
{"county":"Plumas County","state":"California","id":217},
{"county":"Riverside County","state":"California","id":218},
{"county":"Sacramento County","state":"California","id":219},
{"county":"San Benito County","state":"California","id":220},
{"county":"San Bernardino County","state":"California","id":221},
{"county":"San Diego County","state":"California","id":222},
{"county":"San Francisco, City and County of","state":"California","id":223},
{"county":"San Joaquin County","state":"California","id":224},
{"county":"San Luis Obispo County","state":"California","id":225},
{"county":"San Mateo County","state":"California","id":226},
{"county":"Santa Barbara County","state":"California","id":227},
{"county":"Santa Clara County","state":"California","id":228},
{"county":"Santa Cruz County","state":"California","id":229},
{"county":"Shasta County","state":"California","id":230},
{"county":"Sierra County","state":"California","id":231},
{"county":"Siskiyou County","state":"California","id":232},
{"county":"Solano County","state":"California","id":233},
{"county":"Sonoma County","state":"California","id":234},
{"county":"Stanislaus County","state":"California","id":235},
{"county":"Sutter County","state":"California","id":236},
{"county":"Tehama County","state":"California","id":237},
{"county":"Trinity County","state":"California","id":238},
{"county":"Tulare County","state":"California","id":239},
{"county":"Tuolumne County","state":"California","id":240},
{"county":"Ventura County","state":"California","id":241},
{"county":"Yolo County","state":"California","id":242},
{"county":"Yuba County","state":"California","id":243},
{"county":"Adams County","state":"Colorado","id":244},
{"county":"Alamosa County","state":"Colorado","id":245},
{"county":"Arapahoe County","state":"Colorado","id":246},
{"county":"Archuleta County","state":"Colorado","id":247},
{"county":"Baca County","state":"Colorado","id":248},
{"county":"Bent County","state":"Colorado","id":249},
{"county":"Boulder County","state":"Colorado","id":250},
{"county":"Broomfield, City and County of","state":"Colorado","id":251},
{"county":"Chaffee County","state":"Colorado","id":252},
{"county":"Cheyenne County","state":"Colorado","id":253},
{"county":"Clear Creek County","state":"Colorado","id":254},
{"county":"Conejos County","state":"Colorado","id":255},
{"county":"Costilla County","state":"Colorado","id":256},
{"county":"Crowley County","state":"Colorado","id":257},
{"county":"Custer County","state":"Colorado","id":258},
{"county":"Delta County","state":"Colorado","id":259},
{"county":"Denver, City and County of","state":"Colorado","id":260},
{"county":"Dolores County","state":"Colorado","id":261},
{"county":"Douglas County","state":"Colorado","id":262},
{"county":"Eagle County","state":"Colorado","id":263},
{"county":"Elbert County","state":"Colorado","id":264},
{"county":"El Paso County","state":"Colorado","id":265},
{"county":"Fremont County","state":"Colorado","id":266},
{"county":"Garfield County","state":"Colorado","id":267},
{"county":"Gilpin County","state":"Colorado","id":268},
{"county":"Grand County","state":"Colorado","id":269},
{"county":"Gunnison County","state":"Colorado","id":270},
{"county":"Hinsdale County","state":"Colorado","id":271},
{"county":"Huerfano County","state":"Colorado","id":272},
{"county":"Jackson County","state":"Colorado","id":273},
{"county":"Jefferson County","state":"Colorado","id":274},
{"county":"Kiowa County","state":"Colorado","id":275},
{"county":"Kit Carson County","state":"Colorado","id":276},
{"county":"Lake County","state":"Colorado","id":277},
{"county":"La Plata County","state":"Colorado","id":278},
{"county":"Larimer County","state":"Colorado","id":279},
{"county":"Las Animas County","state":"Colorado","id":280},
{"county":"Lincoln County","state":"Colorado","id":281},
{"county":"Logan County","state":"Colorado","id":282},
{"county":"Mesa County","state":"Colorado","id":283},
{"county":"Mineral County","state":"Colorado","id":284},
{"county":"Moffat County","state":"Colorado","id":285},
{"county":"Montezuma County","state":"Colorado","id":286},
{"county":"Montrose County","state":"Colorado","id":287},
{"county":"Morgan County","state":"Colorado","id":288},
{"county":"Otero County","state":"Colorado","id":289},
{"county":"Ouray County","state":"Colorado","id":290},
{"county":"Park County","state":"Colorado","id":291},
{"county":"Phillips County","state":"Colorado","id":292},
{"county":"Pitkin County","state":"Colorado","id":293},
{"county":"Prowers County","state":"Colorado","id":294},
{"county":"Pueblo County","state":"Colorado","id":295},
{"county":"Rio Blanco County","state":"Colorado","id":296},
{"county":"Rio Grande County","state":"Colorado","id":297},
{"county":"Routt County","state":"Colorado","id":298},
{"county":"Saguache County","state":"Colorado","id":299},
{"county":"San Juan County","state":"Colorado","id":300},
{"county":"San Miguel County","state":"Colorado","id":301},
{"county":"Sedgwick County","state":"Colorado","id":302},
{"county":"Summit County","state":"Colorado","id":303},
{"county":"Teller County","state":"Colorado","id":304},
{"county":"Washington County","state":"Colorado","id":305},
{"county":"Weld County","state":"Colorado","id":306},
{"county":"Yuma County","state":"Colorado","id":307},
{"county":"Fairfield County","state":"Connecticut","id":308},
{"county":"Hartford County","state":"Connecticut","id":309},
{"county":"Litchfield County","state":"Connecticut","id":310},
{"county":"Middlesex County","state":"Connecticut","id":311},
{"county":"New Haven County","state":"Connecticut","id":312},
{"county":"New London County","state":"Connecticut","id":313},
{"county":"Tolland County","state":"Connecticut","id":314},
{"county":"Windham County","state":"Connecticut","id":315},
{"county":"Kent County","state":"Delaware","id":316},
{"county":"New Castle County","state":"Delaware","id":317},
{"county":"Sussex County","state":"Delaware","id":318},
{"county":"District of Columbia","state":"District of Columbia","id":319},
{"county":"Alachua County","state":"Florida","id":320},
{"county":"Baker County","state":"Florida","id":321},
{"county":"Bay County","state":"Florida","id":322},
{"county":"Bradford County","state":"Florida","id":323},
{"county":"Brevard County","state":"Florida","id":324},
{"county":"Broward County","state":"Florida","id":325},
{"county":"Calhoun County","state":"Florida","id":326},
{"county":"Charlotte County","state":"Florida","id":327},
{"county":"Citrus County","state":"Florida","id":328},
{"county":"Clay County","state":"Florida","id":329},
{"county":"Collier County","state":"Florida","id":330},
{"county":"Columbia County","state":"Florida","id":331},
{"county":"DeSoto County","state":"Florida","id":332},
{"county":"Dixie County","state":"Florida","id":333},
{"county":"Duval County","state":"Florida","id":334},
{"county":"Escambia County","state":"Florida","id":335},
{"county":"Flagler County","state":"Florida","id":336},
{"county":"Franklin County","state":"Florida","id":337},
{"county":"Gadsden County","state":"Florida","id":338},
{"county":"Gilchrist County","state":"Florida","id":339},
{"county":"Glades County","state":"Florida","id":340},
{"county":"Gulf County","state":"Florida","id":341},
{"county":"Hamilton County","state":"Florida","id":342},
{"county":"Hardee County","state":"Florida","id":343},
{"county":"Hendry County","state":"Florida","id":344},
{"county":"Hernando County","state":"Florida","id":345},
{"county":"Highlands County","state":"Florida","id":346},
{"county":"Hillsborough County","state":"Florida","id":347},
{"county":"Holmes County","state":"Florida","id":348},
{"county":"Indian River County","state":"Florida","id":349},
{"county":"Jackson County","state":"Florida","id":350},
{"county":"Jefferson County","state":"Florida","id":351},
{"county":"Lafayette County","state":"Florida","id":352},
{"county":"Lake County","state":"Florida","id":353},
{"county":"Lee County","state":"Florida","id":354},
{"county":"Leon County","state":"Florida","id":355},
{"county":"Levy County","state":"Florida","id":356},
{"county":"Liberty County","state":"Florida","id":357},
{"county":"Madison County","state":"Florida","id":358},
{"county":"Manatee County","state":"Florida","id":359},
{"county":"Marion County","state":"Florida","id":360},
{"county":"Martin County","state":"Florida","id":361},
{"county":"Miami-Dade County","state":"Florida","id":362},
{"county":"Monroe County","state":"Florida","id":363},
{"county":"Nassau County","state":"Florida","id":364},
{"county":"Okaloosa County","state":"Florida","id":365},
{"county":"Okeechobee County","state":"Florida","id":366},
{"county":"Orange County","state":"Florida","id":367},
{"county":"Osceola County","state":"Florida","id":368},
{"county":"Palm Beach County","state":"Florida","id":369},
{"county":"Pasco County","state":"Florida","id":370},
{"county":"Pinellas County","state":"Florida","id":371},
{"county":"Polk County","state":"Florida","id":372},
{"county":"Putnam County","state":"Florida","id":373},
{"county":"St. Johns County","state":"Florida","id":374},
{"county":"St. Lucie County","state":"Florida","id":375},
{"county":"Santa Rosa County","state":"Florida","id":376},
{"county":"Sarasota County","state":"Florida","id":377},
{"county":"Seminole County","state":"Florida","id":378},
{"county":"Sumter County","state":"Florida","id":379},
{"county":"Suwannee County","state":"Florida","id":380},
{"county":"Taylor County","state":"Florida","id":381},
{"county":"Union County","state":"Florida","id":382},
{"county":"Volusia County","state":"Florida","id":383},
{"county":"Wakulla County","state":"Florida","id":384},
{"county":"Walton County","state":"Florida","id":385},
{"county":"Washington County","state":"Florida","id":386},
{"county":"Appling County","state":"Georgia","id":387},
{"county":"Atkinson County","state":"Georgia","id":388},
{"county":"Bacon County","state":"Georgia","id":389},
{"county":"Baker County","state":"Georgia","id":390},
{"county":"Baldwin County","state":"Georgia","id":391},
{"county":"Banks County","state":"Georgia","id":392},
{"county":"Barrow County","state":"Georgia","id":393},
{"county":"Bartow County","state":"Georgia","id":394},
{"county":"Ben Hill County","state":"Georgia","id":395},
{"county":"Berrien County","state":"Georgia","id":396},
{"county":"Bibb County","state":"Georgia","id":397},
{"county":"Bleckley County","state":"Georgia","id":398},
{"county":"Brantley County","state":"Georgia","id":399},
{"county":"Brooks County","state":"Georgia","id":400},
{"county":"Bryan County","state":"Georgia","id":401},
{"county":"Bulloch County","state":"Georgia","id":402},
{"county":"Burke County","state":"Georgia","id":403},
{"county":"Butts County","state":"Georgia","id":404},
{"county":"Calhoun County","state":"Georgia","id":405},
{"county":"Camden County","state":"Georgia","id":406},
{"county":"Candler County","state":"Georgia","id":407},
{"county":"Carroll County","state":"Georgia","id":408},
{"county":"Catoosa County","state":"Georgia","id":409},
{"county":"Charlton County","state":"Georgia","id":410},
{"county":"Chatham County","state":"Georgia","id":411},
{"county":"Chattahoochee County","state":"Georgia","id":412},
{"county":"Chattooga County","state":"Georgia","id":413},
{"county":"Cherokee County","state":"Georgia","id":414},
{"county":"Clarke County","state":"Georgia","id":415},
{"county":"Clay County","state":"Georgia","id":416},
{"county":"Clayton County","state":"Georgia","id":417},
{"county":"Clinch County","state":"Georgia","id":418},
{"county":"Cobb County","state":"Georgia","id":419},
{"county":"Coffee County","state":"Georgia","id":420},
{"county":"Colquitt County","state":"Georgia","id":421},
{"county":"Columbia County","state":"Georgia","id":422},
{"county":"Cook County","state":"Georgia","id":423},
{"county":"Coweta County","state":"Georgia","id":424},
{"county":"Crawford County","state":"Georgia","id":425},
{"county":"Crisp County","state":"Georgia","id":426},
{"county":"Dade County","state":"Georgia","id":427},
{"county":"Dawson County","state":"Georgia","id":428},
{"county":"Decatur County","state":"Georgia","id":429},
{"county":"DeKalb County","state":"Georgia","id":430},
{"county":"Dodge County","state":"Georgia","id":431},
{"county":"Dooly County","state":"Georgia","id":432},
{"county":"Dougherty County","state":"Georgia","id":433},
{"county":"Douglas County","state":"Georgia","id":434},
{"county":"Early County","state":"Georgia","id":435},
{"county":"Echols County","state":"Georgia","id":436},
{"county":"Effingham County","state":"Georgia","id":437},
{"county":"Elbert County","state":"Georgia","id":438},
{"county":"Emanuel County","state":"Georgia","id":439},
{"county":"Evans County","state":"Georgia","id":440},
{"county":"Fannin County","state":"Georgia","id":441},
{"county":"Fayette County","state":"Georgia","id":442},
{"county":"Floyd County","state":"Georgia","id":443},
{"county":"Forsyth County","state":"Georgia","id":444},
{"county":"Franklin County","state":"Georgia","id":445},
{"county":"Fulton County","state":"Georgia","id":446},
{"county":"Gilmer County","state":"Georgia","id":447},
{"county":"Glascock County","state":"Georgia","id":448},
{"county":"Glynn County","state":"Georgia","id":449},
{"county":"Gordon County","state":"Georgia","id":450},
{"county":"Grady County","state":"Georgia","id":451},
{"county":"Greene County","state":"Georgia","id":452},
{"county":"Gwinnett County","state":"Georgia","id":453},
{"county":"Habersham County","state":"Georgia","id":454},
{"county":"Hall County","state":"Georgia","id":455},
{"county":"Hancock County","state":"Georgia","id":456},
{"county":"Haralson County","state":"Georgia","id":457},
{"county":"Harris County","state":"Georgia","id":458},
{"county":"Hart County","state":"Georgia","id":459},
{"county":"Heard County","state":"Georgia","id":460},
{"county":"Henry County","state":"Georgia","id":461},
{"county":"Houston County","state":"Georgia","id":462},
{"county":"Irwin County","state":"Georgia","id":463},
{"county":"Jackson County","state":"Georgia","id":464},
{"county":"Jasper County","state":"Georgia","id":465},
{"county":"Jeff Davis County","state":"Georgia","id":466},
{"county":"Jefferson County","state":"Georgia","id":467},
{"county":"Jenkins County","state":"Georgia","id":468},
{"county":"Johnson County","state":"Georgia","id":469},
{"county":"Jones County","state":"Georgia","id":470},
{"county":"Lamar County","state":"Georgia","id":471},
{"county":"Lanier County","state":"Georgia","id":472},
{"county":"Laurens County","state":"Georgia","id":473},
{"county":"Lee County","state":"Georgia","id":474},
{"county":"Liberty County","state":"Georgia","id":475},
{"county":"Lincoln County","state":"Georgia","id":476},
{"county":"Long County","state":"Georgia","id":477},
{"county":"Lowndes County","state":"Georgia","id":478},
{"county":"Lumpkin County","state":"Georgia","id":479},
{"county":"McDuffie County","state":"Georgia","id":480},
{"county":"McIntosh County","state":"Georgia","id":481},
{"county":"Macon County","state":"Georgia","id":482},
{"county":"Madison County","state":"Georgia","id":483},
{"county":"Marion County","state":"Georgia","id":484},
{"county":"Meriwether County","state":"Georgia","id":485},
{"county":"Miller County","state":"Georgia","id":486},
{"county":"Mitchell County","state":"Georgia","id":487},
{"county":"Monroe County","state":"Georgia","id":488},
{"county":"Montgomery County","state":"Georgia","id":489},
{"county":"Morgan County","state":"Georgia","id":490},
{"county":"Murray County","state":"Georgia","id":491},
{"county":"Muscogee County","state":"Georgia","id":492},
{"county":"Newton County","state":"Georgia","id":493},
{"county":"Oconee County","state":"Georgia","id":494},
{"county":"Oglethorpe County","state":"Georgia","id":495},
{"county":"Paulding County","state":"Georgia","id":496},
{"county":"Peach County","state":"Georgia","id":497},
{"county":"Pickens County","state":"Georgia","id":498},
{"county":"Pierce County","state":"Georgia","id":499},
{"county":"Pike County","state":"Georgia","id":500},
{"county":"Polk County","state":"Georgia","id":501},
{"county":"Pulaski County","state":"Georgia","id":502},
{"county":"Putnam County","state":"Georgia","id":503},
{"county":"Quitman County","state":"Georgia","id":504},
{"county":"Rabun County","state":"Georgia","id":505},
{"county":"Randolph County","state":"Georgia","id":506},
{"county":"Richmond County","state":"Georgia","id":507},
{"county":"Rockdale County","state":"Georgia","id":508},
{"county":"Schley County","state":"Georgia","id":509},
{"county":"Screven County","state":"Georgia","id":510},
{"county":"Seminole County","state":"Georgia","id":511},
{"county":"Spalding County","state":"Georgia","id":512},
{"county":"Stephens County","state":"Georgia","id":513},
{"county":"Stewart County","state":"Georgia","id":514},
{"county":"Sumter County","state":"Georgia","id":515},
{"county":"Talbot County","state":"Georgia","id":516},
{"county":"Taliaferro County","state":"Georgia","id":517},
{"county":"Tattnall County","state":"Georgia","id":518},
{"county":"Taylor County","state":"Georgia","id":519},
{"county":"Telfair County","state":"Georgia","id":520},
{"county":"Terrell County","state":"Georgia","id":521},
{"county":"Thomas County","state":"Georgia","id":522},
{"county":"Tift County","state":"Georgia","id":523},
{"county":"Toombs County","state":"Georgia","id":524},
{"county":"Towns County","state":"Georgia","id":525},
{"county":"Treutlen County","state":"Georgia","id":526},
{"county":"Troup County","state":"Georgia","id":527},
{"county":"Turner County","state":"Georgia","id":528},
{"county":"Twiggs County","state":"Georgia","id":529},
{"county":"Union County","state":"Georgia","id":530},
{"county":"Upson County","state":"Georgia","id":531},
{"county":"Walker County","state":"Georgia","id":532},
{"county":"Walton County","state":"Georgia","id":533},
{"county":"Ware County","state":"Georgia","id":534},
{"county":"Warren County","state":"Georgia","id":535},
{"county":"Washington County","state":"Georgia","id":536},
{"county":"Wayne County","state":"Georgia","id":537},
{"county":"Webster County","state":"Georgia","id":538},
{"county":"Wheeler County","state":"Georgia","id":539},
{"county":"White County","state":"Georgia","id":540},
{"county":"Whitfield County","state":"Georgia","id":541},
{"county":"Wilcox County","state":"Georgia","id":542},
{"county":"Wilkes County","state":"Georgia","id":543},
{"county":"Wilkinson County","state":"Georgia","id":544},
{"county":"Worth County","state":"Georgia","id":545},
{"county":"Hawaii County","state":"Hawai?i","id":546},
{"county":"Honolulu, City and County of","state":"Hawai?i","id":547},
{"county":"Kalawao County","state":"Hawai?i","id":548},
{"county":"Kauai County","state":"Hawai?i","id":549},
{"county":"Maui County","state":"Hawai?i","id":550},
{"county":"Ada County","state":"Idaho","id":551},
{"county":"Adams County","state":"Idaho","id":552},
{"county":"Bannock County","state":"Idaho","id":553},
{"county":"Bear Lake County","state":"Idaho","id":554},
{"county":"Benewah County","state":"Idaho","id":555},
{"county":"Bingham County","state":"Idaho","id":556},
{"county":"Blaine County","state":"Idaho","id":557},
{"county":"Boise County","state":"Idaho","id":558},
{"county":"Bonner County","state":"Idaho","id":559},
{"county":"Bonneville County","state":"Idaho","id":560},
{"county":"Boundary County","state":"Idaho","id":561},
{"county":"Butte County","state":"Idaho","id":562},
{"county":"Camas County","state":"Idaho","id":563},
{"county":"Canyon County","state":"Idaho","id":564},
{"county":"Caribou County","state":"Idaho","id":565},
{"county":"Cassia County","state":"Idaho","id":566},
{"county":"Clark County","state":"Idaho","id":567},
{"county":"Clearwater County","state":"Idaho","id":568},
{"county":"Custer County","state":"Idaho","id":569},
{"county":"Elmore County","state":"Idaho","id":570},
{"county":"Franklin County","state":"Idaho","id":571},
{"county":"Fremont County","state":"Idaho","id":572},
{"county":"Gem County","state":"Idaho","id":573},
{"county":"Gooding County","state":"Idaho","id":574},
{"county":"Idaho County","state":"Idaho","id":575},
{"county":"Jefferson County","state":"Idaho","id":576},
{"county":"Jerome County","state":"Idaho","id":577},
{"county":"Kootenai County","state":"Idaho","id":578},
{"county":"Latah County","state":"Idaho","id":579},
{"county":"Lemhi County","state":"Idaho","id":580},
{"county":"Lewis County","state":"Idaho","id":581},
{"county":"Lincoln County","state":"Idaho","id":582},
{"county":"Madison County","state":"Idaho","id":583},
{"county":"Minidoka County","state":"Idaho","id":584},
{"county":"Nez Perce County","state":"Idaho","id":585},
{"county":"Oneida County","state":"Idaho","id":586},
{"county":"Owyhee County","state":"Idaho","id":587},
{"county":"Payette County","state":"Idaho","id":588},
{"county":"Power County","state":"Idaho","id":589},
{"county":"Shoshone County","state":"Idaho","id":590},
{"county":"Teton County","state":"Idaho","id":591},
{"county":"Twin Falls County","state":"Idaho","id":592},
{"county":"Valley County","state":"Idaho","id":593},
{"county":"Washington County","state":"Idaho","id":594},
{"county":"Adams County","state":"Illinois","id":595},
{"county":"Alexander County","state":"Illinois","id":596},
{"county":"Bond County","state":"Illinois","id":597},
{"county":"Boone County","state":"Illinois","id":598},
{"county":"Brown County","state":"Illinois","id":599},
{"county":"Bureau County","state":"Illinois","id":600},
{"county":"Calhoun County","state":"Illinois","id":601},
{"county":"Carroll County","state":"Illinois","id":602},
{"county":"Cass County","state":"Illinois","id":603},
{"county":"Champaign County","state":"Illinois","id":604},
{"county":"Christian County","state":"Illinois","id":605},
{"county":"Clark County","state":"Illinois","id":606},
{"county":"Clay County","state":"Illinois","id":607},
{"county":"Clinton County","state":"Illinois","id":608},
{"county":"Coles County","state":"Illinois","id":609},
{"county":"Cook County","state":"Illinois","id":610},
{"county":"Crawford County","state":"Illinois","id":611},
{"county":"Cumberland County","state":"Illinois","id":612},
{"county":"DeKalb County","state":"Illinois","id":613},
{"county":"De Witt County","state":"Illinois","id":614},
{"county":"Douglas County","state":"Illinois","id":615},
{"county":"DuPage County","state":"Illinois","id":616},
{"county":"Edgar County","state":"Illinois","id":617},
{"county":"Edwards County","state":"Illinois","id":618},
{"county":"Effingham County","state":"Illinois","id":619},
{"county":"Fayette County","state":"Illinois","id":620},
{"county":"Ford County","state":"Illinois","id":621},
{"county":"Franklin County","state":"Illinois","id":622},
{"county":"Fulton County","state":"Illinois","id":623},
{"county":"Gallatin County","state":"Illinois","id":624},
{"county":"Greene County","state":"Illinois","id":625},
{"county":"Grundy County","state":"Illinois","id":626},
{"county":"Hamilton County","state":"Illinois","id":627},
{"county":"Hancock County","state":"Illinois","id":628},
{"county":"Hardin County","state":"Illinois","id":629},
{"county":"Henderson County","state":"Illinois","id":630},
{"county":"Henry County","state":"Illinois","id":631},
{"county":"Iroquois County","state":"Illinois","id":632},
{"county":"Jackson County","state":"Illinois","id":633},
{"county":"Jasper County","state":"Illinois","id":634},
{"county":"Jefferson County","state":"Illinois","id":635},
{"county":"Jersey County","state":"Illinois","id":636},
{"county":"Jo Daviess County","state":"Illinois","id":637},
{"county":"Johnson County","state":"Illinois","id":638},
{"county":"Kane County","state":"Illinois","id":639},
{"county":"Kankakee County","state":"Illinois","id":640},
{"county":"Kendall County","state":"Illinois","id":641},
{"county":"Knox County","state":"Illinois","id":642},
{"county":"Lake County","state":"Illinois","id":643},
{"county":"LaSalle County","state":"Illinois","id":644},
{"county":"Lawrence County","state":"Illinois","id":645},
{"county":"Lee County","state":"Illinois","id":646},
{"county":"Livingston County","state":"Illinois","id":647},
{"county":"Logan County","state":"Illinois","id":648},
{"county":"McDonough County","state":"Illinois","id":649},
{"county":"McHenry County","state":"Illinois","id":650},
{"county":"McLean County","state":"Illinois","id":651},
{"county":"Macon County","state":"Illinois","id":652},
{"county":"Macoupin County","state":"Illinois","id":653},
{"county":"Madison County","state":"Illinois","id":654},
{"county":"Marion County","state":"Illinois","id":655},
{"county":"Marshall County","state":"Illinois","id":656},
{"county":"Mason County","state":"Illinois","id":657},
{"county":"Massac County","state":"Illinois","id":658},
{"county":"Menard County","state":"Illinois","id":659},
{"county":"Mercer County","state":"Illinois","id":660},
{"county":"Monroe County","state":"Illinois","id":661},
{"county":"Montgomery County","state":"Illinois","id":662},
{"county":"Morgan County","state":"Illinois","id":663},
{"county":"Moultrie County","state":"Illinois","id":664},
{"county":"Ogle County","state":"Illinois","id":665},
{"county":"Peoria County","state":"Illinois","id":666},
{"county":"Perry County","state":"Illinois","id":667},
{"county":"Piatt County","state":"Illinois","id":668},
{"county":"Pike County","state":"Illinois","id":669},
{"county":"Pope County","state":"Illinois","id":670},
{"county":"Pulaski County","state":"Illinois","id":671},
{"county":"Putnam County","state":"Illinois","id":672},
{"county":"Randolph County","state":"Illinois","id":673},
{"county":"Richland County","state":"Illinois","id":674},
{"county":"Rock Island County","state":"Illinois","id":675},
{"county":"St. Clair County","state":"Illinois","id":676},
{"county":"Saline County","state":"Illinois","id":677},
{"county":"Sangamon County","state":"Illinois","id":678},
{"county":"Schuyler County","state":"Illinois","id":679},
{"county":"Scott County","state":"Illinois","id":680},
{"county":"Shelby County","state":"Illinois","id":681},
{"county":"Stark County","state":"Illinois","id":682},
{"county":"Stephenson County","state":"Illinois","id":683},
{"county":"Tazewell County","state":"Illinois","id":684},
{"county":"Union County","state":"Illinois","id":685},
{"county":"Vermilion County","state":"Illinois","id":686},
{"county":"Wabash County","state":"Illinois","id":687},
{"county":"Warren County","state":"Illinois","id":688},
{"county":"Washington County","state":"Illinois","id":689},
{"county":"Wayne County","state":"Illinois","id":690},
{"county":"White County","state":"Illinois","id":691},
{"county":"Whiteside County","state":"Illinois","id":692},
{"county":"Will County","state":"Illinois","id":693},
{"county":"Williamson County","state":"Illinois","id":694},
{"county":"Winnebago County","state":"Illinois","id":695},
{"county":"Woodford County","state":"Illinois","id":696},
{"county":"Adams County","state":"Indiana","id":697},
{"county":"Allen County","state":"Indiana","id":698},
{"county":"Bartholomew County","state":"Indiana","id":699},
{"county":"Benton County","state":"Indiana","id":700},
{"county":"Blackford County","state":"Indiana","id":701},
{"county":"Boone County","state":"Indiana","id":702},
{"county":"Brown County","state":"Indiana","id":703},
{"county":"Carroll County","state":"Indiana","id":704},
{"county":"Cass County","state":"Indiana","id":705},
{"county":"Clark County","state":"Indiana","id":706},
{"county":"Clay County","state":"Indiana","id":707},
{"county":"Clinton County","state":"Indiana","id":708},
{"county":"Crawford County","state":"Indiana","id":709},
{"county":"Daviess County","state":"Indiana","id":710},
{"county":"Dearborn County","state":"Indiana","id":711},
{"county":"Decatur County","state":"Indiana","id":712},
{"county":"DeKalb County","state":"Indiana","id":713},
{"county":"Delaware County","state":"Indiana","id":714},
{"county":"Dubois County","state":"Indiana","id":715},
{"county":"Elkhart County","state":"Indiana","id":716},
{"county":"Fayette County","state":"Indiana","id":717},
{"county":"Floyd County","state":"Indiana","id":718},
{"county":"Fountain County","state":"Indiana","id":719},
{"county":"Franklin County","state":"Indiana","id":720},
{"county":"Fulton County","state":"Indiana","id":721},
{"county":"Gibson County","state":"Indiana","id":722},
{"county":"Grant County","state":"Indiana","id":723},
{"county":"Greene County","state":"Indiana","id":724},
{"county":"Hamilton County","state":"Indiana","id":725},
{"county":"Hancock County","state":"Indiana","id":726},
{"county":"Harrison County","state":"Indiana","id":727},
{"county":"Hendricks County","state":"Indiana","id":728},
{"county":"Henry County","state":"Indiana","id":729},
{"county":"Howard County","state":"Indiana","id":730},
{"county":"Huntington County","state":"Indiana","id":731},
{"county":"Jackson County","state":"Indiana","id":732},
{"county":"Jasper County","state":"Indiana","id":733},
{"county":"Jay County","state":"Indiana","id":734},
{"county":"Jefferson County","state":"Indiana","id":735},
{"county":"Jennings County","state":"Indiana","id":736},
{"county":"Johnson County","state":"Indiana","id":737},
{"county":"Knox County","state":"Indiana","id":738},
{"county":"Kosciusko County","state":"Indiana","id":739},
{"county":"LaGrange County","state":"Indiana","id":740},
{"county":"Lake County","state":"Indiana","id":741},
{"county":"LaPorte County","state":"Indiana","id":742},
{"county":"Lawrence County","state":"Indiana","id":743},
{"county":"Madison County","state":"Indiana","id":744},
{"county":"Marion County","state":"Indiana","id":745},
{"county":"Marshall County","state":"Indiana","id":746},
{"county":"Martin County","state":"Indiana","id":747},
{"county":"Miami County","state":"Indiana","id":748},
{"county":"Monroe County","state":"Indiana","id":749},
{"county":"Montgomery County","state":"Indiana","id":750},
{"county":"Morgan County","state":"Indiana","id":751},
{"county":"Newton County","state":"Indiana","id":752},
{"county":"Noble County","state":"Indiana","id":753},
{"county":"Ohio County","state":"Indiana","id":754},
{"county":"Orange County","state":"Indiana","id":755},
{"county":"Owen County","state":"Indiana","id":756},
{"county":"Parke County","state":"Indiana","id":757},
{"county":"Perry County","state":"Indiana","id":758},
{"county":"Pike County","state":"Indiana","id":759},
{"county":"Porter County","state":"Indiana","id":760},
{"county":"Posey County","state":"Indiana","id":761},
{"county":"Pulaski County","state":"Indiana","id":762},
{"county":"Putnam County","state":"Indiana","id":763},
{"county":"Randolph County","state":"Indiana","id":764},
{"county":"Ripley County","state":"Indiana","id":765},
{"county":"Rush County","state":"Indiana","id":766},
{"county":"St. Joseph County","state":"Indiana","id":767},
{"county":"Scott County","state":"Indiana","id":768},
{"county":"Shelby County","state":"Indiana","id":769},
{"county":"Spencer County","state":"Indiana","id":770},
{"county":"Starke County","state":"Indiana","id":771},
{"county":"Steuben County","state":"Indiana","id":772},
{"county":"Sullivan County","state":"Indiana","id":773},
{"county":"Switzerland County","state":"Indiana","id":774},
{"county":"Tippecanoe County","state":"Indiana","id":775},
{"county":"Tipton County","state":"Indiana","id":776},
{"county":"Union County","state":"Indiana","id":777},
{"county":"Vanderburgh County","state":"Indiana","id":778},
{"county":"Vermillion County","state":"Indiana","id":779},
{"county":"Vigo County","state":"Indiana","id":780},
{"county":"Wabash County","state":"Indiana","id":781},
{"county":"Warren County","state":"Indiana","id":782},
{"county":"Warrick County","state":"Indiana","id":783},
{"county":"Washington County","state":"Indiana","id":784},
{"county":"Wayne County","state":"Indiana","id":785},
{"county":"Wells County","state":"Indiana","id":786},
{"county":"White County","state":"Indiana","id":787},
{"county":"Whitley County","state":"Indiana","id":788},
{"county":"Adair County","state":"Iowa","id":789},
{"county":"Adams County","state":"Iowa","id":790},
{"county":"Allamakee County","state":"Iowa","id":791},
{"county":"Appanoose County","state":"Iowa","id":792},
{"county":"Audubon County","state":"Iowa","id":793},
{"county":"Benton County","state":"Iowa","id":794},
{"county":"Black Hawk County","state":"Iowa","id":795},
{"county":"Boone County","state":"Iowa","id":796},
{"county":"Bremer County","state":"Iowa","id":797},
{"county":"Buchanan County","state":"Iowa","id":798},
{"county":"Buena Vista County","state":"Iowa","id":799},
{"county":"Butler County","state":"Iowa","id":800},
{"county":"Calhoun County","state":"Iowa","id":801},
{"county":"Carroll County","state":"Iowa","id":802},
{"county":"Cass County","state":"Iowa","id":803},
{"county":"Cedar County","state":"Iowa","id":804},
{"county":"Cerro Gordo County","state":"Iowa","id":805},
{"county":"Cherokee County","state":"Iowa","id":806},
{"county":"Chickasaw County","state":"Iowa","id":807},
{"county":"Clarke County","state":"Iowa","id":808},
{"county":"Clay County","state":"Iowa","id":809},
{"county":"Clayton County","state":"Iowa","id":810},
{"county":"Clinton County","state":"Iowa","id":811},
{"county":"Crawford County","state":"Iowa","id":812},
{"county":"Dallas County","state":"Iowa","id":813},
{"county":"Davis County","state":"Iowa","id":814},
{"county":"Decatur County","state":"Iowa","id":815},
{"county":"Delaware County","state":"Iowa","id":816},
{"county":"Des Moines County","state":"Iowa","id":817},
{"county":"Dickinson County","state":"Iowa","id":818},
{"county":"Dubuque County","state":"Iowa","id":819},
{"county":"Emmet County","state":"Iowa","id":820},
{"county":"Fayette County","state":"Iowa","id":821},
{"county":"Floyd County","state":"Iowa","id":822},
{"county":"Franklin County","state":"Iowa","id":823},
{"county":"Fremont County","state":"Iowa","id":824},
{"county":"Greene County","state":"Iowa","id":825},
{"county":"Grundy County","state":"Iowa","id":826},
{"county":"Guthrie County","state":"Iowa","id":827},
{"county":"Hamilton County","state":"Iowa","id":828},
{"county":"Hancock County","state":"Iowa","id":829},
{"county":"Hardin County","state":"Iowa","id":830},
{"county":"Harrison County","state":"Iowa","id":831},
{"county":"Henry County","state":"Iowa","id":832},
{"county":"Howard County","state":"Iowa","id":833},
{"county":"Humboldt County","state":"Iowa","id":834},
{"county":"Ida County","state":"Iowa","id":835},
{"county":"Iowa County","state":"Iowa","id":836},
{"county":"Jackson County","state":"Iowa","id":837},
{"county":"Jasper County","state":"Iowa","id":838},
{"county":"Jefferson County","state":"Iowa","id":839},
{"county":"Johnson County","state":"Iowa","id":840},
{"county":"Jones County","state":"Iowa","id":841},
{"county":"Keokuk County","state":"Iowa","id":842},
{"county":"Kossuth County","state":"Iowa","id":843},
{"county":"Lee County","state":"Iowa","id":844},
{"county":"Linn County","state":"Iowa","id":845},
{"county":"Louisa County","state":"Iowa","id":846},
{"county":"Lucas County","state":"Iowa","id":847},
{"county":"Lyon County","state":"Iowa","id":848},
{"county":"Madison County","state":"Iowa","id":849},
{"county":"Mahaska County","state":"Iowa","id":850},
{"county":"Marion County","state":"Iowa","id":851},
{"county":"Marshall County","state":"Iowa","id":852},
{"county":"Mills County","state":"Iowa","id":853},
{"county":"Mitchell County","state":"Iowa","id":854},
{"county":"Monona County","state":"Iowa","id":855},
{"county":"Monroe County","state":"Iowa","id":856},
{"county":"Montgomery County","state":"Iowa","id":857},
{"county":"Muscatine County","state":"Iowa","id":858},
{"county":"O'Brien County","state":"Iowa","id":859},
{"county":"Osceola County","state":"Iowa","id":860},
{"county":"Page County","state":"Iowa","id":861},
{"county":"Palo Alto County","state":"Iowa","id":862},
{"county":"Plymouth County","state":"Iowa","id":863},
{"county":"Pocahontas County","state":"Iowa","id":864},
{"county":"Polk County","state":"Iowa","id":865},
{"county":"Pottawattamie County","state":"Iowa","id":866},
{"county":"Poweshiek County","state":"Iowa","id":867},
{"county":"Ringgold County","state":"Iowa","id":868},
{"county":"Sac County","state":"Iowa","id":869},
{"county":"Scott County","state":"Iowa","id":870},
{"county":"Shelby County","state":"Iowa","id":871},
{"county":"Sioux County","state":"Iowa","id":872},
{"county":"Story County","state":"Iowa","id":873},
{"county":"Tama County","state":"Iowa","id":874},
{"county":"Taylor County","state":"Iowa","id":875},
{"county":"Union County","state":"Iowa","id":876},
{"county":"Van Buren County","state":"Iowa","id":877},
{"county":"Wapello County","state":"Iowa","id":878},
{"county":"Warren County","state":"Iowa","id":879},
{"county":"Washington County","state":"Iowa","id":880},
{"county":"Wayne County","state":"Iowa","id":881},
{"county":"Webster County","state":"Iowa","id":882},
{"county":"Winnebago County","state":"Iowa","id":883},
{"county":"Winneshiek County","state":"Iowa","id":884},
{"county":"Woodbury County","state":"Iowa","id":885},
{"county":"Worth County","state":"Iowa","id":886},
{"county":"Wright County","state":"Iowa","id":887},
{"county":"Allen County","state":"Kansas","id":888},
{"county":"Anderson County","state":"Kansas","id":889},
{"county":"Atchison County","state":"Kansas","id":890},
{"county":"Barber County","state":"Kansas","id":891},
{"county":"Barton County","state":"Kansas","id":892},
{"county":"Bourbon County","state":"Kansas","id":893},
{"county":"Brown County","state":"Kansas","id":894},
{"county":"Butler County","state":"Kansas","id":895},
{"county":"Chase County","state":"Kansas","id":896},
{"county":"Chautauqua County","state":"Kansas","id":897},
{"county":"Cherokee County","state":"Kansas","id":898},
{"county":"Cheyenne County","state":"Kansas","id":899},
{"county":"Clark County","state":"Kansas","id":900},
{"county":"Clay County","state":"Kansas","id":901},
{"county":"Cloud County","state":"Kansas","id":902},
{"county":"Coffey County","state":"Kansas","id":903},
{"county":"Comanche County","state":"Kansas","id":904},
{"county":"Cowley County","state":"Kansas","id":905},
{"county":"Crawford County","state":"Kansas","id":906},
{"county":"Decatur County","state":"Kansas","id":907},
{"county":"Dickinson County","state":"Kansas","id":908},
{"county":"Doniphan County","state":"Kansas","id":909},
{"county":"Douglas County","state":"Kansas","id":910},
{"county":"Edwards County","state":"Kansas","id":911},
{"county":"Elk County","state":"Kansas","id":912},
{"county":"Ellis County","state":"Kansas","id":913},
{"county":"Ellsworth County","state":"Kansas","id":914},
{"county":"Finney County","state":"Kansas","id":915},
{"county":"Ford County","state":"Kansas","id":916},
{"county":"Franklin County","state":"Kansas","id":917},
{"county":"Geary County","state":"Kansas","id":918},
{"county":"Gove County","state":"Kansas","id":919},
{"county":"Graham County","state":"Kansas","id":920},
{"county":"Grant County","state":"Kansas","id":921},
{"county":"Gray County","state":"Kansas","id":922},
{"county":"Greeley County","state":"Kansas","id":923},
{"county":"Greenwood County","state":"Kansas","id":924},
{"county":"Hamilton County","state":"Kansas","id":925},
{"county":"Harper County","state":"Kansas","id":926},
{"county":"Harvey County","state":"Kansas","id":927},
{"county":"Haskell County","state":"Kansas","id":928},
{"county":"Hodgeman County","state":"Kansas","id":929},
{"county":"Jackson County","state":"Kansas","id":930},
{"county":"Jefferson County","state":"Kansas","id":931},
{"county":"Jewell County","state":"Kansas","id":932},
{"county":"Johnson County","state":"Kansas","id":933},
{"county":"Kearny County","state":"Kansas","id":934},
{"county":"Kingman County","state":"Kansas","id":935},
{"county":"Kiowa County","state":"Kansas","id":936},
{"county":"Labette County","state":"Kansas","id":937},
{"county":"Lane County","state":"Kansas","id":938},
{"county":"Leavenworth County","state":"Kansas","id":939},
{"county":"Lincoln County","state":"Kansas","id":940},
{"county":"Linn County","state":"Kansas","id":941},
{"county":"Logan County","state":"Kansas","id":942},
{"county":"Lyon County","state":"Kansas","id":943},
{"county":"McPherson County","state":"Kansas","id":944},
{"county":"Marion County","state":"Kansas","id":945},
{"county":"Marshall County","state":"Kansas","id":946},
{"county":"Meade County","state":"Kansas","id":947},
{"county":"Miami County","state":"Kansas","id":948},
{"county":"Mitchell County","state":"Kansas","id":949},
{"county":"Montgomery County","state":"Kansas","id":950},
{"county":"Morris County","state":"Kansas","id":951},
{"county":"Morton County","state":"Kansas","id":952},
{"county":"Nemaha County","state":"Kansas","id":953},
{"county":"Neosho County","state":"Kansas","id":954},
{"county":"Ness County","state":"Kansas","id":955},
{"county":"Norton County","state":"Kansas","id":956},
{"county":"Osage County","state":"Kansas","id":957},
{"county":"Osborne County","state":"Kansas","id":958},
{"county":"Ottawa County","state":"Kansas","id":959},
{"county":"Pawnee County","state":"Kansas","id":960},
{"county":"Phillips County","state":"Kansas","id":961},
{"county":"Pottawatomie County","state":"Kansas","id":962},
{"county":"Pratt County","state":"Kansas","id":963},
{"county":"Rawlins County","state":"Kansas","id":964},
{"county":"Reno County","state":"Kansas","id":965},
{"county":"Republic County","state":"Kansas","id":966},
{"county":"Rice County","state":"Kansas","id":967},
{"county":"Riley County","state":"Kansas","id":968},
{"county":"Rooks County","state":"Kansas","id":969},
{"county":"Rush County","state":"Kansas","id":970},
{"county":"Russell County","state":"Kansas","id":971},
{"county":"Saline County","state":"Kansas","id":972},
{"county":"Scott County","state":"Kansas","id":973},
{"county":"Sedgwick County","state":"Kansas","id":974},
{"county":"Seward County","state":"Kansas","id":975},
{"county":"Shawnee County","state":"Kansas","id":976},
{"county":"Sheridan County","state":"Kansas","id":977},
{"county":"Sherman County","state":"Kansas","id":978},
{"county":"Smith County","state":"Kansas","id":979},
{"county":"Stafford County","state":"Kansas","id":980},
{"county":"Stanton County","state":"Kansas","id":981},
{"county":"Stevens County","state":"Kansas","id":982},
{"county":"Sumner County","state":"Kansas","id":983},
{"county":"Thomas County","state":"Kansas","id":984},
{"county":"Trego County","state":"Kansas","id":985},
{"county":"Wabaunsee County","state":"Kansas","id":986},
{"county":"Wallace County","state":"Kansas","id":987},
{"county":"Washington County","state":"Kansas","id":988},
{"county":"Wichita County","state":"Kansas","id":989},
{"county":"Wilson County","state":"Kansas","id":990},
{"county":"Woodson County","state":"Kansas","id":991},
{"county":"Wyandotte County","state":"Kansas","id":992},
{"county":"Adair County","state":"Kentucky","id":993},
{"county":"Allen County","state":"Kentucky","id":994},
{"county":"Anderson County","state":"Kentucky","id":995},
{"county":"Ballard County","state":"Kentucky","id":996},
{"county":"Barren County","state":"Kentucky","id":997},
{"county":"Bath County","state":"Kentucky","id":998},
{"county":"Bell County","state":"Kentucky","id":999},
{"county":"Boone County","state":"Kentucky","id":1000},
{"county":"Bourbon County","state":"Kentucky","id":1001},
{"county":"Boyd County","state":"Kentucky","id":1002},
{"county":"Boyle County","state":"Kentucky","id":1003},
{"county":"Bracken County","state":"Kentucky","id":1004},
{"county":"Breathitt County","state":"Kentucky","id":1005},
{"county":"Breckinridge County","state":"Kentucky","id":1006},
{"county":"Bullitt County","state":"Kentucky","id":1007},
{"county":"Butler County","state":"Kentucky","id":1008},
{"county":"Caldwell County","state":"Kentucky","id":1009},
{"county":"Calloway County","state":"Kentucky","id":1010},
{"county":"Campbell County","state":"Kentucky","id":1011},
{"county":"Carlisle County","state":"Kentucky","id":1012},
{"county":"Carroll County","state":"Kentucky","id":1013},
{"county":"Carter County","state":"Kentucky","id":1014},
{"county":"Casey County","state":"Kentucky","id":1015},
{"county":"Christian County","state":"Kentucky","id":1016},
{"county":"Clark County","state":"Kentucky","id":1017},
{"county":"Clay County","state":"Kentucky","id":1018},
{"county":"Clinton County","state":"Kentucky","id":1019},
{"county":"Crittenden County","state":"Kentucky","id":1020},
{"county":"Cumberland County","state":"Kentucky","id":1021},
{"county":"Daviess County","state":"Kentucky","id":1022},
{"county":"Edmonson County","state":"Kentucky","id":1023},
{"county":"Elliott County","state":"Kentucky","id":1024},
{"county":"Estill County","state":"Kentucky","id":1025},
{"county":"Fayette County","state":"Kentucky","id":1026},
{"county":"Fleming County","state":"Kentucky","id":1027},
{"county":"Floyd County","state":"Kentucky","id":1028},
{"county":"Franklin County","state":"Kentucky","id":1029},
{"county":"Fulton County","state":"Kentucky","id":1030},
{"county":"Gallatin County","state":"Kentucky","id":1031},
{"county":"Garrard County","state":"Kentucky","id":1032},
{"county":"Grant County","state":"Kentucky","id":1033},
{"county":"Graves County","state":"Kentucky","id":1034},
{"county":"Grayson County","state":"Kentucky","id":1035},
{"county":"Green County","state":"Kentucky","id":1036},
{"county":"Greenup County","state":"Kentucky","id":1037},
{"county":"Hancock County","state":"Kentucky","id":1038},
{"county":"Hardin County","state":"Kentucky","id":1039},
{"county":"Harlan County","state":"Kentucky","id":1040},
{"county":"Harrison County","state":"Kentucky","id":1041},
{"county":"Hart County","state":"Kentucky","id":1042},
{"county":"Henderson County","state":"Kentucky","id":1043},
{"county":"Henry County","state":"Kentucky","id":1044},
{"county":"Hickman County","state":"Kentucky","id":1045},
{"county":"Hopkins County","state":"Kentucky","id":1046},
{"county":"Jackson County","state":"Kentucky","id":1047},
{"county":"Jefferson County","state":"Kentucky","id":1048},
{"county":"Jessamine County","state":"Kentucky","id":1049},
{"county":"Johnson County","state":"Kentucky","id":1050},
{"county":"Kenton County","state":"Kentucky","id":1051},
{"county":"Knott County","state":"Kentucky","id":1052},
{"county":"Knox County","state":"Kentucky","id":1053},
{"county":"Larue County","state":"Kentucky","id":1054},
{"county":"Laurel County","state":"Kentucky","id":1055},
{"county":"Lawrence County","state":"Kentucky","id":1056},
{"county":"Lee County","state":"Kentucky","id":1057},
{"county":"Leslie County","state":"Kentucky","id":1058},
{"county":"Letcher County","state":"Kentucky","id":1059},
{"county":"Lewis County","state":"Kentucky","id":1060},
{"county":"Lincoln County","state":"Kentucky","id":1061},
{"county":"Livingston County","state":"Kentucky","id":1062},
{"county":"Logan County","state":"Kentucky","id":1063},
{"county":"Lyon County","state":"Kentucky","id":1064},
{"county":"McCracken County","state":"Kentucky","id":1065},
{"county":"McCreary County","state":"Kentucky","id":1066},
{"county":"McLean County","state":"Kentucky","id":1067},
{"county":"Madison County","state":"Kentucky","id":1068},
{"county":"Magoffin County","state":"Kentucky","id":1069},
{"county":"Marion County","state":"Kentucky","id":1070},
{"county":"Marshall County","state":"Kentucky","id":1071},
{"county":"Martin County","state":"Kentucky","id":1072},
{"county":"Mason County","state":"Kentucky","id":1073},
{"county":"Meade County","state":"Kentucky","id":1074},
{"county":"Menifee County","state":"Kentucky","id":1075},
{"county":"Mercer County","state":"Kentucky","id":1076},
{"county":"Metcalfe County","state":"Kentucky","id":1077},
{"county":"Monroe County","state":"Kentucky","id":1078},
{"county":"Montgomery County","state":"Kentucky","id":1079},
{"county":"Morgan County","state":"Kentucky","id":1080},
{"county":"Muhlenberg County","state":"Kentucky","id":1081},
{"county":"Nelson County","state":"Kentucky","id":1082},
{"county":"Nicholas County","state":"Kentucky","id":1083},
{"county":"Ohio County","state":"Kentucky","id":1084},
{"county":"Oldham County","state":"Kentucky","id":1085},
{"county":"Owen County","state":"Kentucky","id":1086},
{"county":"Owsley County","state":"Kentucky","id":1087},
{"county":"Pendleton County","state":"Kentucky","id":1088},
{"county":"Perry County","state":"Kentucky","id":1089},
{"county":"Pike County","state":"Kentucky","id":1090},
{"county":"Powell County","state":"Kentucky","id":1091},
{"county":"Pulaski County","state":"Kentucky","id":1092},
{"county":"Robertson County","state":"Kentucky","id":1093},
{"county":"Rockcastle County","state":"Kentucky","id":1094},
{"county":"Rowan County","state":"Kentucky","id":1095},
{"county":"Russell County","state":"Kentucky","id":1096},
{"county":"Scott County","state":"Kentucky","id":1097},
{"county":"Shelby County","state":"Kentucky","id":1098},
{"county":"Simpson County","state":"Kentucky","id":1099},
{"county":"Spencer County","state":"Kentucky","id":1100},
{"county":"Taylor County","state":"Kentucky","id":1101},
{"county":"Todd County","state":"Kentucky","id":1102},
{"county":"Trigg County","state":"Kentucky","id":1103},
{"county":"Trimble County","state":"Kentucky","id":1104},
{"county":"Union County","state":"Kentucky","id":1105},
{"county":"Warren County","state":"Kentucky","id":1106},
{"county":"Washington County","state":"Kentucky","id":1107},
{"county":"Wayne County","state":"Kentucky","id":1108},
{"county":"Webster County","state":"Kentucky","id":1109},
{"county":"Whitley County","state":"Kentucky","id":1110},
{"county":"Wolfe County","state":"Kentucky","id":1111},
{"county":"Woodford County","state":"Kentucky","id":1112},
{"county":"Acadia Parish","state":"Louisiana","id":1113},
{"county":"Allen Parish","state":"Louisiana","id":1114},
{"county":"Ascension Parish","state":"Louisiana","id":1115},
{"county":"Assumption Parish","state":"Louisiana","id":1116},
{"county":"Avoyelles Parish","state":"Louisiana","id":1117},
{"county":"Beauregard Parish","state":"Louisiana","id":1118},
{"county":"Bienville Parish","state":"Louisiana","id":1119},
{"county":"Bossier Parish","state":"Louisiana","id":1120},
{"county":"Caddo Parish","state":"Louisiana","id":1121},
{"county":"Calcasieu Parish","state":"Louisiana","id":1122},
{"county":"Caldwell Parish","state":"Louisiana","id":1123},
{"county":"Cameron Parish","state":"Louisiana","id":1124},
{"county":"Catahoula Parish","state":"Louisiana","id":1125},
{"county":"Claiborne Parish","state":"Louisiana","id":1126},
{"county":"Concordia Parish","state":"Louisiana","id":1127},
{"county":"De Soto Parish","state":"Louisiana","id":1128},
{"county":"East Baton Rouge Parish","state":"Louisiana","id":1129},
{"county":"East Carroll Parish","state":"Louisiana","id":1130},
{"county":"East Feliciana Parish","state":"Louisiana","id":1131},
{"county":"Evangeline Parish","state":"Louisiana","id":1132},
{"county":"Franklin Parish","state":"Louisiana","id":1133},
{"county":"Grant Parish","state":"Louisiana","id":1134},
{"county":"Iberia Parish","state":"Louisiana","id":1135},
{"county":"Iberville Parish","state":"Louisiana","id":1136},
{"county":"Jackson Parish","state":"Louisiana","id":1137},
{"county":"Jefferson Parish","state":"Louisiana","id":1138},
{"county":"Jefferson Davis Parish","state":"Louisiana","id":1139},
{"county":"Lafayette Parish","state":"Louisiana","id":1140},
{"county":"Lafourche Parish","state":"Louisiana","id":1141},
{"county":"LaSalle Parish","state":"Louisiana","id":1142},
{"county":"Lincoln Parish","state":"Louisiana","id":1143},
{"county":"Livingston Parish","state":"Louisiana","id":1144},
{"county":"Madison Parish","state":"Louisiana","id":1145},
{"county":"Morehouse Parish","state":"Louisiana","id":1146},
{"county":"Natchitoches Parish","state":"Louisiana","id":1147},
{"county":"Orleans Parish","state":"Louisiana","id":1148},
{"county":"Ouachita Parish","state":"Louisiana","id":1149},
{"county":"Plaquemines Parish","state":"Louisiana","id":1150},
{"county":"Pointe Coupee Parish","state":"Louisiana","id":1151},
{"county":"Rapides Parish","state":"Louisiana","id":1152},
{"county":"Red River Parish","state":"Louisiana","id":1153},
{"county":"Richland Parish","state":"Louisiana","id":1154},
{"county":"Sabine Parish","state":"Louisiana","id":1155},
{"county":"St. Bernard Parish","state":"Louisiana","id":1156},
{"county":"St. Charles Parish","state":"Louisiana","id":1157},
{"county":"St. Helena Parish","state":"Louisiana","id":1158},
{"county":"St. James Parish","state":"Louisiana","id":1159},
{"county":"St. John the Baptist Parish","state":"Louisiana","id":1160},
{"county":"St. Landry Parish","state":"Louisiana","id":1161},
{"county":"St. Martin Parish","state":"Louisiana","id":1162},
{"county":"St. Mary Parish","state":"Louisiana","id":1163},
{"county":"St. Tammany Parish","state":"Louisiana","id":1164},
{"county":"Tangipahoa Parish","state":"Louisiana","id":1165},
{"county":"Tensas Parish","state":"Louisiana","id":1166},
{"county":"Terrebonne Parish","state":"Louisiana","id":1167},
{"county":"Union Parish","state":"Louisiana","id":1168},
{"county":"Vermilion Parish","state":"Louisiana","id":1169},
{"county":"Vernon Parish","state":"Louisiana","id":1170},
{"county":"Washington Parish","state":"Louisiana","id":1171},
{"county":"Webster Parish","state":"Louisiana","id":1172},
{"county":"West Baton Rouge Parish","state":"Louisiana","id":1173},
{"county":"West Carroll Parish","state":"Louisiana","id":1174},
{"county":"West Feliciana Parish","state":"Louisiana","id":1175},
{"county":"Winn Parish","state":"Louisiana","id":1176},
{"county":"Androscoggin County","state":"Maine","id":1177},
{"county":"Aroostook County","state":"Maine","id":1178},
{"county":"Cumberland County","state":"Maine","id":1179},
{"county":"Franklin County","state":"Maine","id":1180},
{"county":"Hancock County","state":"Maine","id":1181},
{"county":"Kennebec County","state":"Maine","id":1182},
{"county":"Knox County","state":"Maine","id":1183},
{"county":"Lincoln County","state":"Maine","id":1184},
{"county":"Oxford County","state":"Maine","id":1185},
{"county":"Penobscot County","state":"Maine","id":1186},
{"county":"Piscataquis County","state":"Maine","id":1187},
{"county":"Sagadahoc County","state":"Maine","id":1188},
{"county":"Somerset County","state":"Maine","id":1189},
{"county":"Waldo County","state":"Maine","id":1190},
{"county":"Washington County","state":"Maine","id":1191},
{"county":"York County","state":"Maine","id":1192},
{"county":"Allegany County","state":"Maryland","id":1193},
{"county":"Anne Arundel County","state":"Maryland","id":1194},
{"county":"Baltimore County","state":"Maryland","id":1195},
{"county":"Calvert County","state":"Maryland","id":1196},
{"county":"Caroline County","state":"Maryland","id":1197},
{"county":"Carroll County","state":"Maryland","id":1198},
{"county":"Cecil County","state":"Maryland","id":1199},
{"county":"Charles County","state":"Maryland","id":1200},
{"county":"Dorchester County","state":"Maryland","id":1201},
{"county":"Frederick County","state":"Maryland","id":1202},
{"county":"Garrett County","state":"Maryland","id":1203},
{"county":"Harford County","state":"Maryland","id":1204},
{"county":"Howard County","state":"Maryland","id":1205},
{"county":"Kent County","state":"Maryland","id":1206},
{"county":"Montgomery County","state":"Maryland","id":1207},
{"county":"Prince George's County","state":"Maryland","id":1208},
{"county":"Queen Anne's County","state":"Maryland","id":1209},
{"county":"St. Mary's County","state":"Maryland","id":1210},
{"county":"Somerset County","state":"Maryland","id":1211},
{"county":"Talbot County","state":"Maryland","id":1212},
{"county":"Washington County","state":"Maryland","id":1213},
{"county":"Wicomico County","state":"Maryland","id":1214},
{"county":"Worcester County","state":"Maryland","id":1215},
{"county":"Baltimore, City of","state":"Maryland","id":1216},
{"county":"Barnstable County","state":"Massachusetts","id":1217},
{"county":"Berkshire County","state":"Massachusetts","id":1218},
{"county":"Bristol County","state":"Massachusetts","id":1219},
{"county":"Dukes County","state":"Massachusetts","id":1220},
{"county":"Essex County","state":"Massachusetts","id":1221},
{"county":"Franklin County","state":"Massachusetts","id":1222},
{"county":"Hampden County","state":"Massachusetts","id":1223},
{"county":"Hampshire County","state":"Massachusetts","id":1224},
{"county":"Middlesex County","state":"Massachusetts","id":1225},
{"county":"Nantucket, Town and County of","state":"Massachusetts","id":1226},
{"county":"Norfolk County","state":"Massachusetts","id":1227},
{"county":"Plymouth County","state":"Massachusetts","id":1228},
{"county":"Suffolk County","state":"Massachusetts","id":1229},
{"county":"Worcester County","state":"Massachusetts","id":1230},
{"county":"Alcona County","state":"Michigan","id":1231},
{"county":"Alger County","state":"Michigan","id":1232},
{"county":"Allegan County","state":"Michigan","id":1233},
{"county":"Alpena County","state":"Michigan","id":1234},
{"county":"Antrim County","state":"Michigan","id":1235},
{"county":"Arenac County","state":"Michigan","id":1236},
{"county":"Baraga County","state":"Michigan","id":1237},
{"county":"Barry County","state":"Michigan","id":1238},
{"county":"Bay County","state":"Michigan","id":1239},
{"county":"Benzie County","state":"Michigan","id":1240},
{"county":"Berrien County","state":"Michigan","id":1241},
{"county":"Branch County","state":"Michigan","id":1242},
{"county":"Calhoun County","state":"Michigan","id":1243},
{"county":"Cass County","state":"Michigan","id":1244},
{"county":"Charlevoix County","state":"Michigan","id":1245},
{"county":"Cheboygan County","state":"Michigan","id":1246},
{"county":"Chippewa County","state":"Michigan","id":1247},
{"county":"Clare County","state":"Michigan","id":1248},
{"county":"Clinton County","state":"Michigan","id":1249},
{"county":"Crawford County","state":"Michigan","id":1250},
{"county":"Delta County","state":"Michigan","id":1251},
{"county":"Dickinson County","state":"Michigan","id":1252},
{"county":"Eaton County","state":"Michigan","id":1253},
{"county":"Emmet County","state":"Michigan","id":1254},
{"county":"Genesee County","state":"Michigan","id":1255},
{"county":"Gladwin County","state":"Michigan","id":1256},
{"county":"Gogebic County","state":"Michigan","id":1257},
{"county":"Grand Traverse County","state":"Michigan","id":1258},
{"county":"Gratiot County","state":"Michigan","id":1259},
{"county":"Hillsdale County","state":"Michigan","id":1260},
{"county":"Houghton County","state":"Michigan","id":1261},
{"county":"Huron County","state":"Michigan","id":1262},
{"county":"Ingham County","state":"Michigan","id":1263},
{"county":"Ionia County","state":"Michigan","id":1264},
{"county":"Iosco County","state":"Michigan","id":1265},
{"county":"Iron County","state":"Michigan","id":1266},
{"county":"Isabella County","state":"Michigan","id":1267},
{"county":"Jackson County","state":"Michigan","id":1268},
{"county":"Kalamazoo County","state":"Michigan","id":1269},
{"county":"Kalkaska County","state":"Michigan","id":1270},
{"county":"Kent County","state":"Michigan","id":1271},
{"county":"Keweenaw County","state":"Michigan","id":1272},
{"county":"Lake County","state":"Michigan","id":1273},
{"county":"Lapeer County","state":"Michigan","id":1274},
{"county":"Leelanau County","state":"Michigan","id":1275},
{"county":"Lenawee County","state":"Michigan","id":1276},
{"county":"Livingston County","state":"Michigan","id":1277},
{"county":"Luce County","state":"Michigan","id":1278},
{"county":"Mackinac County","state":"Michigan","id":1279},
{"county":"Macomb County","state":"Michigan","id":1280},
{"county":"Manistee County","state":"Michigan","id":1281},
{"county":"Marquette County","state":"Michigan","id":1282},
{"county":"Mason County","state":"Michigan","id":1283},
{"county":"Mecosta County","state":"Michigan","id":1284},
{"county":"Menominee County","state":"Michigan","id":1285},
{"county":"Midland County","state":"Michigan","id":1286},
{"county":"Missaukee County","state":"Michigan","id":1287},
{"county":"Monroe County","state":"Michigan","id":1288},
{"county":"Montcalm County","state":"Michigan","id":1289},
{"county":"Montmorency County","state":"Michigan","id":1290},
{"county":"Muskegon County","state":"Michigan","id":1291},
{"county":"Newaygo County","state":"Michigan","id":1292},
{"county":"Oakland County","state":"Michigan","id":1293},
{"county":"Oceana County","state":"Michigan","id":1294},
{"county":"Ogemaw County","state":"Michigan","id":1295},
{"county":"Ontonagon County","state":"Michigan","id":1296},
{"county":"Osceola County","state":"Michigan","id":1297},
{"county":"Oscoda County","state":"Michigan","id":1298},
{"county":"Otsego County","state":"Michigan","id":1299},
{"county":"Ottawa County","state":"Michigan","id":1300},
{"county":"Presque Isle County","state":"Michigan","id":1301},
{"county":"Roscommon County","state":"Michigan","id":1302},
{"county":"Saginaw County","state":"Michigan","id":1303},
{"county":"St. Clair County","state":"Michigan","id":1304},
{"county":"St. Joseph County","state":"Michigan","id":1305},
{"county":"Sanilac County","state":"Michigan","id":1306},
{"county":"Schoolcraft County","state":"Michigan","id":1307},
{"county":"Shiawassee County","state":"Michigan","id":1308},
{"county":"Tuscola County","state":"Michigan","id":1309},
{"county":"Van Buren County","state":"Michigan","id":1310},
{"county":"Washtenaw County","state":"Michigan","id":1311},
{"county":"Wayne County","state":"Michigan","id":1312},
{"county":"Wexford County","state":"Michigan","id":1313},
{"county":"Aitkin County","state":"Minnesota","id":1314},
{"county":"Anoka County","state":"Minnesota","id":1315},
{"county":"Becker County","state":"Minnesota","id":1316},
{"county":"Beltrami County","state":"Minnesota","id":1317},
{"county":"Benton County","state":"Minnesota","id":1318},
{"county":"Big Stone County","state":"Minnesota","id":1319},
{"county":"Blue Earth County","state":"Minnesota","id":1320},
{"county":"Brown County","state":"Minnesota","id":1321},
{"county":"Carlton County","state":"Minnesota","id":1322},
{"county":"Carver County","state":"Minnesota","id":1323},
{"county":"Cass County","state":"Minnesota","id":1324},
{"county":"Chippewa County","state":"Minnesota","id":1325},
{"county":"Chisago County","state":"Minnesota","id":1326},
{"county":"Clay County","state":"Minnesota","id":1327},
{"county":"Clearwater County","state":"Minnesota","id":1328},
{"county":"Cook County","state":"Minnesota","id":1329},
{"county":"Cottonwood County","state":"Minnesota","id":1330},
{"county":"Crow Wing County","state":"Minnesota","id":1331},
{"county":"Dakota County","state":"Minnesota","id":1332},
{"county":"Dodge County","state":"Minnesota","id":1333},
{"county":"Douglas County","state":"Minnesota","id":1334},
{"county":"Faribault County","state":"Minnesota","id":1335},
{"county":"Fillmore County","state":"Minnesota","id":1336},
{"county":"Freeborn County","state":"Minnesota","id":1337},
{"county":"Goodhue County","state":"Minnesota","id":1338},
{"county":"Grant County","state":"Minnesota","id":1339},
{"county":"Hennepin County","state":"Minnesota","id":1340},
{"county":"Houston County","state":"Minnesota","id":1341},
{"county":"Hubbard County","state":"Minnesota","id":1342},
{"county":"Isanti County","state":"Minnesota","id":1343},
{"county":"Itasca County","state":"Minnesota","id":1344},
{"county":"Jackson County","state":"Minnesota","id":1345},
{"county":"Kanabec County","state":"Minnesota","id":1346},
{"county":"Kandiyohi County","state":"Minnesota","id":1347},
{"county":"Kittson County","state":"Minnesota","id":1348},
{"county":"Koochiching County","state":"Minnesota","id":1349},
{"county":"Lac qui Parle County","state":"Minnesota","id":1350},
{"county":"Lake County","state":"Minnesota","id":1351},
{"county":"Lake of the Woods County","state":"Minnesota","id":1352},
{"county":"Le Sueur County","state":"Minnesota","id":1353},
{"county":"Lincoln County","state":"Minnesota","id":1354},
{"county":"Lyon County","state":"Minnesota","id":1355},
{"county":"McLeod County","state":"Minnesota","id":1356},
{"county":"Mahnomen County","state":"Minnesota","id":1357},
{"county":"Marshall County","state":"Minnesota","id":1358},
{"county":"Martin County","state":"Minnesota","id":1359},
{"county":"Meeker County","state":"Minnesota","id":1360},
{"county":"Mille Lacs County","state":"Minnesota","id":1361},
{"county":"Morrison County","state":"Minnesota","id":1362},
{"county":"Mower County","state":"Minnesota","id":1363},
{"county":"Murray County","state":"Minnesota","id":1364},
{"county":"Nicollet County","state":"Minnesota","id":1365},
{"county":"Nobles County","state":"Minnesota","id":1366},
{"county":"Norman County","state":"Minnesota","id":1367},
{"county":"Olmsted County","state":"Minnesota","id":1368},
{"county":"Otter Tail County","state":"Minnesota","id":1369},
{"county":"Pennington County","state":"Minnesota","id":1370},
{"county":"Pine County","state":"Minnesota","id":1371},
{"county":"Pipestone County","state":"Minnesota","id":1372},
{"county":"Polk County","state":"Minnesota","id":1373},
{"county":"Pope County","state":"Minnesota","id":1374},
{"county":"Ramsey County","state":"Minnesota","id":1375},
{"county":"Red Lake County","state":"Minnesota","id":1376},
{"county":"Redwood County","state":"Minnesota","id":1377},
{"county":"Renville County","state":"Minnesota","id":1378},
{"county":"Rice County","state":"Minnesota","id":1379},
{"county":"Rock County","state":"Minnesota","id":1380},
{"county":"Roseau County","state":"Minnesota","id":1381},
{"county":"St. Louis County","state":"Minnesota","id":1382},
{"county":"Scott County","state":"Minnesota","id":1383},
{"county":"Sherburne County","state":"Minnesota","id":1384},
{"county":"Sibley County","state":"Minnesota","id":1385},
{"county":"Stearns County","state":"Minnesota","id":1386},
{"county":"Steele County","state":"Minnesota","id":1387},
{"county":"Stevens County","state":"Minnesota","id":1388},
{"county":"Swift County","state":"Minnesota","id":1389},
{"county":"Todd County","state":"Minnesota","id":1390},
{"county":"Traverse County","state":"Minnesota","id":1391},
{"county":"Wabasha County","state":"Minnesota","id":1392},
{"county":"Wadena County","state":"Minnesota","id":1393},
{"county":"Waseca County","state":"Minnesota","id":1394},
{"county":"Washington County","state":"Minnesota","id":1395},
{"county":"Watonwan County","state":"Minnesota","id":1396},
{"county":"Wilkin County","state":"Minnesota","id":1397},
{"county":"Winona County","state":"Minnesota","id":1398},
{"county":"Wright County","state":"Minnesota","id":1399},
{"county":"Yellow Medicine County","state":"Minnesota","id":1400},
{"county":"Adams County","state":"Mississippi","id":1401},
{"county":"Alcorn County","state":"Mississippi","id":1402},
{"county":"Amite County","state":"Mississippi","id":1403},
{"county":"Attala County","state":"Mississippi","id":1404},
{"county":"Benton County","state":"Mississippi","id":1405},
{"county":"Bolivar County","state":"Mississippi","id":1406},
{"county":"Calhoun County","state":"Mississippi","id":1407},
{"county":"Carroll County","state":"Mississippi","id":1408},
{"county":"Chickasaw County","state":"Mississippi","id":1409},
{"county":"Choctaw County","state":"Mississippi","id":1410},
{"county":"Claiborne County","state":"Mississippi","id":1411},
{"county":"Clarke County","state":"Mississippi","id":1412},
{"county":"Clay County","state":"Mississippi","id":1413},
{"county":"Coahoma County","state":"Mississippi","id":1414},
{"county":"Copiah County","state":"Mississippi","id":1415},
{"county":"Covington County","state":"Mississippi","id":1416},
{"county":"DeSoto County","state":"Mississippi","id":1417},
{"county":"Forrest County","state":"Mississippi","id":1418},
{"county":"Franklin County","state":"Mississippi","id":1419},
{"county":"George County","state":"Mississippi","id":1420},
{"county":"Greene County","state":"Mississippi","id":1421},
{"county":"Grenada County","state":"Mississippi","id":1422},
{"county":"Hancock County","state":"Mississippi","id":1423},
{"county":"Harrison County","state":"Mississippi","id":1424},
{"county":"Hinds County","state":"Mississippi","id":1425},
{"county":"Holmes County","state":"Mississippi","id":1426},
{"county":"Humphreys County","state":"Mississippi","id":1427},
{"county":"Issaquena County","state":"Mississippi","id":1428},
{"county":"Itawamba County","state":"Mississippi","id":1429},
{"county":"Jackson County","state":"Mississippi","id":1430},
{"county":"Jasper County","state":"Mississippi","id":1431},
{"county":"Jefferson County","state":"Mississippi","id":1432},
{"county":"Jefferson Davis County","state":"Mississippi","id":1433},
{"county":"Jones County","state":"Mississippi","id":1434},
{"county":"Kemper County","state":"Mississippi","id":1435},
{"county":"Lafayette County","state":"Mississippi","id":1436},
{"county":"Lamar County","state":"Mississippi","id":1437},
{"county":"Lauderdale County","state":"Mississippi","id":1438},
{"county":"Lawrence County","state":"Mississippi","id":1439},
{"county":"Leake County","state":"Mississippi","id":1440},
{"county":"Lee County","state":"Mississippi","id":1441},
{"county":"Leflore County","state":"Mississippi","id":1442},
{"county":"Lincoln County","state":"Mississippi","id":1443},
{"county":"Lowndes County","state":"Mississippi","id":1444},
{"county":"Madison County","state":"Mississippi","id":1445},
{"county":"Marion County","state":"Mississippi","id":1446},
{"county":"Marshall County","state":"Mississippi","id":1447},
{"county":"Monroe County","state":"Mississippi","id":1448},
{"county":"Montgomery County","state":"Mississippi","id":1449},
{"county":"Neshoba County","state":"Mississippi","id":1450},
{"county":"Newton County","state":"Mississippi","id":1451},
{"county":"Noxubee County","state":"Mississippi","id":1452},
{"county":"Oktibbeha County","state":"Mississippi","id":1453},
{"county":"Panola County","state":"Mississippi","id":1454},
{"county":"Pearl River County","state":"Mississippi","id":1455},
{"county":"Perry County","state":"Mississippi","id":1456},
{"county":"Pike County","state":"Mississippi","id":1457},
{"county":"Pontotoc County","state":"Mississippi","id":1458},
{"county":"Prentiss County","state":"Mississippi","id":1459},
{"county":"Quitman County","state":"Mississippi","id":1460},
{"county":"Rankin County","state":"Mississippi","id":1461},
{"county":"Scott County","state":"Mississippi","id":1462},
{"county":"Sharkey County","state":"Mississippi","id":1463},
{"county":"Simpson County","state":"Mississippi","id":1464},
{"county":"Smith County","state":"Mississippi","id":1465},
{"county":"Stone County","state":"Mississippi","id":1466},
{"county":"Sunflower County","state":"Mississippi","id":1467},
{"county":"Tallahatchie County","state":"Mississippi","id":1468},
{"county":"Tate County","state":"Mississippi","id":1469},
{"county":"Tippah County","state":"Mississippi","id":1470},
{"county":"Tishomingo County","state":"Mississippi","id":1471},
{"county":"Tunica County","state":"Mississippi","id":1472},
{"county":"Union County","state":"Mississippi","id":1473},
{"county":"Walthall County","state":"Mississippi","id":1474},
{"county":"Warren County","state":"Mississippi","id":1475},
{"county":"Washington County","state":"Mississippi","id":1476},
{"county":"Wayne County","state":"Mississippi","id":1477},
{"county":"Webster County","state":"Mississippi","id":1478},
{"county":"Wilkinson County","state":"Mississippi","id":1479},
{"county":"Winston County","state":"Mississippi","id":1480},
{"county":"Yalobusha County","state":"Mississippi","id":1481},
{"county":"Yazoo County","state":"Mississippi","id":1482},
{"county":"Adair County","state":"Missouri","id":1483},
{"county":"Andrew County","state":"Missouri","id":1484},
{"county":"Atchison County","state":"Missouri","id":1485},
{"county":"Audrain County","state":"Missouri","id":1486},
{"county":"Barry County","state":"Missouri","id":1487},
{"county":"Barton County","state":"Missouri","id":1488},
{"county":"Bates County","state":"Missouri","id":1489},
{"county":"Benton County","state":"Missouri","id":1490},
{"county":"Bollinger County","state":"Missouri","id":1491},
{"county":"Boone County","state":"Missouri","id":1492},
{"county":"Buchanan County","state":"Missouri","id":1493},
{"county":"Butler County","state":"Missouri","id":1494},
{"county":"Caldwell County","state":"Missouri","id":1495},
{"county":"Callaway County","state":"Missouri","id":1496},
{"county":"Camden County","state":"Missouri","id":1497},
{"county":"Cape Girardeau County","state":"Missouri","id":1498},
{"county":"Carroll County","state":"Missouri","id":1499},
{"county":"Carter County","state":"Missouri","id":1500},
{"county":"Cass County","state":"Missouri","id":1501},
{"county":"Cedar County","state":"Missouri","id":1502},
{"county":"Chariton County","state":"Missouri","id":1503},
{"county":"Christian County","state":"Missouri","id":1504},
{"county":"Clark County","state":"Missouri","id":1505},
{"county":"Clay County","state":"Missouri","id":1506},
{"county":"Clinton County","state":"Missouri","id":1507},
{"county":"Cole County","state":"Missouri","id":1508},
{"county":"Cooper County","state":"Missouri","id":1509},
{"county":"Crawford County","state":"Missouri","id":1510},
{"county":"Dade County","state":"Missouri","id":1511},
{"county":"Dallas County","state":"Missouri","id":1512},
{"county":"Daviess County","state":"Missouri","id":1513},
{"county":"DeKalb County","state":"Missouri","id":1514},
{"county":"Dent County","state":"Missouri","id":1515},
{"county":"Douglas County","state":"Missouri","id":1516},
{"county":"Dunklin County","state":"Missouri","id":1517},
{"county":"Franklin County","state":"Missouri","id":1518},
{"county":"Gasconade County","state":"Missouri","id":1519},
{"county":"Gentry County","state":"Missouri","id":1520},
{"county":"Greene County","state":"Missouri","id":1521},
{"county":"Grundy County","state":"Missouri","id":1522},
{"county":"Harrison County","state":"Missouri","id":1523},
{"county":"Henry County","state":"Missouri","id":1524},
{"county":"Hickory County","state":"Missouri","id":1525},
{"county":"Holt County","state":"Missouri","id":1526},
{"county":"Howard County","state":"Missouri","id":1527},
{"county":"Howell County","state":"Missouri","id":1528},
{"county":"Iron County","state":"Missouri","id":1529},
{"county":"Jackson County","state":"Missouri","id":1530},
{"county":"Jasper County","state":"Missouri","id":1531},
{"county":"Jefferson County","state":"Missouri","id":1532},
{"county":"Johnson County","state":"Missouri","id":1533},
{"county":"Knox County","state":"Missouri","id":1534},
{"county":"Laclede County","state":"Missouri","id":1535},
{"county":"Lafayette County","state":"Missouri","id":1536},
{"county":"Lawrence County","state":"Missouri","id":1537},
{"county":"Lewis County","state":"Missouri","id":1538},
{"county":"Lincoln County","state":"Missouri","id":1539},
{"county":"Linn County","state":"Missouri","id":1540},
{"county":"Livingston County","state":"Missouri","id":1541},
{"county":"McDonald County","state":"Missouri","id":1542},
{"county":"Macon County","state":"Missouri","id":1543},
{"county":"Madison County","state":"Missouri","id":1544},
{"county":"Maries County","state":"Missouri","id":1545},
{"county":"Marion County","state":"Missouri","id":1546},
{"county":"Mercer County","state":"Missouri","id":1547},
{"county":"Miller County","state":"Missouri","id":1548},
{"county":"Mississippi County","state":"Missouri","id":1549},
{"county":"Moniteau County","state":"Missouri","id":1550},
{"county":"Monroe County","state":"Missouri","id":1551},
{"county":"Montgomery County","state":"Missouri","id":1552},
{"county":"Morgan County","state":"Missouri","id":1553},
{"county":"New Madrid County","state":"Missouri","id":1554},
{"county":"Newton County","state":"Missouri","id":1555},
{"county":"Nodaway County","state":"Missouri","id":1556},
{"county":"Oregon County","state":"Missouri","id":1557},
{"county":"Osage County","state":"Missouri","id":1558},
{"county":"Ozark County","state":"Missouri","id":1559},
{"county":"Pemiscot County","state":"Missouri","id":1560},
{"county":"Perry County","state":"Missouri","id":1561},
{"county":"Pettis County","state":"Missouri","id":1562},
{"county":"Phelps County","state":"Missouri","id":1563},
{"county":"Pike County","state":"Missouri","id":1564},
{"county":"Platte County","state":"Missouri","id":1565},
{"county":"Polk County","state":"Missouri","id":1566},
{"county":"Pulaski County","state":"Missouri","id":1567},
{"county":"Putnam County","state":"Missouri","id":1568},
{"county":"Ralls County","state":"Missouri","id":1569},
{"county":"Randolph County","state":"Missouri","id":1570},
{"county":"Ray County","state":"Missouri","id":1571},
{"county":"Reynolds County","state":"Missouri","id":1572},
{"county":"Ripley County","state":"Missouri","id":1573},
{"county":"St. Charles County","state":"Missouri","id":1574},
{"county":"St. Clair County","state":"Missouri","id":1575},
{"county":"Ste. Genevieve County","state":"Missouri","id":1576},
{"county":"St. Francois County","state":"Missouri","id":1577},
{"county":"St. Louis County","state":"Missouri","id":1578},
{"county":"Saline County","state":"Missouri","id":1579},
{"county":"Schuyler County","state":"Missouri","id":1580},
{"county":"Scotland County","state":"Missouri","id":1581},
{"county":"Scott County","state":"Missouri","id":1582},
{"county":"Shannon County","state":"Missouri","id":1583},
{"county":"Shelby County","state":"Missouri","id":1584},
{"county":"Stoddard County","state":"Missouri","id":1585},
{"county":"Stone County","state":"Missouri","id":1586},
{"county":"Sullivan County","state":"Missouri","id":1587},
{"county":"Taney County","state":"Missouri","id":1588},
{"county":"Texas County","state":"Missouri","id":1589},
{"county":"Vernon County","state":"Missouri","id":1590},
{"county":"Warren County","state":"Missouri","id":1591},
{"county":"Washington County","state":"Missouri","id":1592},
{"county":"Wayne County","state":"Missouri","id":1593},
{"county":"Webster County","state":"Missouri","id":1594},
{"county":"Worth County","state":"Missouri","id":1595},
{"county":"Wright County","state":"Missouri","id":1596},
{"county":"St. Louis, City of","state":"Missouri","id":1597},
{"county":"Beaverhead County","state":"Montana","id":1598},
{"county":"Big Horn County","state":"Montana","id":1599},
{"county":"Blaine County","state":"Montana","id":1600},
{"county":"Broadwater County","state":"Montana","id":1601},
{"county":"Carbon County","state":"Montana","id":1602},
{"county":"Carter County","state":"Montana","id":1603},
{"county":"Cascade County","state":"Montana","id":1604},
{"county":"Chouteau County","state":"Montana","id":1605},
{"county":"Custer County","state":"Montana","id":1606},
{"county":"Daniels County","state":"Montana","id":1607},
{"county":"Dawson County","state":"Montana","id":1608},
{"county":"Deer Lodge County","state":"Montana","id":1609},
{"county":"Fallon County","state":"Montana","id":1610},
{"county":"Fergus County","state":"Montana","id":1611},
{"county":"Flathead County","state":"Montana","id":1612},
{"county":"Gallatin County","state":"Montana","id":1613},
{"county":"Garfield County","state":"Montana","id":1614},
{"county":"Glacier County","state":"Montana","id":1615},
{"county":"Golden Valley County","state":"Montana","id":1616},
{"county":"Granite County","state":"Montana","id":1617},
{"county":"Hill County","state":"Montana","id":1618},
{"county":"Jefferson County","state":"Montana","id":1619},
{"county":"Judith Basin County","state":"Montana","id":1620},
{"county":"Lake County","state":"Montana","id":1621},
{"county":"Lewis and Clark County","state":"Montana","id":1622},
{"county":"Liberty County","state":"Montana","id":1623},
{"county":"Lincoln County","state":"Montana","id":1624},
{"county":"McCone County","state":"Montana","id":1625},
{"county":"Madison County","state":"Montana","id":1626},
{"county":"Meagher County","state":"Montana","id":1627},
{"county":"Mineral County","state":"Montana","id":1628},
{"county":"Missoula County","state":"Montana","id":1629},
{"county":"Musselshell County","state":"Montana","id":1630},
{"county":"Park County","state":"Montana","id":1631},
{"county":"Petroleum County","state":"Montana","id":1632},
{"county":"Phillips County","state":"Montana","id":1633},
{"county":"Pondera County","state":"Montana","id":1634},
{"county":"Powder River County","state":"Montana","id":1635},
{"county":"Powell County","state":"Montana","id":1636},
{"county":"Prairie County","state":"Montana","id":1637},
{"county":"Ravalli County","state":"Montana","id":1638},
{"county":"Richland County","state":"Montana","id":1639},
{"county":"Roosevelt County","state":"Montana","id":1640},
{"county":"Rosebud County","state":"Montana","id":1641},
{"county":"Sanders County","state":"Montana","id":1642},
{"county":"Sheridan County","state":"Montana","id":1643},
{"county":"Silver Bow County","state":"Montana","id":1644},
{"county":"Stillwater County","state":"Montana","id":1645},
{"county":"Sweet Grass County","state":"Montana","id":1646},
{"county":"Teton County","state":"Montana","id":1647},
{"county":"Toole County","state":"Montana","id":1648},
{"county":"Treasure County","state":"Montana","id":1649},
{"county":"Valley County","state":"Montana","id":1650},
{"county":"Wheatland County","state":"Montana","id":1651},
{"county":"Wibaux County","state":"Montana","id":1652},
{"county":"Yellowstone County","state":"Montana","id":1653},
{"county":"Adams County","state":"Nebraska","id":1654},
{"county":"Antelope County","state":"Nebraska","id":1655},
{"county":"Arthur County","state":"Nebraska","id":1656},
{"county":"Banner County","state":"Nebraska","id":1657},
{"county":"Blaine County","state":"Nebraska","id":1658},
{"county":"Boone County","state":"Nebraska","id":1659},
{"county":"Box Butte County","state":"Nebraska","id":1660},
{"county":"Boyd County","state":"Nebraska","id":1661},
{"county":"Brown County","state":"Nebraska","id":1662},
{"county":"Buffalo County","state":"Nebraska","id":1663},
{"county":"Burt County","state":"Nebraska","id":1664},
{"county":"Butler County","state":"Nebraska","id":1665},
{"county":"Cass County","state":"Nebraska","id":1666},
{"county":"Cedar County","state":"Nebraska","id":1667},
{"county":"Chase County","state":"Nebraska","id":1668},
{"county":"Cherry County","state":"Nebraska","id":1669},
{"county":"Cheyenne County","state":"Nebraska","id":1670},
{"county":"Clay County","state":"Nebraska","id":1671},
{"county":"Colfax County","state":"Nebraska","id":1672},
{"county":"Cuming County","state":"Nebraska","id":1673},
{"county":"Custer County","state":"Nebraska","id":1674},
{"county":"Dakota County","state":"Nebraska","id":1675},
{"county":"Dawes County","state":"Nebraska","id":1676},
{"county":"Dawson County","state":"Nebraska","id":1677},
{"county":"Deuel County","state":"Nebraska","id":1678},
{"county":"Dixon County","state":"Nebraska","id":1679},
{"county":"Dodge County","state":"Nebraska","id":1680},
{"county":"Douglas County","state":"Nebraska","id":1681},
{"county":"Dundy County","state":"Nebraska","id":1682},
{"county":"Fillmore County","state":"Nebraska","id":1683},
{"county":"Franklin County","state":"Nebraska","id":1684},
{"county":"Frontier County","state":"Nebraska","id":1685},
{"county":"Furnas County","state":"Nebraska","id":1686},
{"county":"Gage County","state":"Nebraska","id":1687},
{"county":"Garden County","state":"Nebraska","id":1688},
{"county":"Garfield County","state":"Nebraska","id":1689},
{"county":"Gosper County","state":"Nebraska","id":1690},
{"county":"Grant County","state":"Nebraska","id":1691},
{"county":"Greeley County","state":"Nebraska","id":1692},
{"county":"Hall County","state":"Nebraska","id":1693},
{"county":"Hamilton County","state":"Nebraska","id":1694},
{"county":"Harlan County","state":"Nebraska","id":1695},
{"county":"Hayes County","state":"Nebraska","id":1696},
{"county":"Hitchcock County","state":"Nebraska","id":1697},
{"county":"Holt County","state":"Nebraska","id":1698},
{"county":"Hooker County","state":"Nebraska","id":1699},
{"county":"Howard County","state":"Nebraska","id":1700},
{"county":"Jefferson County","state":"Nebraska","id":1701},
{"county":"Johnson County","state":"Nebraska","id":1702},
{"county":"Kearney County","state":"Nebraska","id":1703},
{"county":"Keith County","state":"Nebraska","id":1704},
{"county":"Keya Paha County","state":"Nebraska","id":1705},
{"county":"Kimball County","state":"Nebraska","id":1706},
{"county":"Knox County","state":"Nebraska","id":1707},
{"county":"Lancaster County","state":"Nebraska","id":1708},
{"county":"Lincoln County","state":"Nebraska","id":1709},
{"county":"Logan County","state":"Nebraska","id":1710},
{"county":"Loup County","state":"Nebraska","id":1711},
{"county":"McPherson County","state":"Nebraska","id":1712},
{"county":"Madison County","state":"Nebraska","id":1713},
{"county":"Merrick County","state":"Nebraska","id":1714},
{"county":"Morrill County","state":"Nebraska","id":1715},
{"county":"Nance County","state":"Nebraska","id":1716},
{"county":"Nemaha County","state":"Nebraska","id":1717},
{"county":"Nuckolls County","state":"Nebraska","id":1718},
{"county":"Otoe County","state":"Nebraska","id":1719},
{"county":"Pawnee County","state":"Nebraska","id":1720},
{"county":"Perkins County","state":"Nebraska","id":1721},
{"county":"Phelps County","state":"Nebraska","id":1722},
{"county":"Pierce County","state":"Nebraska","id":1723},
{"county":"Platte County","state":"Nebraska","id":1724},
{"county":"Polk County","state":"Nebraska","id":1725},
{"county":"Red Willow County","state":"Nebraska","id":1726},
{"county":"Richardson County","state":"Nebraska","id":1727},
{"county":"Rock County","state":"Nebraska","id":1728},
{"county":"Saline County","state":"Nebraska","id":1729},
{"county":"Sarpy County","state":"Nebraska","id":1730},
{"county":"Saunders County","state":"Nebraska","id":1731},
{"county":"Scotts Bluff County","state":"Nebraska","id":1732},
{"county":"Seward County","state":"Nebraska","id":1733},
{"county":"Sheridan County","state":"Nebraska","id":1734},
{"county":"Sherman County","state":"Nebraska","id":1735},
{"county":"Sioux County","state":"Nebraska","id":1736},
{"county":"Stanton County","state":"Nebraska","id":1737},
{"county":"Thayer County","state":"Nebraska","id":1738},
{"county":"Thomas County","state":"Nebraska","id":1739},
{"county":"Thurston County","state":"Nebraska","id":1740},
{"county":"Valley County","state":"Nebraska","id":1741},
{"county":"Washington County","state":"Nebraska","id":1742},
{"county":"Wayne County","state":"Nebraska","id":1743},
{"county":"Webster County","state":"Nebraska","id":1744},
{"county":"Wheeler County","state":"Nebraska","id":1745},
{"county":"York County","state":"Nebraska","id":1746},
{"county":"Churchill County","state":"Nevada","id":1747},
{"county":"Clark County","state":"Nevada","id":1748},
{"county":"Douglas County","state":"Nevada","id":1749},
{"county":"Elko County","state":"Nevada","id":1750},
{"county":"Esmeralda County","state":"Nevada","id":1751},
{"county":"Eureka County","state":"Nevada","id":1752},
{"county":"Humboldt County","state":"Nevada","id":1753},
{"county":"Lander County","state":"Nevada","id":1754},
{"county":"Lincoln County","state":"Nevada","id":1755},
{"county":"Lyon County","state":"Nevada","id":1756},
{"county":"Mineral County","state":"Nevada","id":1757},
{"county":"Nye County","state":"Nevada","id":1758},
{"county":"Pershing County","state":"Nevada","id":1759},
{"county":"Storey County","state":"Nevada","id":1760},
{"county":"Washoe County","state":"Nevada","id":1761},
{"county":"White Pine County","state":"Nevada","id":1762},
{"county":"Carson City, Consolidated Municipality of","state":"Nevada","id":1763},
{"county":"Belknap County","state":"New Hampshire","id":1764},
{"county":"Carroll County","state":"New Hampshire","id":1765},
{"county":"Cheshire County","state":"New Hampshire","id":1766},
{"county":"Coos County","state":"New Hampshire","id":1767},
{"county":"Grafton County","state":"New Hampshire","id":1768},
{"county":"Hillsborough County","state":"New Hampshire","id":1769},
{"county":"Merrimack County","state":"New Hampshire","id":1770},
{"county":"Rockingham County","state":"New Hampshire","id":1771},
{"county":"Strafford County","state":"New Hampshire","id":1772},
{"county":"Sullivan County","state":"New Hampshire","id":1773},
{"county":"Atlantic County","state":"New Jersey","id":1774},
{"county":"Bergen County","state":"New Jersey","id":1775},
{"county":"Burlington County","state":"New Jersey","id":1776},
{"county":"Camden County","state":"New Jersey","id":1777},
{"county":"Cape May County","state":"New Jersey","id":1778},
{"county":"Cumberland County","state":"New Jersey","id":1779},
{"county":"Essex County","state":"New Jersey","id":1780},
{"county":"Gloucester County","state":"New Jersey","id":1781},
{"county":"Hudson County","state":"New Jersey","id":1782},
{"county":"Hunterdon County","state":"New Jersey","id":1783},
{"county":"Mercer County","state":"New Jersey","id":1784},
{"county":"Middlesex County","state":"New Jersey","id":1785},
{"county":"Monmouth County","state":"New Jersey","id":1786},
{"county":"Morris County","state":"New Jersey","id":1787},
{"county":"Ocean County","state":"New Jersey","id":1788},
{"county":"Passaic County","state":"New Jersey","id":1789},
{"county":"Salem County","state":"New Jersey","id":1790},
{"county":"Somerset County","state":"New Jersey","id":1791},
{"county":"Sussex County","state":"New Jersey","id":1792},
{"county":"Union County","state":"New Jersey","id":1793},
{"county":"Warren County","state":"New Jersey","id":1794},
{"county":"Bernalillo County","state":"New Mexico","id":1795},
{"county":"Catron County","state":"New Mexico","id":1796},
{"county":"Chaves County","state":"New Mexico","id":1797},
{"county":"Cibola County","state":"New Mexico","id":1798},
{"county":"Colfax County","state":"New Mexico","id":1799},
{"county":"Curry County","state":"New Mexico","id":1800},
{"county":"De Baca County","state":"New Mexico","id":1801},
{"county":"Do�a Ana County","state":"New Mexico","id":1802},
{"county":"Eddy County","state":"New Mexico","id":1803},
{"county":"Grant County","state":"New Mexico","id":1804},
{"county":"Guadalupe County","state":"New Mexico","id":1805},
{"county":"Harding County","state":"New Mexico","id":1806},
{"county":"Hidalgo County","state":"New Mexico","id":1807},
{"county":"Lea County","state":"New Mexico","id":1808},
{"county":"Lincoln County","state":"New Mexico","id":1809},
{"county":"Los Alamos County","state":"New Mexico","id":1810},
{"county":"Luna County","state":"New Mexico","id":1811},
{"county":"McKinley County","state":"New Mexico","id":1812},
{"county":"Mora County","state":"New Mexico","id":1813},
{"county":"Otero County","state":"New Mexico","id":1814},
{"county":"Quay County","state":"New Mexico","id":1815},
{"county":"Rio Arriba County","state":"New Mexico","id":1816},
{"county":"Roosevelt County","state":"New Mexico","id":1817},
{"county":"Sandoval County","state":"New Mexico","id":1818},
{"county":"San Juan County","state":"New Mexico","id":1819},
{"county":"San Miguel County","state":"New Mexico","id":1820},
{"county":"Santa Fe County","state":"New Mexico","id":1821},
{"county":"Sierra County","state":"New Mexico","id":1822},
{"county":"Socorro County","state":"New Mexico","id":1823},
{"county":"Taos County","state":"New Mexico","id":1824},
{"county":"Torrance County","state":"New Mexico","id":1825},
{"county":"Union County","state":"New Mexico","id":1826},
{"county":"Valencia County","state":"New Mexico","id":1827},
{"county":"Albany County","state":"New York","id":1828},
{"county":"Allegany County","state":"New York","id":1829},
{"county":"Bronx County","state":"New York","id":1830},
{"county":"Broome County","state":"New York","id":1831},
{"county":"Cattaraugus County","state":"New York","id":1832},
{"county":"Cayuga County","state":"New York","id":1833},
{"county":"Chautauqua County","state":"New York","id":1834},
{"county":"Chemung County","state":"New York","id":1835},
{"county":"Chenango County","state":"New York","id":1836},
{"county":"Clinton County","state":"New York","id":1837},
{"county":"Columbia County","state":"New York","id":1838},
{"county":"Cortland County","state":"New York","id":1839},
{"county":"Delaware County","state":"New York","id":1840},
{"county":"Dutchess County","state":"New York","id":1841},
{"county":"Erie County","state":"New York","id":1842},
{"county":"Essex County","state":"New York","id":1843},
{"county":"Franklin County","state":"New York","id":1844},
{"county":"Fulton County","state":"New York","id":1845},
{"county":"Genesee County","state":"New York","id":1846},
{"county":"Greene County","state":"New York","id":1847},
{"county":"Hamilton County","state":"New York","id":1848},
{"county":"Herkimer County","state":"New York","id":1849},
{"county":"Jefferson County","state":"New York","id":1850},
{"county":"Kings County","state":"New York","id":1851},
{"county":"Lewis County","state":"New York","id":1852},
{"county":"Livingston County","state":"New York","id":1853},
{"county":"Madison County","state":"New York","id":1854},
{"county":"Monroe County","state":"New York","id":1855},
{"county":"Montgomery County","state":"New York","id":1856},
{"county":"Nassau County","state":"New York","id":1857},
{"county":"New York County","state":"New York","id":1858},
{"county":"Niagara County","state":"New York","id":1859},
{"county":"Oneida County","state":"New York","id":1860},
{"county":"Onondaga County","state":"New York","id":1861},
{"county":"Ontario County","state":"New York","id":1862},
{"county":"Orange County","state":"New York","id":1863},
{"county":"Orleans County","state":"New York","id":1864},
{"county":"Oswego County","state":"New York","id":1865},
{"county":"Otsego County","state":"New York","id":1866},
{"county":"Putnam County","state":"New York","id":1867},
{"county":"Queens County","state":"New York","id":1868},
{"county":"Rensselaer County","state":"New York","id":1869},
{"county":"Richmond County","state":"New York","id":1870},
{"county":"Rockland County","state":"New York","id":1871},
{"county":"St. Lawrence County","state":"New York","id":1872},
{"county":"Saratoga County","state":"New York","id":1873},
{"county":"Schenectady County","state":"New York","id":1874},
{"county":"Schoharie County","state":"New York","id":1875},
{"county":"Schuyler County","state":"New York","id":1876},
{"county":"Seneca County","state":"New York","id":1877},
{"county":"Steuben County","state":"New York","id":1878},
{"county":"Suffolk County","state":"New York","id":1879},
{"county":"Sullivan County","state":"New York","id":1880},
{"county":"Tioga County","state":"New York","id":1881},
{"county":"Tompkins County","state":"New York","id":1882},
{"county":"Ulster County","state":"New York","id":1883},
{"county":"Warren County","state":"New York","id":1884},
{"county":"Washington County","state":"New York","id":1885},
{"county":"Wayne County","state":"New York","id":1886},
{"county":"Westchester County","state":"New York","id":1887},
{"county":"Wyoming County","state":"New York","id":1888},
{"county":"Yates County","state":"New York","id":1889},
{"county":"Alamance County","state":"North Carolina","id":1890},
{"county":"Alexander County","state":"North Carolina","id":1891},
{"county":"Alleghany County","state":"North Carolina","id":1892},
{"county":"Anson County","state":"North Carolina","id":1893},
{"county":"Ashe County","state":"North Carolina","id":1894},
{"county":"Avery County","state":"North Carolina","id":1895},
{"county":"Beaufort County","state":"North Carolina","id":1896},
{"county":"Bertie County","state":"North Carolina","id":1897},
{"county":"Bladen County","state":"North Carolina","id":1898},
{"county":"Brunswick County","state":"North Carolina","id":1899},
{"county":"Buncombe County","state":"North Carolina","id":1900},
{"county":"Burke County","state":"North Carolina","id":1901},
{"county":"Cabarrus County","state":"North Carolina","id":1902},
{"county":"Caldwell County","state":"North Carolina","id":1903},
{"county":"Camden County","state":"North Carolina","id":1904},
{"county":"Carteret County","state":"North Carolina","id":1905},
{"county":"Caswell County","state":"North Carolina","id":1906},
{"county":"Catawba County","state":"North Carolina","id":1907},
{"county":"Chatham County","state":"North Carolina","id":1908},
{"county":"Cherokee County","state":"North Carolina","id":1909},
{"county":"Chowan County","state":"North Carolina","id":1910},
{"county":"Clay County","state":"North Carolina","id":1911},
{"county":"Cleveland County","state":"North Carolina","id":1912},
{"county":"Columbus County","state":"North Carolina","id":1913},
{"county":"Craven County","state":"North Carolina","id":1914},
{"county":"Cumberland County","state":"North Carolina","id":1915},
{"county":"Currituck County","state":"North Carolina","id":1916},
{"county":"Dare County","state":"North Carolina","id":1917},
{"county":"Davidson County","state":"North Carolina","id":1918},
{"county":"Davie County","state":"North Carolina","id":1919},
{"county":"Duplin County","state":"North Carolina","id":1920},
{"county":"Durham County","state":"North Carolina","id":1921},
{"county":"Edgecombe County","state":"North Carolina","id":1922},
{"county":"Forsyth County","state":"North Carolina","id":1923},
{"county":"Franklin County","state":"North Carolina","id":1924},
{"county":"Gaston County","state":"North Carolina","id":1925},
{"county":"Gates County","state":"North Carolina","id":1926},
{"county":"Graham County","state":"North Carolina","id":1927},
{"county":"Granville County","state":"North Carolina","id":1928},
{"county":"Greene County","state":"North Carolina","id":1929},
{"county":"Guilford County","state":"North Carolina","id":1930},
{"county":"Halifax County","state":"North Carolina","id":1931},
{"county":"Harnett County","state":"North Carolina","id":1932},
{"county":"Haywood County","state":"North Carolina","id":1933},
{"county":"Henderson County","state":"North Carolina","id":1934},
{"county":"Hertford County","state":"North Carolina","id":1935},
{"county":"Hoke County","state":"North Carolina","id":1936},
{"county":"Hyde County","state":"North Carolina","id":1937},
{"county":"Iredell County","state":"North Carolina","id":1938},
{"county":"Jackson County","state":"North Carolina","id":1939},
{"county":"Johnston County","state":"North Carolina","id":1940},
{"county":"Jones County","state":"North Carolina","id":1941},
{"county":"Lee County","state":"North Carolina","id":1942},
{"county":"Lenoir County","state":"North Carolina","id":1943},
{"county":"Lincoln County","state":"North Carolina","id":1944},
{"county":"McDowell County","state":"North Carolina","id":1945},
{"county":"Macon County","state":"North Carolina","id":1946},
{"county":"Madison County","state":"North Carolina","id":1947},
{"county":"Martin County","state":"North Carolina","id":1948},
{"county":"Mecklenburg County","state":"North Carolina","id":1949},
{"county":"Mitchell County","state":"North Carolina","id":1950},
{"county":"Montgomery County","state":"North Carolina","id":1951},
{"county":"Moore County","state":"North Carolina","id":1952},
{"county":"Nash County","state":"North Carolina","id":1953},
{"county":"New Hanover County","state":"North Carolina","id":1954},
{"county":"Northampton County","state":"North Carolina","id":1955},
{"county":"Onslow County","state":"North Carolina","id":1956},
{"county":"Orange County","state":"North Carolina","id":1957},
{"county":"Pamlico County","state":"North Carolina","id":1958},
{"county":"Pasquotank County","state":"North Carolina","id":1959},
{"county":"Pender County","state":"North Carolina","id":1960},
{"county":"Perquimans County","state":"North Carolina","id":1961},
{"county":"Person County","state":"North Carolina","id":1962},
{"county":"Pitt County","state":"North Carolina","id":1963},
{"county":"Polk County","state":"North Carolina","id":1964},
{"county":"Randolph County","state":"North Carolina","id":1965},
{"county":"Richmond County","state":"North Carolina","id":1966},
{"county":"Robeson County","state":"North Carolina","id":1967},
{"county":"Rockingham County","state":"North Carolina","id":1968},
{"county":"Rowan County","state":"North Carolina","id":1969},
{"county":"Rutherford County","state":"North Carolina","id":1970},
{"county":"Sampson County","state":"North Carolina","id":1971},
{"county":"Scotland County","state":"North Carolina","id":1972},
{"county":"Stanly County","state":"North Carolina","id":1973},
{"county":"Stokes County","state":"North Carolina","id":1974},
{"county":"Surry County","state":"North Carolina","id":1975},
{"county":"Swain County","state":"North Carolina","id":1976},
{"county":"Transylvania County","state":"North Carolina","id":1977},
{"county":"Tyrrell County","state":"North Carolina","id":1978},
{"county":"Union County","state":"North Carolina","id":1979},
{"county":"Vance County","state":"North Carolina","id":1980},
{"county":"Wake County","state":"North Carolina","id":1981},
{"county":"Warren County","state":"North Carolina","id":1982},
{"county":"Washington County","state":"North Carolina","id":1983},
{"county":"Watauga County","state":"North Carolina","id":1984},
{"county":"Wayne County","state":"North Carolina","id":1985},
{"county":"Wilkes County","state":"North Carolina","id":1986},
{"county":"Wilson County","state":"North Carolina","id":1987},
{"county":"Yadkin County","state":"North Carolina","id":1988},
{"county":"Yancey County","state":"North Carolina","id":1989},
{"county":"Adams County","state":"North Dakota","id":1990},
{"county":"Barnes County","state":"North Dakota","id":1991},
{"county":"Benson County","state":"North Dakota","id":1992},
{"county":"Billings County","state":"North Dakota","id":1993},
{"county":"Bottineau County","state":"North Dakota","id":1994},
{"county":"Bowman County","state":"North Dakota","id":1995},
{"county":"Burke County","state":"North Dakota","id":1996},
{"county":"Burleigh County","state":"North Dakota","id":1997},
{"county":"Cass County","state":"North Dakota","id":1998},
{"county":"Cavalier County","state":"North Dakota","id":1999},
{"county":"Dickey County","state":"North Dakota","id":2000},
{"county":"Divide County","state":"North Dakota","id":2001},
{"county":"Dunn County","state":"North Dakota","id":2002},
{"county":"Eddy County","state":"North Dakota","id":2003},
{"county":"Emmons County","state":"North Dakota","id":2004},
{"county":"Foster County","state":"North Dakota","id":2005},
{"county":"Golden Valley County","state":"North Dakota","id":2006},
{"county":"Grand Forks County","state":"North Dakota","id":2007},
{"county":"Grant County","state":"North Dakota","id":2008},
{"county":"Griggs County","state":"North Dakota","id":2009},
{"county":"Hettinger County","state":"North Dakota","id":2010},
{"county":"Kidder County","state":"North Dakota","id":2011},
{"county":"LaMoure County","state":"North Dakota","id":2012},
{"county":"Logan County","state":"North Dakota","id":2013},
{"county":"McHenry County","state":"North Dakota","id":2014},
{"county":"McIntosh County","state":"North Dakota","id":2015},
{"county":"McKenzie County","state":"North Dakota","id":2016},
{"county":"McLean County","state":"North Dakota","id":2017},
{"county":"Mercer County","state":"North Dakota","id":2018},
{"county":"Morton County","state":"North Dakota","id":2019},
{"county":"Mountrail County","state":"North Dakota","id":2020},
{"county":"Nelson County","state":"North Dakota","id":2021},
{"county":"Oliver County","state":"North Dakota","id":2022},
{"county":"Pembina County","state":"North Dakota","id":2023},
{"county":"Pierce County","state":"North Dakota","id":2024},
{"county":"Ramsey County","state":"North Dakota","id":2025},
{"county":"Ransom County","state":"North Dakota","id":2026},
{"county":"Renville County","state":"North Dakota","id":2027},
{"county":"Richland County","state":"North Dakota","id":2028},
{"county":"Rolette County","state":"North Dakota","id":2029},
{"county":"Sargent County","state":"North Dakota","id":2030},
{"county":"Sheridan County","state":"North Dakota","id":2031},
{"county":"Sioux County","state":"North Dakota","id":2032},
{"county":"Slope County","state":"North Dakota","id":2033},
{"county":"Stark County","state":"North Dakota","id":2034},
{"county":"Steele County","state":"North Dakota","id":2035},
{"county":"Stutsman County","state":"North Dakota","id":2036},
{"county":"Towner County","state":"North Dakota","id":2037},
{"county":"Traill County","state":"North Dakota","id":2038},
{"county":"Walsh County","state":"North Dakota","id":2039},
{"county":"Ward County","state":"North Dakota","id":2040},
{"county":"Wells County","state":"North Dakota","id":2041},
{"county":"Williams County","state":"North Dakota","id":2042},
{"county":"Adams County","state":"Ohio","id":2043},
{"county":"Allen County","state":"Ohio","id":2044},
{"county":"Ashland County","state":"Ohio","id":2045},
{"county":"Ashtabula County","state":"Ohio","id":2046},
{"county":"Athens County","state":"Ohio","id":2047},
{"county":"Auglaize County","state":"Ohio","id":2048},
{"county":"Belmont County","state":"Ohio","id":2049},
{"county":"Brown County","state":"Ohio","id":2050},
{"county":"Butler County","state":"Ohio","id":2051},
{"county":"Carroll County","state":"Ohio","id":2052},
{"county":"Champaign County","state":"Ohio","id":2053},
{"county":"Clark County","state":"Ohio","id":2054},
{"county":"Clermont County","state":"Ohio","id":2055},
{"county":"Clinton County","state":"Ohio","id":2056},
{"county":"Columbiana County","state":"Ohio","id":2057},
{"county":"Coshocton County","state":"Ohio","id":2058},
{"county":"Crawford County","state":"Ohio","id":2059},
{"county":"Cuyahoga County","state":"Ohio","id":2060},
{"county":"Darke County","state":"Ohio","id":2061},
{"county":"Defiance County","state":"Ohio","id":2062},
{"county":"Delaware County","state":"Ohio","id":2063},
{"county":"Erie County","state":"Ohio","id":2064},
{"county":"Fairfield County","state":"Ohio","id":2065},
{"county":"Fayette County","state":"Ohio","id":2066},
{"county":"Franklin County","state":"Ohio","id":2067},
{"county":"Fulton County","state":"Ohio","id":2068},
{"county":"Gallia County","state":"Ohio","id":2069},
{"county":"Geauga County","state":"Ohio","id":2070},
{"county":"Greene County","state":"Ohio","id":2071},
{"county":"Guernsey County","state":"Ohio","id":2072},
{"county":"Hamilton County","state":"Ohio","id":2073},
{"county":"Hancock County","state":"Ohio","id":2074},
{"county":"Hardin County","state":"Ohio","id":2075},
{"county":"Harrison County","state":"Ohio","id":2076},
{"county":"Henry County","state":"Ohio","id":2077},
{"county":"Highland County","state":"Ohio","id":2078},
{"county":"Hocking County","state":"Ohio","id":2079},
{"county":"Holmes County","state":"Ohio","id":2080},
{"county":"Huron County","state":"Ohio","id":2081},
{"county":"Jackson County","state":"Ohio","id":2082},
{"county":"Jefferson County","state":"Ohio","id":2083},
{"county":"Knox County","state":"Ohio","id":2084},
{"county":"Lake County","state":"Ohio","id":2085},
{"county":"Lawrence County","state":"Ohio","id":2086},
{"county":"Licking County","state":"Ohio","id":2087},
{"county":"Logan County","state":"Ohio","id":2088},
{"county":"Lorain County","state":"Ohio","id":2089},
{"county":"Lucas County","state":"Ohio","id":2090},
{"county":"Madison County","state":"Ohio","id":2091},
{"county":"Mahoning County","state":"Ohio","id":2092},
{"county":"Marion County","state":"Ohio","id":2093},
{"county":"Medina County","state":"Ohio","id":2094},
{"county":"Meigs County","state":"Ohio","id":2095},
{"county":"Mercer County","state":"Ohio","id":2096},
{"county":"Miami County","state":"Ohio","id":2097},
{"county":"Monroe County","state":"Ohio","id":2098},
{"county":"Montgomery County","state":"Ohio","id":2099},
{"county":"Morgan County","state":"Ohio","id":2100},
{"county":"Morrow County","state":"Ohio","id":2101},
{"county":"Muskingum County","state":"Ohio","id":2102},
{"county":"Noble County","state":"Ohio","id":2103},
{"county":"Ottawa County","state":"Ohio","id":2104},
{"county":"Paulding County","state":"Ohio","id":2105},
{"county":"Perry County","state":"Ohio","id":2106},
{"county":"Pickaway County","state":"Ohio","id":2107},
{"county":"Pike County","state":"Ohio","id":2108},
{"county":"Portage County","state":"Ohio","id":2109},
{"county":"Preble County","state":"Ohio","id":2110},
{"county":"Putnam County","state":"Ohio","id":2111},
{"county":"Richland County","state":"Ohio","id":2112},
{"county":"Ross County","state":"Ohio","id":2113},
{"county":"Sandusky County","state":"Ohio","id":2114},
{"county":"Scioto County","state":"Ohio","id":2115},
{"county":"Seneca County","state":"Ohio","id":2116},
{"county":"Shelby County","state":"Ohio","id":2117},
{"county":"Stark County","state":"Ohio","id":2118},
{"county":"Summit County","state":"Ohio","id":2119},
{"county":"Trumbull County","state":"Ohio","id":2120},
{"county":"Tuscarawas County","state":"Ohio","id":2121},
{"county":"Union County","state":"Ohio","id":2122},
{"county":"Van Wert County","state":"Ohio","id":2123},
{"county":"Vinton County","state":"Ohio","id":2124},
{"county":"Warren County","state":"Ohio","id":2125},
{"county":"Washington County","state":"Ohio","id":2126},
{"county":"Wayne County","state":"Ohio","id":2127},
{"county":"Williams County","state":"Ohio","id":2128},
{"county":"Wood County","state":"Ohio","id":2129},
{"county":"Wyandot County","state":"Ohio","id":2130},
{"county":"Adair County","state":"Oklahoma","id":2131},
{"county":"Alfalfa County","state":"Oklahoma","id":2132},
{"county":"Atoka County","state":"Oklahoma","id":2133},
{"county":"Beaver County","state":"Oklahoma","id":2134},
{"county":"Beckham County","state":"Oklahoma","id":2135},
{"county":"Blaine County","state":"Oklahoma","id":2136},
{"county":"Bryan County","state":"Oklahoma","id":2137},
{"county":"Caddo County","state":"Oklahoma","id":2138},
{"county":"Canadian County","state":"Oklahoma","id":2139},
{"county":"Carter County","state":"Oklahoma","id":2140},
{"county":"Cherokee County","state":"Oklahoma","id":2141},
{"county":"Choctaw County","state":"Oklahoma","id":2142},
{"county":"Cimarron County","state":"Oklahoma","id":2143},
{"county":"Cleveland County","state":"Oklahoma","id":2144},
{"county":"Coal County","state":"Oklahoma","id":2145},
{"county":"Comanche County","state":"Oklahoma","id":2146},
{"county":"Cotton County","state":"Oklahoma","id":2147},
{"county":"Craig County","state":"Oklahoma","id":2148},
{"county":"Creek County","state":"Oklahoma","id":2149},
{"county":"Custer County","state":"Oklahoma","id":2150},
{"county":"Delaware County","state":"Oklahoma","id":2151},
{"county":"Dewey County","state":"Oklahoma","id":2152},
{"county":"Ellis County","state":"Oklahoma","id":2153},
{"county":"Garfield County","state":"Oklahoma","id":2154},
{"county":"Garvin County","state":"Oklahoma","id":2155},
{"county":"Grady County","state":"Oklahoma","id":2156},
{"county":"Grant County","state":"Oklahoma","id":2157},
{"county":"Greer County","state":"Oklahoma","id":2158},
{"county":"Harmon County","state":"Oklahoma","id":2159},
{"county":"Harper County","state":"Oklahoma","id":2160},
{"county":"Haskell County","state":"Oklahoma","id":2161},
{"county":"Hughes County","state":"Oklahoma","id":2162},
{"county":"Jackson County","state":"Oklahoma","id":2163},
{"county":"Jefferson County","state":"Oklahoma","id":2164},
{"county":"Johnston County","state":"Oklahoma","id":2165},
{"county":"Kay County","state":"Oklahoma","id":2166},
{"county":"Kingfisher County","state":"Oklahoma","id":2167},
{"county":"Kiowa County","state":"Oklahoma","id":2168},
{"county":"Latimer County","state":"Oklahoma","id":2169},
{"county":"Le Flore County","state":"Oklahoma","id":2170},
{"county":"Lincoln County","state":"Oklahoma","id":2171},
{"county":"Logan County","state":"Oklahoma","id":2172},
{"county":"Love County","state":"Oklahoma","id":2173},
{"county":"McClain County","state":"Oklahoma","id":2174},
{"county":"McCurtain County","state":"Oklahoma","id":2175},
{"county":"McIntosh County","state":"Oklahoma","id":2176},
{"county":"Major County","state":"Oklahoma","id":2177},
{"county":"Marshall County","state":"Oklahoma","id":2178},
{"county":"Mayes County","state":"Oklahoma","id":2179},
{"county":"Murray County","state":"Oklahoma","id":2180},
{"county":"Muskogee County","state":"Oklahoma","id":2181},
{"county":"Noble County","state":"Oklahoma","id":2182},
{"county":"Nowata County","state":"Oklahoma","id":2183},
{"county":"Okfuskee County","state":"Oklahoma","id":2184},
{"county":"Oklahoma County","state":"Oklahoma","id":2185},
{"county":"Okmulgee County","state":"Oklahoma","id":2186},
{"county":"Osage County","state":"Oklahoma","id":2187},
{"county":"Ottawa County","state":"Oklahoma","id":2188},
{"county":"Pawnee County","state":"Oklahoma","id":2189},
{"county":"Payne County","state":"Oklahoma","id":2190},
{"county":"Pittsburg County","state":"Oklahoma","id":2191},
{"county":"Pontotoc County","state":"Oklahoma","id":2192},
{"county":"Pottawatomie County","state":"Oklahoma","id":2193},
{"county":"Pushmataha County","state":"Oklahoma","id":2194},
{"county":"Roger Mills County","state":"Oklahoma","id":2195},
{"county":"Rogers County","state":"Oklahoma","id":2196},
{"county":"Seminole County","state":"Oklahoma","id":2197},
{"county":"Sequoyah County","state":"Oklahoma","id":2198},
{"county":"Stephens County","state":"Oklahoma","id":2199},
{"county":"Texas County","state":"Oklahoma","id":2200},
{"county":"Tillman County","state":"Oklahoma","id":2201},
{"county":"Tulsa County","state":"Oklahoma","id":2202},
{"county":"Wagoner County","state":"Oklahoma","id":2203},
{"county":"Washington County","state":"Oklahoma","id":2204},
{"county":"Washita County","state":"Oklahoma","id":2205},
{"county":"Woods County","state":"Oklahoma","id":2206},
{"county":"Woodward County","state":"Oklahoma","id":2207},
{"county":"Baker County","state":"Oregon","id":2208},
{"county":"Benton County","state":"Oregon","id":2209},
{"county":"Clackamas County","state":"Oregon","id":2210},
{"county":"Clatsop County","state":"Oregon","id":2211},
{"county":"Columbia County","state":"Oregon","id":2212},
{"county":"Coos County","state":"Oregon","id":2213},
{"county":"Crook County","state":"Oregon","id":2214},
{"county":"Curry County","state":"Oregon","id":2215},
{"county":"Deschutes County","state":"Oregon","id":2216},
{"county":"Douglas County","state":"Oregon","id":2217},
{"county":"Gilliam County","state":"Oregon","id":2218},
{"county":"Grant County","state":"Oregon","id":2219},
{"county":"Harney County","state":"Oregon","id":2220},
{"county":"Hood River County","state":"Oregon","id":2221},
{"county":"Jackson County","state":"Oregon","id":2222},
{"county":"Jefferson County","state":"Oregon","id":2223},
{"county":"Josephine County","state":"Oregon","id":2224},
{"county":"Klamath County","state":"Oregon","id":2225},
{"county":"Lake County","state":"Oregon","id":2226},
{"county":"Lane County","state":"Oregon","id":2227},
{"county":"Lincoln County","state":"Oregon","id":2228},
{"county":"Linn County","state":"Oregon","id":2229},
{"county":"Malheur County","state":"Oregon","id":2230},
{"county":"Marion County","state":"Oregon","id":2231},
{"county":"Morrow County","state":"Oregon","id":2232},
{"county":"Multnomah County","state":"Oregon","id":2233},
{"county":"Polk County","state":"Oregon","id":2234},
{"county":"Sherman County","state":"Oregon","id":2235},
{"county":"Tillamook County","state":"Oregon","id":2236},
{"county":"Umatilla County","state":"Oregon","id":2237},
{"county":"Union County","state":"Oregon","id":2238},
{"county":"Wallowa County","state":"Oregon","id":2239},
{"county":"Wasco County","state":"Oregon","id":2240},
{"county":"Washington County","state":"Oregon","id":2241},
{"county":"Wheeler County","state":"Oregon","id":2242},
{"county":"Yamhill County","state":"Oregon","id":2243},
{"county":"Adams County","state":"Pennsylvania","id":2244},
{"county":"Allegheny County","state":"Pennsylvania","id":2245},
{"county":"Armstrong County","state":"Pennsylvania","id":2246},
{"county":"Beaver County","state":"Pennsylvania","id":2247},
{"county":"Bedford County","state":"Pennsylvania","id":2248},
{"county":"Berks County","state":"Pennsylvania","id":2249},
{"county":"Blair County","state":"Pennsylvania","id":2250},
{"county":"Bradford County","state":"Pennsylvania","id":2251},
{"county":"Bucks County","state":"Pennsylvania","id":2252},
{"county":"Butler County","state":"Pennsylvania","id":2253},
{"county":"Cambria County","state":"Pennsylvania","id":2254},
{"county":"Cameron County","state":"Pennsylvania","id":2255},
{"county":"Carbon County","state":"Pennsylvania","id":2256},
{"county":"Centre County","state":"Pennsylvania","id":2257},
{"county":"Chester County","state":"Pennsylvania","id":2258},
{"county":"Clarion County","state":"Pennsylvania","id":2259},
{"county":"Clearfield County","state":"Pennsylvania","id":2260},
{"county":"Clinton County","state":"Pennsylvania","id":2261},
{"county":"Columbia County","state":"Pennsylvania","id":2262},
{"county":"Crawford County","state":"Pennsylvania","id":2263},
{"county":"Cumberland County","state":"Pennsylvania","id":2264},
{"county":"Dauphin County","state":"Pennsylvania","id":2265},
{"county":"Delaware County","state":"Pennsylvania","id":2266},
{"county":"Elk County","state":"Pennsylvania","id":2267},
{"county":"Erie County","state":"Pennsylvania","id":2268},
{"county":"Fayette County","state":"Pennsylvania","id":2269},
{"county":"Forest County","state":"Pennsylvania","id":2270},
{"county":"Franklin County","state":"Pennsylvania","id":2271},
{"county":"Fulton County","state":"Pennsylvania","id":2272},
{"county":"Greene County","state":"Pennsylvania","id":2273},
{"county":"Huntingdon County","state":"Pennsylvania","id":2274},
{"county":"Indiana County","state":"Pennsylvania","id":2275},
{"county":"Jefferson County","state":"Pennsylvania","id":2276},
{"county":"Juniata County","state":"Pennsylvania","id":2277},
{"county":"Lackawanna County","state":"Pennsylvania","id":2278},
{"county":"Lancaster County","state":"Pennsylvania","id":2279},
{"county":"Lawrence County","state":"Pennsylvania","id":2280},
{"county":"Lebanon County","state":"Pennsylvania","id":2281},
{"county":"Lehigh County","state":"Pennsylvania","id":2282},
{"county":"Luzerne County","state":"Pennsylvania","id":2283},
{"county":"Lycoming County","state":"Pennsylvania","id":2284},
{"county":"McKean County","state":"Pennsylvania","id":2285},
{"county":"Mercer County","state":"Pennsylvania","id":2286},
{"county":"Mifflin County","state":"Pennsylvania","id":2287},
{"county":"Monroe County","state":"Pennsylvania","id":2288},
{"county":"Montgomery County","state":"Pennsylvania","id":2289},
{"county":"Montour County","state":"Pennsylvania","id":2290},
{"county":"Northampton County","state":"Pennsylvania","id":2291},
{"county":"Northumberland County","state":"Pennsylvania","id":2292},
{"county":"Perry County","state":"Pennsylvania","id":2293},
{"county":"Philadelphia County","state":"Pennsylvania","id":2294},
{"county":"Pike County","state":"Pennsylvania","id":2295},
{"county":"Potter County","state":"Pennsylvania","id":2296},
{"county":"Schuylkill County","state":"Pennsylvania","id":2297},
{"county":"Snyder County","state":"Pennsylvania","id":2298},
{"county":"Somerset County","state":"Pennsylvania","id":2299},
{"county":"Sullivan County","state":"Pennsylvania","id":2300},
{"county":"Susquehanna County","state":"Pennsylvania","id":2301},
{"county":"Tioga County","state":"Pennsylvania","id":2302},
{"county":"Union County","state":"Pennsylvania","id":2303},
{"county":"Venango County","state":"Pennsylvania","id":2304},
{"county":"Warren County","state":"Pennsylvania","id":2305},
{"county":"Washington County","state":"Pennsylvania","id":2306},
{"county":"Wayne County","state":"Pennsylvania","id":2307},
{"county":"Westmoreland County","state":"Pennsylvania","id":2308},
{"county":"Wyoming County","state":"Pennsylvania","id":2309},
{"county":"York County","state":"Pennsylvania","id":2310},
{"county":"Bristol County","state":"Rhode Island","id":2311},
{"county":"Kent County","state":"Rhode Island","id":2312},
{"county":"Newport County","state":"Rhode Island","id":2313},
{"county":"Providence County","state":"Rhode Island","id":2314},
{"county":"Washington County","state":"Rhode Island","id":2315},
{"county":"Abbeville County","state":"South Carolina","id":2316},
{"county":"Aiken County","state":"South Carolina","id":2317},
{"county":"Allendale County","state":"South Carolina","id":2318},
{"county":"Anderson County","state":"South Carolina","id":2319},
{"county":"Bamberg County","state":"South Carolina","id":2320},
{"county":"Barnwell County","state":"South Carolina","id":2321},
{"county":"Beaufort County","state":"South Carolina","id":2322},
{"county":"Berkeley County","state":"South Carolina","id":2323},
{"county":"Calhoun County","state":"South Carolina","id":2324},
{"county":"Charleston County","state":"South Carolina","id":2325},
{"county":"Cherokee County","state":"South Carolina","id":2326},
{"county":"Chester County","state":"South Carolina","id":2327},
{"county":"Chesterfield County","state":"South Carolina","id":2328},
{"county":"Clarendon County","state":"South Carolina","id":2329},
{"county":"Colleton County","state":"South Carolina","id":2330},
{"county":"Darlington County","state":"South Carolina","id":2331},
{"county":"Dillon County","state":"South Carolina","id":2332},
{"county":"Dorchester County","state":"South Carolina","id":2333},
{"county":"Edgefield County","state":"South Carolina","id":2334},
{"county":"Fairfield County","state":"South Carolina","id":2335},
{"county":"Florence County","state":"South Carolina","id":2336},
{"county":"Georgetown County","state":"South Carolina","id":2337},
{"county":"Greenville County","state":"South Carolina","id":2338},
{"county":"Greenwood County","state":"South Carolina","id":2339},
{"county":"Hampton County","state":"South Carolina","id":2340},
{"county":"Horry County","state":"South Carolina","id":2341},
{"county":"Jasper County","state":"South Carolina","id":2342},
{"county":"Kershaw County","state":"South Carolina","id":2343},
{"county":"Lancaster County","state":"South Carolina","id":2344},
{"county":"Laurens County","state":"South Carolina","id":2345},
{"county":"Lee County","state":"South Carolina","id":2346},
{"county":"Lexington County","state":"South Carolina","id":2347},
{"county":"McCormick County","state":"South Carolina","id":2348},
{"county":"Marion County","state":"South Carolina","id":2349},
{"county":"Marlboro County","state":"South Carolina","id":2350},
{"county":"Newberry County","state":"South Carolina","id":2351},
{"county":"Oconee County","state":"South Carolina","id":2352},
{"county":"Orangeburg County","state":"South Carolina","id":2353},
{"county":"Pickens County","state":"South Carolina","id":2354},
{"county":"Richland County","state":"South Carolina","id":2355},
{"county":"Saluda County","state":"South Carolina","id":2356},
{"county":"Spartanburg County","state":"South Carolina","id":2357},
{"county":"Sumter County","state":"South Carolina","id":2358},
{"county":"Union County","state":"South Carolina","id":2359},
{"county":"Williamsburg County","state":"South Carolina","id":2360},
{"county":"York County","state":"South Carolina","id":2361},
{"county":"Aurora County","state":"South Dakota","id":2362},
{"county":"Beadle County","state":"South Dakota","id":2363},
{"county":"Bennett County","state":"South Dakota","id":2364},
{"county":"Bon Homme County","state":"South Dakota","id":2365},
{"county":"Brookings County","state":"South Dakota","id":2366},
{"county":"Brown County","state":"South Dakota","id":2367},
{"county":"Brule County","state":"South Dakota","id":2368},
{"county":"Buffalo County","state":"South Dakota","id":2369},
{"county":"Butte County","state":"South Dakota","id":2370},
{"county":"Campbell County","state":"South Dakota","id":2371},
{"county":"Charles Mix County","state":"South Dakota","id":2372},
{"county":"Clark County","state":"South Dakota","id":2373},
{"county":"Clay County","state":"South Dakota","id":2374},
{"county":"Codington County","state":"South Dakota","id":2375},
{"county":"Corson County","state":"South Dakota","id":2376},
{"county":"Custer County","state":"South Dakota","id":2377},
{"county":"Davison County","state":"South Dakota","id":2378},
{"county":"Day County","state":"South Dakota","id":2379},
{"county":"Deuel County","state":"South Dakota","id":2380},
{"county":"Dewey County","state":"South Dakota","id":2381},
{"county":"Douglas County","state":"South Dakota","id":2382},
{"county":"Edmunds County","state":"South Dakota","id":2383},
{"county":"Fall River County","state":"South Dakota","id":2384},
{"county":"Faulk County","state":"South Dakota","id":2385},
{"county":"Grant County","state":"South Dakota","id":2386},
{"county":"Gregory County","state":"South Dakota","id":2387},
{"county":"Haakon County","state":"South Dakota","id":2388},
{"county":"Hamlin County","state":"South Dakota","id":2389},
{"county":"Hand County","state":"South Dakota","id":2390},
{"county":"Hanson County","state":"South Dakota","id":2391},
{"county":"Harding County","state":"South Dakota","id":2392},
{"county":"Hughes County","state":"South Dakota","id":2393},
{"county":"Hutchinson County","state":"South Dakota","id":2394},
{"county":"Hyde County","state":"South Dakota","id":2395},
{"county":"Jackson County","state":"South Dakota","id":2396},
{"county":"Jerauld County","state":"South Dakota","id":2397},
{"county":"Jones County","state":"South Dakota","id":2398},
{"county":"Kingsbury County","state":"South Dakota","id":2399},
{"county":"Lake County","state":"South Dakota","id":2400},
{"county":"Lawrence County","state":"South Dakota","id":2401},
{"county":"Lincoln County","state":"South Dakota","id":2402},
{"county":"Lyman County","state":"South Dakota","id":2403},
{"county":"McCook County","state":"South Dakota","id":2404},
{"county":"McPherson County","state":"South Dakota","id":2405},
{"county":"Marshall County","state":"South Dakota","id":2406},
{"county":"Meade County","state":"South Dakota","id":2407},
{"county":"Mellette County","state":"South Dakota","id":2408},
{"county":"Miner County","state":"South Dakota","id":2409},
{"county":"Minnehaha County","state":"South Dakota","id":2410},
{"county":"Moody County","state":"South Dakota","id":2411},
{"county":"Pennington County","state":"South Dakota","id":2412},
{"county":"Perkins County","state":"South Dakota","id":2413},
{"county":"Potter County","state":"South Dakota","id":2414},
{"county":"Roberts County","state":"South Dakota","id":2415},
{"county":"Sanborn County","state":"South Dakota","id":2416},
{"county":"Shannon County","state":"South Dakota","id":2417},
{"county":"Spink County","state":"South Dakota","id":2418},
{"county":"Stanley County","state":"South Dakota","id":2419},
{"county":"Sully County","state":"South Dakota","id":2420},
{"county":"Todd County","state":"South Dakota","id":2421},
{"county":"Tripp County","state":"South Dakota","id":2422},
{"county":"Turner County","state":"South Dakota","id":2423},
{"county":"Union County","state":"South Dakota","id":2424},
{"county":"Walworth County","state":"South Dakota","id":2425},
{"county":"Yankton County","state":"South Dakota","id":2426},
{"county":"Ziebach County","state":"South Dakota","id":2427},
{"county":"Anderson County","state":"Tennessee","id":2428},
{"county":"Bedford County","state":"Tennessee","id":2429},
{"county":"Benton County","state":"Tennessee","id":2430},
{"county":"Bledsoe County","state":"Tennessee","id":2431},
{"county":"Blount County","state":"Tennessee","id":2432},
{"county":"Bradley County","state":"Tennessee","id":2433},
{"county":"Campbell County","state":"Tennessee","id":2434},
{"county":"Cannon County","state":"Tennessee","id":2435},
{"county":"Carroll County","state":"Tennessee","id":2436},
{"county":"Carter County","state":"Tennessee","id":2437},
{"county":"Cheatham County","state":"Tennessee","id":2438},
{"county":"Chester County","state":"Tennessee","id":2439},
{"county":"Claiborne County","state":"Tennessee","id":2440},
{"county":"Clay County","state":"Tennessee","id":2441},
{"county":"Cocke County","state":"Tennessee","id":2442},
{"county":"Coffee County","state":"Tennessee","id":2443},
{"county":"Crockett County","state":"Tennessee","id":2444},
{"county":"Cumberland County","state":"Tennessee","id":2445},
{"county":"Davidson County","state":"Tennessee","id":2446},
{"county":"Decatur County","state":"Tennessee","id":2447},
{"county":"DeKalb County","state":"Tennessee","id":2448},
{"county":"Dickson County","state":"Tennessee","id":2449},
{"county":"Dyer County","state":"Tennessee","id":2450},
{"county":"Fayette County","state":"Tennessee","id":2451},
{"county":"Fentress County","state":"Tennessee","id":2452},
{"county":"Franklin County","state":"Tennessee","id":2453},
{"county":"Gibson County","state":"Tennessee","id":2454},
{"county":"Giles County","state":"Tennessee","id":2455},
{"county":"Grainger County","state":"Tennessee","id":2456},
{"county":"Greene County","state":"Tennessee","id":2457},
{"county":"Grundy County","state":"Tennessee","id":2458},
{"county":"Hamblen County","state":"Tennessee","id":2459},
{"county":"Hamilton County","state":"Tennessee","id":2460},
{"county":"Hancock County","state":"Tennessee","id":2461},
{"county":"Hardeman County","state":"Tennessee","id":2462},
{"county":"Hardin County","state":"Tennessee","id":2463},
{"county":"Hawkins County","state":"Tennessee","id":2464},
{"county":"Haywood County","state":"Tennessee","id":2465},
{"county":"Henderson County","state":"Tennessee","id":2466},
{"county":"Henry County","state":"Tennessee","id":2467},
{"county":"Hickman County","state":"Tennessee","id":2468},
{"county":"Houston County","state":"Tennessee","id":2469},
{"county":"Humphreys County","state":"Tennessee","id":2470},
{"county":"Jackson County","state":"Tennessee","id":2471},
{"county":"Jefferson County","state":"Tennessee","id":2472},
{"county":"Johnson County","state":"Tennessee","id":2473},
{"county":"Knox County","state":"Tennessee","id":2474},
{"county":"Lake County","state":"Tennessee","id":2475},
{"county":"Lauderdale County","state":"Tennessee","id":2476},
{"county":"Lawrence County","state":"Tennessee","id":2477},
{"county":"Lewis County","state":"Tennessee","id":2478},
{"county":"Lincoln County","state":"Tennessee","id":2479},
{"county":"Loudon County","state":"Tennessee","id":2480},
{"county":"McMinn County","state":"Tennessee","id":2481},
{"county":"McNairy County","state":"Tennessee","id":2482},
{"county":"Macon County","state":"Tennessee","id":2483},
{"county":"Madison County","state":"Tennessee","id":2484},
{"county":"Marion County","state":"Tennessee","id":2485},
{"county":"Marshall County","state":"Tennessee","id":2486},
{"county":"Maury County","state":"Tennessee","id":2487},
{"county":"Meigs County","state":"Tennessee","id":2488},
{"county":"Monroe County","state":"Tennessee","id":2489},
{"county":"Montgomery County","state":"Tennessee","id":2490},
{"county":"Moore County","state":"Tennessee","id":2491},
{"county":"Morgan County","state":"Tennessee","id":2492},
{"county":"Obion County","state":"Tennessee","id":2493},
{"county":"Overton County","state":"Tennessee","id":2494},
{"county":"Perry County","state":"Tennessee","id":2495},
{"county":"Pickett County","state":"Tennessee","id":2496},
{"county":"Polk County","state":"Tennessee","id":2497},
{"county":"Putnam County","state":"Tennessee","id":2498},
{"county":"Rhea County","state":"Tennessee","id":2499},
{"county":"Roane County","state":"Tennessee","id":2500},
{"county":"Robertson County","state":"Tennessee","id":2501},
{"county":"Rutherford County","state":"Tennessee","id":2502},
{"county":"Scott County","state":"Tennessee","id":2503},
{"county":"Sequatchie County","state":"Tennessee","id":2504},
{"county":"Sevier County","state":"Tennessee","id":2505},
{"county":"Shelby County","state":"Tennessee","id":2506},
{"county":"Smith County","state":"Tennessee","id":2507},
{"county":"Stewart County","state":"Tennessee","id":2508},
{"county":"Sullivan County","state":"Tennessee","id":2509},
{"county":"Sumner County","state":"Tennessee","id":2510},
{"county":"Tipton County","state":"Tennessee","id":2511},
{"county":"Trousdale County","state":"Tennessee","id":2512},
{"county":"Unicoi County","state":"Tennessee","id":2513},
{"county":"Union County","state":"Tennessee","id":2514},
{"county":"Van Buren County","state":"Tennessee","id":2515},
{"county":"Warren County","state":"Tennessee","id":2516},
{"county":"Washington County","state":"Tennessee","id":2517},
{"county":"Wayne County","state":"Tennessee","id":2518},
{"county":"Weakley County","state":"Tennessee","id":2519},
{"county":"White County","state":"Tennessee","id":2520},
{"county":"Williamson County","state":"Tennessee","id":2521},
{"county":"Wilson County","state":"Tennessee","id":2522},
{"county":"Anderson County","state":"Texas","id":2523},
{"county":"Andrews County","state":"Texas","id":2524},
{"county":"Angelina County","state":"Texas","id":2525},
{"county":"Aransas County","state":"Texas","id":2526},
{"county":"Archer County","state":"Texas","id":2527},
{"county":"Armstrong County","state":"Texas","id":2528},
{"county":"Atascosa County","state":"Texas","id":2529},
{"county":"Austin County","state":"Texas","id":2530},
{"county":"Bailey County","state":"Texas","id":2531},
{"county":"Bandera County","state":"Texas","id":2532},
{"county":"Bastrop County","state":"Texas","id":2533},
{"county":"Baylor County","state":"Texas","id":2534},
{"county":"Bee County","state":"Texas","id":2535},
{"county":"Bell County","state":"Texas","id":2536},
{"county":"Bexar County","state":"Texas","id":2537},
{"county":"Blanco County","state":"Texas","id":2538},
{"county":"Borden County","state":"Texas","id":2539},
{"county":"Bosque County","state":"Texas","id":2540},
{"county":"Bowie County","state":"Texas","id":2541},
{"county":"Brazoria County","state":"Texas","id":2542},
{"county":"Brazos County","state":"Texas","id":2543},
{"county":"Brewster County","state":"Texas","id":2544},
{"county":"Briscoe County","state":"Texas","id":2545},
{"county":"Brooks County","state":"Texas","id":2546},
{"county":"Brown County","state":"Texas","id":2547},
{"county":"Burleson County","state":"Texas","id":2548},
{"county":"Burnet County","state":"Texas","id":2549},
{"county":"Caldwell County","state":"Texas","id":2550},
{"county":"Calhoun County","state":"Texas","id":2551},
{"county":"Callahan County","state":"Texas","id":2552},
{"county":"Cameron County","state":"Texas","id":2553},
{"county":"Camp County","state":"Texas","id":2554},
{"county":"Carson County","state":"Texas","id":2555},
{"county":"Cass County","state":"Texas","id":2556},
{"county":"Castro County","state":"Texas","id":2557},
{"county":"Chambers County","state":"Texas","id":2558},
{"county":"Cherokee County","state":"Texas","id":2559},
{"county":"Childress County","state":"Texas","id":2560},
{"county":"Clay County","state":"Texas","id":2561},
{"county":"Cochran County","state":"Texas","id":2562},
{"county":"Coke County","state":"Texas","id":2563},
{"county":"Coleman County","state":"Texas","id":2564},
{"county":"Collin County","state":"Texas","id":2565},
{"county":"Collingsworth County","state":"Texas","id":2566},
{"county":"Colorado County","state":"Texas","id":2567},
{"county":"Comal County","state":"Texas","id":2568},
{"county":"Comanche County","state":"Texas","id":2569},
{"county":"Concho County","state":"Texas","id":2570},
{"county":"Cooke County","state":"Texas","id":2571},
{"county":"Coryell County","state":"Texas","id":2572},
{"county":"Cottle County","state":"Texas","id":2573},
{"county":"Crane County","state":"Texas","id":2574},
{"county":"Crockett County","state":"Texas","id":2575},
{"county":"Crosby County","state":"Texas","id":2576},
{"county":"Culberson County","state":"Texas","id":2577},
{"county":"Dallam County","state":"Texas","id":2578},
{"county":"Dallas County","state":"Texas","id":2579},
{"county":"Dawson County","state":"Texas","id":2580},
{"county":"Deaf Smith County","state":"Texas","id":2581},
{"county":"Delta County","state":"Texas","id":2582},
{"county":"Denton County","state":"Texas","id":2583},
{"county":"DeWitt County","state":"Texas","id":2584},
{"county":"Dickens County","state":"Texas","id":2585},
{"county":"Dimmit County","state":"Texas","id":2586},
{"county":"Donley County","state":"Texas","id":2587},
{"county":"Duval County","state":"Texas","id":2588},
{"county":"Eastland County","state":"Texas","id":2589},
{"county":"Ector County","state":"Texas","id":2590},
{"county":"Edwards County","state":"Texas","id":2591},
{"county":"Ellis County","state":"Texas","id":2592},
{"county":"El Paso County","state":"Texas","id":2593},
{"county":"Erath County","state":"Texas","id":2594},
{"county":"Falls County","state":"Texas","id":2595},
{"county":"Fannin County","state":"Texas","id":2596},
{"county":"Fayette County","state":"Texas","id":2597},
{"county":"Fisher County","state":"Texas","id":2598},
{"county":"Floyd County","state":"Texas","id":2599},
{"county":"Foard County","state":"Texas","id":2600},
{"county":"Fort Bend County","state":"Texas","id":2601},
{"county":"Franklin County","state":"Texas","id":2602},
{"county":"Freestone County","state":"Texas","id":2603},
{"county":"Frio County","state":"Texas","id":2604},
{"county":"Gaines County","state":"Texas","id":2605},
{"county":"Galveston County","state":"Texas","id":2606},
{"county":"Garza County","state":"Texas","id":2607},
{"county":"Gillespie County","state":"Texas","id":2608},
{"county":"Glasscock County","state":"Texas","id":2609},
{"county":"Goliad County","state":"Texas","id":2610},
{"county":"Gonzales County","state":"Texas","id":2611},
{"county":"Gray County","state":"Texas","id":2612},
{"county":"Grayson County","state":"Texas","id":2613},
{"county":"Gregg County","state":"Texas","id":2614},
{"county":"Grimes County","state":"Texas","id":2615},
{"county":"Guadalupe County","state":"Texas","id":2616},
{"county":"Hale County","state":"Texas","id":2617},
{"county":"Hall County","state":"Texas","id":2618},
{"county":"Hamilton County","state":"Texas","id":2619},
{"county":"Hansford County","state":"Texas","id":2620},
{"county":"Hardeman County","state":"Texas","id":2621},
{"county":"Hardin County","state":"Texas","id":2622},
{"county":"Harris County","state":"Texas","id":2623},
{"county":"Harrison County","state":"Texas","id":2624},
{"county":"Hartley County","state":"Texas","id":2625},
{"county":"Haskell County","state":"Texas","id":2626},
{"county":"Hays County","state":"Texas","id":2627},
{"county":"Hemphill County","state":"Texas","id":2628},
{"county":"Henderson County","state":"Texas","id":2629},
{"county":"Hidalgo County","state":"Texas","id":2630},
{"county":"Hill County","state":"Texas","id":2631},
{"county":"Hockley County","state":"Texas","id":2632},
{"county":"Hood County","state":"Texas","id":2633},
{"county":"Hopkins County","state":"Texas","id":2634},
{"county":"Houston County","state":"Texas","id":2635},
{"county":"Howard County","state":"Texas","id":2636},
{"county":"Hudspeth County","state":"Texas","id":2637},
{"county":"Hunt County","state":"Texas","id":2638},
{"county":"Hutchinson County","state":"Texas","id":2639},
{"county":"Irion County","state":"Texas","id":2640},
{"county":"Jack County","state":"Texas","id":2641},
{"county":"Jackson County","state":"Texas","id":2642},
{"county":"Jasper County","state":"Texas","id":2643},
{"county":"Jeff Davis County","state":"Texas","id":2644},
{"county":"Jefferson County","state":"Texas","id":2645},
{"county":"Jim Hogg County","state":"Texas","id":2646},
{"county":"Jim Wells County","state":"Texas","id":2647},
{"county":"Johnson County","state":"Texas","id":2648},
{"county":"Jones County","state":"Texas","id":2649},
{"county":"Karnes County","state":"Texas","id":2650},
{"county":"Kaufman County","state":"Texas","id":2651},
{"county":"Kendall County","state":"Texas","id":2652},
{"county":"Kenedy County","state":"Texas","id":2653},
{"county":"Kent County","state":"Texas","id":2654},
{"county":"Kerr County","state":"Texas","id":2655},
{"county":"Kimble County","state":"Texas","id":2656},
{"county":"King County","state":"Texas","id":2657},
{"county":"Kinney County","state":"Texas","id":2658},
{"county":"Kleberg County","state":"Texas","id":2659},
{"county":"Knox County","state":"Texas","id":2660},
{"county":"Lamar County","state":"Texas","id":2661},
{"county":"Lamb County","state":"Texas","id":2662},
{"county":"Lampasas County","state":"Texas","id":2663},
{"county":"La Salle County","state":"Texas","id":2664},
{"county":"Lavaca County","state":"Texas","id":2665},
{"county":"Lee County","state":"Texas","id":2666},
{"county":"Leon County","state":"Texas","id":2667},
{"county":"Liberty County","state":"Texas","id":2668},
{"county":"Limestone County","state":"Texas","id":2669},
{"county":"Lipscomb County","state":"Texas","id":2670},
{"county":"Live Oak County","state":"Texas","id":2671},
{"county":"Llano County","state":"Texas","id":2672},
{"county":"Loving County","state":"Texas","id":2673},
{"county":"Lubbock County","state":"Texas","id":2674},
{"county":"Lynn County","state":"Texas","id":2675},
{"county":"McCulloch County","state":"Texas","id":2676},
{"county":"McLennan County","state":"Texas","id":2677},
{"county":"McMullen County","state":"Texas","id":2678},
{"county":"Madison County","state":"Texas","id":2679},
{"county":"Marion County","state":"Texas","id":2680},
{"county":"Martin County","state":"Texas","id":2681},
{"county":"Mason County","state":"Texas","id":2682},
{"county":"Matagorda County","state":"Texas","id":2683},
{"county":"Maverick County","state":"Texas","id":2684},
{"county":"Medina County","state":"Texas","id":2685},
{"county":"Menard County","state":"Texas","id":2686},
{"county":"Midland County","state":"Texas","id":2687},
{"county":"Milam County","state":"Texas","id":2688},
{"county":"Mills County","state":"Texas","id":2689},
{"county":"Mitchell County","state":"Texas","id":2690},
{"county":"Montague County","state":"Texas","id":2691},
{"county":"Montgomery County","state":"Texas","id":2692},
{"county":"Moore County","state":"Texas","id":2693},
{"county":"Morris County","state":"Texas","id":2694},
{"county":"Motley County","state":"Texas","id":2695},
{"county":"Nacogdoches County","state":"Texas","id":2696},
{"county":"Navarro County","state":"Texas","id":2697},
{"county":"Newton County","state":"Texas","id":2698},
{"county":"Nolan County","state":"Texas","id":2699},
{"county":"Nueces County","state":"Texas","id":2700},
{"county":"Ochiltree County","state":"Texas","id":2701},
{"county":"Oldham County","state":"Texas","id":2702},
{"county":"Orange County","state":"Texas","id":2703},
{"county":"Palo Pinto County","state":"Texas","id":2704},
{"county":"Panola County","state":"Texas","id":2705},
{"county":"Parker County","state":"Texas","id":2706},
{"county":"Parmer County","state":"Texas","id":2707},
{"county":"Pecos County","state":"Texas","id":2708},
{"county":"Polk County","state":"Texas","id":2709},
{"county":"Potter County","state":"Texas","id":2710},
{"county":"Presidio County","state":"Texas","id":2711},
{"county":"Rains County","state":"Texas","id":2712},
{"county":"Randall County","state":"Texas","id":2713},
{"county":"Reagan County","state":"Texas","id":2714},
{"county":"Real County","state":"Texas","id":2715},
{"county":"Red River County","state":"Texas","id":2716},
{"county":"Reeves County","state":"Texas","id":2717},
{"county":"Refugio County","state":"Texas","id":2718},
{"county":"Roberts County","state":"Texas","id":2719},
{"county":"Robertson County","state":"Texas","id":2720},
{"county":"Rockwall County","state":"Texas","id":2721},
{"county":"Runnels County","state":"Texas","id":2722},
{"county":"Rusk County","state":"Texas","id":2723},
{"county":"Sabine County","state":"Texas","id":2724},
{"county":"San Augustine County","state":"Texas","id":2725},
{"county":"San Jacinto County","state":"Texas","id":2726},
{"county":"San Patricio County","state":"Texas","id":2727},
{"county":"San Saba County","state":"Texas","id":2728},
{"county":"Schleicher County","state":"Texas","id":2729},
{"county":"Scurry County","state":"Texas","id":2730},
{"county":"Shackelford County","state":"Texas","id":2731},
{"county":"Shelby County","state":"Texas","id":2732},
{"county":"Sherman County","state":"Texas","id":2733},
{"county":"Smith County","state":"Texas","id":2734},
{"county":"Somervell County","state":"Texas","id":2735},
{"county":"Starr County","state":"Texas","id":2736},
{"county":"Stephens County","state":"Texas","id":2737},
{"county":"Sterling County","state":"Texas","id":2738},
{"county":"Stonewall County","state":"Texas","id":2739},
{"county":"Sutton County","state":"Texas","id":2740},
{"county":"Swisher County","state":"Texas","id":2741},
{"county":"Tarrant County","state":"Texas","id":2742},
{"county":"Taylor County","state":"Texas","id":2743},
{"county":"Terrell County","state":"Texas","id":2744},
{"county":"Terry County","state":"Texas","id":2745},
{"county":"Throckmorton County","state":"Texas","id":2746},
{"county":"Titus County","state":"Texas","id":2747},
{"county":"Tom Green County","state":"Texas","id":2748},
{"county":"Travis County","state":"Texas","id":2749},
{"county":"Trinity County","state":"Texas","id":2750},
{"county":"Tyler County","state":"Texas","id":2751},
{"county":"Upshur County","state":"Texas","id":2752},
{"county":"Upton County","state":"Texas","id":2753},
{"county":"Uvalde County","state":"Texas","id":2754},
{"county":"Val Verde County","state":"Texas","id":2755},
{"county":"Van Zandt County","state":"Texas","id":2756},
{"county":"Victoria County","state":"Texas","id":2757},
{"county":"Walker County","state":"Texas","id":2758},
{"county":"Waller County","state":"Texas","id":2759},
{"county":"Ward County","state":"Texas","id":2760},
{"county":"Washington County","state":"Texas","id":2761},
{"county":"Webb County","state":"Texas","id":2762},
{"county":"Wharton County","state":"Texas","id":2763},
{"county":"Wheeler County","state":"Texas","id":2764},
{"county":"Wichita County","state":"Texas","id":2765},
{"county":"Wilbarger County","state":"Texas","id":2766},
{"county":"Willacy County","state":"Texas","id":2767},
{"county":"Williamson County","state":"Texas","id":2768},
{"county":"Wilson County","state":"Texas","id":2769},
{"county":"Winkler County","state":"Texas","id":2770},
{"county":"Wise County","state":"Texas","id":2771},
{"county":"Wood County","state":"Texas","id":2772},
{"county":"Yoakum County","state":"Texas","id":2773},
{"county":"Young County","state":"Texas","id":2774},
{"county":"Zapata County","state":"Texas","id":2775},
{"county":"Zavala County","state":"Texas","id":2776},
{"county":"Beaver County","state":"Utah","id":2777},
{"county":"Box Elder County","state":"Utah","id":2778},
{"county":"Cache County","state":"Utah","id":2779},
{"county":"Carbon County","state":"Utah","id":2780},
{"county":"Daggett County","state":"Utah","id":2781},
{"county":"Davis County","state":"Utah","id":2782},
{"county":"Duchesne County","state":"Utah","id":2783},
{"county":"Emery County","state":"Utah","id":2784},
{"county":"Garfield County","state":"Utah","id":2785},
{"county":"Grand County","state":"Utah","id":2786},
{"county":"Iron County","state":"Utah","id":2787},
{"county":"Juab County","state":"Utah","id":2788},
{"county":"Kane County","state":"Utah","id":2789},
{"county":"Millard County","state":"Utah","id":2790},
{"county":"Morgan County","state":"Utah","id":2791},
{"county":"Piute County","state":"Utah","id":2792},
{"county":"Rich County","state":"Utah","id":2793},
{"county":"Salt Lake County","state":"Utah","id":2794},
{"county":"San Juan County","state":"Utah","id":2795},
{"county":"Sanpete County","state":"Utah","id":2796},
{"county":"Sevier County","state":"Utah","id":2797},
{"county":"Summit County","state":"Utah","id":2798},
{"county":"Tooele County","state":"Utah","id":2799},
{"county":"Uintah County","state":"Utah","id":2800},
{"county":"Utah County","state":"Utah","id":2801},
{"county":"Wasatch County","state":"Utah","id":2802},
{"county":"Washington County","state":"Utah","id":2803},
{"county":"Wayne County","state":"Utah","id":2804},
{"county":"Weber County","state":"Utah","id":2805},
{"county":"Addison County","state":"Vermont","id":2806},
{"county":"Bennington County","state":"Vermont","id":2807},
{"county":"Caledonia County","state":"Vermont","id":2808},
{"county":"Chittenden County","state":"Vermont","id":2809},
{"county":"Essex County","state":"Vermont","id":2810},
{"county":"Franklin County","state":"Vermont","id":2811},
{"county":"Grand Isle County","state":"Vermont","id":2812},
{"county":"Lamoille County","state":"Vermont","id":2813},
{"county":"Orange County","state":"Vermont","id":2814},
{"county":"Orleans County","state":"Vermont","id":2815},
{"county":"Rutland County","state":"Vermont","id":2816},
{"county":"Washington County","state":"Vermont","id":2817},
{"county":"Windham County","state":"Vermont","id":2818},
{"county":"Windsor County","state":"Vermont","id":2819},
{"county":"Accomack County","state":"Virginia","id":2820},
{"county":"Albemarle County","state":"Virginia","id":2821},
{"county":"Alleghany County","state":"Virginia","id":2822},
{"county":"Amelia County","state":"Virginia","id":2823},
{"county":"Amherst County","state":"Virginia","id":2824},
{"county":"Appomattox County","state":"Virginia","id":2825},
{"county":"Arlington County","state":"Virginia","id":2826},
{"county":"Augusta County","state":"Virginia","id":2827},
{"county":"Bath County","state":"Virginia","id":2828},
{"county":"Bedford County","state":"Virginia","id":2829},
{"county":"Bland County","state":"Virginia","id":2830},
{"county":"Botetourt County","state":"Virginia","id":2831},
{"county":"Brunswick County","state":"Virginia","id":2832},
{"county":"Buchanan County","state":"Virginia","id":2833},
{"county":"Buckingham County","state":"Virginia","id":2834},
{"county":"Campbell County","state":"Virginia","id":2835},
{"county":"Caroline County","state":"Virginia","id":2836},
{"county":"Carroll County","state":"Virginia","id":2837},
{"county":"Charles City County","state":"Virginia","id":2838},
{"county":"Charlotte County","state":"Virginia","id":2839},
{"county":"Chesterfield County","state":"Virginia","id":2840},
{"county":"Clarke County","state":"Virginia","id":2841},
{"county":"Craig County","state":"Virginia","id":2842},
{"county":"Culpeper County","state":"Virginia","id":2843},
{"county":"Cumberland County","state":"Virginia","id":2844},
{"county":"Dickenson County","state":"Virginia","id":2845},
{"county":"Dinwiddie County","state":"Virginia","id":2846},
{"county":"Essex County","state":"Virginia","id":2847},
{"county":"Fairfax County","state":"Virginia","id":2848},
{"county":"Fauquier County","state":"Virginia","id":2849},
{"county":"Floyd County","state":"Virginia","id":2850},
{"county":"Fluvanna County","state":"Virginia","id":2851},
{"county":"Franklin County","state":"Virginia","id":2852},
{"county":"Frederick County","state":"Virginia","id":2853},
{"county":"Giles County","state":"Virginia","id":2854},
{"county":"Gloucester County","state":"Virginia","id":2855},
{"county":"Goochland County","state":"Virginia","id":2856},
{"county":"Grayson County","state":"Virginia","id":2857},
{"county":"Greene County","state":"Virginia","id":2858},
{"county":"Greensville County","state":"Virginia","id":2859},
{"county":"Halifax County","state":"Virginia","id":2860},
{"county":"Hanover County","state":"Virginia","id":2861},
{"county":"Henrico County","state":"Virginia","id":2862},
{"county":"Henry County","state":"Virginia","id":2863},
{"county":"Highland County","state":"Virginia","id":2864},
{"county":"Isle of Wight County","state":"Virginia","id":2865},
{"county":"James City County","state":"Virginia","id":2866},
{"county":"King and Queen County","state":"Virginia","id":2867},
{"county":"King George County","state":"Virginia","id":2868},
{"county":"King William County","state":"Virginia","id":2869},
{"county":"Lancaster County","state":"Virginia","id":2870},
{"county":"Lee County","state":"Virginia","id":2871},
{"county":"Loudoun County","state":"Virginia","id":2872},
{"county":"Louisa County","state":"Virginia","id":2873},
{"county":"Lunenburg County","state":"Virginia","id":2874},
{"county":"Madison County","state":"Virginia","id":2875},
{"county":"Mathews County","state":"Virginia","id":2876},
{"county":"Mecklenburg County","state":"Virginia","id":2877},
{"county":"Middlesex County","state":"Virginia","id":2878},
{"county":"Montgomery County","state":"Virginia","id":2879},
{"county":"Nelson County","state":"Virginia","id":2880},
{"county":"New Kent County","state":"Virginia","id":2881},
{"county":"Northampton County","state":"Virginia","id":2882},
{"county":"Northumberland County","state":"Virginia","id":2883},
{"county":"Nottoway County","state":"Virginia","id":2884},
{"county":"Orange County","state":"Virginia","id":2885},
{"county":"Page County","state":"Virginia","id":2886},
{"county":"Patrick County","state":"Virginia","id":2887},
{"county":"Pittsylvania County","state":"Virginia","id":2888},
{"county":"Powhatan County","state":"Virginia","id":2889},
{"county":"Prince Edward County","state":"Virginia","id":2890},
{"county":"Prince George County","state":"Virginia","id":2891},
{"county":"Prince William County","state":"Virginia","id":2892},
{"county":"Pulaski County","state":"Virginia","id":2893},
{"county":"Rappahannock County","state":"Virginia","id":2894},
{"county":"Richmond County","state":"Virginia","id":2895},
{"county":"Roanoke County","state":"Virginia","id":2896},
{"county":"Rockbridge County","state":"Virginia","id":2897},
{"county":"Rockingham County","state":"Virginia","id":2898},
{"county":"Russell County","state":"Virginia","id":2899},
{"county":"Scott County","state":"Virginia","id":2900},
{"county":"Shenandoah County","state":"Virginia","id":2901},
{"county":"Smyth County","state":"Virginia","id":2902},
{"county":"Southampton County","state":"Virginia","id":2903},
{"county":"Spotsylvania County","state":"Virginia","id":2904},
{"county":"Stafford County","state":"Virginia","id":2905},
{"county":"Surry County","state":"Virginia","id":2906},
{"county":"Sussex County","state":"Virginia","id":2907},
{"county":"Tazewell County","state":"Virginia","id":2908},
{"county":"Warren County","state":"Virginia","id":2909},
{"county":"Washington County","state":"Virginia","id":2910},
{"county":"Westmoreland County","state":"Virginia","id":2911},
{"county":"Wise County","state":"Virginia","id":2912},
{"county":"Wythe County","state":"Virginia","id":2913},
{"county":"York County","state":"Virginia","id":2914},
{"county":"Alexandria, City of","state":"Virginia","id":2915},
{"county":"Bedford, City of","state":"Virginia","id":2916},
{"county":"Bristol, City of","state":"Virginia","id":2917},
{"county":"Buena Vista, City of","state":"Virginia","id":2918},
{"county":"Charlottesville, City of","state":"Virginia","id":2919},
{"county":"Chesapeake, City of","state":"Virginia","id":2920},
{"county":"Colonial Heights, City of","state":"Virginia","id":2921},
{"county":"Covington, City of","state":"Virginia","id":2922},
{"county":"Danville, City of","state":"Virginia","id":2923},
{"county":"Emporia, City of","state":"Virginia","id":2924},
{"county":"Fairfax, City of","state":"Virginia","id":2925},
{"county":"Falls Church, City of","state":"Virginia","id":2926},
{"county":"Franklin, City of","state":"Virginia","id":2927},
{"county":"Fredericksburg, City of","state":"Virginia","id":2928},
{"county":"Galax, City of","state":"Virginia","id":2929},
{"county":"Hampton, City of","state":"Virginia","id":2930},
{"county":"Harrisonburg, City of","state":"Virginia","id":2931},
{"county":"Hopewell, City of","state":"Virginia","id":2932},
{"county":"Lexington, City of","state":"Virginia","id":2933},
{"county":"Lynchburg, City of","state":"Virginia","id":2934},
{"county":"Manassas, City of","state":"Virginia","id":2935},
{"county":"Manassas Park, City of","state":"Virginia","id":2936},
{"county":"Martinsville, City of","state":"Virginia","id":2937},
{"county":"Newport News, City of","state":"Virginia","id":2938},
{"county":"Norfolk, City of","state":"Virginia","id":2939},
{"county":"Norton, City of","state":"Virginia","id":2940},
{"county":"Petersburg, City of","state":"Virginia","id":2941},
{"county":"Poquoson, City of","state":"Virginia","id":2942},
{"county":"Portsmouth, City of","state":"Virginia","id":2943},
{"county":"Radford, City of","state":"Virginia","id":2944},
{"county":"Richmond, City of","state":"Virginia","id":2945},
{"county":"Roanoke, City of","state":"Virginia","id":2946},
{"county":"Salem, City of","state":"Virginia","id":2947},
{"county":"Staunton, City of","state":"Virginia","id":2948},
{"county":"Suffolk, City of","state":"Virginia","id":2949},
{"county":"Virginia Beach, City of","state":"Virginia","id":2950},
{"county":"Waynesboro, City of","state":"Virginia","id":2951},
{"county":"Williamsburg, City of","state":"Virginia","id":2952},
{"county":"Winchester, City of","state":"Virginia","id":2953},
{"county":"Adams County","state":"Washington","id":2954},
{"county":"Asotin County","state":"Washington","id":2955},
{"county":"Benton County","state":"Washington","id":2956},
{"county":"Chelan County","state":"Washington","id":2957},
{"county":"Clallam County","state":"Washington","id":2958},
{"county":"Clark County","state":"Washington","id":2959},
{"county":"Columbia County","state":"Washington","id":2960},
{"county":"Cowlitz County","state":"Washington","id":2961},
{"county":"Douglas County","state":"Washington","id":2962},
{"county":"Ferry County","state":"Washington","id":2963},
{"county":"Franklin County","state":"Washington","id":2964},
{"county":"Garfield County","state":"Washington","id":2965},
{"county":"Grant County","state":"Washington","id":2966},
{"county":"Grays Harbor County","state":"Washington","id":2967},
{"county":"Island County","state":"Washington","id":2968},
{"county":"Jefferson County","state":"Washington","id":2969},
{"county":"King County","state":"Washington","id":2970},
{"county":"Kitsap County","state":"Washington","id":2971},
{"county":"Kittitas County","state":"Washington","id":2972},
{"county":"Klickitat County","state":"Washington","id":2973},
{"county":"Lewis County","state":"Washington","id":2974},
{"county":"Lincoln County","state":"Washington","id":2975},
{"county":"Mason County","state":"Washington","id":2976},
{"county":"Okanogan County","state":"Washington","id":2977},
{"county":"Pacific County","state":"Washington","id":2978},
{"county":"Pend Oreille County","state":"Washington","id":2979},
{"county":"Pierce County","state":"Washington","id":2980},
{"county":"San Juan County","state":"Washington","id":2981},
{"county":"Skagit County","state":"Washington","id":2982},
{"county":"Skamania County","state":"Washington","id":2983},
{"county":"Snohomish County","state":"Washington","id":2984},
{"county":"Spokane County","state":"Washington","id":2985},
{"county":"Stevens County","state":"Washington","id":2986},
{"county":"Thurston County","state":"Washington","id":2987},
{"county":"Wahkiakum County","state":"Washington","id":2988},
{"county":"Walla Walla County","state":"Washington","id":2989},
{"county":"Whatcom County","state":"Washington","id":2990},
{"county":"Whitman County","state":"Washington","id":2991},
{"county":"Yakima County","state":"Washington","id":2992},
{"county":"Barbour County","state":"West Virginia","id":2993},
{"county":"Berkeley County","state":"West Virginia","id":2994},
{"county":"Boone County","state":"West Virginia","id":2995},
{"county":"Braxton County","state":"West Virginia","id":2996},
{"county":"Brooke County","state":"West Virginia","id":2997},
{"county":"Cabell County","state":"West Virginia","id":2998},
{"county":"Calhoun County","state":"West Virginia","id":2999},
{"county":"Clay County","state":"West Virginia","id":3000},
{"county":"Doddridge County","state":"West Virginia","id":3001},
{"county":"Fayette County","state":"West Virginia","id":3002},
{"county":"Gilmer County","state":"West Virginia","id":3003},
{"county":"Grant County","state":"West Virginia","id":3004},
{"county":"Greenbrier County","state":"West Virginia","id":3005},
{"county":"Hampshire County","state":"West Virginia","id":3006},
{"county":"Hancock County","state":"West Virginia","id":3007},
{"county":"Hardy County","state":"West Virginia","id":3008},
{"county":"Harrison County","state":"West Virginia","id":3009},
{"county":"Jackson County","state":"West Virginia","id":3010},
{"county":"Jefferson County","state":"West Virginia","id":3011},
{"county":"Kanawha County","state":"West Virginia","id":3012},
{"county":"Lewis County","state":"West Virginia","id":3013},
{"county":"Lincoln County","state":"West Virginia","id":3014},
{"county":"Logan County","state":"West Virginia","id":3015},
{"county":"McDowell County","state":"West Virginia","id":3016},
{"county":"Marion County","state":"West Virginia","id":3017},
{"county":"Marshall County","state":"West Virginia","id":3018},
{"county":"Mason County","state":"West Virginia","id":3019},
{"county":"Mercer County","state":"West Virginia","id":3020},
{"county":"Mineral County","state":"West Virginia","id":3021},
{"county":"Mingo County","state":"West Virginia","id":3022},
{"county":"Monongalia County","state":"West Virginia","id":3023},
{"county":"Monroe County","state":"West Virginia","id":3024},
{"county":"Morgan County","state":"West Virginia","id":3025},
{"county":"Nicholas County","state":"West Virginia","id":3026},
{"county":"Ohio County","state":"West Virginia","id":3027},
{"county":"Pendleton County","state":"West Virginia","id":3028},
{"county":"Pleasants County","state":"West Virginia","id":3029},
{"county":"Pocahontas County","state":"West Virginia","id":3030},
{"county":"Preston County","state":"West Virginia","id":3031},
{"county":"Putnam County","state":"West Virginia","id":3032},
{"county":"Raleigh County","state":"West Virginia","id":3033},
{"county":"Randolph County","state":"West Virginia","id":3034},
{"county":"Ritchie County","state":"West Virginia","id":3035},
{"county":"Roane County","state":"West Virginia","id":3036},
{"county":"Summers County","state":"West Virginia","id":3037},
{"county":"Taylor County","state":"West Virginia","id":3038},
{"county":"Tucker County","state":"West Virginia","id":3039},
{"county":"Tyler County","state":"West Virginia","id":3040},
{"county":"Upshur County","state":"West Virginia","id":3041},
{"county":"Wayne County","state":"West Virginia","id":3042},
{"county":"Webster County","state":"West Virginia","id":3043},
{"county":"Wetzel County","state":"West Virginia","id":3044},
{"county":"Wirt County","state":"West Virginia","id":3045},
{"county":"Wood County","state":"West Virginia","id":3046},
{"county":"Wyoming County","state":"West Virginia","id":3047},
{"county":"Adams County","state":"Wisconsin","id":3048},
{"county":"Ashland County","state":"Wisconsin","id":3049},
{"county":"Barron County","state":"Wisconsin","id":3050},
{"county":"Bayfield County","state":"Wisconsin","id":3051},
{"county":"Brown County","state":"Wisconsin","id":3052},
{"county":"Buffalo County","state":"Wisconsin","id":3053},
{"county":"Burnett County","state":"Wisconsin","id":3054},
{"county":"Calumet County","state":"Wisconsin","id":3055},
{"county":"Chippewa County","state":"Wisconsin","id":3056},
{"county":"Clark County","state":"Wisconsin","id":3057},
{"county":"Columbia County","state":"Wisconsin","id":3058},
{"county":"Crawford County","state":"Wisconsin","id":3059},
{"county":"Dane County","state":"Wisconsin","id":3060},
{"county":"Dodge County","state":"Wisconsin","id":3061},
{"county":"Door County","state":"Wisconsin","id":3062},
{"county":"Douglas County","state":"Wisconsin","id":3063},
{"county":"Dunn County","state":"Wisconsin","id":3064},
{"county":"Eau Claire County","state":"Wisconsin","id":3065},
{"county":"Florence County","state":"Wisconsin","id":3066},
{"county":"Fond du Lac County","state":"Wisconsin","id":3067},
{"county":"Forest County","state":"Wisconsin","id":3068},
{"county":"Grant County","state":"Wisconsin","id":3069},
{"county":"Green County","state":"Wisconsin","id":3070},
{"county":"Green Lake County","state":"Wisconsin","id":3071},
{"county":"Iowa County","state":"Wisconsin","id":3072},
{"county":"Iron County","state":"Wisconsin","id":3073},
{"county":"Jackson County","state":"Wisconsin","id":3074},
{"county":"Jefferson County","state":"Wisconsin","id":3075},
{"county":"Juneau County","state":"Wisconsin","id":3076},
{"county":"Kenosha County","state":"Wisconsin","id":3077},
{"county":"Kewaunee County","state":"Wisconsin","id":3078},
{"county":"La Crosse County","state":"Wisconsin","id":3079},
{"county":"Lafayette County","state":"Wisconsin","id":3080},
{"county":"Langlade County","state":"Wisconsin","id":3081},
{"county":"Lincoln County","state":"Wisconsin","id":3082},
{"county":"Manitowoc County","state":"Wisconsin","id":3083},
{"county":"Marathon County","state":"Wisconsin","id":3084},
{"county":"Marinette County","state":"Wisconsin","id":3085},
{"county":"Marquette County","state":"Wisconsin","id":3086},
{"county":"Menominee County","state":"Wisconsin","id":3087},
{"county":"Milwaukee County","state":"Wisconsin","id":3088},
{"county":"Monroe County","state":"Wisconsin","id":3089},
{"county":"Oconto County","state":"Wisconsin","id":3090},
{"county":"Oneida County","state":"Wisconsin","id":3091},
{"county":"Outagamie County","state":"Wisconsin","id":3092},
{"county":"Ozaukee County","state":"Wisconsin","id":3093},
{"county":"Pepin County","state":"Wisconsin","id":3094},
{"county":"Pierce County","state":"Wisconsin","id":3095},
{"county":"Polk County","state":"Wisconsin","id":3096},
{"county":"Portage County","state":"Wisconsin","id":3097},
{"county":"Price County","state":"Wisconsin","id":3098},
{"county":"Racine County","state":"Wisconsin","id":3099},
{"county":"Richland County","state":"Wisconsin","id":3100},
{"county":"Rock County","state":"Wisconsin","id":3101},
{"county":"Rusk County","state":"Wisconsin","id":3102},
{"county":"St. Croix County","state":"Wisconsin","id":3103},
{"county":"Sauk County","state":"Wisconsin","id":3104},
{"county":"Sawyer County","state":"Wisconsin","id":3105},
{"county":"Shawano County","state":"Wisconsin","id":3106},
{"county":"Sheboygan County","state":"Wisconsin","id":3107},
{"county":"Taylor County","state":"Wisconsin","id":3108},
{"county":"Trempealeau County","state":"Wisconsin","id":3109},
{"county":"Vernon County","state":"Wisconsin","id":3110},
{"county":"Vilas County","state":"Wisconsin","id":3111},
{"county":"Walworth County","state":"Wisconsin","id":3112},
{"county":"Washburn County","state":"Wisconsin","id":3113},
{"county":"Washington County","state":"Wisconsin","id":3114},
{"county":"Waukesha County","state":"Wisconsin","id":3115},
{"county":"Waupaca County","state":"Wisconsin","id":3116},
{"county":"Waushara County","state":"Wisconsin","id":3117},
{"county":"Winnebago County","state":"Wisconsin","id":3118},
{"county":"Wood County","state":"Wisconsin","id":3119},
{"county":"Albany County","state":"Wyoming","id":3120},
{"county":"Big Horn County","state":"Wyoming","id":3121},
{"county":"Campbell County","state":"Wyoming","id":3122},
{"county":"Carbon County","state":"Wyoming","id":3123},
{"county":"Converse County","state":"Wyoming","id":3124},
{"county":"Crook County","state":"Wyoming","id":3125},
{"county":"Fremont County","state":"Wyoming","id":3126},
{"county":"Goshen County","state":"Wyoming","id":3127},
{"county":"Hot Springs County","state":"Wyoming","id":3128},
{"county":"Johnson County","state":"Wyoming","id":3129},
{"county":"Laramie County","state":"Wyoming","id":3130},
{"county":"Lincoln County","state":"Wyoming","id":3131},
{"county":"Natrona County","state":"Wyoming","id":3132},
{"county":"Niobrara County","state":"Wyoming","id":3133},
{"county":"Park County","state":"Wyoming","id":3134},
{"county":"Platte County","state":"Wyoming","id":3135},
{"county":"Sheridan County","state":"Wyoming","id":3136},
{"county":"Sublette County","state":"Wyoming","id":3137},
{"county":"Sweetwater County","state":"Wyoming","id":3138},
{"county":"Teton County","state":"Wyoming","id":3139},
{"county":"Uinta County","state":"Wyoming","id":3140},
{"county":"Washakie County","state":"Wyoming","id":3141},
{"county":"Weston County","state":"Wyoming","id":3142}]