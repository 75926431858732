import structuredClone from '@ungap/structured-clone';

export function addCity(item, activeFilterValues) {
    let newCitiesArray = addToArrayWithoutDuplication(item.cityState, activeFilterValues.cities);
    let newFilterValues = structuredClone(activeFilterValues);
    newFilterValues.cities = newCitiesArray;
    return newFilterValues;
}

export function addCounty(item, activeFilterValues) {
    let newCountiesArray = addToArrayWithoutDuplication(item.county, activeFilterValues.counties);
    let newFilterValues = structuredClone(activeFilterValues);
    newFilterValues.counties = newCountiesArray;
    return newFilterValues;
}

export function addInstitution(item, activeFilterValues) {
    let newInstitutionsArray = addToArrayWithoutDuplication(item.institution, activeFilterValues.institutions);
    let newFilterValues = structuredClone(activeFilterValues);
    newFilterValues.institutions = newInstitutionsArray;
    return newFilterValues;
}

export function addToArrayWithoutDuplication(item, filterArray) {
    let newFilterArray = structuredClone(filterArray);
    if (!newFilterArray.includes(item)){
        newFilterArray.push(item);
    }
    return newFilterArray;
}