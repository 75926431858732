import { StatesFilters } from '../sidebarComponents/statesFilters/StatesFilters';
import { CitiesFilters } from '../sidebarComponents/citiesFilters/CitiesFilters';
import { UniversityFilters } from '../sidebarComponents/universityFilters/UniversityFilters';
import { CountiesFilters } from '../sidebarComponents/countyFilters/CountyFilters';
import { VetsourceFilters } from '../sidebarComponents/vetsourceFilters/VetsourceFilters';
import { SortOrderFilters } from '../sidebarComponents/sortOrderFilters/SortOrderFilters';
import { DateFilters } from '../sidebarComponents/dateFilters/DateFilters';
import './sidebar.css';

export function SideBar(props){

    let filtersComponent;

    switch(props.description) {
        case "States":
            filtersComponent = <StatesFilters activeFilterValues = {props.activeFilterValues} setActiveFilterValues = {props.setActiveFilterValues}/>
            break;
        case "Cities":
            filtersComponent = <CitiesFilters activeFilterValues = {props.activeFilterValues} setActiveFilterValues = {props.setActiveFilterValues}/>
            break;
        case "University":
            filtersComponent = <UniversityFilters activeFilterValues = {props.activeFilterValues} setActiveFilterValues = {props.setActiveFilterValues}/>
            break;
        case "County":
            filtersComponent = <CountiesFilters activeFilterValues = {props.activeFilterValues} setActiveFilterValues = {props.setActiveFilterValues}/>
            break;
        case "Vetsource":
            filtersComponent = <VetsourceFilters activeFilterValues = {props.activeFilterValues} setActiveFilterValues = {props.setActiveFilterValues}/>
            break;
        case "Sort":
            filtersComponent = <SortOrderFilters activeFilterValues = {props.activeFilterValues} setActiveFilterValues = {props.setActiveFilterValues}/>
            break;
        case "Date":
            filtersComponent = <DateFilters activeFilterValues = {props.activeFilterValues} setActiveFilterValues = {props.setActiveFilterValues}/>
            break;
        default:
          console.log("Unrecognized filter type");
      }

    return(
        <div id="sideBarFilterContainerId" className="sideBarFilterContainer closeSideBar">
            <div id="sideBarFiltersId" className="sideBarFilters"> 
                <button onClick={closeSideBar} id="closeBtn" className="closeTopFilter"> X </button>
                <div className="filtersComponent">
                    {filtersComponent}
                </div>
            </div>
        </div>
    );
}

function closeSideBar(){
    document.getElementById("sideBarFilterContainerId").classList.add("closeSideBar");
}