import '../../sidebar/sidebar.css';
import { vetsources } from '../../../../util/data/vetsources';

export function VetsourceFilters(props){
    const toggleFilter = (checkboxId) => {
        console.log("Clicked");
        console.log(document.getElementById(checkboxId).checked);
        //TODO: modern js has a better way to deep copy arrays, need to find it again
        let newFilterValues = JSON.parse(JSON.stringify(props.activeFilterValues));
        const checkboxText = document.getElementById(checkboxId).parentElement.innerText;
        if (document.getElementById(checkboxId).checked) {
            newFilterValues.vetsources.push(checkboxText);
        }else{
            const indexToRemove = props.activeFilterValues.vetsources.indexOf(checkboxText);
            newFilterValues.vetsources.splice(indexToRemove, 1);
        }
        props.setActiveFilterValues(newFilterValues);
    }

    const isFilterApplied = (filterName) => {
        if (props.activeFilterValues.vetsources.includes(filterName)){
            return true;
        }
        return false;
   }

   const vetsourceKeys = Object.keys(vetsources);
   const filterVetsourceKeys = vetsourceKeys.map((filterValue) =>
   <label key = {filterValue} className='container'> {filterValue} 
        <input
            type="checkbox"
            onChange={() => toggleFilter(filterValue + "checkbox")}
            id= {filterValue + "checkbox"}
            checked={isFilterApplied(filterValue)}
        />
        <span className="checkmark"></span>
    </label>
    );

    return(
        <>
        {filterVetsourceKeys}
        </>
    )
}