import './About.css';

function About() {
    return (
        <>
            
                <div className='SVA'>
                    <h1>Student Veterans of America</h1>
                </div>
                <div className='SVAbanner'>
                    <img src= 'https://veterans.wustl.edu/files/2017/11/SVA-28whwi9.jpg' alt="SVA Banner"/>
                </div>
                <div>
                    <h4>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum eu velit dapibus tortor mollis vestibulum. Duis at quam quam. Donec sit amet ex dignissim, euismod sem in, porta nunc. Cras rutrum vel nunc eget mollis. Sed quis odio eleifend, dictum elit ac, ullamcorper arcu. Suspendisse porta turpis mauris, ut tincidunt erat condimentum sed. Vivamus tristique venenatis cursus. Cras et interdum purus. Vivamus placerat, odio sit amet dapibus dignissim, nisl risus iaculis libero, at mollis felis magna ac neque. <br/><br/>

                    Integer sit amet nisi ac sem faucibus dapibus. Morbi venenatis sapien dui, sit amet tincidunt tellus aliquet vel. Sed faucibus nibh ac tincidunt venenatis. Curabitur id mi auctor, commodo tellus et, venenatis eros. Vivamus egestas consequat nibh lobortis elementum. Pellentesque nulla libero, sollicitudin nec euismod vel, posuere vitae massa. Integer eleifend dolor pretium urna pulvinar consequat.<br/><br/>

                    Nullam mauris neque, tincidunt ac hendrerit vitae, mollis auctor nisi. Nunc vestibulum augue sit amet vehicula lacinia. Curabitur enim lectus, accumsan sed ex id, molestie luctus purus. Cras convallis diam ut magna bibendum malesuada vitae quis dolor. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Praesent convallis sapien enim, at tempor ligula porta id. Nunc aliquam vitae ex et cursus. Fusce pulvinar orci urna, et semper nulla porttitor ac. Donec arcu orci, bibendum id aliquet et, placerat in nisi. Nunc sollicitudin justo non justo tristique tempus. Morbi eu metus egestas felis bibendum vehicula sit amet a purus.<br/><br/>

                    Proin at magna id orci tempor mattis. Fusce faucibus odio vel cursus fringilla. Etiam fringilla velit vel justo ultricies feugiat. Duis quis volutpat justo, in malesuada neque. Aenean in gravida leo, at blandit quam. Maecenas et luctus ante. Nulla facilisis metus in felis accumsan feugiat.<br/><br/>

                    Nunc arcu dolor, luctus ut aliquam quis, vestibulum id metus. Sed at nunc sit amet eros lacinia placerat at in leo. Maecenas quis cursus nibh. Sed scelerisque lobortis elementum. Ut faucibus et felis vitae elementum. Praesent sit amet elementum enim. Donec egestas auctor tortor sed interdum. Interdum et malesuada fames ac ante ipsum primis in faucibus. Donec vitae ante urna.<br/><br/>
                        
                    </h4>
                </div>
        </>        
    );
  }

export default About;
