import {Vetsource} from '../../components/vetsource/Vetsource'
import {Filterbar} from '../../components/filtering/filterbar/Filterbar'
import { useState, useEffect } from 'react';
import './search.css';
import * as sortOrderConstants from '../../components/filtering/filterbar/fileWithConstants';
import { useQueryParams, StringParam, NumberParam, DelimitedArrayParam } from 'use-query-params';

// export const URLBarFilters = (filterName, key, newFilters, searchParams, setSearchParams) => {
//     let isFilterNamePresent = false;
//     let currentFilters = searchParams.get(key)?.split(',');
//     if (currentFilters != null) {
//         currentFilters.forEach((value) => {
//         if (filterName !== value){
//             newFilters.push(value);
//         }
//         else {isFilterNamePresent = true}
//         });
//     }

//     if (isFilterNamePresent !== true){
//         newFilters.push(filterName);
//     }

//     editFilter(newFilters.length, key, newFilters, searchParams);
//     setSearchParams(searchParams);
// }

// export const editFilter = (newFilterLength, key, newFilters, searchParams) => {
//     if (newFilterLength === 0){
//         searchParams.delete(key);
//     }else{
//         searchParams.set(key, newFilters);
//     }
// }

// export const pageURL = (page, searchParams, setSearchParams) => {
//     let currentFilters = searchParams.toString();
//     let pageInfo = page.substring(5);
//     if (pageInfo.includes("size")){
//         let pageSize = pageInfo.substring(4);
//         let currentPageSize = searchParams.get('size');
//         if ((currentFilters.includes("size")) && (pageSize.localeCompare(currentPageSize) === 0)){
//             searchParams.delete('size');
//         }else {
//             searchParams.set('size',pageSize);
//         }
//     }
//     else if (pageInfo.includes("number")){
//         let pageNumber = pageInfo.substring(6);
//         let currentNumber = searchParams.get('number');
//         if ((currentFilters.includes("number")) && (pageNumber.localeCompare(currentNumber) === 0)){
//             searchParams.delete('number');
//         }else {
//             searchParams.set('number',pageNumber);
//         }
//     }
//     setSearchParams(searchParams);
// }

// export const sortData = (value, searchParams, setSearchParams) => {
//     searchParams.set('sort', value);
//     setSearchParams(searchParams);
// }

// export const dateURLParams = (date, searchParams, setSearchParams) => {
//     let currentFilters = searchParams.toString();
//     if (date.includes("startDate")){
//         let currentDate = searchParams.get('startDate');
//         let newDate = date.substring(9);
//         if (currentDate !== null){
//             if ((currentFilters.includes('startDate'))  && (!currentDate.localeCompare(newDate))){
//                 searchParams.delete('startDate');
//             }else{
//                 searchParams.set('startDate', newDate);
//             }
//         }else{
//             searchParams.set('startDate', newDate);
//         }
//     }
//     else if (date.includes("endDate")){
//         let currentDate = searchParams.get('endDate');
//         let newDate = date.substring(7);
//         if (currentDate !== null){
//             if ((currentFilters.includes('endDate')) && (!currentDate.localeCompare(newDate))){
//                 searchParams.delete('endDate');
//             }else{
//                 searchParams.set('endDate', newDate);
//             }
//         }else{
//             searchParams.set('endDate', newDate);
//         }
//     }
//     setSearchParams(searchParams);
// }

function Search() {
    
    // const [searchParams, setSearchParams] = useSearchParams(); // For URL
    // const [vetsources, setVetsources] = useQueryParam('vetsources', StringParam);
    // const [states, setStates] = useQueryParam('states', DelimitedArrayParam);
    // const [cities, setCities] = useQueryParams([], DelimitedArrayParam);
    // const [counties, setCounties] = useQueryParams([], DelimitedArrayParam);
    // const [institutions, setInstitutions] = useQueryParams([], DelimitedArrayParam);
    // const [sortOrder, setSortOrder] = useQueryParams(undefined, StringParam);
    // const [startDate, setStartDate] = useQueryParams(undefined, StringParam);
    // const [endDate, setEndDate] = useQueryParams(undefined, StringParam);
    // const [pageSize, setPageSize] = useQueryParams(undefined, NumberParam);
    // const [pageNumber, setPageNumber] = useQueryParams(undefined, NumberParam);

    const [query, setQuery] = useQueryParams({
        vetsources: DelimitedArrayParam,
        states: DelimitedArrayParam,
        cities: DelimitedArrayParam,
        counties: DelimitedArrayParam,
        institutions: DelimitedArrayParam,
        sortOrder: StringParam,
        startDate: StringParam,
        endDate: StringParam,
        pageSize: NumberParam,
        pageNumber: NumberParam
    });

    console.log(query.states);
    const [activeFilterValues, setActiveFilterValues] = useState(
        {
            vetsources: query.vetsources === undefined ? [] : query.vetsources,
            states: query.states === undefined ? [] : query.states,
            cities: query.cities === undefined ? [] : query.cities,
            counties: query.counties === undefined ? [] : query.counties,
            institutions: query.institutions === undefined ? [] : query.institutions,
            sortOrder: query.sortOrder === undefined ? sortOrderConstants.SORTORDER_RECENTLY_UPDATED : query.sortOrder,
            startDate: query.startDate === undefined ? '' : query.startDate,
            endDate: query.endDate === undefined ? '' : query.endDate,
            pageSize: query.pageSize === undefined ? 20 : query.pageSize,
            pageNumber: query.pageNumber === undefined ? 1 : query.pageNumber,
        }
    );

    console.log(activeFilterValues);
    
    useEffect(() => {
        setQuery({
            vetsources: activeFilterValues.vetsources.length === 0 ? undefined : activeFilterValues.vetsources,
            states: activeFilterValues.states.length === 0 ? undefined : activeFilterValues.states,
            cities: activeFilterValues.cities.length === 0 ? undefined : activeFilterValues.cities,
            counties: activeFilterValues.counties.length === 0 ? undefined : activeFilterValues.counties,
            institutions: activeFilterValues.institutions.length === 0 ? undefined : activeFilterValues.institutions,
            sortOrder: activeFilterValues.sortOrder.length === 0 ? undefined : activeFilterValues.sortOrder,
            startDate: activeFilterValues.startDate.length === 0 ? undefined : activeFilterValues.startDate,
            endDate: activeFilterValues.endDate.length === 0 ? undefined : activeFilterValues.endDate,
            pageSize: activeFilterValues.pageSize,
            pageNumber: activeFilterValues.pageNumber
        },
        'replaceIn'
        );

    }, [activeFilterValues, query, setQuery]);

    console.log("_________activeFilterValues:");
    console.log(activeFilterValues);

    return (
        <>
            <Filterbar 
                activeFilterValues={activeFilterValues}
                setActiveFilterValues={setActiveFilterValues}
            />

            <div className="vetsources-container">
                <Vetsource 
                    title="Some Unusually Long Title To Test Length Limits" 
                    address="12399 SE Really Long Street Name for Test Purposes Trwy NW Unit 1" 
                    date="08/27/2022"
                    description="Some description for this event that has a realistic length to it except actually this description is overly long and even though we probably won't allow the description to be so huge it's going to be anyways for testing purposes."
                    picture={"https://images.unsplash.com/photo-1480714378408-67cf0d13bc1b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxjb2xsZWN0aW9uLXBhZ2V8MXwxNjYzMTR8fGVufDB8fHx8&w=1000&q=80"}
                    vetsourceType="Event"
                />
                <Vetsource 
                    title="Some Title with a realistic length and such" 
                    address="5555 E Somewhere St" 
                    date="08/27/2022"
                    description="Some description for this event that has a realistic length to it, it describes the event in some detail and encourages the reader to click the item to learn more."
                    picture={"https://laney.edu/veteran_affairs/wp-content/uploads/sites/147/2020/08/new-website-veterans-day-1.jpg"}
                    vetsourceType="Resource"
                />
                <Vetsource 
                    title="Some Title" 
                    address="5555 E Somewhere St" 
                    date="08/27/2022"
                    description="Some description for this event that has a realistic length to it"
                    picture={"https://images.squarespace-cdn.com/content/6114c313c704e77895eae698/1629256490529-T7JWMCQ98PYOOWYSM3ZJ/homeaid-san-deigo-hawthorne-veteran-and-family-resource-center.jpg?format=1500w&content-type=image%2Fjpeg"}
                    vetsourceType="Facility"
                />
                <Vetsource 
                    title="Some Unusually Long Title To Test Length Limits" 
                    address="12399 SE Really Long Street Name for Test Purposes Trwy NW Unit 1" 
                    date="08/27/2022"
                    description="Some description for this event that has a realistic length to it except actually this description is overly long and even though we probably won't allow the description to be so huge it's going to be anyways for testing purposes."
                    picture={"https://images.unsplash.com/photo-1480714378408-67cf0d13bc1b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxjb2xsZWN0aW9uLXBhZ2V8MXwxNjYzMTR8fGVufDB8fHx8&w=1000&q=80"}
                    vetsourceType="Event"
                />
                <Vetsource 
                    title="Some Title with a realistic length and such" 
                    address="5555 E Somewhere St" 
                    date="08/27/2022"
                    description="Some description for this event that has a realistic length to it, it describes the event in some detail and encourages the reader to click the item to learn more."
                    picture={"https://laney.edu/veteran_affairs/wp-content/uploads/sites/147/2020/08/new-website-veterans-day-1.jpg"}
                    vetsourceType="Resource"
                />
                <Vetsource 
                    title="Some Title" 
                    address="5555 E Somewhere St" 
                    date="08/27/2022"
                    description="Some description for this event that has a realistic length to it"
                    picture={"https://images.squarespace-cdn.com/content/6114c313c704e77895eae698/1629256490529-T7JWMCQ98PYOOWYSM3ZJ/homeaid-san-deigo-hawthorne-veteran-and-family-resource-center.jpg?format=1500w&content-type=image%2Fjpeg"}
                    vetsourceType="Facility"
                />
                <Vetsource 
                    title="Some Unusually Long Title To Test Length Limits" 
                    address="12399 SE Really Long Street Name for Test Purposes Trwy NW Unit 1" 
                    date="08/27/2022"
                    description="Some description for this event that has a realistic length to it except actually this description is overly long and even though we probably won't allow the description to be so huge it's going to be anyways for testing purposes."
                    picture={"https://images.unsplash.com/photo-1480714378408-67cf0d13bc1b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxjb2xsZWN0aW9uLXBhZ2V8MXwxNjYzMTR8fGVufDB8fHx8&w=1000&q=80"}
                    vetsourceType="Event"
                />
                <Vetsource 
                    title="Some Title with a realistic length and such" 
                    address="5555 E Somewhere St" 
                    date="08/27/2022"
                    description="Some description for this event that has a realistic length to it, it describes the event in some detail and encourages the reader to click the item to learn more."
                    picture={"https://laney.edu/veteran_affairs/wp-content/uploads/sites/147/2020/08/new-website-veterans-day-1.jpg"}
                    vetsourceType="Resource"
                />
                <Vetsource 
                    title="Some Title" 
                    address="5555 E Somewhere St" 
                    date="08/27/2022"
                    description="Some description for this event that has a realistic length to it"
                    picture={"https://images.squarespace-cdn.com/content/6114c313c704e77895eae698/1629256490529-T7JWMCQ98PYOOWYSM3ZJ/homeaid-san-deigo-hawthorne-veteran-and-family-resource-center.jpg?format=1500w&content-type=image%2Fjpeg"}
                    vetsourceType="Facility"
                />
            </div>
        </>        
    );
  }
  
  export default Search;